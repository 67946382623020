@import 'base/r_reset';

@import 'abstracts/r_variables';
@import 'abstracts/r_mixins';

@import 'components/r_animations';
@import 'base/r_base';
@import 'layout/r_header';
@import 'layout/r_footer';

@import 'libs/zebra-datepicker.scss';
@import 'libs/croppie/croppie.css';
@import 'libs/_jquery-ui.scss';
@import 'libs/_select2.scss';

@import 'components/_r_components';
@import 'components/r_filter-list';
@import 'components/r_quick-filter';
@import 'components/r_forms';
@import 'components/r_svg-sizes';
@import 'components/r_tabular-data';
@import 'components/r_mobile-app-banner';



// Games
@import 'components/r_memory-game';
// end Games

@import 'r_main';
@import 'abstracts/r_helpers';

// Pages
@import 'pages/r_onboarding';
@import 'pages/r_friends';
@import 'pages/r_jobs';
@import 'pages/r_challenges';
@import 'pages/r_messages';
@import 'pages/r_shop';
@import 'pages/r_redesign-homepage';
@import 'pages/r_landings';
@import 'pages/r_candidate-profile';

@import 'components/candidate-gamification';

// Redesign
@import 'redesign';