// Challanges and academies sections

.r_challenges {
	&__visual {
		margin-bottom: 65px;
		display: flex;
		align-items: center;
		padding: 27px 90px 27px 60px;
		justify-content: space-between;

		font-size: 1.5rem;
		line-height: 2rem;

		@include mq($tablet) {
			padding: 15px 40px 15px 30px;
			font-size: 1.25rem;
		}

		@include mq(767) {
			flex-direction: column;
			padding: 20px;

			&--graphic {
				order: -1;
			}
		}

		h1 {
			font-size: 2.5rem;
			line-height: 1.23;

			.bc {
				display: inline;
			}
		}

		&--cnt {
			max-width: 520px;
		}

		&--graphic svg {
			width: 119px;
			height: 220px;

			&.recommender {
				width: 200px;
			}

			&.resume-upload {
				width: 200px;
			}

			&.empty {
				width: 206px;
				height: 206px;
			}

			@include mq($tablet) {
				width: 60px;
				height: 110px;

				&.recommender {
					width: 100px;
				}

				&.resume-upload {
					width: 150px;
				}

				&.empty {
					width: 103px;
					height: 103px;
				}
			}
		}
	}

	section {
		width: calc(100% - 245px);
	}

	&__cards {
		&:after {
			content: '';
			width: calc((100% - 40px) / 3);
		}

		&.full-row {
			margin-bottom: 15px;

			.r_challenges__card {
				width: calc((100% - 60px) / 4);
				margin-right: 20px;

				&:nth-child(4n) {
					margin-right: 0;
				}

				@include mq($tablet) {

					width: calc((100% - 40px) / 3);



					&:nth-child(4n) {
						margin-right: 20px;
					}

					&:nth-child(3n) {
						margin-right: 0;
					}

				}
				@include mq(767) {
					width: 100%;
					margin-right: 0;

					&:nth-child(4n) {
						margin-right: 0;
					}
				}
			}
		}
	}

	&__card {
		background-color: #ffffff;
		width: calc((100% - 40px) / 3);
		min-height: 350px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 20px;

		@include mq($tablet) {
			width: calc((100% - 20px) / 2);
		}
		@include mq(767) {
			width: 100%;
			height: auto;
		}

		&:hover {
			filter: drop-shadow(0 0 3px rgba($bc2, .3));
		}

		&-header {
			height: 45px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&-points {
			width: 100px;
			height: 45px;
			padding-left: 18px;
			font-size: 1.25rem;
			line-height: 45px;
			border-bottom: 1px solid #D9DEE3;
		}

		&-tag {
			padding: 0 20px;
			height: 18px;
			border-radius: 10px 0 0 10px;
			color: #fff;
			line-height: 18px;
			font-size: 10px;
			font-weight: bold;
			text-transform: uppercase;
			white-space: nowrap;
			max-width: 120px;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&-footer {
			height: 125px;
			position: relative;
		}

		&-body {
			flex-grow: 1;
			margin-bottom: auto;
			margin-top: 0;
			padding: 25px 20px;
			font-size: 1.625rem;
			font-weight: bold;

			@include mq($tablet) {
				font-size: 1.25rem;
			}
		}

		&-cnt {
			padding: 25px 20px;
		}

		&-title {
			font-weight: bold;
			font-size: 1rem !important;
			line-height: 1.25rem !important;
			display: block;
			margin-bottom: 1rem !important;
		}
		&-desc {
			font-size: 14px;
			line-height: 17px;
			color: #8090a1;
		}

		&-time {
			font-size: 14px;
			padding: 0 0 20px 18px;
			display: flex;
			align-items: center;
			height: 46px;

			svg {
				width: 19px;
				height: 20px;
				margin-right: 7px;
				fill: #bfc8d0;
				stroke: #bfc8d0;
			}

			small {
				display: block;
				font-size: 8px;
				color: #bfc8d0;
			}
		}

		&-rating {
			width: 66px;
			height: 30px;
			background-color: #F2F4F6;
			border-radius: 15px 0 0 15px;
			margin-left: auto;
			margin-right: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-right: 10px;
			margin-bottom: 16px;

			svg {
				width: 15px;
				height: 15px;
				fill: $bc1;
				margin-left: 7px;

			}
		}

		&-company {
			border-top: 1px solid #D9DEE3;
			padding-left: 20px;
			height: 80px;
			display: flex;
			align-items: center;
		}

		&-company-logo {
			min-width: 40px;
			width: 40px;
			height: 40px;
			margin-right: 10px;
		}

		&-saved-ribbon {
			display: block;
			width: 14px;
			height: 22px;
			position: absolute;
			right: 10px;
			top: 46px;
			fill: none;
			stroke: #d9dee3;
		}

		&.saved {
			.r_challenges__card-saved-ribbon {
				stroke: $bc1;
				fill: $bc1;
			}

			.r_challenges__card-company {
				border-color: $bc1;
			}
		}
	}

	&__section-title {
		padding-left: 10px;
		border-left: 5px solid $bc1;
		line-height: 18px;
		font-size: 9px;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 17px;

		@include mq($tablet) {
			margin-left: 20px;
			margin-right: 20px;
		}
	}

	.select2-container--default {
		.select2-selection--single {
			height: auto;
			padding-left: 0;

			.select2-selection__rendered {
				line-height: 1.23;
				color: #8090A1;
				font-size: 12px;
				padding-right: 0;
			}
		}

		.select2-dropdown {
			background-color: #F2F4F6EF;
			font-size: 12px;
			padding: 0;
			border-radius: 0;
			min-width: 86px;

			transform: translateY(10px);

			&:before {
				content: '';
				border: 10px solid transparent;
				border-bottom-color: #F2F4F6EF;
				height: 0;
				width: 0;
				position: absolute;
				bottom: 100%;
				left: 50%;
				margin-left: -5px;
			}

			.select2-results__option {
				color: #8090a1;

				&--highlighted, &[aria-selected=true] {
					background-color: transparent;
					color: $bc1;
				}
			}
		}

		&.select2-container--open .select2-dropdown {
			filter: drop-shadow(0 0 1px rgba($bc2, .5));
		}
	}

	.select2-search--dropdown {
		display: none;
	}

	&__finished {
		text-align: center;
		padding: 40px 0;
		font-size: 1.25rem;

		p {
			margin-bottom: 2rem;
		}

		.btn {
			min-width: 174px;
			margin: 5px;
		}
	}

	&__invitation {
		background-color: #ffffff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px 20px;
		margin-bottom: 30px;

		@include mq($tablet) {
			flex-direction: column;
			margin-left: -20px;
			margin-right: -20px;
		}

		&--graphic {
			padding: 0 40px 0 60px;
			transform: translateY(-40px);

			@include mq($tablet) {
				padding: 0 30px;
			}

			svg {
				width: 115px;
				height: 214px;
			}
		}

		&--form {
			width: 260px;
			padding: 20px 0 0;

			@include mq($tablet) {
				width: 100%;
			}
		}

		&--cnt {
			flex-grow: 1;
			font-size: 1.25rem;

			@include mq($tablet) {
				text-align: center;
			}
		}

		input[type='text'], input[type='email'], textarea {
			background-color: #F2F4F6;
			border: 0;
		}
		textarea {
			min-height: 39px;
		}
		.form__group {
			margin-bottom: 10px;
		}
		.btn {
			width: 100%;
		}
		.share-on-social {
			padding-top: 15px;
			margin-top: 20px;
			border-top: 1px solid #D9DEE3;
			font-size: 14px;
			color: #8090a1;

			&__links {
				display: flex;
				align-items: center;
				justify-content: space-between;

				a {
					margin: 0;
				}

				li {
					padding: 0 10px;

					&:last-child {
						padding-right: 0;
					}

				}
			}
		}
	}
}

.r_challenge {

	.r_main_container {
		flex-direction: column;
	}
	aside {
		background-color: #fff;
		text-align: center;
		display: flex;
		flex-direction: column;

		&.auto {
			margin-bottom: auto;
			padding-bottom: 20px;
		}

		&.hidden {
			opacity: 0;
			visibility: hidden;
			animation: none;

			@include mq(767) {
				display: none;
			}
		}
	}

	&__saves {
		position: absolute;
		right: 7px;
		top: 7px;


		label[for] {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 2px solid #BFC8D0;

			&:after {
				content: '+';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				font-weight: bold;
				color: #bfc8d0;
				@extend %transition;
			}
		}

		input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;

			&:checked + label[for] {
				border-color: $bc1;

				svg {
					opacity: 1;
				}

				&:after {
					opacity: 0;
				}
			}
		}
		svg {
			fill: $bc1;
			width: 10px;
			height: 7px;
			opacity: 0;
		}
	}

	&__title {
		font-size: 1.875rem;
		font-weight: 600;
		flex-grow: 1;

		//@include mq(767) {
		//
		//	~ .btn {
		//		display: none;
		//	}
		//}
	}

	&__tag {
		color: #fff;
	}

	&__company {
		color: #8090A1;

		.name {
			font-weight: 700;
		}
	}

	&__points {
		display: flex;
		justify-content: center;

		.cell {
			padding: 0 30px;

			&:not(:first-child) {
				border-left: 1px solid #D9DEE3;
			}
		}
	}

	.rowEssentials, .r_cards {
		.r_card {
			width: calc((100% - 20px ) / 2);
			min-width: calc((100% - 20px ) / 2);
			flex-grow: 0;
			padding: 0;

			&:nth-last-child(2) {
				margin-bottom: 0;
			}

			@include mq(767) {
				width: 100%;
				min-width: 100%;

				&:nth-last-child(2) {
					margin-bottom: 20px;
				}
			}
		}
	}

	.rowEssentials {
		aside {
			min-height: 300px;
			padding: 20px;
		}
		.r_card {
			padding-left: 20px;
			padding-right: 20px;
		}
		@include mq(767) {
			.r_card {
				padding: 20px;
			}
			aside {
				min-height: 1px;
			}

			.backLink {
				top: 35px;
			}
		}
	}

	.value {
		font-size: 1.875rem;
		font-weight: bold;
	}

	.legend {
		color: #BFC8D0;
		transform: translateY(15px);
		font-size: 14px;
		text-align: center;
		user-select: none;

		&:only-child {
			transform: none;
		}

		@include mq(767) {
			transform: none;
		}
	}

	.r_card-title {
		margin-bottom: 15px;
	}

	.r_cards {
		margin-bottom: 20px;

		.files {
			width: 100%;
			margin-top: -10px;
		}
	}

	.rowMoreDetails {
		.r_card {
			padding: 0 20px;

			&__cnt {
				padding: 0 0 20px;
				justify-content: flex-start;
			}
		}
	}

	&__notlogged-options {
		a:not(.btn) {
			text-decoration: underline;
		}
		.cell {
			padding: 15px 10px;

			&:first-child {
				flex-grow: 1;
				border-bottom: 1px solid $graybg;
			}
		}

		@include mq(767) {
			order: 2;
		}
	}

	.r_card.challenge-controls {
		display: none;

		.btn {
			width: 100%;
		}

		@include mq(767) {
			display: block;
			padding: 20px;
		}
	}
}

.r_answer {

	&__timer, &__steps {
		min-height: 53px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 10px 20px;
		font-size: 1rem;
		background-color: #ffffff;
		text-transform: uppercase;
	}
	&__steps {
		.value {
			color: #BFC8D0;
			margin-left: 5px;
		}
	}
	&__timer {
		color: #fff;
		background-color: $bc1;
		justify-content: center;

		svg {
			width: 15px;
			height: 17px;
			margin-right: 10px;
			fill: #fff;
		}
		path {
			fill: #fff;
		}
	}

	&__header {
		width: 100%;
		min-width: 100%;
		background-color: #fff;
		margin-bottom: 20px;
		padding: 0 20px;

		@include mq(767) {
			flex-direction: column !important;
			text-align: center;
			padding: 10px 15px;
		}
	}
	&__title {
		font-size: 1.875rem;
		align-self: center;
		padding: 20px 0;

		@include mq($tablet) {
			font-size: 1.35rem;
		}
	}
	&__company {
		padding: 20px 0 20px 35px;
		margin-left: 15px;
		border-left: 1px solid #D9DEE3;
		color: #8090A1;
		min-width: 210px;

		@include mq(767) {
			padding: 15px;
			border-left: 0;
			border-top: 1px solid #D9DEE3;
			margin-top: 20px;
			margin-left: 0;
		}
	}
}

.r_challenge-quiz {
	background-color: #fff;
	margin-bottom: 20px;

	&__header, &__body, &__footer {
		padding: 20px;
		user-select: none;
	}

	&__header {
		font-size: 1.375rem;
		border-bottom: 1px solid #D9DEE3;

		&:only-child {
			border-bottom: 0;
		}

		@include mq($tablet) {
			font-size: 1rem;
		}
	}

	&__footer {
		font-size: .9375rem;
		border-top: 1px solid #D9DEE3;
		display: none;
	}

	fieldset {
		.form__group {
			position: relative;
		}
		input[type='radio'],
		input[type='checkbox'] {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
		}

		label[for] {
			display: block;
			background-color: #F2F4F6;
			border-radius: 18px;
			text-align: center;
			line-height: 1.23;
			padding: 10px 45px;
			@extend %transition;
			position: relative;

			&:after {
				content: '';
				width: 25px;
				height: 25px;
				position: absolute;
				right: 6px;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				@extend %transition;
			}

			@include mq(767) {
				padding: 10px;
				border-radius: 10px;
			}
		}

		input:checked + label {
			background-color: $bc2;
			color: #fff;
		}
		label:hover {
			background-color: rgba($bc2,.75);
			color: #fff;
		}
	}

	.qImgHolder{
		display:block;
		img{
			display: block;
			width: auto;
			max-width: 100%;
		}
	}

	textarea {
		background-color: #f2f4f6;
		border: 0;
	}

	.verification-message {
		padding: 5px 5px 5px 35px;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: 25px;
	}

	.verification-message.correct, fieldset label.correct:after {
		background-image: url(../../images/front/icon-verif-correct.svg);
	}
	.verification-message.wrong, fieldset label.wrong:after {
		background-image: url(../../images/front/icon-verif-wrong.svg);
	}
	.verification-message.partial, fieldset label.partial:after {
		background-image: url(../../images/front/icon-verif-partial.svg);
	}

	+ .next-step {
		display: none;
	}

	&.submitted {
		.verification {
			display: none;
		}
		.r_challenge-quiz__footer,
		+ .next-step {
			display: block;

			&.flex {
				display: flex;
			}
		}

		fieldset label[for] {
			pointer-events: none;

			&:after {
				opacity: 1;
			}
		}
	}

	.article {
		padding: 20px;
		margin-bottom: 0;

		img {
			margin-bottom: 35px;

			@include mq(767) {
				margin-bottom: 15px;
			}
		}
	}

	&__top {
		height: 54px;
		margin-bottom: 20px;
		background-color: #ffffff;
	}
	&__timer {
		background-color: $bc1;
		color: #fff;
		width: 30%;
		min-width: 30%;

		@include mq(767) {
			min-width: 40%;
		}

		svg {
			stroke: #fff;
		}
	}

	&__stage {
		padding-left: 20px;
		color: #bfc8d0;

		span {
			margin-right: 5px;
			color: $bc2;
		}
	}
}

.r_challenge-upload {
	display: flex;
	align-items: flex-start;

	@include mq(767) {
		flex-direction: column;
	}

	&__title {
		background-color: #ffffff;
		font-size: 1.375rem;
		padding: 20px;
		flex-grow: 1;

		@include mq($tablet) {
			font-size: 1rem;
		}

		a {
			color: $bc1;
		}
	}

	&__uploader {
		min-width: 220px;
		margin-left: 20px;
		padding: 0 0 20px;
		background-color: #ffffff;
		text-align: center;
		position: relative;

		@include mq(767) {
			width: 100%;
			margin-left: 0;
		}

		input[type='file'] {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
		}

		svg {
			width: 55px;
			height: 47px;
			fill: #BFC8D0;
			@extend %transition;
			display: inline-block;
		}

		small {
			display: block;
			padding-top: 25px;
		}

		label {
			color: #BFC8D0;
			font-size: 15px;
			margin-bottom: 40px;
			width: 100%;
			display: block;
			padding: 0 20px;
			@extend %transition;

			&:hover {
				color: $bc1;

				svg {
					fill: $bc1;
				}
			}
		}

		.dropzone-holder {
			margin: 0 0 45px;
			color: #8090A1;
			font-size: 14px;

			.dz-preview {
				border-bottom: 1px solid #D9DEE3;
				display: flex;
				justify-content: flex-end;
				padding: 10px 20px;
			}
			.dz-image, .dz-progress, .dz-error-message, .dz-size {
				display: none;
			}

			.dz-success-mark, .dz-error-mark {
				width: 20px;
				height: 20px;
				margin-left: 3px;
				display: none;

				svg {
					width: 20px;
					height: 20px;
				}
			}
			.dz-details {
				display: flex;
				margin-right: auto;
				margin-left: 0;
			}
			//.dz-remove {
			//	font-size: 0;
			//	text-indent: -999rem;
			//	width: 20px;
			//	height: 20px;
			//
			//}
		}
	}
}

.r_challenges__card:hover span:not(.r_challenges__card-body) {
	color: $bc2;

	.r_challenges__card-tag {
		color: #fff;
	}
}

.r_challenges__filter-list li:first-child {
	background-color: $bc1;
}

.r_challenges__filter-list li:first-child .the-title {
	color: #fff;
}

.r_course {
	.r_challenge-quiz:last-child, section {
		margin-bottom: 0;
	}

	.videoH {
		margin-bottom: 15px;
	}

	.vidList {

		&__ul {
			font-size: 0;
			padding-left: 0;
		}

		li {
			width: 108px;
			margin-bottom: 15px;
			margin-right: 15px;
			display: inline-block;
		}
		li:nth-child(6n-1) {
			margin-right: 0;

			@include mq($tablet) {
				margin-right: 15px;
			}
		}

		img, a {
			display: block;
		}

		a {
			width: 100%;
			height: 0;
			padding-top: 56.25%;
			position: relative;
			z-index: 1;
			overflow: hidden;
			cursor: pointer;
			opacity: .25;
			pointer-events: all;

			&.current {
				opacity: 1;
			}

			&:hover {
				opacity: 1;
			}

			img {
				position: absolute;
				pointer-events: none;
				top: 50%;
				left: 0;
				z-index: 1;
				width: 100%;
				transform: translateY(-50%);
			}
		}
	}
}
.videoPlayerHolder{
	position: relative;
	width:100%;
	min-height: 380px;
}
#ytPlayer, #cloudflarePlayer{
	position:absolute;
	width:100%;
	pointer-events: none;
	opacity:0;
	top: 0;
	left: 0;
	&.showPlayer{
		opacity: 1;
		pointer-events: all;
	}
}
#cloudflareQuestionsPlayer{
	display: block;
	width: 100%;
}
.r_course-specs {
	margin-right: 0;
	margin-left: auto;
	min-width: 200px;
	padding: 18px 0;

	&__row {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		padding: 3px 0;
	}
	&__cell {
		min-width: 40%;
		max-width: 40%;
		padding-left: 10px;
		text-align: left;

		&:first-child {
			min-width: 60%;
			max-width: 60%;
			text-align: right;
			color: #BFC8D0;
			padding-left: 0;
			padding-right: 10px;
		}
	}

	@include mq(767) {
		width: 100%;
		padding: 0;

		&__cell {
			min-width: 50%;
			width: 50%;

			&:first-child {
				min-width: 50%;
				max-width: 50%;
			}
		}
	}
}

.r_course-progress {
	margin-bottom: 20px;
	height: 5px;
	width: 100%;
	position: relative;
	z-index: 1;
	background-color: #D9DEE3;

	&__bar {
		background-color: $bc1;
		position: absolute;
		z-index: 1;
		height: 100%;
		left: 0;
		top: 0;
	}

	&__home, &__quiz {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: $bc1;
		position: absolute;
		z-index: 3;
		top: 50%;
		margin-top: -12px;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 11px;
			height: 11px;
			fill: #fff;
		}
	}

	&__home {
		left: -3px;
	}

	&__quiz {
		margin-left: -12px;
		background-color: #D9DEE3;

		&.done {
			background-color: $bc1;
		}

		svg {
			width: 13px;
			height: 10px;
		}
	}
}

.r_course-contents {
	background-color: #ffffff;

	.r_card-title {
		margin-left: 20px;
		margin-right: 20px;
		width: auto;
		transition: all .25s ease;

		&:not(.clipped-expanded) {
			margin-bottom: 0;
			border-bottom-color: transparent;
		}
	}

	li {
		margin-bottom: 15px;
		position: relative;
		z-index: 1;

		&.done {
			&:after {
				content: '';
				background: url('../../images/front/svg-sprite-source/r_check_red.svg');
				background-size: cover;
				width: 9px;
				height: 7px;
				position: absolute;
				z-index: 2;
				right: 100%;
				top: 5px;
				margin-right: 4px;
			}
		}

		> a {
			color: #8090A1;
		}

		&.active, &.done {
			> a {
				color: #0f233f;
			}
		}

		&.active {
			> a {
				font-weight: bold;
			}
		}
	}

	&__list {
		font-size: 13px;
		line-height: 17px;
		padding-left: 31px;
		padding-right: 20px;

		@extend %acc1;

		ul {
			padding-top: 10px;
			padding-left: 15px;
		}
	}

	.clipped-expanded ~ .r_course-contents__list {
		@extend %acc2;
		margin-bottom: 20px;
	}

	&__quiz-item {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			right: 100%;
			width: 31px;
			top: 0;
			height: 100%;
			background-color: #BFC8D0;
		}

		> a {
			background-color: #BFC8D0;
			color: #fff !important;
			padding: 5px 20px 5px 0;
			border-radius: 0 13px 13px 0;
			display: inline-block;
			line-height: 1.2;
			min-width: 70px;
		}

		&.done {
			&:before, > a {
				background-color: $bc1;
			}

			&:after {
				background-image: url('../../images/front/svg-sprite-source/r_check.svg') !important;
				top: 10px !important;
			}
		}
	}
}

.r_course-quiz {
	&-general {
		display: flex;
		align-items: stretch;
		justify-content: space-between;

		@include mq(767) {
			flex-direction: column;
		}

		svg {
			stroke: #bfc8d0;
		}

		&__right-col, &__left-col {
			background-color: #ffffff;
			padding: 20px;
		}

		&__right-col {
			min-width: 222px;
			order: 1;

			.duration svg {
				margin-right: 10px;
			}

			.btn {
				margin: 10px 0;
				width: 100%;
			}
		}

		&__left-col {
			min-width: calc(100% - 242px);
			max-width: calc(100% - 242px);

			@include mq(767) {
				max-width: 100%;
				min-width: 100%;
				margin-bottom: 20px;
			}
		}


	}

	.bb {
		position: relative;
		z-index: 1;
		padding-bottom: 20px;
		margin-bottom: 20px;

		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			width: 60px;
			height: 1px;
			background-color: #D9DEE3;
			bottom: 0;
			margin-left: -30px;
			left: 50%;
		}
	}

	.r_course-quiz__points {
		font-size: 2rem;

		small {
			font-size: 0.875rem;
			color: #bfc8d0;
			display: block;
			padding-top: 10px;
		}

		&.gray {
			color: #bfc8d0;
		}
	}

	.icon-quiz-finished {
		position: relative;
		z-index: 1;
		margin: 30px auto;

		.splash_svg {
			width: 58px;
			height: 58px;
		}
		.check_svg {
			fill: #fff;
			width: 23px;
			height: 15px;
			position: absolute;
			z-index: 2;
			top: 20px;
			left: 50%;
			transform: translateX(-50%);
		}
		.btn {
			pointer-events: none;
			transform: translateY(-50%);

			&:before {
				content: unset;
				display: none;
			}
			&:after {
				opacity: 1 !important;
			}
		}
	}
}

.r_course-finished {
	margin-bottom: 40px;


	@include mq(899) {

		.r_container {
			flex-direction: column;
		}
	}

	&__left-col {
		padding: 0 20px;
		background-color: #ffffff;
		min-width: calc(100% - 320px);
		max-width: 100%;
		margin-bottom: 20px;
	}

	.cell {
		text-align: center;
		background-color: #ffffff;
		padding: 10px;
		margin-bottom: 20px;
	}

	&__right-col {
		display: flex;
		flex-direction: column;
		max-width: 100%;
	}

	.add-review.main {
		flex-direction: column;
	}

	@include mqq(899) {

		.cell {
			height: calc(50% - 10px);
			margin-bottom: 0;
		}

		&__left-col {
			margin-bottom: 0;
		}

		&__right-col {
			display: flex;
			flex-direction: column;
			width: 300px;
			min-width: 300px;
			max-width: 100%;
			order: 1;
		}

		.add-review {
			&.main {
				flex-direction: row;
			}
			.form__group {
				margin-bottom: 0;
			}

			.rate-it {
				order: -1;
				margin-right: 20px;
			}

			textarea {
				min-height: 165px;
			}
		}
	}

	@include mqq(521) {
		.add-review {
			.col:not(.rate-it) {
				flex-grow: 1;
			}
		}
	}

	&-graphic {
		margin-top: -225px;
		margin-bottom: 30px;

		@include mq(767) {
			margin-top: -30px;
		}

		&-svgs {
			position: relative;
			z-index: 2;
			transform: translateY(25px);

			@include mq(999) {
				transform: translateY(15px);
			}
		}

		.congrats-ribbon {
			width: 72px;
			height: 70px;
			position: absolute;
			z-index: 2;
			top: 50%;
			left: 50%;
			transform: translate(-45%,-45%);
		}
		.btn {
			position: relative;
			z-index: 1;
			height: 74px;
			width: 300px;
			background-color: $bc2;
			margin: 0;
			border-radius: 37px;

			&:before {
				content: unset;
				display: none;
			}

			@include mq(999) {
				width: auto;
			}
		}
	}
}
