.more-options {

	position: relative;
	z-index: 1;

	&:hover {
		.more-options__drop {
			opacity: 1;
			visibility: visible;
			transform: translateY(20px);
		}
	}

	&__trigger {
		display: block;
		color: rgba($bc2, .25);
		font-size: 18px;
		font-weight: bold;
		line-height: 5px;
		cursor: pointer;
		position: relative;
		z-index: 1;
	}

	&__drop {
		position: absolute;
		z-index: 3;
		right: 2px;
		top: 0;
		filter: drop-shadow(0 0 6px rgba($bc2, .4));
		padding: 20px 20px;
		background-color: #fff;
		min-width: 150px;

		opacity: 0;
		visibility: hidden;
		transform: translateY(30px);
		transition: opacity 0.25s ease, transform 0.25s ease;

		&:before {
			content: '';
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-bottom-color: #fff;
			position: absolute;
			bottom: 100%;
			right: 20px;
		}

		li {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}