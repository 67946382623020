$font: 'Ubuntu', sans-serif;
$bc1: #e95b63; // brand color 1 - orange
$bc2: #0f233f; // brand color 2 - dark blue
$graybg: #f1f3f6; // background
$bc1grad: #ff7c81; // brand color 1 - gradient

$gray: #C0C7CF;

$buttonc1: #ff4d54;
$buttonc2: #ed737a;

$green: #5DAF25;
$yellow: #AF7C25;
$orange: #F59C20;

$filter-shadow: drop-shadow(0 0 3px rgba($bc2,.25));
$transition: all 0.25s ease-in-out;

$tablet: 999;


//

%gradient {
  background: -moz-linear-gradient(top, #F37E7B 0%, #F15F65 100%);
  background: -webkit-linear-gradient(top, #F37E7B 0%, #F15F65 100%);
  background: linear-gradient(to bottom, #F37E7B 0%, #F15F65 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F15F65', endColorstr='#F37E7B', GradientType=0);
  background-color: #F15F65;
}
