.clearfix {
    &:after,
    &:before {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}
.relative {
    position: relative;
}

.centred {
    text-align: center;
}

.display-none {
    display: none !important;
}

.flex {
    display: flex;

    // justify-content
    &.sb {
        justify-content: space-between;
    }
    &.sa {
        justify-content: space-around;
    }
    &.se {
        justify-content: space-evenly;
    }
    &.jcc {
        justify-content: center;
    }
    &.jcs {
        justify-content: flex-start;
    }
    &.jce {
        justify-content: flex-end;
    }

    // Align items
    &.aic {
        align-items: center;
    }
    &.aie {
        align-items: flex-end;
    }
    &.ais {
        align-items: flex-start;
    }
    &.aist {
        align-items: stretch;
    }
    // Direction
    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
    }

    &.fw {
        flex-wrap: wrap;
    }

    .right {
        margin-left: auto;
        margin-right: 0;
    }
}
.prev-flex-row {
    display: flex;
    flex-direction: row;
}
.prev-mr-0 {
    margin-right: 0 !important;
}
.mr-1 {
    margin-right: .25rem !important;
}
.mr-2 {
    margin-right: .5rem !important;
}
.mr-3 {
    margin-right: .75rem !important;
}
.prev-mr-4 {
    margin-right: 1rem !important;
}
.mr-5 {
    margin-right: 1.5rem !important;
}
.mr-6 {
    margin-right: 2rem !important;
}
.prev-mr-20 {
    margin-right: 20px;
}
.mr-25 {
    margin-right: 25px;
}
.mr-50 {
    margin-right: 50px;
}
.prev-mr-40 {
    margin-right: 40px;
}
.prev-mb-0 {
    margin-bottom: 0 !important;
}
.prev-mb-1 {
    margin-bottom: .25rem !important;
}
.prev-mb-2 {
    margin-bottom: .5rem !important;
}
.prev-mb-3 {
    margin-bottom: .75rem !important;
}
.prev-mb-4 {
    margin-bottom: 1rem !important;
}
.mb-5 {
    margin-bottom: 1.5rem !important;
}
.prev-mb-6 {
    margin-bottom: 2rem !important;
}
.prev-mb-10 {
    margin-bottom: 10px;
}
.prev-mb-20 {
    margin-bottom: 20px;
}
.prev-mb-30 {
    margin-bottom: 30px;
}
.prev-prev-mb-40 {
    margin-bottom: 40px;
}
.prev-mb-45 {
    margin-bottom: 45px;
}
.mb-50 {
    margin-bottom: 50px;
}
.prev-mb-60 {
    margin-bottom: 60px;
}
.mb-90 {
    margin-bottom: 60px;
}
.prev-mb-100 {
    margin-bottom: 100px;
}
.mt-0 {
    margin-top: 0 !important;
}
.mt-1 {
    margin-top: .25rem !important;
}
.prev-mt-2 {
    margin-top: .5rem !important;
}
.prev-mt-3 {
    margin-top: .75rem !important;
}
.prev-mt-4 {
    margin-top: 1rem !important;
}
.mt-5 {
    margin-top: 1.5rem !important;
}
.mt-6 {
    margin-top: 2rem !important;
}
.prev-mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.prev-mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.prev-mt-40 {
    margin-top: 40px;
}
.prev-prev-mt-20 {
    margin-top: 60px;
}
.mt-90 {
    margin-top: 60px;
}
.prev-mt-100 {
    margin-top: 100px;
}
.prev-ml-0 {
    margin-left: 0 !important;
}
.prev-ml-1 {
    margin-left: .25rem !important;
}
.ml-2 {
    margin-left: .5rem !important;
}
.ml-3 {
    margin-left: .75rem !important;
}
.prev-ml-4 {
    margin-left: 1rem !important;
}
.ml-5 {
    margin-left: 1.5rem !important;
}
.ml-6 {
    margin-left: 2rem !important;
}
.prev-ml-20 {
    margin-left: 20px;
}
.prev-ml-40 {
    margin-left: 40px;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pb-1 {
    padding-bottom: .25rem !important;
}
.pb-2 {
    padding-bottom: .5rem !important;
}
.prev-pb-3 {
    padding-bottom: .75rem !important;
}
.pb-4 {
    padding-bottom: 1rem !important;
}
.pb-5 {
    padding-bottom: 1.5rem !important;
}
.prev-pb-6 {
    padding-bottom: 2rem !important;
}
.prev-pb-10 {
    padding-bottom: 10px;
}
.prev-pt-0 {
    padding-top: 0 !important;
}
.prev-pt-1 {
    padding-top: .25rem !important;
}
.pt-2 {
    padding-top: .5rem !important;
}
.prev-pt-3 {
    padding-top: .75rem !important;
}
.pt-4 {
    padding-top: 1rem !important;
}
.pt-5 {
    padding-top: 1.5rem !important;
}
.pt-6 {
    padding-top: 2rem !important;
}
.prev-pt-10 {
    padding-top: 10px;
}
.pl-10 {
    padding-left: 10px;
}
.prev-pt-40 {
    padding-top: 40px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pl-0 {
    padding-left: 0 !important;
}
.pl-1 {
    padding-left: .25rem !important;
}
.prev-pl-2 {
    padding-left: .5rem !important;
}
.prev-pl-3 {
    padding-left: .75rem !important;
}
.pl-4 {
    padding-left: 1rem !important;
}
.pl-5 {
    padding-left: 1.5rem !important;
}
.prev-pl-6 {
    padding-left: 2rem !important;
}
.pr-0 {
    padding-right: 0 !important;
}
.pr-1 {
    padding-right: .25rem !important;
}
.pr-2 {
    padding-right: .5rem !important;
}
.pr-3 {
    padding-right: .75rem !important;
}
.pr-4 {
    padding-right: 1rem !important;
}
.pr-5 {
    padding-right: 1.5rem !important;
}
.prev-pr-6 {
    padding-right: 2rem !important;
}
.helper {
    position: relative;
}
.dib {
    display: inline-block;
}
.db {
    display: block;
}
.df {
    display: flex;
}
.va-m {
    vertical-align: middle;
}
.pos-rel {
    position: relative;
}
.hide {
    display: none;
}
.tal {
    text-align: left;
}
.tac {
    text-align: center;
}
.flex-h-center {
    display: flex;
    align-items: center;
}
.tar {
    text-align: right;
}

//////////////////////
///// LAYOUT HELPERS
//////////////////////
.d-inline-block {
    display: inline-block !important;
}
.d-flex {
    display: flex !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-space-between {
    justify-content: space-between !important;
}
.prev-overflow-visible {
    overflow: visible !important;
}

//////////////////////
///// COLOR HELPERS
//////////////////////
.color-danger, .hover-danger:hover {
    color: $bc1 !important;
}
.color-warning, .hover-warning:hover {
    color: $yellow !important;
}
.color-success, .hover-success:hover {
    color: $green !important;
}
.color-grey, .hover-grey:hover {
    color: rgba($bc2, .5) !important;
}
.color-greydark, .hover-greydark:hover {
    color: rgba($bc2, .75) !important;
}

//////////////////////
///// TEXT HELPERS
//////////////////////
.prev-text-left {
    text-align: center !important;
}
.text-smaller {
    font-size: .8125rem !important;
}
.text-small {
    font-size: .9rem !important;
}
.text-strong {
    font-weight: bold !important;
}
.overflow-auto {
    overflow: auto;
}
.text-decoration-none {
    text-decoration: none !important;
}
.font-size-75 {
    font-size: 75% !important;
}
.font-size-85 {
    font-size: 85% !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}