// Messages Page--
.r_messages-page {

	@include mq($tablet) {
		.r_main_container {
			flex-direction: column;
		}
		aside, section {
			width: 100%;
			min-width: 100%;
		}
	}

	.conversations-list {
		background-color: #ffffff;

		&__item {
			min-height: 90px;
			@extend %transition;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 20px;
			cursor: pointer;

			@include mq($tablet) {
				padding: 10px 20px;
				min-height: 1px;
			}

			&:not(:last-child) {
				border-bottom: 2px solid rgba($bc2,.15);
			}

			&:hover, &.selected {
				background-color: $bc2;
				color: #fff;
			}
		}

		.plus_svg {
			width: 30px;
			height: 30px;
			fill: rgba($bc2,.25);
		}

		&__icon {
			width: 50px;
			height: 50px;
			margin-right: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__new {
			font-weight: bold;
		}

		&__cnt {
			font-size: 1rem;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			max-width: 100%;
		}

		&__name {
			font-weight: bold;
		}

		&__info {
			max-width: calc(100% - 70px);
		}

		&__name, &__desc {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;
		}

		&__desc {
			opacity: .5;
		}

		&__avatar {
			min-width: 50px;
			width: 50px;
			height: 50px;
			margin-right: 20px;
			overflow: hidden;
			border-radius: 50%;
		}

		.clipped-handler {
			display: none;
			width: 100%;
			transform: none;
			color: #fff;
			background-color: $bc1;
			padding: 10px 20px;
			height: auto;
			position: relative;
			border-bottom: 0;
			justify-content: space-between;
			min-height: 1px;

			.toggleArr {
				transform: rotate(90deg);
				height: 100%;
				padding: 0;
				display: flex;

				svg {
					stroke: #fff;
				}
			}
		}

		@include mq($tablet) {
			.clipped-handler {
				display: flex;
			}

			&__item {
				display: none;
			}
			&__new {
				padding: 5px 20px;
				display: flex;
			}

			&.clipped-expanded {

				.conversations-list__item {
					display: flex;
				}

				.clipped-handler .toggleArr {
					transform: rotate(-90deg);
				}
			}
		}
	}


	.r_card__search {
		margin-bottom: 0;
		min-height: 90px;
	}

	.r_main-friends-list {
		border-top: 2px solid rgba($bc2,.15);
		padding-top: 20px;
		margin-left: -20px;
		margin-right: -20px;
		width: auto;

		@include mq(767) {
			padding-top: 15px;
			margin-left: -30px;
			margin-right: -30px;
		}

		&__row {
			justify-content: flex-start;
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.chat__messages {

	@include mq(767) {
		padding-bottom: 10px;
	}

	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	::-webkit-scrollbar-track-piece {
		background-color: transparent;
		box-shadow: none;
	}
	::-webkit-scrollbar-thumb:vertical,
	::-webkit-scrollbar-thumb:horizontal {
		background-color: rgba($bc2,.5);
	}


	.r_card__cnt {
		position: relative;
		z-index: 1;

		&:before, &:after {
			content: '';
			height: 25px;
			right: 10px;
			position: absolute;
			z-index: 2;
			left: 0;
		}
		&:before {
			background: #fff;
			background: linear-gradient(0deg, rgba(#fff,0) 40%, rgba(#fff,1) 100%);
			top: 0;
		}
		&:after {
			background: #fff;
			background: linear-gradient(0deg, rgba(#fff,1) 40%, rgba(#fff,0) 100%);
			bottom: 0;
		}

		@include mq(767) {
			padding-left: 10px;
			padding-right: 10px;
			margin-right: -8px;
		}
	}

	&-header {
		margin-bottom: 0;
		border-bottom: 2px solid rgba($bc2,.15);
		padding-bottom: 20px;

		.r_main-friends-list {
			border-top: 0;

			//@include mq(767) {
			//	margin-right: -20px;
			//	margin-left: -20px;
			//}
		}
	}

	&-day {
		position: relative;
		z-index: 1;
		padding-top: 30px;
	}

	&-date {
		font-size: 13px;
		color: rgba($bc2,.25);
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
	}

	&-avatar {
		width: 38px;
		height: 38px;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
	}

	&-list {
		padding-top: 30px;
		max-height: 680px;
		overflow: auto;
		min-height: 300px;
		margin-right: -15px;
		padding-right: 15px;
		position: relative;
		z-index: 1;

		@include mq(767) {
			max-height: 360px;
		}
	}

	&-group {
		padding: 0 80px 0 50px;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 20px;

		@include mq(767) {
			padding: 0 15px 0 45px;
		}

		&.me {
			align-items: flex-end;
			padding: 0 0 0 80px;

			.chat__messages-bubble {
				background-color: rgba($bc1,.2);

				&:before {
					left: auto;
					right: 100%;
					margin-right: 10px;
					margin-left: 0;
				}
			}

			@include mq(767) {
				padding: 0 0 0 15px;

				.chat__messages-bubble:before {
					right: auto;
					left: 0;
				}
			}
		}
	}

	&-bubble {
		background-color: rgba($bc2, .05);
		margin-bottom: 10px;
		padding: 12px 20px;
		border-radius: 20px;
		position: relative;

		@include mq(767) {
			padding: 8px 12px;
			margin-bottom: 18px;
			border-radius: 10px;
			font-size: 14px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			content: attr(data-time);
			position: absolute;
			left: 100%;
			margin-left: 10px;
			top: 14px;
			font-size: 13px;
			color: rgba($bc2,.25);
			opacity: 0;
			@extend %transition;
			visibility: hidden;

			@include mq(767) {
				opacity: 1;
				visibility: visible;
				left: auto;
				right: 0;
				top: auto;
				bottom: 100%;
			}
		}

		&:hover:before {
			opacity: 1;
			visibility: visible;
		}
	}

	&-reply {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;

		@include mq(767) {
			padding: 0 15px;
		}

		textarea {
			min-height: 38px;
			margin-right: 10px;
			padding-top: 8px;
			padding-bottom: 8px;
		}

		.btn {
			padding: 0;
			width: 38px;
			min-width: 1px;
			display: flex;

			svg {
				fill: #fff;
			}
		}
	}

	.note {
		font-size: 13px;
		opacity: .25;
		padding: 0 20px;

		@include mq(767) {
			padding: 0 12px;
		}
	}

	&-empty {
		text-align: center;
		font-size: 18px;
		color: rgba($bc2,.5);
		padding-top: 40px;

		svg {
			fill: rgba($bc2,.25);
			margin-bottom: 20px;
		}
	}
	&-company-name {
		color: $bc2;
		font-weight: 600;
	}
}