
.candidate-gamification {
	position: fixed;
	z-index: 12;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;

	@include mq(1100) {
		top: 120px;
		flex-direction: column;
		transform: translateX(200px);

		&.expanded {
			transform: translateX(0);
		}
	}
	@include mq(999) {
		top: 100px;
	}

	header.expanded + & {
		z-index: 10;
	}

	&__header {
		filter: drop-shadow(0 0 3px rgba(15, 35, 63, 0.2));

		@extend %transition;

		&--list {
			@include mq(1100) {
				display: flex;
			}
		}

		&--item {
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			position: relative;
			z-index: 1;
			overflow: hidden;
			cursor: pointer;

			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				@extend %gradient;
				@extend %transition;
				opacity: 0;
			}

			&:first-child {
				border-radius: 8px 0 0 0;

				@include mq(1100) {
					border-radius: 8px 0 0 8px;

					.candidate-gamification.expanded & {
						border-radius: 8px 0 0 0;
					}
				}
			}
			&:last-child {
				border-radius: 0 0 0 8px;

				@include mq(1100) {
					border-radius: 0 8px 0 0;
				}
			}

			&:hover, &.current {
				&:before {
					opacity: 1;
				}

				svg {
					fill: #FFFFFF;
				}
			}
		}

		svg {
			position: relative;
			z-index: 2;
			fill: #aaaab7;
			@extend %transition;
		}
	}

	&__tabs {
		border-radius: 2px;
		width: 350px;
		height: 640px;
		max-width: 0;
		opacity: 0;
		visibility: hidden;
		@extend %transition;
		filter: $filter-shadow;
		background-color: #fff;

		.candidate-gamification.expanded & {
			max-width: 350px;
			opacity: 1;
			visibility: visible;
		}

		@include mq(1100) {
			height: calc(100vh - 175px);
		}
		@include mq(999) {
			height: 0;

			.candidate-gamification.expanded & {
				height: calc(100vh - 155px);
			}
		}

		@include mq(767) {
			width: 100vw;

			.candidate-gamification.expanded & {
				max-width: 100vw;
			}
		}
	}

	&__tab {
		width: 350px;
		display: none;

		@include mq(767) {
			width: 100vw;
		}

		&.current {
			display: block;
		}
	}

	&__module-header {
		border-bottom: 1px solid $bc1;
		padding: 16px 20px;

		@include mq(787) {
			padding: 8px 20px;
		}

		h3 {
			font-size: 14px;
			margin: 0;
		}

		a {
			font-size: 12px;
			color: rgba($bc2,.5)
		}
	}

	&__module-cnt {
		width: 100%;
		max-width: 100%;
		max-height: 590px;
		height: 590px;
		overflow-y: auto;
		padding: 10px 0 0;

		@include mq(1100) {
			height: calc(100vh - 225px);
			max-height: calc(100vh - 225px);
		}
		@include mq(999) {
			height: calc(100vh - 205px);
			max-height: calc(100vh - 205px);
		}
	}

	.green {
		color: $green;
	}

	.btn {
		&.small {
			height: 22px;
			line-height: 22px;
			padding: 0 18px !important;
			font-size: 10px;
			min-width: 1px;
			text-transform: none;
		}
	}

	.icon-done {
		width: 20px;
		height: 20px;
		display: inline-block;
		vertical-align: bottom;
		margin-right: 3px;

		img {
			width: 20px;
			height: 20px;
		}
	}

	&__profile {
		&--header {
			padding: 20px;

			@include mq(1100) {
				padding: 10px 20px;
			}
		}

		.avatar {
			min-width: 50px;
			width: 50px;
			height: 50px;
			margin-right: 20px;
			border-radius: 50%;
			overflow: hidden;
			background-color: red;

			img {
				width: 100%;
				display: block;
			}

			@include mq(1100) {
				width: 30px;
			}
		}

		.tag {
			display: inline-block;
			color: #ffffff;
			background-color: #6cc2bf;
			padding: 5px 10px;
			font-size: 12px;
		}

		.user {
			flex-grow: 1;
		}

		.name {
			font-size: 18px;
			display: block;
			font-weight: bold;
			margin-bottom: 7px;
		}

		.badge {
			min-width: 50px;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			overflow: hidden;
			background-color: rgba($bc2,25%);
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				max-width: 40px;

				@include mq(1100) {
					width: 30px;
				}
			}
		}
		.levelProgress {
			margin-bottom: 10px;
			width: 100px;

			> div {
				width: 100px !important;
				height: 100px !important;
			}
		}

		.userLevel {
			margin-bottom: 0;

			.levelIndicators:hover .theLevel {
				opacity: 1;
			}
		}
	}

	&__news-highlights {

		&--list {
			padding: 20px 20px 30px;
		}

		&--item {
			display: flex;
			border-radius: 5px;
			border: 1px solid rgba($bc2,.1);
			margin-bottom: 15px;
			position: relative;

			&:hover {
				z-index: 2;
			}

			&:nth-child(3) {
				display: none;
			}
		}

		&--ico {
			width: 70px;
			height: 70px;
			min-width: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			background-color: rgba($bc2,.1);

			img, svg {
				max-width: 40px;
				max-height: 40px;
			}
		}

		&--desc {
			padding: 10px 10px 10px 20px;
			flex-grow: 1;

			@include mq(767) {
				padding-bottom: 0;
			}
		}

		&--title {
			display: block;
			font-size: 12px;
			font-weight: bold;
			margin-bottom: 3px;
		}

		&--body {
			font-size: 14px;
			opacity: .5;

			@include mq(767) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&--options {
			margin: 15px 0 0 0;
			padding-right: 10px;
		}

		.more-options__drop {
			right: -10px;
		}

		@include mq(1100) {


			.more-options {
				padding-left: 20px;
				padding-bottom: 10px;
				margin: 0;

				&__trigger {
					display: none;
				}

				&__drop {
					position: static;
					padding: 0;
					background: none transparent;
					opacity: 1;
					visibility: visible;
					transform: none !important;

					&:before {
						content: unset;
						display: none;
					}

					li {
						display: inline;
						font-weight: normal;
						margin-right: 5px;
					}

					a {
						color: $bc1;
						font-size: 12px;
					}
				}
			}

			&--item {
				padding-left: 70px;
				position: relative;
				z-index: 1;
				flex-direction: column;
			}

			&--ico {
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				height: auto;
				bottom: 0;
			}
		}
	}

	&__subtab-link {
		opacity: .5;
		cursor: pointer;

		&.selected {
			opacity: 1;
		}
	}

	&__subtab {
		display: none;

		&.selected {
			display: block;
		}
	}

	&__leaderboard {
		//counter-reset: section;

		&--item {
			//counter-increment: section;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 10px 15px 10px 0;
			color: rgba($bc2,.5);
			width: 100%;

			//&:before {
			//	content: counter(section);
			//	width: 66px;
			//	text-align: center;
			//}

			&.highlight {
				color: $bc2;
				border-top: 1px solid $bc1;
				border-bottom: 1px solid $bc1;

			}
		}

		&--points {
			margin-left: auto;
			margin-right: 0;
		}

		&--avatar {
			margin-right: 10px;

			img {
				display: block;
				width: 35px;
				height: 35px;
			}
		}
	}

	&__slider {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		position: relative;
		z-index: 1;

		font-size: 18px;
		color: rgba($bc2,.5);

		@include mq(1100) {
			padding-top: 50px;
		}

		h4 {
			color: $bc2;
			margin: 15px 0;
		}

		small {
			font-size: 14px;
		}

		.big {
			display: block;
			font-size: 27px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		&--pager {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 8px;

			span {
				width: 5px;
				height: 5px;
				margin: 0 5px;
				cursor: pointer;
				text-indent: -999vw;
				overflow: hidden;
				background-color: rgba($bc2,.25);
				border-radius: 50%;
				display: inline-block;
				@extend %transition;

				&.cycle-pager-active {
					background-color: $bc1;
					width: 8px;
					height: 8px;
				}
			}
		}

		&--prev, &--next {
			min-width: 20px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			@extend %gradient;
			cursor: pointer;

			svg {
				width: 6px;
				height: 12px;
				stroke: #fff;
			}

			&.disabled {
				pointer-events: none;
				opacity: 0;
				@extend %transition;
			}
		}

		&--prev svg {
			margin-left: -3px;
		}

		&--next svg {
			margin-right: -3px;
		}

		&--footer {
			width: 100%;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			position: absolute;
			z-index: 11;
			bottom: 0;
			padding: 0 20px;

			border-top: 1px solid rgba($bc2, .15);
			user-select: none;

			@include mq(1100) {
				bottom: auto;
				top: 0;
				border-top: 0px;
				border-bottom: 1px solid rgba($bc2, .15);
			}
		}
	}

	&__slide {
		width: 100%;
		flex-grow: 1;
		height: calc(100% - 50px);

		@include mq(1100) {
			top: 50px !important;
		}

		&--cnt {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			padding: 20px;
			text-align: center;
			height: 100%;

		}
	}

	&__news {

		&--list {
			padding: 20px 20px 40px;
		}

		&--item {
			border-radius: 5px;
			border: 1px solid rgba($bc2,.1);
			padding: 20px;
			margin-bottom: 20px;
			position: relative;
			font-size: 14px;
		}

		&--visual {
			margin-bottom: 17px;
			display: flex;
			align-items: center;

			> *:not(:last-child) {
				margin-right: 15px;
			}

			&.match {
				justify-content: space-between;
			}
			.match-icon {
				flex-grow: 1;
				text-align: center;
				position: relative;
				z-index: 1;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					width: 100%;
					top: 50%;
					border-bottom: 3px dotted rgba($bc2,.25);
					margin-top: -3px;
				}

				img {
					position: relative;
					z-index: 2;
				}
			}

			.banner-image {
				max-width: 304px;
				margin: -20px -20px 0 -20px;
			}
		}

		&--head {
			margin-bottom: 17px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&--title {
			font-weight: bold;
		}

		&--date {
			font-size: 12px;
		}

		&--body, &--date {
			color: rgba($bc2,.5);
		}

		.btn {
			margin-right: 15px;
		}
	}

	&__badges-achievements {
		color: rgba($bc2,.5);
		font-size: 15px;

		strong {
			color: $bc2;
			display: block;
			margin-bottom: 5px;
		}

		&--item {
			display: flex;
			align-items: center;
			padding: 20px 20px 20px 0;
			border-bottom: 1px solid rgba($bc2,.15);
			filter: grayscale(100%);

			div {
				opacity: .4;
			}

			&.owned {
				filter: none;

				div {
					opacity: 1;
				}
			}
		}

		&--icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 110px;
			min-width: 110px;

			img {
				max-width: 60px;
			}
		}

		&--desc {
			padding-right: 20px;
			flex-grow: 1;
		}
	}
}

