.r_jobs {

	&__category-title {
		height: 100%;
		width: 100%;

		.the-title {
			font-size: 15px;
			padding: 20px 15px;
			font-weight: bold;
			display: block;

			@include mq($tablet) {
				padding: 8px 15px;
				font-weight: normal;
				color: $bc2;
				text-transform: none;

				&:hover {
					color: #fff;
				}
			}
		}
    }

	&__filter-list {
		@extend ._filter-list;
	}

	.r_card {
		height: auto;

		&.full {
			width: 100%;
		}
		&__cnt {
			padding-top: 20px;
		}
    }

	.rowEssentials {
		margin-bottom: 20px;

		aside {
			margin-bottom: 0;
		}
	}

	.rowMoreDetails {
		align-items: flex-start;

		aside {
			top: 0;
		}
	}

	.r_job-item {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-direction: row;
		font-size: 0.8125rem;

		&__title {
			width: 52%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		&__title-head {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 15px;
			min-height: 66px;
		}

		&__title-body {
			color: rgba($bc2,.5);
		}

		.theTitle {
			font-size: 1.125rem;
			font-weight: bold;
			color: $bc2;
			margin-bottom: 5px;
		}

		&__desc {
			width: 45%;
		}

		&__company-logo {
			max-width: 66px;
			margin-right: 20px;
			border-radius: 50%;

			@include rounded-avatar;

			img {
				display: block;
				border-radius: 50%;
			}
		}

		&__applicants {
			background-color: rgba($bc2,.05);
			display: inline-block;
			height: 25px;
			line-height: 25px;
			padding: 0 15px;
			border-radius: 13px;
			color: rgba($bc2,.5)

		}

		.jobSpecs {
			padding-top: 20px;
		}

		@include mq(767) {
			flex-direction: column;

			.r_job-item__title, .r_job-item__desc {
				width: 100%;
			}
		}
	}

	.sideMenu {
		.clipped-handler {
			display: none;
		}
	}

	@include mq($tablet) {
		.r_main_container {

			.rowMoreDetails {
				flex-direction: column;
			}

			aside, section {
				width: 100%;
				min-width: 100%;
			}
		}

		.sideMenu {
			.clipped-handler {
				width: 100%;
				transform: none;
				color: #fff;
				background-color: $bc1;
				padding: 10px 20px;
				height: auto;
				position: relative;
				display: block;

				.toggleArr svg {
					stroke: #fff;
				}
				.toggleArr {
					height: 100%;
					padding: 0;
					display: flex;
				}
			}

			.r_jobs__filter-list, .r_resume__submit-action {
				display: none;
			}

			&.clipped-expanded {
				.r_jobs__filter-list, .r_resume__submit-action {
					display: block;
				}
				.clipped-handler .toggleArr {
					transform: rotate(-90deg);
				}
			}
		}
	}

}

.r_opportunities {

	.rowMoreDetails  aside.sideMenu {
		top: 10px;

		@include mq(999) {
			top: 0;
		}
	}

	.tab {
		display: none;

		&.current {
			display: block;
		}
	}

	.r_card:not(.r_card__search) {
		margin-bottom: 38px;

		@include mq(767) {
			margin-bottom: 20px;
		}
	}

	.r_job-item {
		@include mq(767) {
			position: relative;
		}
	}

	.r_job-item__desc {
		position: relative;
		//z-index: 1;  // explanation for commenting this line for future coders:
		// specifically setting z index on elements that have children causes all children to be rendered in a lower stacking context
		// this means that setting z index on subsequent children has no effect, causing them to be covered by other elements with no way of rendering them above them
		// therefore, let's not explicitly set z-index on elements that have children if it's not *absolutely necessary*

		.notif-new {
			position: absolute;
			right: -10px;
			top: -10px;
			text-transform: uppercase;
		}

		@include mq(767) {
			position: static;

			.notif-new {
				right: 10px;
				top: 10px;
			}
		}
	}

	@include mq(999) {
		aside.sideMenu ul {
			position: static;
			margin-bottom: 0;

			li.current {
				position: static;
			}
		}

		.tab > .btn {
			margin: 0 30px;
		}
	}
}

.r_job-item-status-bar {
	background-color: $bc2;
	color: #fff;
	width: 100%;
	height: 38px;
	font-size: 14px;
	border-radius: 19px;
	margin: 15px 0 -38px;
	padding: 0 20px;

	&__status {
		width: 45%;
		position: relative;
		.userPop .btn {
			width: auto;
		}
		.userPop a {
			color: $bc2;
		}
		.userPop {
			top: 40px;
		}
		.userPop.userNotesCnt:before {
			left: 60%;
			transform: translateX(-50%);
		}
	}

	.notifsTrigger {
		padding: 0;
		margin: 0 0 0 -10px;
		height: auto;

		.qmk {
			position: relative;
			top: 0;
			left: 0;
			margin-right: 7px;
			width: 20px;
			height: 20px;
			pointer-events: none;
		}

		.qmk_svg {
			width: 4px;
			height: 12px;
		}

		.timer {
			padding: 0;
		}
	}

	a {
		color: #fff;
		font-weight: bold;
	}

	.controls {
		span {
			flex-grow: 1;
		}
	}

	&.expired {
		background-color: $gray;

		span {
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.btn {
		width: 20px;
		height: 20px;
		padding: 0;
		min-width: 1px;
		margin-right: 10px;
		line-height: 20px;
	}

	.send-message-link {
		margin-left: -10px;

		.btn {

			svg {
				fill: #fff;
			}
		}

		&:hover {
			.btn:before {
				opacity: 0;
			}

			.btn:after {
				opacity: 1;
				background-color: #fff;
			}

			.btn svg {
				fill: $bc1;
			}
		}
	}

	@include mq(767) {
		display: block;
		height: auto;
		margin: 15px 30px 0;
		border-radius: 5px;
		padding: 8px 15px;
		width: calc(100% - 60px);

		.r_job-item-status-bar__status {
			width: 100%;
			margin-top: 5px;
		}

		.send-message-link, .notifsTrigger {
			margin-left: 0;
		}
	}
}
