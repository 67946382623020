.home-visual {
	position: relative;
	
	&:before, &:after {
		content: '';
		position: absolute;
		z-index: 0;
	}

	&:before {
		//@include bg('home-visual-bubbles.svg');
		background-size: cover;
		top: 80px;
		height: 65%;
		left: 10px;
		right: 0;
		max-height: 100vh;
	}

	&:after {
		bottom: 0;
		height: 30%;
		background-color: #fff;
		left: 0;
		right: 0;
	}

	h1 {
		font-size: 3.125rem;

		.bc {
			font-weight: bold;
		}

		@include mq(1000) {
			font-size: 2.4rem;
		}
	}

	h4 {
		font-size: 1.25rem;
	}

	h1, h4 {
		color: $bc2;
		font-weight: normal;
	}
	
	.r_container {
		z-index: 1;
		padding-top: 100px;

		@include mq(1000) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}

	&__desc {
		color: #979797;
		flex-grow: 1;

		@include mqq(1000) {
			padding-right: 90px;
		}
	}

	&__image {
		min-width: fit-content;
		max-width: 100%;
	}

	&__list {
		display: flex;
		justify-content: center;
		align-items: stretch;
		padding-top: 190px;
		padding-bottom: 70px;

		@include mq(1000) {
			padding-top: 30px;
			padding-bottom: 0;
		}
	}
	
	&__item {
		background-color: #fff;
		text-align: center;
		padding: 0 20px 30px;

		display: flex;
		flex-direction: column;
		align-items: center;

		box-shadow: 0 3px 15px rgba(#000,.06);
		border-radius: 5px;

		margin: 0 8px 20px;

		&:hover {
			background-color: $bc1;

			.home-visual__item--title {
				color: $bc2;
			}

			p {
				color: #fff;
			}

			.btn:after {
				opacity: 1;
			}
		}

		@include mqq(1000) {
			padding: 15px;
			width: calc(33% - 11px);

			&:last-child {
				border-top-right-radius: 100px;
			}
			&:first-child {
				border-top-left-radius: 100px;
			}
		}

		img {
			width: 202px;

			@include mqq(1000) {
				margin-top: -101px;
			}
		}

		&--title {
			font-weight: bold;
			font-size: 2rem;
			margin-bottom: 2rem;
			padding-top: 20px;

			@include mq(1000) {
				br {
					display: none;
				}
			}
		}

		p {
			font-size: 90%;
			line-height: 1.5;
			color: #979797;
			transition: all .25s ease-in-out;

			@include mqq(1000) {
				margin-bottom: 43px;
			}
		}

		.btn {
			margin: auto auto 0;
			width: fit-content;
			height: 46px;
			line-height: 46px;
			text-transform: none;
			pointer-events: none;
		}
	}
}

.home-empower-pillars {
	//@include bg('home-visual-bubbles.svg');
	background-size: 95%;

	&__list {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-top: 100px;
	}

	&__item {
		background-color: #fff;
		box-shadow: 0 3px 15px rgba(#000,.06);
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 307px;

		padding: 0 25px 40px;

		margin-bottom: 84px;
		border-radius: 5px;

		&:first-child {
			border-top-left-radius: 35px;
		}
		&:last-child {
			border-bottom-right-radius: 35px;
		}

		@include mqq(768) {
			max-width: 32%;

			&:nth-child(3) {
				border-top-right-radius: 35px;
			}
			&:nth-child(4) {
				border-bottom-left-radius: 35px;
			}
		}
		@include mq(767) {

			width: calc(50% - 10px);

			&:nth-child(2) {
				border-top-right-radius: 35px;
			}
			&:nth-last-child(2) {
				border-bottom-left-radius: 35px;
			}
			@include mqq(541) {
				&:nth-last-child(2), &:last-child {
					margin-bottom: 0;
				}
			}
		}
		@include mq(540) {
			width: 100%;
			margin-bottom: 67px;

			&:nth-child(2) {
				border-top-right-radius: 5px;
			}
			&:nth-last-child(2) {
				border-bottom-left-radius: 5px;
			}

			&:first-child {
				border-radius: 35px 35px 5px 5px;
			}
			&:last-child {
				border-radius: 5px 5px 35px 35px;
				margin-bottom: 0;
			}
		}

		&.regular-rad {
			border-radius: 5px;
		}

		p {
			color: #979797;
		}
	}

	img {
		margin-top: -60px;
		margin-bottom: 1.25rem;
	}

	&__title {
		font-weight: bold;
		color: $bc2;
		font-size: 1.5rem;
		margin-bottom: 1rem;
	}
}

.home-clients-module {

	h2 {
		margin-bottom: 0;
		font-weight: normal;
		font-size: 2.75rem;

		.bc {
			font-weight: bold;
		}

		@include mq(1200) {
			font-size: 2rem;
		}

		@include mq(767) {
			font-size: 1.5rem;
		}
	}

	.clients-module {

		@include mq(767) {
			padding: 15px 0;
		}

		&__item {
			background-color: #fff;
			border-radius: 7px;
			padding: 15px 20px;
			display: flex;
			justify-content: center;
			align-items: center;

		}
	}

	.clients-module__row .cycle-cnt {
		align-items: stretch;
	}
}

.home-testimonials {

	.slider {
		padding-bottom: 40px;
	}

	.slide {
		background-color: #fff;
		padding: 50px 25px;
		border-radius: 7px;

		@include mq(1200) {
			padding: 30px 20px;
		}
		@include mq(767) {
			padding: 20px 15px;
		}
	}

	&.large-testimonial {
		.cycle-pager {
			bottom: 0;
			padding: 0;
		}
	}

	.large-testimonial__body {
		padding-bottom: 10px;
	}

	@include mq(979) {
		.cycle-slideshow .flex {
			display: flex;
		}
		.cycle-item-space {
			margin: 0 5px 0 5px;
		}
	}
}

.home-path-selection {
	padding: 5px 0 65px;

	@include mq(979) {
		padding: 45px 0;
	}

	&__item {
		background-color: #ffffff;
		box-shadow: 0 15px 20px rgba(217, 217, 236, 0.52);
		text-align: center;
		font-size: 1.5rem;
		line-height: 2rem;
		padding: 30px 20px;
		border-radius: 5px;

		@include mqq(980) {
			min-width: calc((100% - 40px) / 3);
			max-width: calc((100% - 40px) / 3);
		}

		@include mq(979) {
			font-size: 1.2rem;
			line-height: 1.23;
			padding: 20px 15px;
			width: 100%;
		}
	}

	&__icon {
		margin-bottom: 1.5rem;
		height: 135px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	h3 {
		font-size: 2.1875rem;
		font-weight: 500;
		margin-bottom: 2rem;

		@include mq(979) {
			font-size: 1.75rem;
			margin-bottom: 1.5rem;
		}
	}
}

.home-path-selection, .whitelabel-cnt .cycle-cnt, .cycle-slideshow {
	.cycle-pager {
		text-align: center;
		display: none;
		width: 100%;
		padding: 25px 10px 10px;

		&.show {
			display: block;
		}

		@include mq(979) {
			display: block;
		}

		span {
			font-size: 0;
			text-indent: -9999rem;
			overflow: hidden;
			margin: 0 2px;
			border-radius: 50%;
			width: 9px;
			height: 9px;
			display: inline-block;
			background: rgba($bc2,.25);
			cursor: pointer;

			&.cycle-pager-active {
				background: $bc1;
			}
		}
	}

	@include mq(979) {
		.flex {
			display: block;
		}
	}
}
.home-paths {

	h2 {
		font-size: 3.125rem;
		font-weight: 500;
		margin-bottom: 2rem;

		@include mq(979) {
			font-size: 2rem;
			margin-bottom: 1rem;
		}
	}

	h6 {
		font-size: 1.5rem;
		font-weight: 400;
		margin-bottom: 2rem;

		@include mq(979) {
			font-size: 1.2rem;
			margin-bottom: .8rem;
		}
	}

	p {
		margin-bottom: 2rem;

		@include mq(979) {
			margin-bottom: .8rem;
		}
	}

	&__item {
		padding-top: 55px;
		padding-bottom: 65px;

		@include mq(979) {
			padding-top: 20px;
			padding-bottom: 10px;
		}
	}

	&__desc, &__image {
		min-width: 50%;
		max-width: 50%;

		@include mq(979) {
			min-width: 100%;
			max-width: 100%;
		}
	}

	&__image {
		text-align: center;

		@include mqq(980) {
			&.reverse {
				order: -1;
			}
		}
	}

	@include mq(979) {
		.r_container {
			flex-wrap: wrap;
		}
	}
}

.clients-module {
	padding: 45px 0;

	h3 {
		text-align: center;
		margin-bottom: 0;
		font-weight: 400;
	}
}