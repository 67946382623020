.r_redesign {
	header {
		padding: 40px 0;
		margin-bottom: 60px;
		position: relative;
		z-index: 11;
		@extend %transition;
		user-select: none;
		min-height: 90px;

		@include mq($tablet) {
			padding: 30px 0;
		}
		@include mq(767) {
			padding: 0;
			// height: 90px;
			background-color: $graybg;
			position: fixed;
			margin: 0;
			z-index: 11;
			left: 0;
			top: 0;
			right: 0;

			&.scrolled {
				filter: drop-shadow(0 0 3px rgba($bc2, .2));
				background-color: #fff;
			}
			nav {
				max-height: 90px;
				transition: max-height 0.65s cubic-bezier(0, 1, 0, 1);
			}
			.btn, .notifications {
				position: absolute;
				z-index: 1;
				top: 30px;
				right: 55px;
			}

			&.expanded {
				filter: drop-shadow(0 0 3px rgba($bc2, .2));
				background-color: #ececf0;
				overflow-y: auto;

				nav {
					max-height: 9999px;
					transition: max-height 0.95s cubic-bezier(1, 0, 1, 0);
				}
			}
		}

		.r_container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: static;

			@include mq(767) {
				margin: 0;
				//display: block;
			}
		}

		.r_logo {
			display: inline-block;
			width: 160px;
			position: relative;
			z-index: 3;

			svg {
				display: block;
			}

			@include mq($tablet) {
				width: 105px;
			}

			@include mq(767) {
				position: absolute;
				z-index: 3;
				top: 28px;
				left: 15px;
			}
		}

		nav {
			max-width: calc(100% - 200px);
			min-width: calc(100% - 400px);
			//position: relative;
			//z-index: 1;

			display: flex;
			align-items: center;
			justify-content: flex-end;

			font-size: 14px;
			text-transform: uppercase;
			font-weight: bold;

			@include mq(767) {
				overflow: hidden;
				max-width: 100%;
				width: 100%;
				padding: 0 30px;
				display: block;
			}
			@include mq(359) {
				padding: 0 20px;
			}
		}

		.sub-nav {
			position: absolute;
			left: 0;
			top: 100%;
			width: 100%;
			background: #FFFFFF;
			//box-shadow: 0 10px 15px rgba(217, 217, 236, 0.52);
			filter: drop-shadow(0 0 6px rgba($bc2, .2));
			border-radius: 5px;
			@extend %transition;
			opacity: 0;
			visibility: hidden;
			transform: translateY(-15px);

			ul {
				width: 100%;
				height: 70px;

				&.dropHolder {
					height: auto;
					width: auto;
				}
			}

			@include mq(767) {
				top: 0;
				padding-top: 105px;
				filter: none;
				border-radius: 0;
				background-color: transparent;
				position: static;

				ul {
					height: auto;
					flex-direction: column;
				}

				li {
					width: 100%;
					margin-bottom: 15px;
				}
			}
		}

		.top-menu, .home-menu {
			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				@include mq(767) {
					display: block;

					li {
						display: block;
					}
				}
			}
		}

		.top-menu {
			//margin-bottom: 47px;

			ul {
				justify-content: flex-end;

				li {
					&.main-cta {
						margin-left: 35px;
					}

					&:first-child {
						margin-left: 0;
					}

					&.mm-like {
						margin-right: 25px;
					}
				}
			}

			@include mq(767) {
				margin-bottom: 0;
				padding-top: 125px;
				padding-bottom: 15px;

				+ .sub-nav {
					padding-top: 0;
					margin-top: -20px;
				}


				li {
					margin-bottom: 15px;
					width: 10%;
				}

				.top-ql {
					display: none;
				}
			}
		}

		.home-menu {
			z-index: 14;

			a {
				cursor: pointer;
			}

			@include mq(767) {
				padding-top: 120px;

				li {
					margin-bottom: 33px;
				}

				@include mq(520, max, height) {
					padding-top: 105px;

					li {
						margin-bottom: 15px;
					}
				}
			}

			&.main-menu {

				@include mq(767) {
					li {
						margin-bottom: 15px;
						clear: both;
					}
				}
			}
		}

		.r_social-block {
			font-size: 0;
			padding-bottom: 20px;

			li {
				display: inline-block;
				vertical-align: middle;
				margin-right: 20px;

				&:last-child {
					margin-right: -0px;
				}
			}

			a {
				display: block;
				width: 30px;
				height: 30px;
				background-color: rgba($bc2, .25);
				border-radius: 50%;
				position: relative;
				z-index: 1;
				overflow: hidden;

				&:hover {
					background-color: rgba($bc2, 1);
				}
			}

			svg.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				fill: #E7E6EA;

				&__fb {
					width: 8px;
				}

				&__yt {
					width: 13px;
				}

				&__insta {
					width: 11px;
				}
			}
		}

		.r_quick-links, .r_social-block {
			display: none;

			@include mq(767) {
				display: block;
			}
		}

		.r_quick-links {
			border-top: 2px solid rgba($bc2, .15);
			margin-bottom: 20px;
			padding-top: 30px;

			li {
				margin-bottom: 30px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			@include mq(767) {
				padding-top: 15px;
				margin-bottom: 20px;

				li {
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			@include mq(520, max, height) {
				padding-top: 15px;
				margin-bottom: 15px;

				li {
					margin-bottom: 10px;
				}
				ul {
					display: flex;
					justify-content: space-between;
				}
			}
		}

		.btn {
			min-width: 10px;
		}

		.menu-icon {
			cursor: pointer;
			position: absolute;
			z-index: 2;
			right: 15px;
			top: 35px;
			width: 30px;
			height: 25px;
			display: none;
			transition: 0.55s;
			margin-left: 45px;

			@include mq(767) {
				display: block;
			}


			&.home-version, &.desktop-version {
				display: block;
				position: relative;
				right: 0;
				top: 0;

				@include mq(767) {
					display: none;
				}
			}


			.menu-icon-global {
				backface-visibility: hidden;
				position: absolute;
				left: 0;
				border-top: 4px solid $bc1;
				width: 100%;
				transition: 0.55s;
				user-select: none;
				display: block;
			}

			.menu-icon-top {
				top: 0;
			}

			.menu-icon-middle {
				top: 9px;
			}

			.menu-icon-bottom {
				top: 18px;
			}

		}

		&.expanded {
			.menu-icon {
				transform: translateY(-3px);

				.menu-icon-top {
					backface-visibility: hidden;
					top: 15px;
					transform: rotate(50deg);
				}

				.menu-icon-middle {
					opacity: 0;
				}

				.menu-icon-bottom {
					backface-visibility: hidden;
					top: 15px;
					transform: rotate(-410deg);

				}
			}

			.sub-nav {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}

			@include mq(767) {
				~ main, ~ footer {
					&:before {
						opacity: 1;
						visibility: visible;
						z-index: 12;
					}
				}
			}
		}

		@media (min-width: 760px) {
			.langSelector {
				margin-right: auto;
			}
		}

		.langSelector {
			align-self: center;
			// margin-right: auto;
			margin-left: 10px;
			margin-top: 15px;
			position: relative;
			z-index: 1;
			transform: translateY(-10px);

			&.open {
				filter: drop-shadow(0 0 3px rgba($bc2, .25));

				.isSelected {
					color: rgba($bc2, .6);
					background-color: #fff;

					svg {
						fill: rgba($bc2, .6);
					}
				}

				ul {
					opacity: 1;
					visibility: visible;
					transform: translate(0, 0);
				}
			}

			.isSelected {
				padding: 5px 10px;
				border-radius: 5px 5px 0 0;
				font-weight: bold;
				font-size: 13px;
				@extend %transition;
				color: rgba($bc2, .2);
				cursor: pointer;

				svg {
					display: block;
					width: 6px;
					height: 3px;
					fill: rgba($bc2, .2);

					&.flag {
						width: 20px;
						height: 10px;
						min-width: 20px;
						margin-right: 3px;
					}
				}

				.arr {
					height: 3px;
					width: 6px;
					display: inline-block;
					vertical-align: middle;
					margin-left: 3px;
				}
			}

			ul {
				position: absolute;
				z-index: 1;
				top: 100%;
				right: 0;
				text-align: right;
				background-color: #fff;
				min-width: 120px;
				border-radius: 5px 0 5px 5px;
				overflow: hidden;
				font-size: 13px;
				opacity: 0;
				visibility: hidden;
				transform: translate(0, 5px);
				@extend %transition;
			}

			li {
				display: block;

				&:first-child a {
					border-top: 0 none;
				}

				a, span {
					display: block;
					padding: 4px 10px;
					border-top: 1px solid rgba($bc2, .1);
				}

				&.selected, &:hover {
					a, span {
						background-color: $bc2;
						color: #fff;
						border-top-color: $bc2;
					}
				}

				&.selected {
					pointer-events: none;
				}
			}
		}

		.hasChildren {
			position: relative;
			z-index: 1;

			@include mq(767) {
				.dropdownToggle {
					display: none;

					&.isVisible {
						display: block;
					}
				}
			}

			@include mqq(768) {

				.dropHolder {
					right: 50%;
					transform: translate(50%, 25px);
					display: block;

					ul {
						display: block;
					}

					li {
						margin-bottom: 10px;
					}

					li:last-child {
						margin-bottom: 0;
					}
				}

				.dropHolder:before {
					right: 50%;
					margin-right: -10px;
				}

				&.open, &:hover {
					.dropHolder {
						opacity: 1;
						visibility: visible;
						transform: translate(50%, 14px);
					}
				}
			}
		}
	}

	&.r_profile_page {
		header {
			margin-bottom: 30px;
		}
	}

	&.menuOpen {
		@include mq(979) {
			overflow: hidden;
			position: absolute;
			width: 100%;
		}
	}

	main, footer { // overlay for menu open

		&:before {
			@extend %transition;
		}


		@include mq(767) {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				z-index: 10;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;


				background-color: rgba($bc2, .8);
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	&.r_homepage {
		header {
			background-color: #ffffff;

			@include mq($tablet) {
				padding-top: 35px;
				padding-bottom: 35px;
			}
			@include mq(767) {
				padding-top: 0;
				padding-bottom: 0;
			}

			.btn {
				min-width: 10px;

				@include mq(767) {
					position: absolute;
					z-index: 1;
					top: 30px;
					right: 55px;
				}
			}

			.r_logo {
				width: 105px;

				@include mq($tablet) {
					width: 105px;
				}
				@include mq(767) {
					width: 105px;
				}
			}

			.langSelector {
				transform: translateY(-10px);
			}
		}
	}

	.userAccount .actHolder, .hasChildren .dropHolder {
		@extend %transition;

		@include mqq(768) {
			position: absolute;
			top: 100%;
			right: -3px;
			z-index: 1;
			background-color: #fff;
			filter: drop-shadow(0 0 6px rgba($bc2, .2));
			min-width: 180px;
			transform: translateY(25px);
			padding: 10px 0;
			opacity: 0;
			visibility: hidden;

			&:before {
				content: '';
				width: 0;
				height: 0;
				border: 10px solid transparent;
				border-bottom-color: #fff;
				position: absolute;
				bottom: 100%;
				right: 12px;
			}
		}

		ul {
			display: block;
		}

		li {
			display: block;
			padding: 10px 20px;
		}

		.noticeNo {
			color: #fff;
			font-size: 9px;
			font-weight: bold;
			border-radius: 50%;
			background-color: $bc1;
			min-width: 16px;
			padding: 0 3px;
			height: 16px;
			display: inline-block;
			text-align: center;
			line-height: 16px;
			vertical-align: middle;
		}

		@include mq(767) {
			position: static;
			//padding-left: 15px;
			//padding-top: 15px;

			li {
				padding: 0;
			}
		}
	}

	.actHolder {
		@include mqq(768) {
			li:last-child {
				padding-top: 35px;

				a {
					color: rgba($bc2, .2);

					&:hover {
						color: $bc1;
					}
				}

				@include mq(767) {
					padding-top: 0;
				}
			}
		}
	}

	.userAccount {
		position: relative;
		z-index: 1;

		.avatar {
			width: 100%;
			border-radius: 50%;
			display: block;
		}

		.actTrigger {
			position: relative;
			z-index: 1;
			cursor: pointer;
			width: 44px;
			border-radius: 50%;
			margin: 0 auto;

			@include mq(767) {
				margin-bottom: 20px;
				display: none;
			}

			.notice {
				width: 16px;
				height: 16px;
				background-color: #f1f3f5;
				border-radius: 50%;
				position: absolute;
				z-index: 2;
				right: 0;
				top: 0;

				svg {
					fill: $bc1;
				}

				.bell_svg {
					width: 8px;
					height: 9px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		&.open {
			@include mqq(768) {
				.actHolder {
					opacity: 1;
					visibility: visible;
					transform: translateY(14px);
				}
			}
		}
	}

	.notifsTrigger {
		height: 38px;
		padding: 6px 32px 6px 6px;
		background-color: $bc2;
		border-radius: 19px;
		color: #fff;
		position: relative;
		z-index: 1;
		max-width: 130px;
		margin: 0 auto;

		.timer {
			line-height: 26px;
			font-size: 14px;
			font-weight: bold;
			display: inline-block;
			padding: 0 15px;
		}

		.btn {
			width: 26px;
			height: 26px;
			padding: 0;
			position: absolute;
			z-index: 1;
			top: 6px !important;
			right: 6px !important;
			min-width: 1px;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				fill: #fff;
				@extend %transition;
			}

			&:hover {
				background-color: #fff;
				background-image: none;

				&:before, &:after {
					opacity: 0;
				}

				svg {
					fill: $bc1;
				}
			}
		}


	}

	&.r_closer {
		header {
			margin-bottom: 40px;
		}

		@include mq(767) {
			.r_company-profile.r_profile .rowEssentials {
				padding-top: 0;
			}
		}
	}

	@include mqq(768) {
		.main-menu {
			width: 100%;

			> ul > li > a {
				line-height: 44px;
			}
		}
	}
}

.r_companies-page {
	header {
		margin-bottom: 85px;
	}
}

.public-pages {

	@include mq(767) {
		padding-top: 0 !important;

	
	}

	@media (min-width: 768px) {
		.menu-icon, .mobile-burger {
			display: none!important;
		}
	}

	.mobile-burger {
		margin-left:15px;
	}



	header {
		padding: 0;
		height: 64px;
		min-height: 64px;
		filter: drop-shadow(0 2px 7px rgba(#000,.06));
		border-radius: 0px 0px 15px 15px;
		background-color: #ffffff;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;

		.r_container {
			height: 64px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			padding: 0 15px;
		}

		.logo {
			margin-left: 0;
			margin-right: 25px;
			position: relative;
			z-index: 1;
		}

		.menu-icon {
			position: relative;
			right: 0;
			top: 0;
			display: block;
			width: 64px;
			height: 64px;
			padding: 22px;
			background-color: $bc2;
			border-radius: 12px 0 12px 12px;
			margin-left: 25px;
			@extend %transition;

			&:hover {
				background-color: $bc1;
			}

			.menu-icon-global {
				border: 2px solid #fff;
				width: 30px;
				left: 18px;
				-webkit-border-radius: 10px;
				-moz-border-radius: 10px;
				border-radius: 10px;
			}
			.menu-icon-top {top: 20px;}
			.menu-icon-middle {top: 29px;}
			.menu-icon-bottom {top: 38px;}
		}

		&.expanded {
			background-color: #fff;
			border-radius: 0;

			.menu-icon {
				transform: none;

				.menu-icon-top, .menu-icon-bottom {top: 28px;}
				.menu-icon-top {transform: rotate(45deg);}
				.menu-icon-bottom {transform: rotate(-405deg);}
			}

			.quick-links, .r_container > .btn, .r_container > .langSelector {
				opacity: 0;
				visibility: hidden;
			}
		}

		.quick-links {
			display: flex;
			transition: all .25s ease-in-out;

			@include mq(767) {
				display: none;
			}

			li {
				margin-right: 50px;

				&.open {
					.quick-links__link {
						color: $bc1;

						&:after {
							transform: rotate(90deg);
						}
					}
					.quick-links__sub-menu {
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}
			}

			&__link {
				display: flex;
				align-items: center;
				position: relative;
				z-index: 2;

				&:after {
					content: '›';
					font-size: 20px;
					margin-left: 8px;
					transition: $transition;
				}
			}

			&__sub-menu {
				z-index: 1;
			}
		}


		@media  (min-width: 768px) {
			.quick-links__sub-menu, .mega-menu {
				position: absolute;
				top: 64px;
				left: 0;
				right: 0;
				-webkit-border-radius: 0 0 12px 12px;
				-moz-border-radius: 0 0 12px 12px;
				border-radius: 0 0 12px 12px;
				background-color: #fff;
				filter: drop-shadow(0 7px 7px rgba(#000,.06));
				opacity: 0;
				visibility: hidden;
				transform: translateY(-100%);
				transition: $transition;
			}


		.quick-links__sub-menu {
			display: flex;
			align-items: stretch;
			padding: 32px 0;

			.cell {
				flex-grow: 1;
				padding: 20px 0;
				display: flex;
				align-items: stretch;

				&:not(:first-child) {
					border-left: 1px solid rgba(#707070,.16);
				}
			}

			a {
				display: flex;
				flex-direction: column;
				width: 100%;
				align-items: center;
				justify-content: center;
			}

			img {
				margin-bottom: 12px;
			}
		}
		}

		@media (max-width: 767px) {
			.mega-menu {
				position: absolute;
				top: 64px;
				left: 0;
				right: 0;
				-webkit-border-radius: 0 0 12px 12px;
				-moz-border-radius: 0 0 12px 12px;
				border-radius: 0 0 12px 12px;
				background-color: #fff;
				filter: drop-shadow(0 7px 7px rgba(#000,.06));
				opacity: 0;
				visibility: hidden;
				transform: translateY(-100%);
				transition: $transition;
			}

			.quick-links__sub-menu {
				padding-left: 10px;
			}
		}


		&.expanded {
			overflow: visible;

			.mega-menu {
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
			}
		}

		.mega-menu {
			padding: 60px 25px 30px;

			@include mq(1200) {
				padding: 40px 15px 15px;
				max-height: calc(100vh - 64px);
				overflow-y: auto;
			}

			&__main {
				justify-content: space-between;

				display: grid;
				grid-template: "list-top tab-cnt"
								"list-bottom tab-cnt";
				grid-template-columns: auto 1fr;

				@include mq(767) {
					display: block;
				}
			}

			&__tabbed {
				flex-grow: 1;
				padding: 10px 0;
				grid-area: tab-cnt;

				@include mq(768) {
					padding: 10px 18px 0;
				}
			}

			&__main-list {
				min-width: 225px;
				padding: 0 25px 75px;
				margin-right: 50px;
				font-size: 22px;
				font-weight: bold;
				display: inline-block;

				&.list-top {
					grid-area: list-top;
					padding-bottom: 0;
				}
				&.list-bottom {
					grid-area: list-bottom;
				}

				@include mq(1200) {
					padding: 0 0 15px;
					margin-right: 25px;
					font-size: 18px;
				}

				li {
					margin-bottom: 10px;

					@include mq(768) {
						margin-bottom: 7px;
					}
				}

				li[data-tab] {
					a:after {
						content: '›';
						font-size: 30px;
						margin-left: 12px;
						transition: transform 0.25s ease-in-out;
						font-weight: normal;
					}

					&.current a:after {
						transform: rotate(90deg)translateX(3px);
					}
				}

				a {
					padding: 10px 30px;
					border-radius: 50px;
					display: flex;
					align-items: center;

					@include mq(768) {
						padding: 5px 18px;
					}

				}

				.current {

					a {
						color: #fff;
						background-color: $bc2;
					}
				}
			}

			&__tab {
				display: none;
				justify-content: space-between;

				@include mq(767) {
					flex-wrap: wrap;
					align-items: stretch;
				}

				&.current {
					display: flex;
				}

				ul {
					width: 146px;

					@include mq(767) {
						flex-grow: 1;
						border-bottom: 1px solid rgba($bc2,.2);
						margin-bottom: 20px;
					}
				}

				li {
					margin-bottom: 20px;
				}

				a {
					color: rgba($bc2,.4);

					&:hover {
						color: $bc1;
					}
				}

				.title {
					border-bottom: 1px solid rgba($bc2,.2);
					margin-bottom: 30px;
					padding-bottom: 20px;

					@include mq(767) {
						padding-bottom: 0;
						margin-bottom: 20px;
						border-bottom: 0;
					}
				}
			}

			&__footer {
				width: 100%;
				padding: 30px 0 0;
				display: flex;
				justify-content: space-around;
				border-top: 1px solid rgba($bc2,.2);

				@include mq(1200) {
					padding-top: 15px;
				}

				@include mq(767) {
					flex-wrap: wrap;
					justify-content: flex-start;
					padding: 10px 18px;

					li {
						width: 50%;
						padding: 5px 0;
					}
				}
			}
		}

		.btn {
			height: 40px;
			line-height: 40px;
			text-transform: none;

			@include mq(767) {
				position: relative;
				top: 0;
				right: 0;
			}
		}
	}
}
