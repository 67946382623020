// Carousel 
:root {
  /* Color */
  --vc-clr-primary: #000;
  --vc-clr-secondary: #090f207f;
  --vc-clr-white: #ffffff;

  /* Icon */
  --vc-icn-width: 1.2em;

  /* Navigation */
  --vc-nav-width: 30px;
  --vc-nav-height: 30px;
  --vc-nav-border-radius: 0;
  --vc-nav-color: var(--vc-clr-primary);
  --vc-nav-color-hover: var(--vc-clr-secondary);
  --vc-nav-background: transparent;

  /* Pagination */
  --vc-pgn-width: 12px;
  --vc-pgn-height: 4px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}

.carousel {
  position: relative;
  text-align: center;
  box-sizing: border-box;
  touch-action: pan-y;
  overscroll-behavior: none;
  // height: 100%;
}

.carousel.is-dragging {
  touch-action: none;
}

.carousel * {
  box-sizing: border-box;
}

.carousel__track {
  display: flex;
  padding: 0 !important;
  position: relative;
  height: 100%;
}

.carousel__viewport {
  overflow: hidden;
  // height: 12rem;
  height: 100%;
}

.carousel__sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: currentColor;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background: var(--vc-nav-background);
  border-radius: var(--vc-nav-border-radius);
  width: var(--vc-nav-width);
  height: var(--vc-nav-height);
  text-align: center;
  font-size: var(--vc-nav-height);
  padding: 0;
  color: var(--vc-nav-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
  margin: 0 10px;
  top: 50%;
  transform: translateY(-50%);
}

.carousel__next--disabled,
.carousel__prev--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.carousel__prev {
  left: 0;
}

.carousel__next {
  right: 0;
}

.carousel--rtl .carousel__prev {
  left: auto;
  right: 0;
}

.carousel--rtl .carousel__next {
  right: auto;
  left: 0;
}

@media (hover: hover) {

  .carousel__prev:hover,
  .carousel__next:hover {
    color: var(--vc-nav-color-hover);
  }
}

.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  line-height: 0;
  margin: 10px 0 0;
  padding: 0;
}

.carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  padding: var(--vc-pgn-margin);
  background: transparent;
}

.carousel__pagination-button::after {
  display: block;
  content: '';
  width: var(--vc-pgn-width);
  height: var(--vc-pgn-height);
  border-radius: var(--vc-pgn-border-radius);
  background-color: var(--vc-pgn-background-color);
}

.carousel__pagination-button--active::after {
  background-color: var(--vc-pgn-active-color);
}

.carousel__slide--next .activity-summary-card {
  border: none !important;
}

@media(hover: hover) {
  .carousel__pagination-button:hover::after {
    background-color: var(--vc-pgn-active-color);
  }
}

.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  justify-content: center;
  align-items: center;

  /* Fix iOS scrolling #22 */
  transform: translateZ(0);
}

// End Carousel

.candidate-profile-card {
  height: 350px;
}

.activity-summary-card {
  border-left: 2px solid;
}

.customscrollbar {
  // Simple
  &--simple::-webkit-scrollbar {
    margin-right: -5px;
    width: 6px;
    height: 6px;
  }
  &--simple::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f4f4f5;
  }
  &--simple::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: #a3a3a3;
    height: 40px !important;
  }
  &--simple::-webkit-scrollbar-thumb:hover{
  	background: #a3a3a3;
  }
  &--simple::-webkit-scrollbar-thumb:active{
  	background: #a3a3a3;
  }
}

.carousel__prev:has(.carousel_btn_prev) {
  left: -45px;
  @media screen and (min-width: 1024px) {
    left: -40px;
  }
}

.carousel__next:has(.carousel_btn_next) {
  right: -45px;
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    right: -40px;
  }
}

.profile-edit-btn {
  padding: 2px 6px !important
}

.activity-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.group-btn {
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .events-buttons {
    display: table !important;
    a {
      margin-bottom: 10px;
    }
  }
}

.candidate-profile-page {
  .text-sm {
    line-height: 20px !important;
  }

  .text-base {
    line-height: 24px !important;
  }

  .text-lg {
    line-height: 28px !important;
  }

  .text-xl {
    line-height: 28px !important;
  }

  .text-2xl {
    line-height: 32px !important;
  }

  .text-3xl {
    line-height: 36px !important;
  }

  .text-4xl {
    line-height: 40px !important;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .interests-card {
      height: 350px !important;
    }

    .events-card {
      height: 420px !important;
    }
    
    .summary-card {
      height: 395px !important;
    }
  }

  @media screen and (min-width: 768px) {
    .about-and-summary {
      height: 400px;
    }
  
    .interests-and-events {
      height: 400px;
    }

    .groups-grid {
      height: 350px;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1300px) {
    .interests-and-events {
      height: 490px;
    }
  }
}