.r_friends {

	.rowEssentials {
		align-items: flex-start;

		@include mq(767) {
			padding-top: 20px;
		}
	}

	.profileCard {
		height: auto;

		h3 {
			font-size: 1.375rem;
			width: 100%;
			height: auto;

			@include mq($tablet) {
				text-align: center;
			}
		}

		.userSocial {
			position: static;

			a {
				margin-right: 0;
			}
		}
		.r_card__cnt {
			@include mq($tablet) {
				padding-bottom: 0;
			}
		}
	}

	&__avatar {
		margin-bottom: 44px;

		img {
			width: 142px;
		}
	}

	.referForm {
		padding-bottom: 10px;


		textarea {
			min-height: 38px;
			padding: 9px 20px;
		}

		.form__group {
			margin-bottom: 10px;
		}

		.btn {
			width: 100%;
			height: 38px;
			line-height: 38px;
		}
	}

	&__social {
		text-align: right;
		padding-top: 20px;
		border-top: 1px solid rgba($bc2,.15);
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.labelLike {
			font-size: 0.875rem;
			color: rgba($bc2,.5);
		}

		ul {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			li {
				margin-left: 20px;
			}
		}

		svg {
			fill: rgba($bc2,.15);
		}
	}

	.r_card {
		width: 100%;
		height: auto;
	}

	.search-friend {
		width: 100%;

		.row {
			display: flex;
			justify-content: space-between;

			@include mq(767) {
				flex-direction: column;

				.cell {
					width: 100%;
					margin-bottom: 10px;
				}
			}
		}

		.cell {
			margin-bottom: 0;
			width: calc(100% - 165px);

			&--btn {
				width: 145px;

				.btn {
					width: 100%;
				}
			}
		}
	}

	.toggleSearch {
		width: 17px;
		height: 17px;
		margin-left: auto;
		margin-right: 0;

		svg {
			width: 17px;
			height: 17px;
			fill: rgba($bc2,.15);
		}
	}

	.open .toggleSearch,
	.toggleSearch:hover {
		svg {
			fill: $bc1;
		}
	}

	.searchDrop {
		position: absolute;
		z-index: 2;
		top: 5px;
		right: 0;
		height: 42px;
		padding: 0 20px;
		transform: translate(-30px,0);
		opacity: 0;
		visibility: hidden;
		transition: $transition;
		background-color: #ffffff;

		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 42px;
		}

		.form__group {
			margin: 0;
		}

		input {
			height: 30px;
			max-width: 205px;
		}

		@include mq(767) {
			width: 100%;

			.form__group.cell {
				width: 100%;
			}

			input {
				max-width: 100%;
			}
		}
	}

	.open .searchDrop {
		opacity: 1;
		transform: translate(0,0);
		visibility: visible;
	}

	.r_main-friends-list, .r_referrals-list {
		width: 100%;

		&__row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			color: rgba($bc2,.5);
			border-bottom: 2px solid rgba($bc2,.15);
			padding-bottom: 20px;
			margin-bottom: 20px;

			&:last-of-type {
				border-bottom: 0;
				margin-bottom: 0;
				padding-bottom: 0;
			}

			@include mq(767) {
				flex-wrap: wrap;
				margin-bottom: 15px;
				padding-bottom: 15px;
			}
		}

		&__name {
			color: $bc2;
			font-weight: bold;
		}

	}

	.r_main-friends-list {

		&__avatar {
			width: 50px;
			height: 50px;
			margin-right: 20px;
		}
		&__info {
			width: 40%;
			margin-right: 20px;

			@include mq(767) {
				width: calc(100% - 70px);
				margin-right: 0;
			}
		}
		&__activity {
			width: 48%;
			font-size: 0.875rem;

			@include mq(767) {
				width: 100%;
				padding-top: 10px;
			}
		}
		&__buttons {
			width: 41%;
			font-size: 0.875rem;

			@include mq(767) {
				width: 100%;
				padding-top: 10px;
			}
		}
		&__points {
			@include mqq(768) {
				&:before {
					content: '•';
					margin-left: 5px;
					margin-right: 8px;
					color: $bc1;
				}
			}
		}
	}

	.r_referrals-list {

		&__desc {
			text-align: right;
			padding: 0 20px;
			font-size: 0.875rem;

			@include mq(767) {
				text-align: left;
				padding-left: 0;
			}
		}

		.btn {
			min-width: 100px;
			font-size: 11px;
		}

		&__name, &__desc {
			width: calc(50% - 50px);
		}

		@include mq(767) {
			&__name, &__desc {
				width: 100%;
				margin-bottom: 5px;
			}
		}

	}

	.r_pagination {
		padding-top: 40px;
	}

	.refer-incentive {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5px;
		flex-direction: row;
		padding: 10px 20px;
		font-size: 0.875rem;
		font-weight: bold;

		.btn {
			width: 32px;
			height: 32px;
			padding: 0;
			min-width: 1px;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				fill: #fff;
				width: 17px;
				height: 17px;
			}
		}

		@include mq(767) {
			padding: 10px 30px;

			span {
				width: calc(100% - 50px);
			}
		}
	}
}
