.whitelabel-page {
	// box-shadow: 0 15px 20px rgba(217, 217, 236, 0.52);

	.whitelabel-cnt {

		svg {
			max-width: 100%;
		}
		h1, h2 {
			font-weight: 500;
		}
		h1 {
			font-size: 3.75rem;
			line-height: 1;

			@include mq(1559) {
				font-size: 3rem;
			}

			@include mq(979) {
				font-size: 2.2rem;
			}
		}

		h2 {
			font-size: 2.8625rem;

			@include mq(1559) {
				font-size: 2.5rem;
			}

			@include mq(979) {
				font-size: 1.75rem;
			}
		}

		.smaller-heading {
			font-size: 2.8625rem;
			margin-bottom: 2rem;
		}
	}

	h4.tag {
		color: $bc1;
		text-transform: uppercase;
		font-size: 15px;
		margin-bottom: 10px;
	}
	
	header {
		background-color: #fff;
	}

	.landing-visual {
		padding: 27px 0 87px;

		@include mq(1599) {
			padding: 20px 0 40px;
		}
		@include mq(979) {
			padding: 20px 0 20px;

			.r_container {
				flex-wrap: wrap;
			}
		}

		p {
			font-size: 1.5rem;
			line-height: 2rem;
		}

		&__desc {
			max-width: 550px;
		}

		&__graph {
			max-width: 382px;
			text-align: center;
		}

		@include mq(979) {

			&__desc {
				margin-bottom: 1px;
			}

			&__desc, &__graph {
				max-width: 100%;
				width: 100%;
			}
		}

		+ .alternate-splits {
			padding-top: 50px;
		}
	}

	.kpi-list {
		padding: 83px 0 100px;

		@include mq(1599) {
			padding: 50px 0;
		}

		@include mq(979) {
			padding: 20px 0;
		}

		&__items {
			margin: 0 -10px;

			@include mq(979) {
				margin: 0;
			}
		}

		&__item {
			max-width: 230px;
			font-size: 1rem;
			line-height: 1.5rem;
			text-align: center;
			box-shadow: 0 15px 20px rgba(217, 217, 236, 0.52);
			padding: 40px 15px 25px;
			background: #FFFFFF;
			border-radius: 5px;
			margin: 0 10px 20px;
		}

		&__value {
			line-height: 1;
			font-size: 3.75rem;
			font-weight: bold;
			margin-bottom: 25px;

			@include mq(979) {
				font-size: 2.75em;
				margin-bottom: 15px;
			}

		}

		&__title {
			font-size: 1.5rem;
			line-height: 2rem;
			font-weight: 500;
			margin-bottom: 25px;

			@include mq(979) {
				font-size: 1.25rem;
			}
		}
	}

	.r_blog-posts {
		margin-bottom: 0;
		padding-top: 100px;
		margin-top: 0;

		@include mq(1399) {
			padding-top: 30px;
		}

		@include mq(979) {
			padding-top: 20px;
		}

		&__item {
			box-shadow: 0 15px 20px rgba(217, 217, 236, 0.52);

			@include mq(979) {
				width: 100%;
			}
		}
	}

	.cycle-cnt {
		@include mq(979) {
			display: block;
		}
	}

	.cycle-item {
		@include mq(979) {
			//width: 100%;
			//min-width: 100%;
			margin: 0;
		}
	}

	.round {
		border-radius: 50%;
	}

	.no-margin {
		margin: 0;
	}

	.full-width {
		width: 100%;
	}
}

.alternate-splits {
	padding-bottom: 100px;

	@include mq(1199) {
		padding-bottom: 50px;
	}
	@include mq(767) {
		padding-bottom: 15px;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		font-size: 1.25rem;
		line-height: 2rem;
		padding-bottom: 50px;

		@include mq(1199) {
			padding-bottom: 35px;
		}
		@include mq(767) {
			padding-bottom: 15px;
			display: block;
		}

		&.reverse {
			.alternate-splits__graph {
				order: -1;
			}
		}
	}
	h2 {
		margin-bottom: .5rem;
	}
	h3 {
		font-size: 2.0875rem;
		line-height: 1.2;
		font-weight: bold;
		margin-bottom: 2rem;

		@include mq(979) {
			font-size: 1.75rem;
			margin-bottom: 1.2rem;
		}
	}

	.benefits-listing {
		li {
			font-size: 1.1rem;
			padding-left: 25px;
			position: relative;

			&:before {
				content: '';
				//background-image: url('../../images/front/svg-sprite-source/r_check.svg') !important;
				@include bg('svg-sprite-source/r_check.svg', 50% 58%);
				background-size: 7px auto;
				width: 12px;
				height: 12px;
				background-color: $bc1;
				display: block;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 10px;
			}
		}
	}

	.subheader {
		font-size: 1.2rem;
		margin-bottom: 3rem;
	}
	p {
		margin-bottom: 10px;
	}
	p.heineken {
		font-size: 1rem;
		line-height: 1.5rem;
	}
	&__graph {
		text-align: center;
	}

	&__desc {
		padding-right: 15px;
		margin-bottom: 15px;
	}
	&__desc, &__graph {
		width: 48%;
		max-width: 48%;

		@include mq(767) {
			max-width: 100%;
			width: 100%;
		}
	}
}

.existing-infrastructure {
	padding: 60px 0 100px 0;

	@include mq(768) {
		padding: 20px 0;
	}

	@include mq(900) {
		.flex {
			flex-direction: column;
		}
		.existing-infrastructure__cell {
			margin-bottom: 15px;
		}
	}

	img {
		margin-bottom: 15px;
	}

	&__cell {
		padding: 0 10px;
		font-size: 16px;
	}
}

.available-platforms {
	padding: 50px 0 64px;

	&__item {
		padding: 15px 15px 32px;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 15px 20px rgba(217, 217, 236, 0.52);
		min-width: calc((100% - 40px) / 3);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		@include mq(899) {
			min-width: 100%;
			width: 100%;
			margin-bottom: 15px;
		}
	}
	&__logo {
		height: 81px;
		display: flex;
		align-items: center;
	}
	&__title {
		font-size: 1.25rem;
		line-height: 2rem;
		margin-bottom: 1.25rem;
	}
}

.plans-choice {
	padding: 50px 0 64px;

	&__item {
		padding: 15px 15px 32px;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 15px 20px rgba(217, 217, 236, 0.52);
		width: calc((100% - 40px) / 3);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		font-size: 1.5rem;
		line-height: 2rem;
		position: relative;
		z-index: 1;
		text-align: center;

		&.expanded {
			.pricingForm {
				opacity: 1;
				visibility: visible;
			}
		}

		@include mq(767) {
			font-size: 1rem;
			line-height: 1.23;
		}
	}
	&__icon {
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1.12rem;
		text-align: center;
	}
	p {
		margin-bottom: 1.8rem;
	}
	&__title {
		font-size: 2.1875rem;
		line-height: 2.5625rem;
		margin-bottom: 1.5rem;
		font-weight: bold;
	}
}
.plans-choice, .recruiter-plans {
	.pricingForm {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;


		.form__group {
			width: 100%;
		}
	}

	.blueForm {
		background-color: rgba($bc2, .85);
	}
}


.impact-calculator {
	padding: 65px 0;

	@include mq(1599) {
		padding: 45px 0;
	}
	@include mq(767) {
		padding: 30px 0;
	}

	.r_container {
		background: #FFFFFF;
		box-shadow: 0 15px 29px rgba(217, 217, 236, 0.52);
		border-radius: 5px;
		padding-top: 40px;

		@include mq(1599) {
			padding-top: 25px;
		}
		@include mq(767) {
			padding-top: 20px;
		}
	}

	&__holder {
		padding: 0 64px 43px;

		@include mq(1599) {
			padding: 0 40px 25px;
		}
		@include mq(767) {
			padding: 0 20px 20px;
		}
	}



	&__sliders {
		max-width: 464px;
		padding: 0 10px;

		@include mq(959) {
			margin-bottom: 20px;
		}
	}

	&__output {
		text-align: center;
		max-width: 314px;
		padding: 25px 20px;
		box-shadow: 0 15px 20px rgba(217, 217, 236, 0.52);
	}

	&__sliders, &__output {
		width: 100%;

		@include mq(959) {
			max-width: 100%;
			min-width: 100%;
		}
	}

	&__slider-item {
		margin-bottom: 33px;

		&:last-child {
			margin-bottom: 0;
		}

		.theProgressBar {
			height: 2px;

			.ui-slider-handle {
				transform: translate(0,-50%);
				background: #ffffff;
				box-shadow: 0 3px 10px rgba(1, 34, 67, 0.2);
			}
		}

		.ui-slider-handle {
			&:before {
				content: attr(data-indicator);
				position: absolute;
				bottom: 100%;
				left: 50%;
				transform: translate(-50%,0);
				font-size: 12px;
				color: #8091a1;
			}
		}
	}

	&__slider-title {
		margin: 0 -7px 25px;
		font-size: 1.25rem;

		@include mq(1399) {
			font-size: 1.15rem;
		}

		@include mq(767) {
			font-size: 1rem;
		}
	}

	&__slider-labels {
		padding-top: 15px;
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		color: #8091a1;
		margin: 0 -7px;
	}

	// Output

	&__output-value {
		font-size: 2.5rem;
		font-weight: 500;
		color: $bc1;

		@include mq(1399) {
			font-size: 2.4rem;
		}
		@include mq(767) {
			font-size: 1.4rem;
		}
	}
	&__output-label {
		font-size: 1.5rem;
		margin-bottom: 35px;

		@include mq(1399) {
			font-size: 1.25rem;
			margin-bottom: 25px;
		}

		@include mq(767) {
			font-size: 1.15rem;
			margin-bottom: 15px;
		}
	}

	.userNotes {
		vertical-align: text-bottom;

		svg {
			width: 8px;
			transform: translateX(-10px);
		}
	}
}

.large-testimonial {
	padding: 25px 0;

	&__header {
		font-size: 1.25rem;
		margin-bottom: 10px;
	}
	&__avatar {
		margin-right: 20px;
	}
	&__body {
		font-size: 1.875rem;
		padding: 40px 10px 50px 40px;
		position: relative;
		z-index: 1;

		&:before, &:after {
			content: '';
			display: block;
			width: 32px;
			height: 24px;
			position: absolute;
		}

		&:before {
			top: 0;
			left: 0;
			background-image: url('../../images/front/quotes-top-left.svg');
		}

		&:after {
			right: 0;
			bottom: 0;
			background-image: url('../../images/front/quotes-bottom-right.svg');
		}

		@include mq(1299) {
			font-size: 1.5rem;
		}

		@include mq(767) {
			font-size: 1.25rem;
			padding: 25px 15px 25px 25px;
		}
	}
	
	.cycle-pager {
		position: absolute;
		z-index: 101;
		left: 0;
		bottom: -10px;
		right: 0;
	}
}

.free-trial {
	padding: 100px 0;

	@include mq(1299) {
		padding: 60px 0;
	}

	@include mq(767) {
		padding: 25px 0;
	}

	&__cnt {
		padding: 70px 0 40px;
		background: url('../../images/front/free-trial-splash.svg') 50% 50% no-repeat;
		background-size: auto 100%;
		max-width: calc(100% - 20px);
		margin: 0 auto;

		@include mq(767) {
			padding: 40px 0 20px;
			background-size: 100% 100%;
		}
	}
}

.recruiter-plans {
	padding-top: 30px;

	@include mq(979) {
		flex-direction: column;
	}

	&__item {
		background-color: #ffffff;
		width: calc((100% - 50px) / 3);
		margin-bottom: 25px;
		border-radius: 5px;
		text-align: center;
		padding: 0 30px 38px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		position: relative;

		&.full {
			min-width: 100%;
			padding: 15px 35px;
			flex-direction: row;
			justify-content: space-between;
		}

		&.expanded .pricingForm {
			opacity: 1;
			visibility: visible;
		}

		@include mq(979) {
			width: 100%;
			min-width: 100%;
		}
	}

	&__icon {
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__title {
		font-size: 2.1875em;
		line-height: 2.625rem;
		font-weight: bold;
		height: 78px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include mq(979) {
			font-size: 1.75rem;
			line-height: 1.5;
		}
	}

	&__cta {
		margin-top: auto;
		margin-bottom: 0;
	}

	h3 {
		margin: 0;
		padding: 20px 0;
	}
	&__body {
		width: 100%;
		text-align: left;
		font-size: 1.25rem;
		line-height: 2rem;
		margin-bottom: 30px;

		li {
			margin-bottom: 20px;
		}

		@include mq(979) {
			text-align: center;
		}
	}
}

.sticky-descriptions {

	.subtitle {
		font-size: 1.5rem;
		margin-bottom: 100px;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		margin-bottom: 124px;
		min-height: 402px;
		width: 100%;

		@include mq(767) {
			min-height: 250px;
			flex-direction: column;
		}
	}

	&__graph, &__desc {
		width: 50%;

		@include mq(767) {
			width: 100%;
		}

	}
	
	&__graph {
		position: relative;
		z-index: 1;
		padding-right: 20px;
		text-align: center;

		//&-bg {
		//	position: absolute;
		//	z-index: 0;
		//	top: 50%;
		//	left: 50%;
		//	transform: translate(-50%,-50%);
		//}
		&-elem {
			//position: relative;
			z-index: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);

			width: 100%;
			@extend %transition;
			display: none;


			&:first-of-type {
				display: block;
			}

			&.is-active {
				display: block;
			}

			img {
				max-width: 120%;
				position: relative;
				left: 50%;
				transform: translate(-50%);

				@include mq(767) {
					left: auto;
					transform: none;
					max-width: 90%;
				}
			}
		}

	}

	&__desc {
		padding-left: 16px;
		align-self: center;

		@include mq(767) {
			padding-left: 0;
			order: -1;
		}
	}

	&__title {
		margin-bottom: 1.5rem;
		font-size: 1.5rem;
	}
}

.about-us {
	overflow-wrap: break-word;

	p {
		font-size: 1.25rem;
		line-height: 2rem;

		a {
			color: $bc1;
			position: relative;
			z-index: 1;
			text-decoration: underline;
			text-decoration-color: transparent;

			&:hover {
				text-decoration-color: $bc1;
			}
		}
	}
}

.about-values {

	.slide {

		width: 100%;
		user-select: none;

		@include mqq(768) {
			display: flex !important;
		}

		p {
			font-size: 1rem;
			line-height: 1.5;
		}

		h3 {
			margin-bottom: calc(20 / 16) * 1rem;
		}
	}

	.cycle-arrow {
		background-color: #F1F3F6;
		width: 30px;
		height: 30px;
		margin-right: 3px;
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 100;

		&.cycle-prev {
			transform: translate(-2px,0);
		}
		&.cycle-next {
			transform: translate(2px,0);
		}

		svg {
			width: 7px;
			height: 14px;
			stroke: $bc1;
		}
	}

	@include mqq(768) {
		&__desc {
			max-width: 463px;
			padding-right: 20px;
		}

		&__graph {
			text-align: center;
		}
	}



	@include mq(979) {
		.slider .flex {
			display: flex;
			justify-content: center;
		}

		&__graph {
			max-width: 260px;
			margin: 0 auto;
		}
	}
}

.about-achievements {
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		border-radius: 5px;
		background-color: #fff;
		filter: drop-shadow(0 4px 10px rgba(#000,.15));
		width: 300px;
		height: 300px;
		margin: 0 0 30px;
		font-size: 1.5rem;
		text-align: center;
		padding: 30px 20px;

		@include mq(979) {
			width: 49%;
			margin-bottom: 20px;
			padding: 15px;
			font-size: 1.25rem;
		}
		@include mq(767) {
			width: 100%;
			margin-bottom: 15px;
			height: auto;
		}
	}

	img {
		margin-bottom: 10px;
	}

	small {
		font-size: 1rem;
	}
}

.page-title-visual {
	background: $bc2;
	//url('../../images/front/page-title-bubbles.svg') 10px 50% no-repeat;
	min-height: 633px;
	padding-top: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	text-align: center;

	.r_container > div {
		flex-grow: 1;
	}

	&.short {
		min-height: 400px;
	}

	@include mq(1200) {
		min-height: 1px;
		padding: 100px 0 35px;

		&.short {
			min-height: 1px;
		}
	}

	.r_container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include mq(767) {
			flex-direction: column;
		}
	}

	&__desc {
		max-width: 520px;
		margin-right: 40px;
		text-align: left;
	}

	h1 {
		width: 100%;
		font-weight: normal;

		.bc {
			font-weight: bold;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}

	a {
		color: $bc1;
	}
}

.page-section {
	padding: 65px 0;

	@include mq(1599) {
		padding: 45px 0;
	}
	@include mq(767) {
		padding: 30px 0;
	}

	.alternate-splits__item {
		font-size: 1rem;
		line-height: 1.5;
		padding-bottom: 0;

	}

	h2 {
		font-weight: normal;

		.bc {
			display: inline;
			font-weight: bold;
		}
	}

	p {
		color: #979797;
	}

	a {
		color: $bc1;
	}
}

.career-platform-solutions {

	h2 {
		font-weight: normal;

		.bc {
			display: inline;
			font-weight: bold;
		}
	}

	p {
		max-width: 520px;
	}

	&__list {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-top: 100px;
	}

	&__item {
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 49%;
		padding: 0 25px 40px;
		border-radius: 5px;
		color: #979797;
		text-align: center;
		box-shadow: 0 3px 15px rgba(#000,.06);

		&:first-child {
			border-top-left-radius: 35px;
		}

		&:last-child {
			border-top-right-radius: 35px;
		}

		@include mq(540) {
			width: 100%;

			&:first-child {
				margin-bottom: 70px;
				border-top-right-radius: 35px;
			}

			&:last-child {
				border-top-right-radius: 5px;

				border-bottom-right-radius: 35px;
				border-bottom-left-radius: 35px;
			}
		}
	}
	img {
		margin-top: -60px;
		margin-bottom: 1.25rem;
	}

	&__title {
		font-weight: bold;
		color: $bc2;
		font-size: 1.5rem;
		margin-bottom: 1rem;
	}
	.btn {
		margin-top: auto;
		margin-bottom: 0;
	}
}

.sustainable-benefits {
	position: relative;

	img {
		position: absolute;
		bottom: -13px;
		right: 0;

		@include mq(1490) {
			display: none;
		}
	}

	h1 {
		color: $bc2 !important;
	}

	&__item {
		width: 100%;
		max-width: 307px;
		border-bottom: 18px;
		background-color: #fff;
		box-shadow: 0 3px 15px rgba(#000,.06);
		text-align: center;

		padding: 50px 30px;

		&:first-child {
			border-top-left-radius: 35px;
		}
		&:last-child {
			border-bottom-right-radius: 35px;
		}

		@include mq(1000) {
			max-width: 100%;
			padding: 30px 15px;

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}

	&__title {
		color: $bc2;
		font-size: 1.375rem;
		margin-bottom: 30px;
	}
}

.carousel-info {
	margin: 25px auto;

	.cycle-carousel-wrap {
		display: flex;
		align-items: stretch;
	}

	.slide {
		width: 712px;
		background-color: #fff;
		box-shadow: 0 3px 15px rgba(#000,.06);
		white-space: normal;
		padding: 25px 15px;
		max-width: 80vw;
	}

	&__image {
		min-width: 197px;
		margin-right: 15px;
	}

	&__title {
		font-size: 1.5rem;
		margin-bottom: 20px;
	}
}

.white-bg .carousel-info .slide {
	background-color: #F1F3F6;
}

.image-bottom-right-block {
	position: relative;

	.image-bottom-right {
		position: absolute;
		right: 0;
		bottom: -20px;
	}
}

.four-steps-block {
	counter-reset: mission;
	background-color: #F1F3F6;
	box-shadow: 0 3px 15px rgba(#000,.06);
	margin-top: 100px;
	border-radius: 5px;

	li {
		counter-increment: mission;
		position: relative;
		width: 25%;
		padding: 0 25px 55px;
		text-align: center;
		font-size: 1.2rem;
		color: $bc2;

		img {
			display: block;
			margin: -50px auto 30px;
		}

		img, span {
			position: relative;
			z-index: 2;
		}

		&:before {
			content: counter(mission);
			position: absolute;
			z-index: 1;
			color: $bc1;
			font-weight: bold;
			opacity: .26;
			font-size: 80px;

			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);

		}
	}

	@include mq(900) {
		margin-top: 0;
		display: block;
		background-color: transparent;
		box-shadow: none;

		li {
			display: flex;
			align-items: center;
			text-align: left;
			padding: 15px 10px;
			width: 100%;
			background-color: #F1F3F6;
			box-shadow: 0 3px 15px rgba(#000,.06);
			border-radius: 5px;

			&:not(:last-child) {
				margin-bottom: 15px;
			}

			img {
				margin: 0 10px 0 0;
			}

			&:before {
				position: static;
				font-size: 40px;
				opacity: 1;
				transform: none;
				margin-right: 10px;
			}
		}
	}
	@include mq(540) {
		li img {
			width: 100px;
		}
	}
}

.about-us-benefits {

	.tabbedcnt {
		padding: 0 15px;
		margin-bottom: 15px;
		display: flex;
		align-items: center;

		@include mq(980) {
			flex-wrap: wrap;
		}

	}

	li {
		display: flex;
		align-items: center;
		cursor: pointer;

		img {
			margin-right: 10px;
		}

		&.current {
			font-weight: bold;
		}

		@include mq(980) {
			width: 50%;
			margin-bottom: 10px;
		}
		@include mq(580) {
			width: 100%;
		}
	}

	.tab__cnt {
		padding: 40px 65px 65px;

		@include mq(980) {
			padding: 15px 20px 0;

			.flex {
				flex-wrap: wrap;
			}
		}
	}

	.col {
		width: 40%;

		@include mq(1200) {
			width: 48%;
		}
		@include mq(980) {
			width: 100%;
			margin-bottom: 25px;
		}
	}

	h3 {
		border-bottom: 1px solid #DEDEDE;
		padding-bottom: 40px;
		margin-bottom: 40px;

		display: flex;
		align-items: center;

		@include mq(980) {
			margin-bottom: 20px;
			padding-bottom: 20px;
		}

		img {
			margin-right: 15px;
		}
	}

	h3, h5 {
		color: $bc2;
		font-weight: normal;


		.bc {
			font-weight: bold;
			display: inline;
		}
	}
	h5 {
		font-size: 1.5rem;
	}

	.tab__cnt {
		display: none;

		&.current {
			display: block;
		}
	}
}

.team-members {

	&__item {
		width: 308px;
		padding: 0 15px;
		max-width: 33%;

		&:hover img.rollover {
			opacity: 1;
			visibility: visible;
		}

		@include mq(767) {
			width: 50%;
			max-width: 50%;
		}
	}

	&__pic {
		width: 308px;
		height: 0;
		padding-top: 100%;
		position: relative;
		display: flex;
		max-width: 100%;
		margin: 0 auto;
	}

	img {
		position: absolute;
		margin: auto;
		max-width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

		&.initial {
			z-index: 1;
		}

		&.rollover {
			z-index: 2;
			opacity: 0;
			visibility: hidden;
			transition: all 0.25s ease-in-out;
		}
	}
	&__name {
		font-size: 20px;
		font-weight: bold;
	}
	&__title {
		letter-spacing: .3;
	}
}

.page-nav {
	display: flex;
	justify-content: center;
	transform: translateY(50%);
	margin-bottom: 50px;

	@include mq(767) {
		flex-wrap: wrap;
		transform: none;
		margin-bottom: 20px;
	}

	li {
		width: calc(25% - 4px);
		display: flex;
		align-items: stretch;
		margin: 0 8px;

		@include mq(767) {
			width: 100%;
			margin: 0 0 4px;
		}
	}

	a {
		background-color: #fff;
		box-shadow: 0 3px 15px rgba(#000,.06);
		min-height: 45px;
		padding: 12px 20px;
		font-size: 18px;
		border-radius: 5px;
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;

		&:hover {
			color: #fff;
			background-color: $bc1;
		}
	}
}

.page-section .gray-card {
	padding: 50px 80px;
	box-shadow: 0 3px 15px rgba(#000,.06);
	border-radius: 5px;
	background-color: #F1F3F6;
	color: $bc2
}

.pop-out-icons-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	img {
		height: 60px;
		margin: -30px 10px -5px 0;
	}

	li {
		display: flex;
		align-items: center;
		background-color: #fff;
		border-radius: 5px;
		margin-bottom: 35px;
		box-shadow: 0 3px 15px rgba(#000,.06);
		max-width: 470px;
		padding: 15px 10px;
		width: 100%;
		color: $bc2;

		@include mq(1000) {
			max-width: 100%;
		}
	}
}

