.r_redesign {
	footer {
		padding: 45px 0;
		user-select: none;
		position: relative;
		z-index: 0;


		@include mq(767) {
			padding: 25px 0;
		}

		.r_container {
			&.top {
				display: flex;
				justify-content: space-between;
				margin-bottom: 65px;

				@include mq(767) {
					flex-direction: column;
					margin-bottom: 30px;
				}
			}
		}

		h3 {
			margin-bottom: 2rem;

			@include mq(767) {
				margin-bottom: 1.5rem;
			}
		}

		.r_nl-block {

			form {
				position: relative;
				z-index: 1;
				max-width: 390px;
			}

			input {
				position: relative;
				z-index: 1;
				padding: 0 150px 0 25px;

			}

			.btn {
				position: absolute;
				height: 38px;
				z-index: 2;
				right: 0;
				top: 0;
				padding: 0 30px;
			}

			@include mq(767) {
				.btn {
					position: relative;
					margin-top: 15px;
				}
			}
		}

		.r_social-block {
			font-size: 0;
			order: -1;

			@include mq(767) {
				margin-bottom: 2rem;
			}

			li {
				display: inline-block;
				vertical-align: middle;
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}
			}

			a {
				display: block;
				width: 38px;
				height: 38px;
				background-color: rgba($bc2, .25);
				border-radius: 50%;
				position: relative;
				z-index: 1;
				overflow: hidden;

				&:hover {
					background-color: rgba($bc2, 1);
				}
			}

			svg.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				fill: #E7E6EA;

				&__fb {
					width: 10px;
				}

				&__yt {
					width: 19px;
				}

				&__insta {
					width: 17px;
				}
			}
		}

		.footer-menu {
			font-size: 0.8125rem;

			li {
				display: inline-block;
				vertical-align: middle;
				margin-right: 28px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
		&.normalize-with-redesign{
			padding: 0 0;
			h3{
				margin-bottom: inherit;
				font-size: 0.875rem;
				font-weight: 600;
				font-family: 'Ubuntu', sans-serif;
				color: #3d434f;
				&.text-xs-forced{
					font-size: 0.75rem;
					line-height: 1rem;
					margin-bottom: 0.5rem;
				}
			}
			li{
				min-height: 25px;
			}
		}
	}
}