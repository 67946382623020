section {
	.tabular-data {
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: -20px;

		@include mq(767) {
			margin-left: -30px;
			margin-right: -30px;
			margin-bottom: -30px;
		}
	}
}

.r_graduates-page {
	.tabular-data {
		&__td {
			&:first-child {
				max-width: 55px;
				width: 55px;
				min-width: 55px;

				@include mq($tablet) {
					max-width: 35px;
					min-width: 35px;
					position: absolute;
					z-index: 1;
					left: 0;
					top: 50%;
					margin-top: -10px;
				}
			}

			&:nth-last-child(1) { // add
				max-width: 50px;
				width: 50px;
				min-width: 50px;
				text-align: right;
				padding: 15px 5px;



				@include mq($tablet) {
					min-width: 22px;
					max-width: 22px;
					width: 22px;
					padding: 0;
					position: absolute;
					z-index: 1;
					right: 15px;
					top: 50%;
					margin-top: -11px;
				}
			}

			&:nth-last-child(2) { // notes
				max-width: 110px;
				width: 110px;
				min-width: 110px;
				text-align: center;

				@include mq(767) {
					text-align: left;
				}
			}

			&:nth-last-child(3) { // industry
				max-width: 165px;
				width: 165px;
				min-width: 165px;
				text-align: center;

				@include mq(767) {
					text-align: left;
				}
			}

			&:nth-last-child(4) { // exp
				max-width: 136px;
				width: 136px;
				min-width: 136px;
			}

			&:nth-last-child(5) { // score
				max-width: 150px;
				width: 150px;
				min-width: 150px;
			}

			&:nth-last-child(7) { // username
				min-width: 100px;

				@include mqq($tablet) {
					max-width: 100px;
					width: 100px;
				}

				@include mq($tablet) {
					min-width: 100%;
					max-width: 100%;
					font-weight: bold;
					font-size: 15px;
				}
			}

			&.source {
				max-width: 100px;
				width: 100px;
				text-align: left;

				@include mq($tablet) {
					max-width: 100px;
					width: 100px;
					margin-top: 0;
					position: static;
					padding: 2px 5px;
				}
			}

			&.experience {
				max-width: 120px;
				min-width: 120px;
				width: 120px;

				@include mq($tablet) {
					max-width: 120px;
					min-width: 120px;
					width: 120px;
				}
			}
		}

		.members {
			max-width: none;
			text-align: initial;
		}

		.r_nlist {
			li {
				margin-bottom: 3px;
				padding-left: 60px;
				border-top: 1px dotted rgba($bc2,.15);
				padding-top: 15px;
				padding-bottom: 15px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;

				@include mq(767) {
					padding-left: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:before {
					width: 10px;
					text-align: center;
					font-size: 13px;
					color: $bc2;
					left: 25px;
					margin-left: -5px;
					font-weight: normal;
					top: 15px;
				}

				.chTitle {
					max-width: 275px;
					width: 275px;
					min-width: 275px;

					@include mq(767) {
						width: 60%;
						max-width: 60%;
						min-width: 60%;
					}
				}
				.chCateg {
					max-width: 150px;
					width: 150px;
					min-width: 150px;

					@include mq(767) {
						width: 40%;
						max-width: 40%;
						min-width: 40%;
						text-align: right;
						padding-right: 15px;
					}
				}

				.chResult {
					@include mq(767) {
						width: 100%;
						padding-top: 15px;
					}
				}
			}

			@include mq($tablet) {
				li {
					&:before {
						left: -25px;
					}
				}
			}
		}
	}
}

.tabular-data {
	counter-reset: row;
	position: relative;

	@include mq($tablet) {
		border-top: 1px solid rgba($bc2,.15);
	}

	&.hasSelections {
		.tabular-data__row.first .tabular-data__td {
			@include mqq($tablet) {
				padding-top: 59px;
			}
		}

		.selectionOptions {
			opacity: 1;
			visibility: visible;
			transform: translate3D(0,0,0);
			z-index: 100;

			@include mq($tablet) {
				display: flex;
			}
		}
	}

	.selectionOptions {
		height: 45px;
		background: transparent linear-gradient(0deg, #24C274 50%, #62CE99 100%) 0% 0% no-repeat padding-box;
		position: absolute;
		z-index: -1;
		top: 46px;
		left: 0;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		color: #fff;
		display: flex;
		justify-content: flex-end;
		align-items: stretch;
		transform: translate3D(0,-50px,0);

		@include mq($tablet) {
			display: none;
			flex-wrap: wrap;
			position: fixed;
			top: auto;
			bottom: 0;
			height: auto;
		}


		.inviteUser {
			left: 50%;
			transform: translate(-50%,15px);
			max-width: 300px;
			color: #222;
			text-align: left;
			right: auto;
			top: 100%;
			width: 300px;


			&:before {
				right: 50%;
				transform: translate(50%,0);
			}

			label {
				display: block;
				margin-bottom: 5px;
			}
		}

		&__title, &__cell {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__title {
			margin-left: 0;
			margin-right: auto;
			justify-content: flex-start;
			padding: 0 20px 0 52px;
			font-size: 13px;

			@include mq($tablet) {
				min-width: 100%;
				padding: 10px 15px;
				text-align: center;
				justify-content: center;
				border-bottom: 1px solid #F2F4F6;
			}
		}

		&__cell {
			border-left: 1px solid #F2F4F6;
			padding: 0 25px;
			position: relative;
			z-index: 1;
			@extend %transition;

			@include mq($tablet) {
				width: 33.33%;
				padding: 10px 10px;
				position: static;

				&:nth-child(2) {
					border-left: 0 none;
				}
				
				&.showDrop {
					background-color: $bc1;
					color: #fff;

					.inviteUser {
						transform: translate(-50%,0) !important;
					}
				}

				.userPop:before {
					content: unset;
					display: none;
				}
			}

			> span {
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
			}

			.arrSort--icon {
				transform: none;
				margin-left: 5px;
			}

			.userPop {
				@include mq(767) {
					max-width: 100%;
					width: 100%;
					top: auto;
					bottom: 100%;
				}
			}
		}



		.showDrop {
			.arrSort--icon {
				transform: scale(-1);
			}

			.inviteUser {
				opacity: 1;
				visibility: visible;
				transform: translate(-50%,15px);
			}
		}
	}

	&__row {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		border-bottom: 1px solid rgba($bc2,.15);
		width: 100%;
		@extend %transition;

		&:not(.tabular-data__head) {
			counter-increment: row;
		}

		&:last-child {
			border-bottom: 0;
		}

		&.activeRow {
			border-bottom-color: transparent;

			.toggleArr {
				svg {
					transform: scaleX(-1);
					stroke: #8090A1;
				}
			}

			+ .tabular-data__row-details {
				max-height: 500px;
				border-bottom: 1px solid rgba($bc2,.15);
				padding-bottom: 20px;

				.tabular-data__row {
					border-bottom-color: transparent;

					.tabular-data__td {
						opacity: 1;
					}
				}

				@include mq(767) {
					padding-bottom: 5px;
				}
			}
		}

		&.checked {
			background-color: #F5FBF8;
		}

		@include mq($tablet) {
			position: relative;
			z-index: 1;
			padding: 10px 40px 10px 35px;
			justify-content: space-between;

			+ .tabular-data__row-details {
				padding: 0;
			}
		}
	}

	&__td {
		padding: 18px 5px;
		@extend %transition;
		flex: 1 auto;
		line-height: 16px;
		vertical-align: middle;

		&.sortable {
			cursor: pointer;
		}

		&.counter {
			text-align: left;
			&:before {
				content: counter(row) '. ';
			}
		}

		@include mq($tablet) {
			// padding: 2px 5px;

			&[data-label] {
				min-width: unset;
				margin-right: 15px;
				width: auto;

				&:before {
					content: attr(data-label)':' ;
					font-style: italic;
					margin-right: 5px;
					opacity: .5;
				}
			}
		}

	}

	&__row-details {
		@extend %transition;
		max-height: 0;
		overflow: hidden;
		display: block;
		border-bottom-color: transparent;
		padding-left: 60px;
		padding-right: 0;

		.tabular-data__row {
			border: 0;

			.tabular-data__td {
				padding: 4px 5px 4px 0;
				opacity: 0;

				&:first-child, &:last-child {
					max-width: 100%;
					text-align: left;
				}

				&:first-child {
					font-weight: bold;
					font-size: 12px;
					max-width: 150px;
				}
			}
		}

		@include mq($tablet) {
			padding-left: 35px;

			.tabular-data__row {
				padding-top: 0;
				padding-bottom: 0;

				.tabular-data__td {
					padding: 0 0 0 5px;

					&:first-child, &:last-child {
						position: static;
						margin: 0;
					}
				}
			}
		}
	}

	&__head {
		font-weight: bold;
		background-color: #D2EDED;
		border-bottom: 0;
		font-size: 12px;

		.tabular-data__td {
			border-bottom: 0;
			padding: 15px 5px;
			text-transform: uppercase;

			&.counter:before {
				content: unset;
				display: none;
			}

		}

		+ .tabular-data__row .tabular-data__td {
			border-top: 0;
		}

	}

	&__extra {
		margin-top: 20px;
		margin-left: -60px;
		margin-bottom: -20px;

		@include mq($tablet) {
			margin-left: 0;
			padding-left: 40px;
		}

		@include mq(767) {
			margin-bottom: 0;
			padding-top: 10px;
			margin-top: 10px;
		}
	}

	@include mq($tablet) {
		.btn {
			margin-bottom: 0;
		}
	}

	.notif-no {
		transform: translate(100%,-50%);
		position: absolute;
		right: 0;
		top: 0;
		font-size: 12px;
		padding: 0 3px;
	}

	.tabular-data__td {

		.userNotes {
			z-index: 1;

			svg {
				position: static;
			}

		}
		.userPop {
			top: 100%;
			min-width: 260px;
			left: 50%;
			transform: translate(-50%, 15px);
			padding: 10px;
			text-align: left;
			z-index: 7;

			label {
				margin-bottom: 5px;
				display: block;
			}

			&.open {
				transform: translate(-50%, 5px);
			}


			&:before {
				left: 50%;
				transform: translateX(-50%);
			}


			.previousMessages {
				margin-bottom: 15px;
			}
			//@include mq($tablet) {
			//	left: 50%;
			//	transform: translate(0, 15px);
			//
			//	&.open {
			//		transform: translate(0, 5px);
			//	}
			//}

			&.userNotesCnt {
				@include mqq($tablet) {
					&:before {
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
        }

		.btn {
			&.small {
				font-size: 12px;
				font-weight: normal;
				padding-left: 8px !important;
				padding-right: 8px !important;
				}
			}
		}

	&.matching-table {
		display: table;
		width: 100%;
		text-align: center;
		background-color: #ffffff;
		margin-bottom: 20px;
		counter-reset: row;
		border-radius: 5px;

		.tabular-data__row {
			display: table-row;
		}
		.tabular-data__row:not(.tabular-data__head) {
			counter-increment: row;

			.tabular-data__td:first-child a {
				position: relative;
				margin-left: 20px;

				&:before {
					content: counter(row)'.';
					position: absolute;
					z-index: 1;
					top: 1px;
					right: 100%;
					margin-right: 3px;
				}
			}
		}
		.tabular-data__head {

			.tabular-data__td {
				border-top: 0 none;
			}

			+ .tabular-data__row .tabular-data__td {
				border-top: 0;
			}
		}
		.tabular-data__td {
			display: table-cell;
			border-top: 1px solid rgba($bc2,.15);

			&:first-child {
				text-align: left;
			}

		}

		&.response-td {
			width: 125px;
		}

		@include mq($tablet) {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			flex-wrap: wrap;

			.tabular-data__row, .tabular-data__td {
				display: block;
			}

			.tabular-data__head {
				display: flex;
				justify-content: space-between;
			}

			.tabular-data__head .tabular-data__td {
				&:not(:first-child), &:not(.filterHandler) {
					display: none;
				}
				&:first-child, &.filterHandler {
					display: flex;
				}
			}


			.tabular-data__row {
				padding: 0;
				width: 100%;
				position: relative;
				z-index: unset;
			}
			.tabular-data__td {
				border-top: 0 none;
				text-align: left;
				padding: 6px 20px;
				display: flex;
				justify-content: flex-start;

				&.centred {
					justify-content: center;
				}
			}

			.relative {
				width: 100%;
				text-align: center;
			}
		}

		@include mq(767) {
			.tabular-data__row {
				width: 100%;
			}
		}
	}

	.toggleArr {
		margin: 0 auto;
	}

	.filterHandler {
		max-width: 62px;

		.editIco svg {
			fill: $bc2;
			margin: 0;
		}

		&.active {
			background-color: #fff;

			svg {
				transform: scale(-1);
				fill: $bc1;
			}
		}
	}

	.filterHolder {
		text-align: left;
		position: absolute;
		z-index: 101;
		left: 0;
		right: 0;
		padding: 20px 10px;
		background-color: #ffffff;

		text-transform: none;
		font-size: 12px;
		font-weight: normal;

		filter: drop-shadow(0 2px 3px rgba($bc2,.15));
		opacity: 0;
		visibility: hidden;
		transform: translate(0,10px);

		&.active {
			opacity: 1;
			visibility: visible;
			transform: translate(0,0);
		}

		&__cnt {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		&__cell {
			padding: 5px 10px;
			max-width: 16.66%;
			min-width: 16.66%;
		}

		&__footer {
			span {
				color: #8090A1;
				cursor: pointer;

				&:hover {
					color: $bc2;
				}
			}
		}

		label {
			margin-bottom: 5px;
			padding: 0 10px;
			font-size: 12px;
			display: flex;
			justify-content: space-between;
		}
		.addFilter {
			display: flex;
			border-radius: 50%;
			width: 14px;
			height: 14px;
			background-color: $bc2;
			color: #fff;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			@extend %transition;
			user-select: none;

			&:hover {
				background-color: $bc1;
			}
		}

		@include mq($tablet) {
			top: 32px;

			.filterHolder__cnt {
				flex-wrap: wrap;
			}
			.filterHolder__cell {
				min-width: 33%;
				max-width: 33%;
			}
		}
		@include mq(767) {
			.filterHolder__cell {
				min-width: 50%;
				max-width: 50%;
			}
		}
	}

	.select2 {
		margin-bottom: 10px;
		display: block;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.select2-container--default .select2-selection--single {
		height: 28px;
		padding: 0 10px;
	}

	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 28px;
		padding: 0;
		font-size: 13px;
	}

	.candidate-specs, .activeRow {
		background-color: #F7FCFC;
	}

	.candidate-specs {
		filter: drop-shadow(0 3px 2px rgba($bc2,.15));
		position: absolute;
		z-index: 99;
		left: 0;
		right: 0;
		padding: 0;
		text-align: left;



		svg {
			fill: $bc1;
		}

		&__cnt {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 20px 40px 0;
		}

		ul {
			width: 25%;
			margin-bottom: 40px;
			padding-left: 30px;
			position: relative;
			font-size: 13px;
		}

		li {
			margin-bottom: 5px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.iconH {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
		}

		.theTitle {
			padding-bottom: 10px;
			display: block;
		}

		@include mq($tablet) {
			position: static;

			ul {
				width: 33%;
			}
		}

		@include mq(767) {
			ul {
				width: 50%;
			}
		}
	}

	.inviteIco {
		z-index: 1;
	}
}


.nurture-page .select2-container {
	font-size: 13px;
}

.add-pipeline {
	margin-bottom: 25px;
	text-align: center;

	label {
		margin-bottom: 8px;
		display: block;
	}

	&__adding {
		display: none;
	}

	&.active {
		.add-pipeline__adding {
			display: block;
		}
		.add-pipeline__trigger {
			display: none;
		}
	}

	input[type='text'],.select2-container {
		min-width: 240px;
		max-width: 240px;
		height: 34px;
		background-color: #ffffff;
		position: relative;
		z-index: 1;
	}

	.select2-container .select2-selection__rendered {
		line-height: 34px;
	}

	.select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 34px;
	}

	.add-btn {
		transform: translateX(-50%);
		position: relative;
		z-index: 2;
		min-width: 1px;
	}

	.cancel {
		cursor: pointer;

		&:hover {
			color: $bc1;
		}
	}
}