@media screen and (min-width: 760px) {
    .r_bottom_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .r_poweredby {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
  }


  @media screen and (max-width: 760px) {
    .r_poweredby {
        margin-top: 2rem;
    }

    .r_poweredby img {
        padding-top: 1rem;
    }
  }

.company-selector-group {
	display: flex;
	width: 20rem;
	left: -22.5rem;
	padding: 24px !important;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
}

.language-selector-group {
    width: 20rem !important;
	left: -22.5rem !important;
}