.sideMenu ._filter-list {
	position: static !important;

	&__title {
		display: block;
		font-size: 15px;
		font-weight: bold;
		padding: 20px 15px;
		z-index: 1;
		position: relative;
	}

	&__clipped {
		@extend %acc1;
	}

	&__cnt {
		padding: 20px;
		text-transform: none;
		font-weight: normal;
		color: $bc2;
		background-color: #ffffff;
	}

	li.expanded, li.extended {

		&:not(.itExtends) {
			background-color: #ffffff;
		}

		.toggleArr {
			transform: rotate(90deg) scale(-1);

			svg {
				stroke: rgba($bc2, .25) !important;
			}
		}

		&:hover {
			.toggleArr svg {
				stroke: rgba($bc2,.25) !important;
			}
		}

		&.itExtends:hover {
			.toggleArr svg {
				stroke: #fff !important;
			}
		}

		._filter-list__clipped {
			@extend %acc2;
		}
	}

	&.js-filter-list .toggleArr {
		pointer-events: auto !important;
	}

	&__radio-item {
		position: relative;
		z-index: 1;

		&:not(:last-child) {
			margin-bottom: 12px;
		}

		input[type='radio'] {
			+ label {
				padding-left: 30px !important;
				display: block;

				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					z-index: 1;
					border-radius: 50%;
					top: 50%;
					@extend %transition;
				}
				&:before {
					left: 0;
					width: 16px;
					height: 16px;
					border: 2px solid rgba($bc2,.25);
					transform: translateY(-50%);
				}
				&:after {
					left: 8px;
					width: 9px;
					height: 9px;
					background-color: $bc1;
					transform: translate(-50%,-50%);
					opacity: 0;
				}
			}

			&:checked + label {
				color: $bc2 !important;
				background-color: transparent !important;

				&:before {
					border-color: $bc1;
				}
				&:after {
					opacity: 1;
				}
			}
		}
	}

	li:hover, .extended {

		> div > .the-title {
			background-color: $bc2;
			color: #ffffff;
		}
		> .toggleArr svg {
			stroke: #fff  !important;
		}
	}

	li {
		padding: 0 !important;
		position: relative;

		ul {
			@extend %acc1;
			visibility: hidden;
			background-color: #ffffff;
			border-top: 1px solid #fff;

			li {
				background-color: rgba($bc2,.01);
			}
		}

		.toggleArr {
			top: 6px !important;
			display: flex !important;
			pointer-events: none !important;

			@include mq($tablet) {
				top: 0 !important;
				height: 31px;
				width: 31px;
			}

			svg {
				stroke: rgba($bc2,.25) !important;
			}
		}
	}

	.extended {
		> ul {
			@extend %acc2;
			visibility: visible;
		}
		> .toggleArr {
			transform: rotate(90deg)scale(-1);
		}
	}

	input {
		position: absolute;
		z-index: 1;
		left: 0;
		opacity: 0;
		visibility: hidden;

		&[type='checkbox']:checked + label {

			&:after {
				background-color: $bc1;
				background-size: 17px 14px;
				border: 1px solid $bc1;
			}
		}
		&[type='radio'] + label {
			padding: 0;
		}
		&[type='radio']:checked {
			+ label {
				background-color: $bc2;
				color: #fff;

				.the-icon {
					border-color: #ffffff;
				}
				.the-number {
					opacity: 1;
					visibility: visible;
				}
				svg {
					fill: #fff;
				}
			}

			~ ul {
				@extend %acc2;
			}
		}
	}

	input[type='checkbox'] + label {
		position: relative;
		display: block;
		padding: 20px 35px 20px 20px;
		//@extend %transition;

		@include mq($tablet) {
			padding: 12px 25px 12px 15px;
		}

		&:after {
			border: 1px solid rgba($bc2,.15);
			content: '';
			width: 16px;
			height: 16px;
			position: absolute;
			z-index: 1;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			@extend %transition;

			@include mq($tablet) {
				width: 15px;
				height: 15px;
				right: 10px;
			}
		}

		&:hover {
			background-color: $bc2;

			&:after {
				border-color: #fff;
			}
		}
	}
}
