@mixin animation($animation, $duration, $delay) {
	-webkit-animation: $duration $animation $delay forwards;
	-moz-animation: $duration $animation $delay forwards;
	animation: $duration $animation $delay forwards;
}

// -webkit-animation-iteration-count: infinite;
// animation-iteration-count: infinite;

// -webkit-animation-timing-function: linear;
// animation-timing-function: linear;

// animation-play-state: paused;
//@for $i from 1 through 9 {
// .mainMenu li:nth-of-type(#{$i+1}) {
//  @include animation-delay( 4s + $i * .135s);
// }
//}

// usage:; .class {@include animation(fadeOut, .5s, 4.2s);}

@keyframes fadeIn {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes slideUp {
	0% {
		opacity: 0;
		transform: translateY(15px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideUpE {
	0% {
		opacity: 0;
		transform: translate(-50%, calc(-50% + 15px));
	}
	100% {
		opacity: 1;
		transform: translate(-50%, -50%);
	}
}

@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translateY(-15px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideDownE {
	0% {
		opacity: 0;
		transform: translate(-50%, calc(-50% - 15px));
	}
	100% {
		opacity: 1;
		transform: translate(-50%, -50%);
	}
}

@keyframes slideRight {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideRightE {
	0% {
		opacity: 0;
		transform: translate(calc(-50% - 15px), -50%);
	}
	100% {
		opacity: 1;
		transform: translate(-50%, -50%);
	}
}

@keyframes slideLeft {
	0% {
		opacity: 0;
		transform: translateX(15px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideLeftE {
	0% {
		opacity: 0;
		transform: translate(calc(-50% + 15px), -50%);
	}
	100% {
		opacity: 1;
		transform: translate(-50%, -50%);
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// INTRO
@keyframes bounce {
	0% {
		transform: translate3d(0, -32px, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes logo-movement {
	0% {
		transform: translate(465px, 40vh) scale(2);
		opacity: 0;
	}
	5% {
		transform: translate(465px, 40vh) scale(2);
		opacity: 1;
	}
	70% {
		transform: translate(465px, 40vh) scale(2);
	}
	85% {
		transform: translate(290px, 40vh) scale(2);
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

@keyframes logo-movementSmall {
	0% {
		transform: translate(calc(50vw - 30px), 40vh) scale(2);
		opacity: 0;
	}
	5% {
		transform: translate(calc(50vw - 30px), 40vh) scale(2);
		opacity: 1;
	}
	70% {
		transform: translate(calc(50vw - 30px), 40vh) scale(2);
	}
	85% {
		transform: translate(25vw, 40vh) scale(2);
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

@keyframes bodyclip {
	0% {
		height: 100vh;
		overflow: hidden;
	}
	99.99% {
		height: auto;
		overflow: inherit;
	}
	100% {
		height: auto;
		overflow: inherit;
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
//.introAnim, // removing logo animation for every homepage visit as per users feedback - logo animation remains for initial site visit.

.fullAnim {

	// height: 100vh;
	// overflow: hidden;
	// @include animation(bodyclip, 0.001s, 6.45s);

	.ball {
		@include animation(bounce, 0.5s, 0.5s);
		animation-direction: alternate;
		animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
		animation-iteration-count: 7;
	}

	.r_logo {
		opacity: 0;
		transform-origin: 0 50%;


		@include mqq($tablet) {
			transform: translate(480px, 40vh) scale(2);
			@include animation(logo-movement, 5s, 0.5s);
		}
		@include mq($tablet) {
			transform: translate(calc(50vw - 30px), 40vh) scale(2);
			@include animation(logo-movementSmall, 5s, 0.5s);
		}

		svg path {
			opacity: 0;
			visibility: hidden;
			@include animation(fadeIn, .75s, 3.5s);
		}
	}

	main, footer, nav, .langSelector {
		opacity: 0;
		visibility: hidden;
		@include animation(fadeIn, 0.5s, 5.5s);
	}

	main {
		animation-delay: 6.15s;
	}

	footer {
		animation-delay: 8.5s;
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// end INTRO

// next
$t1: 6.5s;
// $t1: 0s;

@keyframes linesTop {
	0% {
		max-width: 100%;
	}
	100% {
		max-width: 30px;
	}
}

@keyframes linesRight {
	0% {
		max-height: 100%;
	}
	100% {
		max-height: 30px;
	}
}

@keyframes drawLines01 {
	0% {
		top: 150px;
	}
	100% {
		top: 10px;
	}
}

@keyframes drawLines02 {
	0% {
		right: 150px;
	}
	100% {
		right: 10px;
	}
}

@keyframes drawLines03 {
	0% {
		bottom: 150px;
	}
	100% {
		bottom: 10px;
	}
}

@keyframes drawLines04 {
	0% {
		left: 60%;
	}
	100% {
		left: 20%;
	}
}

@keyframes drawLines05 {
	0% {
		bottom: 50%;
		opacity: 0;
	}
	1% {
		bottom: 50%;
		opacity: 1;
	}
	100% {
		bottom: 20%;
		opacity: 1;
	}
}

@keyframes drawLines06 {
	0% {
		left: 20%;
		right: 80%;
		opacity: 0;
	}
	1% {
		left: 20%;
		right: 80%;
		opacity: 1;
	}
	100% {
		left: 0;
		right: 60%;
		opacity: 1;
	}
}

@keyframes drawLines10 {
	0% {
		right: 40%;
		opacity: 0;
	}
	1% {
		right: 40%;
		opacity: 1;
	}
	100% {
		right: 10px;
		opacity: 1;
	}
}

@keyframes drawLinesLast {
	0% {
		bottom: 20%;
		opacity: 0;
	}
	1% {
		bottom: 20%;
		opacity: 1;
	}
	100% {
		bottom: 10px;
		opacity: 1;
	}
}

@keyframes popInFlag {
	0% {
		opacity: 0;
		transform: translate(-20px, -27px) scale(0.75);
	}
	1% {
		opacity: 1;
		transform: translate(-20px, -27px) scale(0.75);
	}
	40% {
		opacity: 1;
		transform: translate(-20px, -27px) scale(1.25);
	}
	100% {
		opacity: 1;
		transform: translate(-20px, -27px) scale(1);
	}
}

@keyframes dotsV {
	0% {
		opacity: 0;
		bottom: 100%;
	}
	1% {
		opacity: 1;
		bottom: 100%;
	}
	100% {
		opacity: 1;
		bottom: 0;
	}
}

@keyframes dotsH {
	0% {
		opacity: 0;
		left: 100%;
	}
	1% {
		opacity: 1;
		left: 100%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

.r_homepage.fullAnim {
	@include mqq(1200) {
		.intro-desc.dotted-module {
			opacity: 1;
			animation: none;

			.lines {
				&:before {
					max-width: 100%;
					@include animation(linesTop, 1.5s, $t1);
				}

				&:after {
					max-height: 100%;
					@include animation(linesRight, 1.5s, $t1);
				}
			}

			.intro-desc__cnt {
				opacity: 0;
				transform: translateY(15px);
				@include animation(slideUp, .75s, ($t1 + 1.2s));
			}

			h1 .sec {
				display: block;
				opacity: 0;
				transform: translateY(15px);
				@include animation(slideUp, 0.75s, ($t1 + 1.75s));
			}

			.intro-desc__graphic {
				opacity: 0;
				visibility: hidden;
				@include animation(fadeIn, 1s, ($t1 + 2.5s));
			}

			ul.gray_lines {

				li:nth-child(1) {
					top: 150px;
					@include animation(drawLines01, .5s, ($t1 + 3.25s));
				}

				li:nth-child(2) {
					right: 150px;
					@include animation(drawLines02, .5s, ($t1 + 3.25s));
				}

				li:nth-child(3) {
					bottom: 150px;
					@include animation(drawLines03, .5s, ($t1 + 3.25s));
				}

				li:nth-child(4) {
					left: 60%;
					@include animation(drawLines04, .5s, ($t1 + 3.25s));
				}

				li:nth-child(5), li:nth-child(6), li:nth-child(7), li:nth-child(8), li:nth-child(9), li:nth-child(10) {
					opacity: 0;
				}

				li:nth-child(5) {
					bottom: 50%;
					@include animation(drawLines05, .5s, ($t1 + 3.75s));
				}

				li:nth-child(6) {
					left: 20%;
					right: 80%;
					@include animation(drawLines06, .5s, ($t1 + 4.25s));
				}

				li:nth-child(10) {
					right: 40%;
					@include animation(drawLines10, .5s, ($t1 + 4.25s));
				}

				li:nth-child(7), li:nth-child(8), li:nth-child(9) {
					bottom: 20%;
					@include animation(drawLinesLast, .5s, ($t1 + 4.75s));
				}
			}

			p {
				opacity: 0;
				transform: translateY(15px);
				@include animation(slideUp, .75s, ($t1 + 5.15s));
			}

			ul.elems {

				li {
					opacity: 0;

					&.cog {
						transform: translate(calc(-50% - 15px), -50%);
						@include animation(slideRightE, .25s, ($t1 + 6s));
					}

					&.edit {
						transform: translate(-50%, calc(-50% - 15px));
						@include animation(slideDownE, .25s, ($t1 + 6.1s));
					}

					&.check {
						transform: translate(calc(-50% + 15px), -50%);
						@include animation(slideLeftE, .25s, ($t1 + 6.2s));
					}

					&.search {
						transform: translate(-50%, calc(-50% - 15px));
						@include animation(slideDownE, .25s, ($t1 + 6.3s));
					}

					&.house {
						transform: translate(calc(-50% - 15px), -50%);
						@include animation(slideRightE, .25s, ($t1 + 6.4s));
					}

					&.badge {
						transform: translate(-50%, calc(-50% + 15px));
						@include animation(slideUpE, .25s, ($t1 + 6.5s));
					}
				}
			}

			.flag {
				opacity: 0;
				transform: translate(-20px, -27px) scale(0.75);
				@include animation(popInFlag, .25s, ($t1 + 7s));
			}

			.dots {
				&.v:after, &.v:before {
					height: auto;
				}

				&.h:after, &.h:before {
					width: auto;
				}

				&.v:after {
					opacity: 0;
					bottom: 100%;
					@include animation(dotsV, .5s, ($t1 + 7.25s));
				}

				&.h:before {
					opacity: 0;
					left: 100%;
					@include animation(dotsH, .5s, ($t1 + 7.25s));
				}

				&.v:before {
					opacity: 0;
					bottom: 100%;
					@include animation(dotsV, .5s, ($t1 + 7.75s));
				}

				&.h:after {
					opacity: 0;
					left: 100%;
					@include animation(dotsH, .5s, ($t1 + 7.75s));
				}
			}

			.btn {
				opacity: 0;
				@include animation(fadeIn, .5s, ($t1 + 8.25s));
			}
		}

		// main .r_container {
		// 	opacity: 0;
		// 	@include animation(fadeIn, .5s, ($t1 + 9s));
		// }
		footer {
			opacity: 0;
		}
	}
}

// main {
// 	min-height: calc(100vh - 509px);
// 	opacity: 0;
// 	visibility: hidden;
// 	animation: fadeIn .75s .75s forwards;
// }

//// company's first visit INTRO ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@keyframes logo-movementCompany {
	0% {
		transform: translate(465px, 40vh) scale(2);
		opacity: 0;
	}
	5% {
		transform: translate(465px, 40vh) scale(2);
		opacity: 1;
	}
	50% {
		transform: translate(465px, 40vh) scale(2);
	}
	75% {
		transform: translate(290px, 40vh) scale(2);
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

@keyframes mainIntroMessage {
	0% {
		transform: translate(465px, 40vh) scale(2);
		opacity: 0;
	}
	5% {
		transform: translate(465px, 40vh) scale(2);
		opacity: 1;
	}
	50% {
		transform: translate(465px, 40vh) scale(2);
	}
	75% {
		transform: translate(290px, 40vh) scale(2);
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

@keyframes swapMainIntroMsg {
	0% {
		opacity: 0;
		visibility: hidden;
		transform: translate(0, 50px);
	}
	20% {
		opacity: 1;
		visibility: visible;
		transform: translate(0, 0px);
	}
	80% {
		opacity: 1;
		visibility: visible;
		transform: translate(0, 0px);
	}
	100% {
		opacity: 0;
		visibility: hidden;
		transform: translate(0, -100px);
	}
}

@keyframes skipIntroArrH {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	20% {
		opacity: 1;
		visibility: visible;
	}
	80% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes skipIntroArr {
	0% {
		transform: translateX(20px);
	}
	50% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(20px);
	}
}

@keyframes swapMainIntroMsgH {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	5% {
		opacity: 1;
		visibility: visible;
	}
	95% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes mainbgintro {
	0% {
		background-color: $graybg;
	}
	1% {
		background-color: $bc2;
	}
	99% {
		background-color: $bc2;
	}
	100% {
		background-color: $graybg;
	}
}

@keyframes fadeinout {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	1% {
		opacity: 1;
		visibility: visible;
	}
	99% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.force100height {
	height: 100vh;
	overflow: hidden;

	header {
		z-index: 1 !important;
	}
}

$endpoint: 77s;
$endpoint2: $endpoint - 14s;

.r_redesign {

	.skipCIntro {
		text-shadow: 1px 1px 3px rgba(#000, .25);
		color: #fff;
		position: fixed;
		top: 15px;
		right: 20px;
		z-index: 1000;
		font-size: 1.5rem;
		display: none;
		cursor: pointer;

		span.arr {
			transform: translateY(-50%) rotate(180deg);
			position: absolute;
			right: 100%;
			top: 50%;
			display: block;
			padding: 0 10px;
		}

		svg {
			display: block;
			fill: $bc1;
			width: 28px;
			height: 28px;
		}
	}

	.mainIntroMessage {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $bc2;
		text-align: center;
		color: $graybg;
		font-size: 2rem;
		text-shadow: 2px 2px 5px rgba(#000, .25);

		opacity: 0;
		visibility: hidden;
		line-height: 1.5;

		&__item {
			font-size: 4rem;

			position: absolute;
			top: 30vh;
			left: 0;
			right: 0;

			opacity: 0;
			visibility: hidden;

			&:first-child {
				font-size: 5rem;
			}

			&:nth-child(4), &:nth-child(5) {
				font-size: 2.5rem;
			}
		}
	}

	.firstVisitDesc {
		display: none;
	}
}

.companyFirstVisit.r_redesign {

	@include mqq($tablet) {

		@include animation(mainbgintro, $endpoint2, 14s);

		.skipCIntro, .mainIntroMessage {
			display: block;
		}

		header:before {
			opacity: 0;
			visibility: hidden;
			@include animation(fadeinout, $endpoint2, 14s);
			background-color: $bc2;
		}

		.ball {
			@include animation(bounce, 0.3s, 0.5s);
			animation-direction: alternate;
			animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
			animation-iteration-count: 5;
		}

		.r_logo {
			opacity: 0;
			transform-origin: 0 50%;
			transform: translate(480px, 40vh) scale(2);
			@include animation(logo-movementCompany, 4s, 0.25s);

			svg path {
				opacity: 0;
				visibility: hidden;
				@include animation(fadeIn, .75s, 1.5s);
			}
		}

		// main, footer, nav, .langSelector {
		// 	opacity: 0;
		// 	visibility: hidden;
		// 	@include animation(fadeIn, 1s, 4s);
		// }

		main {
			animation-delay: 4.5s;
		}

		footer {
			animation-delay: $endpoint;
		}

		.skipCIntro {
			span.arr {
				opacity: 0;
				@include animation(skipIntroArrH, 4s, 15s);
			}

			svg {
				transform: translateX(20px);
				@include animation(skipIntroArr, 0.85s, 15s);
				animation-iteration-count: 4;
				animation-timing-function: linear;
			}
		}

		.mainIntroMessage {
			@include animation(swapMainIntroMsgH, 18s, 3.75s);

			&__item {
				opacity: 0;
				visibility: hidden;
				transform: translate(0, 50px);

				&:first-child {
					@include animation(swapMainIntroMsg, 2s, 5s);
				}

				&:nth-child(2) {
					@include animation(swapMainIntroMsg, 2s, 7s);
				}

				&:nth-child(3) {
					@include animation(swapMainIntroMsg, 3s, 9s);
				}

				&:nth-child(4) {
					@include animation(swapMainIntroMsg, 3.5s, 12s);
				}

				&:nth-child(5) {
					@include animation(swapMainIntroMsg, 3s, 16s);
				}
			}
		}

		header {

			.r_container {
				z-index: 2;
			}

			.logo {
				z-index: 4;
			}
		}

		header, aside, section {
			&:before {
				content: '';
				position: absolute;
				z-index: 3;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		aside, section {
			&:before {
				background-color: rgb($bc2, .9);
			}
		}

		.firstVisitDesc {
			font-size: 2rem;
			color: #fff;
			line-height: 1.5;
			width: 640px;
			position: absolute;
			z-index: 4;
			display: block;

			opacity: 0;
			visibility: hidden;

			svg {
				position: absolute;
				z-index: 6;
				width: 30px;
				height: 30px;
				fill: $bc1;
				filter: drop-shadow(1px 1px 3px rgb($bc2, .5));
			}

			.intro {
				font-size: 2.5rem;
			}
		}

		aside {
			.firstVisitDesc {
				left: 100%;
				margin-left: 20px;
				top: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				padding: 15px;

				p {
					position: relative;
					padding-left: 35px;

					svg {
						left: 0;
						top: 50%;
						transform: translate(-50%);
					}
				}
			}
		}

		.rowEssentials {
			aside {
				&:before {
					height: auto;
					top: -80px;
					bottom: 0;
					@include animation(skipIntroArr, 0.85s, 18s);
					animation: 0.75s fadeOut 19s forwards, 0.5s fadeIn 24s forwards, 0.75s fadeOut $endpoint forwards;
				}

				.firstVisitDesc {
					animation: 0.75s fadeIn 19s forwards, 0.5s fadeOut 24s forwards;
				}
			}

			section {
				&:before {
					@include animation(skipIntroArr, 0.85s, 18s);
					animation: 0.75s fadeOut 25s forwards, 0.5s fadeIn 29s forwards, 0.75s fadeOut $endpoint forwards;
				}

				.firstVisitDesc {
					animation: 0.75s fadeIn 25s forwards, 0.5s fadeOut 29s forwards;
					top: 100%;
					text-align: center;
					padding: 15px;

					svg {
						transform: rotate(90deg) translateX(-50%);
						top: 0;
						left: 50%;
					}
				}
			}
		}

		.rowMoreDetails  aside {

			&:before {
				@include animation(skipIntroArr, 0.85s, 18s);
				animation: 0.75s fadeOut 30s forwards, 0.5s fadeIn $endpoint forwards, 0.75s fadeOut $endpoint forwards;
			}

			.firstVisitDesc {
				opacity: 0;
				visibility: hidden;
				font-size: 1.5rem;
				animation: 0.75s fadeIn 30s forwards, 0.5s fadeOut $endpoint forwards;

				p {
					display: block;
					position: absolute;
					z-index: 1;
					height: 1px;
					width: 100%;
					top: 50%;
					left: 0;

					svg {
						top: 50%;
						transform: translate(-50%, -50%);
					}

					animation: 0.75s moveDesc1 33.5s forwards,
					0.5s moveDesc2 38.5s forwards,
					0.5s moveDesc3 45s forwards,
					0.5s moveDesc4 49s forwards,
					0.5s moveDesc5 53.5s forwards,
					0.5s moveDesc6 56.5s forwards,
					0.5s moveDesc7 60.5s forwards,
					0.5s moveDesc8 66.5s forwards,
					0.5s moveDesc9 72.5s forwards;
				}

				.elem {
					opacity: 0;
					visibility: hidden;
					display: block;
					position: absolute;
					z-index: 1;
					width: calc(100% - 35px);
					top: -18px;
					left: 35px;

					&.intro {
						top: 50%;
						transform: translateY(-50%);
						animation: 0.75s fadeIn 30s forwards, 0.5s fadeOut 33s forwards;
					}

					&.updates {
						animation: 0.75s fadeIn 33.5s forwards, 0.5s fadeOut 38s forwards;
					}

					&.about {
						animation: 0.75s fadeIn 38.5s forwards, 0.5s fadeOut 44.5s forwards;
					}

					&.jobs {
						animation: 0.75s fadeIn 45s forwards, 0.5s fadeOut 48.5s forwards;
					}

					&.videos {
						animation: 0.75s fadeIn 49s forwards, 0.5s fadeOut 53s forwards;
					}

					&.events {
						animation: 0.75s fadeIn 53.5s forwards, 0.5s fadeOut 56s forwards;
					}

					&.faqs {
						animation: 0.75s fadeIn 56.5s forwards, 0.5s fadeOut 60s forwards;
					}

					&.testimonials {
						animation: 0.75s fadeIn 60.5s forwards, 0.5s fadeOut 66s forwards;
					}

					&.process {
						animation: 0.75s fadeIn 66.5s forwards, 0.5s fadeOut 72s forwards;
					}

					&.challenges {
						animation: 0.75s fadeIn 72.5s forwards, 0.5s fadeOut 77s forwards;
					}
				}
			}

		}

		.rowMoreDetails section:before, .skipCIntro {

			animation: 0.75s fadeOut $endpoint forwards;
		}
	}
}

@keyframes moveDesc1 {
	0% {
		top: 50%;
	}
	100% {
		top: 26px;
		margin-top: 0;
	}
}

@keyframes moveDesc2 {
	0% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(52px);
	}
}

@keyframes moveDesc3 {
	0% {
		transform: translateY(52px);
	}
	100% {
		transform: translateY(calc(52px * 2));
	}
}

@keyframes moveDesc4 {
	0% {
		transform: translateY(calc(52px * 2));
	}
	100% {
		transform: translateY(calc(52px * 3));
	}
}

@keyframes moveDesc5 {
	0% {
		transform: translateY(calc(52px * 3));
	}
	100% {
		transform: translateY(calc(52px * 4));
	}
}

@keyframes moveDesc6 {
	0% {
		transform: translateY(calc(52px * 4));
	}
	100% {
		transform: translateY(calc(52px * 5));
	}
}

@keyframes moveDesc7 {
	0% {
		transform: translateY(calc(52px * 5));
	}
	100% {
		transform: translateY(calc(52px * 6));
	}
}

@keyframes moveDesc8 {
	0% {
		transform: translateY(calc(52px * 6));
	}
	100% {
		transform: translateY(calc(52px * 7));
	}
}

@keyframes moveDesc9 {
	0% {
		transform: translateY(calc(52px * 7));
	}
	100% {
		transform: translateY(calc(52px * 8));
	}
}

//// END company's first visit INTRO ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
body {
		opacity: 0;
		@include animation(fadeIn, 0.2s, 0.25s);
}