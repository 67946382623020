.btn {
	&:disabled,
	&[disabled] {
		pointer-events: none;
		filter: opacity(0.5);
	}
}

.site-alert {
	color: #F4F4F4;
	text-align: center;
	padding: 4px 0 6px;
	margin-top: 10px;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 32;

	&--home {
		top: 0;
		margin-top: 0;
	}

	&--positive {
		background: #012243;
	}

	&--negative {
		background: #FF5B62;
	}

	&.fixed {
		position: fixed;
	}
}

.fixed-site-alert {
	margin-top: -40px ;
}

.r_homepage.r_redesign {
	@include mq(767) {
		padding-top: 130px;
	}
}

main {
	position: relative;
	z-index: 2;
}

.r_redesign {
	@extend %scroll;

	@include mq(767) {
		padding-top: 100px;
	}

	.r_profile {
		.r_main_container {
			flex-direction: column;
		}
	}

	.userPop {
		position: absolute;
		z-index: 5;
		top: 60px;
		left: 0;
		right: 0;
		padding: 20px;
		background-color: #fff;
		filter: drop-shadow(0 0 6px rgba($bc2, .25));
		opacity: 0;
		visibility: hidden;
		transform: translateY(15px);
		@extend %transition;

		&.open {
			opacity: 1;
			visibility: visible;
			transform: translate(0);
		}

		&:before {
			content: '';
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-bottom-color: #fff;
			position: absolute;
			bottom: 100%;
			right: 30px;
		}

		&.userNotesCnt {
			&:before {
				right: auto;
				left: 30px;
			}
		}

		&.negative-feedback-options {
			top: 45px;
			&:before {
				right: 79px;
			}
		}

		.previousMessages {
			margin-bottom: 35px;

			.item {
				padding: 10px 0;
				border-bottom: 1px solid rgba($bc2, .15);

				&:last-child {
					border-bottom: 0;
				}
			}

			.date {
				font-size: 0.8125rem;
				color: rgba($bc2, .5);
			}
		}



		textarea {
			border-radius: 5px;
			border: 1px solid rgba($bc2, .15);
			padding: 8px 10px;
			min-height: 70px;
			font-size: 14px;
		}

		.noteFoot {
			display: flex;
			justify-content: space-between;
			font-size: 0.8125rem;
			align-items: center;
			padding-top: 10px;

			a, .discard {
				cursor: pointer;
			}
		}

		.discard {
			@extend %transition;

			&:hover {
				color: $bc1;
			}
		}

		.btn {
			height: 24px;
			line-height: 24px;
			text-transform: none;
			font-weight: normal;
			font-size: 0.8125rem;
			min-width: 10px;
			padding: 0 23px;
		}

	}

	.arrSort {
		width: 6px;
		height: 3px;
		display: inline-block;
		transform: translateY(-3px);

		svg {
			display: block;
		}

		&--icon {
			width: 6px;
			height: 3px;
			display: inline-block;
			transform: translateY(-3px);
			@include bg('r_select_arr.svg', 100% 50%);
			background-size: 6px 3px;

			&.white {
				@include bg('r_select_arr-white.svg', 100% 50%);
			}
		}
	}

	.sortarr_svg {
		width: 6px;
		height: 3px;
		fill: $bc2;
	}

	.sortable {

		&.reverse {
			.arrSort {
				transform: rotate(180deg) translateY(3px);

				&--icon {
					transform: rotate(180deg) translateY(3px);
				}
			}
		}
	}

	.userBadges {

		ul {
			user-select: none;
			margin: 0 -10px;
			max-height: 100%;
			overflow-x: hidden;
			overflow-y: auto;

			@include mq($tablet) {
				text-align: center;
			}
		}

		li {
			width: 79px;
			height: 79px;
			display: inline-block;
			vertical-align: middle;
			margin: 10px 2px;

			@include mq($tablet) {
				width: 70px;
				height: 70px;
				margin: 0 2px;
			}
		}

		img {
			display: block;
			width: 79px;
		}

		.inactive {
			img {
				filter: grayscale(100%);
				opacity: .2;
			}
		}
	}

	.editor-content {
		display: block;

		ul {
			margin-bottom: 1.25rem;

			li {
				position: relative;
				z-index: 1;
				padding-left: 25px;
				margin-bottom: 6px;

				ul {
					padding-top: 6px;
				}

				&:before {
					content: '•';
					color: $bc1;
					position: absolute;
					left: 0;
					top: 1px;
					z-index: 1;
					font-size: 14px;
				}
			}
		}

		img {
			max-width: 100%;
		}

		.btn {
			margin-top: 50px;

			@include mq(767) {
				margin-top: 30px;
			}
		}

		h1, h2, h3, h4 {
			margin-bottom: 1.5rem;
		}

		h1 {
			font-size: 1.75rem;
		}

		h2 {
			font-size: 1.5rem;
		}

		h3 {
			font-size: 1.25rem;
		}

		> div {
			margin-bottom: 1.25rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	aside {
		width: 300px;
		margin-bottom: 20px;
		position: relative;
		z-index: 2;
		// opacity: 0;
		// @include animation(fadeIn, 0.2s, 0.25s);

		.editor-content {
			ul {
				li {
					padding-left: 25px;
				}
			}
		}
	}

	section {
		margin-bottom: 20px;
		// opacity: 0;
		// @include animation(fadeIn, 0.2s, 0.35s);
		position: relative;
		z-index: 1;

		&:not(.full-width) {
			width: calc(100% - 320px);
		}
	}

	@include mq($tablet) {
		aside {
			width: 250px;
		}

		section {
			width: calc(100% - 270px);
		}
	}
	@include mq(767) {
		aside, section {
			width: 100%;
			max-width: 100%;
		}
	}

	main {
		min-height: calc(100vh - 470px);
	}
	.profileCover {
		min-height: 200px;
		margin-bottom: 20px;
		background-color: rgba($bc2, .15);

		position: relative;
		z-index: 1;

		&:before {
			content: 'UNLOCK COVER AT LEVEL 20';
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 30px;
			color: #fff;
		}

		> * {
			position: relative;
			z-index: 2;
		}

		@include mq($tablet) {
			min-height: 106px;

			&:before {
				font-size: 1.25rem;
				left: 0;
				right: 0;
				transform: none;
				text-align: center;
				top: 20px;
			}
		}
	}
	.genericSlider, .newsSlider {
		.slide {
			display: none;
			user-select: none;
		}

		.gs-foot {
			position: absolute;
			z-index: 102;
			left: 0;
			bottom: 0;
			width: 100%;
			text-align: center;
			font-size: 0.8125rem;
			min-height: 20px;
		}

		.slide.cycle-slide {
			display: block;
			z-index: 1;
		}

		.cycle-prev, .cycle-next,
		.prevNews, .nextNews {
			height: 21px;
			width: 12px;
			display: block;
			cursor: pointer;
			position: absolute;
			bottom: 0;
			z-index: 101;

			svg {
				height: 21px;
				width: 12px;
				stroke: rgba($bc2, .2);
				display: block;
				cursor: pointer;
			}
		}

		.cycle-prev,
		.prevNews {
			left: 0;
		}

		.cycle-next, .nextNews {
			right: 0;
		}
	}
	ul.jobSpecs {
		flex-grow: 1;

		svg {
			width: 14px;
			fill: rgba($bc2, .15);
			display: inline-block;
			height: 17px;
		}

		.icoH {
			width: 18px;
			text-align: center;
			margin-right: 12px;
			height: 18px;
		}

		li {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			font-size: 0.8125rem;

			&:last-child {
				margin-bottom: 0;
			}

			.joblabelLike {
				width: 50%;
				min-width: 50%;
				opacity: .5;
			}

			.text {
				font-weight: bold;
			}
		}
	}
	.recommendation-feedback-btn {
		svg {
			width: 29px !important;
			height: 29px !important;
			stroke: #8090A1 !important;
			fill: none !important;

			&:hover {
				stroke: #FF5B62 !important;
				fill: #FF5B62 !important;
			}
		}
		&.selected svg {
			stroke: #FF5B62 !important;
			fill: #FF5B62 !important;
		}
	}
	.videoH {
		width: 100%;
		height: 0;
		position: relative;
		padding-top: 56.25%;


		iframe {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.r_friends-list {

		.r_simple-list__cnt {
			overflow: hidden;
			margin-right: 0;
			width: 100%;
		}

		.searchFriend {
			display: block;
		}

		.r_pagination {
			display: flex;
		}

		.inviteLink {
			display: none;
		}
	}
	.clipped-cnt {
		@extend %acc1;
	}
	.clipped-handler {
		width: 12px;
		height: 25px;
		cursor: pointer;

		transform: rotate(90deg);
		@extend %transition;

		&:hover {
			svg {
				stroke: rgba($bc2, .5);
			}
		}

		svg {
			width: 12px;
			height: 25px;
			stroke: rgba($bc2, .2);
			@extend %transition;
		}
	}
	.clipped-expanded.clipped-cnt, .clipped-shown.clipped-cnt {
		@extend %acc2;
	}
	.clipped-expanded {
		.clipped-cnt {
			@extend %acc2;
		}

		.clipped-handler {
			transform: rotate(90deg) scaleX(-1);

			svg {
				// transform: translate(-50%,-50%)rotate(-90deg);
			}
		}
	}
	.toTopBtn {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		position: fixed;
		z-index: 15;
		@extend %transition;
		right: 25px;
		bottom: 25px;
		cursor: pointer;
		opacity: 0;
		min-width: 10px;

		svg {
			width: 12px;
			height: 25px;
			transform: translate(-50%, -50%) rotate(-90deg);
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			stroke: #fff;
		}

		@include mq(767) {
			display: none;
		}
	}
	.dotted-module {
		position: relative;
		z-index: 1;

		.dots {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			width: 100%;
			height: 100%;

			&:before, &:after {
				content: '';
				position: absolute;
				z-index: 1;
				@include bg('r_dot.svg', 0 0, repeat);
				background-size: 10px 10px;
			}

			&.v {
				&:before, &:after {
					width: 3px;
					height: 100%;
					top: 0;
				}

				&:before {
					left: 0;
				}

				&:after {
					right: 0;
				}
			}

			&.h {
				&:before, &:after {
					height: 3px;
					width: 100%;
					right: 0;
				}

				&:before {
					top: 0;
				}

				&:after {
					bottom: 0;
				}
			}
		}

		.lines {
			position: absolute;
			z-index: 2;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			&:before, &:after {
				content: '';
				background-color: $bc1;
				position: absolute;
				z-index: 1;
			}

			&:before {
				right: 0;
				top: 0;
				height: 3px;
				// width: 30px;
				width: 100%;
				max-width: 30px;
			}

			&:after {
				top: 0;
				right: 0;
				width: 3px;
				// height: 30px;
				height: 100%;
				max-height: 30px;
			}

			&__bottom-right {
				&:before {
					top: auto;
					bottom: 0;
				}

				&:after {
					top: auto;
					bottom: 0;
				}
			}
		}

		> * {
			position: relative;
			z-index: 3;
		}
	}
	.bubbled-module {
		padding: 50px 0;
		margin-bottom: 50px;
		background: url(../../images/front/r_bubble-top.png) 0 0 repeat-x, url(../../images/front/r_bubble-bottom.png) 0 100% repeat-x;
		width: 100%;

		&__cnt {
			background-color: #fff;
		}

		@include mq(767) {
			padding: 40px 0;
			margin-bottom: 20px;
			background-size: auto 49px, auto 49px;
		}
	}
	.intro-desc {
		padding: 70px 540px 0 60px;
		margin-bottom: 85px;

		@include mq($tablet) {
			min-height: 443px;
		}

		p {
			font-size: 1.5625rem;
			padding-right: 95px;
			margin-bottom: 3.25rem;
		}

		.btn {
			transform: translateY(50%);
		}

		&__cnt {
			position: relative;
			z-index: 4;
		}

		&__graphic {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 2;
			width: 540px;

			.splash_svgs {
				position: absolute;
				z-index: 3;
				left: 60%;
				top: 50%;
				transform: translate(-50%, -50%);
			}


			ul.elems {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				li {
					position: absolute;
					z-index: 1;
					border-radius: 50%;
					transform: translate(-50%, -50%);
				}


			}
			li {
				svg {
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					fill: #fff;

					@include mq($tablet) {
						transform: translate(-50%, -50%) scale(.7);
					}
				}

				&.blue {
					width: 50px;
					height: 50px;
					background-color: $bc2;

					@include mq($tablet) {
						width: 35px;
						height: 35px;
					}
				}

				&.orange {
					width: 30px;
					height: 30px;
					background-color: $bc1;

				}

				&.badge {
					left: 60%;
					top: 17%;

					svg {
						width: 24px;
						height: 28px;
					}
				}

				&.edit {
					left: 20%;
					top: 80%;

					svg {
						width: 26px;
						height: 26px;
					}
				}

				&.house {
					left: 80%;
					top: 80%;

					svg {
						width: 21px;
						height: 21px;
					}
				}

				&.check {
					left: 20%;
					top: 50%;

					svg {
						width: 15px;
						height: 12px;
					}
				}

				&.cog {
					left: 90%;
					top: 50%;

					svg {
						width: 15px;
						height: 15px;
					}

					@include mq($tablet) {
						left: 85%;
						top: 10px;
					}
				}

				&.search {
					left: 60%;
					top: 90%;

					svg {
						width: 15px;
						height: 15px;
					}
				}
			}

			ul.gray_lines {
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;

				li {
					background-color: rgba($bc2, .15);
					position: absolute;
					z-index: 1;
				}

				li:nth-child(1),
				li:nth-child(3),
				li:nth-child(5),
				li:nth-child(7),
				li:nth-child(8),
				li:nth-child(9) { // vertical
					width: 2px;
					transform: translateX(-1px);
				}

				li:nth-child(2),
				li:nth-child(4),
				li:nth-child(6),
				li:nth-child(10) { // horizontal
					height: 2px;
					transform: translateY(-1px);
				}

				li:nth-child(1) {
					bottom: 50%;
					top: 10px;
					left: 60%;

				}

				li:nth-child(2) {
					top: 50%;
					right: 10px;
					left: 60%;
				}

				li:nth-child(3) {
					top: 50%;
					bottom: 10px;
					left: 60%;
				}

				li:nth-child(4) {
					top: 50%;
					right: 40%;
					left: 20%;
				}

				li:nth-child(5) {
					top: 50%;
					bottom: 20%;
					left: 20%;
				}

				li:nth-child(6) {
					top: 80%;
					bottom: 20%;
					left: 0;
					right: 60%;
				}

				li:nth-child(7) {
					top: 80%;
					bottom: 10px;
					left: 0;

					@include mq($tablet) {
						right: 10px;
						top: 10px;
						left: 60%;
						width: auto;
						height: 2px;
						transform: translateY(-1px);
						bottom: auto;
					}
				}

				li:nth-child(8) {
					top: 80%;
					bottom: 10px;
					left: 20%;
				}

				li:nth-child(9) {
					top: 80%;
					bottom: 10px;
					left: 40%;
				}

				li:nth-child(10) {
					top: 80%;
					right: 10px;
					left: 60%;
				}
			}
		}

		@include mq($tablet) {
			padding: 0;
			&__cnt {
				padding: 20px 30px;
			}

			&__graphic {
				position: relative;
				width: 100%;
				height: 350px;
			}

			p {
				font-size: 1.25rem;
				padding-right: 0;
				margin-bottom: 1.25rem;
			}
			.btn {
				transform: translateY(0);
			}
		}
		@include mq(767) {
			.btn {
				display: none;
			}
		}
	}
	.how-it-works {
		margin-bottom: 85px;

		.iconH {
			height: 60px;
			margin-bottom: 20px;

			@include mq($tablet) {
				height: auto;
				margin-bottom: 10px;
			}
		}

		.edit_blue_svg {
			width: 57px;
			height: 57px;

			@include mq($tablet) {
				width: 41px;
				height: 41px;
			}
		}

		.check_blue_svg {
			width: 68px;
			height: 53px;

			@include mq($tablet) {
				width: 49px;
				height: 38px;
			}
		}

		.badge_blue_svg {
			width: 52px;
			height: 60px;

			@include mq($tablet) {
				width: 37px;
				height: 43px;
			}
		}

		.arrow_blue_svg {
			width: 40px;
			height: 50px;

			@include mq($tablet) {
				width: 29px;
				height: 34px;
			}
		}

		.row {
			display: flex;
			position: relative;
			z-index: 6;

			@include mq($tablet) {
				flex-direction: column;
				counter-reset: section;
			}

			&-titles {
				counter-reset: section;
				margin-bottom: -20px;
				position: relative;
				z-index: 2;
				align-items: flex-end;

				.step {
					counter-increment: section;

					&:after {
						content: counter(section);
						width: 40px;
						height: 40px;
						text-align: center;
						background-color: $bc2;
						color: #fff;
						display: block;
						border-radius: 50%;
						line-height: 40px;
						font-weight: bold;
						font-size: 1.4375rem;
					}
				}

				@include mq($tablet) {
					display: none;
				}
			}


		}

		.step {
			flex-basis: 25%;

			@include mq($tablet) {
				counter-increment: section;
				padding: 0 20px 45px 40px;

				&:last-child {
					padding-bottom: 35px;
				}

				h4 {
					position: relative;
					z-index: 4;
					margin-bottom: 1.25rem;
				}

				h4:after {
					content: counter(section);
					width: 25px;
					height: 25px;
					text-align: center;
					background-color: $bc2;
					color: #fff;
					display: block;
					border-radius: 50%;
					line-height: 25px;
					font-weight: bold;
					font-size: 0.875rem;

					position: absolute;
					z-index: 3;
					left: -40px;
					top: 0;
					transform: translate(-50%, 0);
				}
			}
		}

		.dots.v:before {
			display: none;
		}

		p {
			margin: 0;
			padding-right: 60px;
			line-height: 1.5rem;
		}

		.dotted-module {
			padding: 45px 0 65px;
			min-height: 303px;
		}

		.btn.big {
			transform: translateY(-50%);
		}

		@include mqq($tablet) {
			.row-desc .step-title {
				display: none;
			}
		}

		@include mq($tablet) {
			margin-bottom: 70px;

			.dotted-module {
				margin-bottom: 20px;
				padding-bottom: 0;
			}

			.dots.v:before {
				display: block;
				top: 105px;
				height: auto;
				bottom: 0;
			}
			.dots.h:before, .dots.v:after {
				display: none;
			}
			.dots.h:after {
				width: calc(100% + 20px);
				right: auto;
				left: 0;
			}

			p {
				padding-right: 0;
			}

			@include mqq(768) {
				h4 .bc ~ br {
					display: none;
				}
			}
			.btn.big {
				transform: translateY(0);
			}

			.lines {
				&:before, &:after {
					top: auto;
					bottom: 0;
					right: auto;
					left: 0;
				}
			}
			h3 {
				margin-bottom: 10px;
			}
		}
	}
	.quiz-module {
		padding-top: 55px;
		padding-bottom: 30px;

		h2 {
			color: #fff;
			height: 70px;
			margin-top: -70px;
			line-height: 70px;
			margin: 0;
			font-size: 2.875rem;
			background-color: $bc2;
			display: inline-block;
			padding: 0 115px;

			position: absolute;
			top: -70px;
			left: 0;
		}

		p {
			font-size: 1.25rem;
			line-height: 1.875rem;
		}

		h5 {
			padding-right: 100px;
		}

		&__desc {
			width: 490px;
			padding-right: 160px;
			display: inline-block;
			vertical-align: text-top;
		}

		&__theQuiz {
			width: 410px;
			display: inline-block;
			vertical-align: text-top;
			position: relative;
			z-index: 1;
			overflow: hidden;
		}

		&__questions {
			display: flex;
			flex-direction: row;
			@extend %transition;

			fieldset {
				min-width: 100%;
				@extend %t2;
				opacity: 0;
				visibility: hidden;

				&.current {
					opacity: 1;
					visibility: visible;
				}
			}

			&[data-active='2'] {

				fieldset {
					transform: translateX(-100%);
				}
			}

			&[data-active='3'] {

				fieldset {
					transform: translateX(-200%);
				}
			}


			.quiz-row {
				margin-bottom: 20px;
				position: relative;
				z-index: 1;

				label {
					position: relative;
					z-index: 1;
					display: block;
					background-color: rgba($bc2, .05);
					text-align: center;
					height: 40px;
					line-height: 40px;
					border-radius: 20px;
					color: $bc2;
					@extend %transition;
					font-size: 1.125rem;

					&:hover {
						background-color: rgba($bc2, .1);
					}
				}

				input {
					position: absolute;
					z-index: -2;
					left: 0;
					top: 0;
					opacity: 0;
					visibility: hidden;

					&:checked + label {
						background-color: $bc2;
						color: #fff;
					}
				}
			}
		}

		&__nav {
			padding: 20px 0;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			user-select: none;

			span {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: rgba($bc2, .2);

				line-height: 30px;
				text-align: center;
				font-size: 1.0625rem;
				font-weight: bold;
				color: #fff;
				@extend %t2;

				&.active {
					background-color: $bc2;
				}

				&:first-child {
					margin-left: 0;
					margin-right: auto;
				}

				&:last-child {
					margin-left: auto;
					margin-right: 0;
				}

				&.prev, &.next {
					background-color: transparent;
					cursor: pointer;
					@extend %transition;
					width: 12px;
					height: 25px;
					border-radius: 0;

					svg {
						width: 12px;
						height: 25px;
						stroke: rgba($bc2, .2);
						@extend %transition;
					}

					&.inactive {
						opacity: .25;
						pointer-events: none;
					}

					&:hover svg {
						stroke: $bc2;
					}
				}

				&.sep {
					position: relative;
					z-index: 1;
					margin: 0 73px;

					&:before, &:after {
						content: '';
						@include bg('r_dot.svg', 0 1px, repeat);
						background-size: 10px auto;
						width: 65px;
						height: 4px;
						display: block;
						position: absolute;
						z-index: 1;
						top: 50%;
						transform: translateY(-50%);
					}

					&:before {
						right: 100%;
						margin-right: 5px;
						background-position: 2px 1px
					}

					&:after {
						left: 100%;
						margin-left: 5px;
					}
				}
			}

			&.prevent {
				span {
					&.prev, &.next {
						opacity: 0;
						visibility: hidden;
					}

					&.prev {
						transform: translate(10px);
					}

					&.next {
						transform: translate(-10px);
					}
				}
			}
		}

		.seeResult {
			opacity: 0;
			visibility: hidden;
			@extend %transition;
		}

		&.quizDone {
			.seeResult {
				opacity: 1;
				visibility: visible;
			}

			// fieldset.last {
			// 	opacity: 1;
			// 	visibility: visible;
			// }
		}

		&__result {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding-top: 18px;
			text-align: center;

			opacity: 0;
			visibility: hidden;
			transform: translateY(-50px);
			@extend %t2;

			.graphic-elem {
				position: absolute;
				z-index: 1;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 190px;
				height: 190px;
			}

			.splash_svgs {
				margin: 0 auto;
				z-index: 3;
				width: 153px;
				height: 153px;

				.splash_svg {
					width: 153px;
					height: 153px;
				}
			}

			h3 {
				margin-bottom: 0;
				color: $bc1;
			}

			.result-title {
				display: inline-block;
				height: 80px;
				line-height: 80px;
				color: #fff;
				background-color: $bc2;
				position: relative;
				z-index: 2;
				border-radius: 40px;
				font-size: 2.1875rem;
				font-weight: bold;
				margin-top: -20px;
				padding: 0 40px;
				margin-bottom: 40px;
			}
		}

		&__result-cnt {
			position: relative;
			z-index: 2;
		}

		@include mq($tablet) {
			&__desc {
				width: 55%;
				padding-right: 80px;
			}
			&__theQuiz {
				width: 43.5%;
			}
		}
		@include mq(767) {
			padding-top: 30px;
			padding-bottom: 20px;

			h2 {
				font-size: 2rem;
				height: 50px;
				line-height: 50px;
				top: -50px;
				padding: 0;
				text-align: center;
				width: 200px;
				left: 50%;
				transform: translateX(-100px);
			}
			h3 br {
				display: none;
			}
			h5 {
				padding-right: 0;
			}
			p {
				font-size: 1rem;
				line-height: 1.5;
			}
			&__desc {
				margin-bottom: 20px;
				padding-right: 0;
			}
			&__desc, &__theQuiz {
				width: 100%;
				display: block;
			}
		}

		////////
		// .quiz-module__questions, .quiz-module__nav, .seeResult,  {
		// 	opacity: 0;
		// 	visibility: hidden;
		// }
		///////////

		&.resultIsShown {
			.quiz-module__questions, .quiz-module__nav, .seeResult {
				opacity: 0;
				visibility: hidden;
			}

			.quiz-module__result {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}
	}
	.training-module {
		margin-bottom: 55px;

		.dotted-module {
			padding: 3px;
		}

		p {
			line-height: 1.5;
		}

		.row {
			display: flex;

			&__specs {
				background-color: #fff;
				position: relative;
				z-index: 3;

				&:before, &:after {
					content: '';
					position: absolute;
					z-index: 1;
					background-color: rgba($bc2, .15);
				}

				&:before {
					height: 2px;
					left: 10px;
					right: 10px;
					top: 0;
				}

				&:after {
					width: 2px;
					bottom: 8px;
					top: 0;
					left: 50%;
					margin-left: -1px;
				}

				h3 {
					margin-bottom: 1rem;
				}
			}
		}

		.cell {
			width: 50%;
			padding: 45px 30px 80px 60px;

			&__desc {
				padding-right: 70px;

				p {
					font-size: 1.25rem;
				}
			}

			&__graphic {
				position: relative;
				z-index: 1;
			}
			.splash_svgs {
				position: absolute;
				z-index: 3;
				left: 40%;
				top: 40%;
				transform: translate(-50%, -50%);
			}

			ul.gray_lines {
				li {
					background-color: rgba($bc2, .15);
					position: absolute;
					z-index: 1;
				}

				li:nth-child(1),
				li:nth-child(3),
				li:nth-child(5) { // vertical
					width: 2px;
					transform: translateX(-1px);
				}

				li:nth-child(2),
				li:nth-child(4) { // horizontal
					height: 2px;
					transform: translateY(-1px);
				}

				li:nth-child(1) {
					bottom: 60%;
					top: 0;
					left: 40%;
				}

				li:nth-child(2) {
					right: 10px;
					top: 40%;
					left: 40%;
				}

				li:nth-child(3) {
					bottom: 0;
					top: 40%;
					left: 40%;
				}

				li:nth-child(4) {
					right: 60%;
					top: 40%;
					left: 0;
				}

				li:nth-child(5) {
					bottom: 0;
					top: 40%;
					left: 0;
				}
			}

			ul.elems {

				li {
					position: absolute;
					z-index: 2;
					border-radius: 50%;
					transform: translate(-50%, -50%);

					svg {
						position: absolute;
						z-index: 1;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						fill: #fff;
					}

					&.blue {
						width: 50px;
						height: 50px;
						background-color: $bc2;
					}

					&.orange {
						width: 30px;
						height: 30px;
						background-color: $bc1;
					}

					&.edit {
						left: 80%;
						top: 40%;
					}

					&.edit svg {
						width: 26px;
						height: 26px;
					}

					&.house {
						left: 0;
						top: 70%;
					}
					&.house svg {
						width: 21px;
						height: 21px;
					}

					&.cog {
						left: 40%;
						top: 80%;
					}
					&.cog svg {
						width: 15px;
						height: 15px;
					}
				}
			}
		}

		.btn.big {
			transform: translate(60px, -50%);
		}

		.training-score {
			border: 3px solid $bc1;
			border-radius: 50%;
			width: 140px;
			height: 140px;
			text-align: center;
			margin-bottom: 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			line-height: 1;

			&__points {
				font-size: 2.75rem;
				color: $bc1;
				font-weight: bold;
			}

			&__desc {
				font-size: 1.25rem;
			}
		}

		.skillTags {
			padding-top: 25px;

			.st-title {
				font-weight: bold;
				font-size: 0.9375rem;
				margin-bottom: 1rem;
			}

			li {
				display: inline-block;
				vertical-align: middle;
				background-color: rgba($bc2, .05);
				color: $bc2;
				padding: 0 12px;
				height: 1.875rem;
				line-height: 1.875rem;
				border-radius: 1rem;
				font-size: 0.875rem;
				margin: 0 7px 10px 0;
			}
		}

		.author {
			margin-bottom: 40px;
			position: relative;
			z-index: 1;
		}

		.clipped-handler {
			float: right;
		}

		@include mqq(768) {
			.clipped-cnt {
				max-height: 9999px;
				opacity: 1;
			}
			.clipped-handler {
				display: none;
			}
		}


		@include mq($tablet) {
			.cell {
				padding: 25px 25px 30px 30px;
			}
			.btn.big {
				transform: translate(30px, -50%);
			}
			.author {
				margin-bottom: 20px;
			}
			.skillTags {
				padding-top: 10px;
			}
		}
		@include mq(767) {
			.row {
				flex-direction: column;

				&__specs {
					&:before, &:after {
						display: none;
					}

					.cell {
						border-top: 2px solid rgba($bc2, .15);

					}
				}
			}
			.cell {
				width: 100%;

				&__desc {
					padding-top: 0;
				}

				&__graphic {
					order: -1;
					height: 350px;

					.splash_svgs {
						left: 50%;
						top: 75%;
					}

					ul.gray_lines li:nth-child(1) {
						bottom: 25%;
						left: 50%;
					}

					ul.gray_lines li:nth-child(2) {
						right: 10px;
						top: 75%;
						left: 50%;
					}

					ul.gray_lines li:nth-child(4) {
						right: 50%;
						top: 75%;
					}

					ul.gray_lines li:nth-child(3) {
						display: none;
					}

					li svg {
							transform: translate(-50%, -50%) scale(0.7);
						}

					li.blue {
						width: 35px;
						height: 35px;
					}

					li.orange {
						width: 20px;
						height: 20px;
					}

					li.edit {
						left: 80%;
						top: 75%;
					}

					li.house {
						left: 20%;
						top: 75%;
					}

					li.cog {
						left: 50%;
						top: 25%;

					}

				}
			}
			.clipped-handler {
				margin-top: -23px;
				margin-right: -5px;
				position: relative;
				z-index: 2;
			}
			.author {
				margin-bottom: 0;
			}
			.clipped-cnt {
				padding-top: 20px;
			}
			.training-score {
				width: 100px;
				height: 100px;
				display: block;
				margin: 0 auto 20px;
				padding-top: 25px;

				&__points {
					font-size: 1.875rem;
				}

				&__desc {
					font-size: 0.85rem;
				}
			}
			.btn.big {
				transform: translate(30px, 0);
				margin-top: 20px;
			}
		}
		@include mq(500) {
			.cell {
				padding: 20px 15px 20px 25px;
			}
			.cell__graphic {
				height: 240px;
				margin-bottom: 30px;

				li {
					&.edit {
						left: 85%;
						top: 75%;
					}

					&.house {
						left: 15%;
						top: 75%;
					}

					&.cog {
						left: 50%;
						top: 25%;

					}
				}
			}
		}
	}
	.stats-module {
		margin-bottom: 85px;

		@include mq(767) {
			margin-bottom: 50px;
		}

		&__row {
			display: grid;
			grid-template-columns: repeat(3, calc((100% - 80px) / 3));
			grid-column-gap: 40px;
			grid-row-gap: 40px;
		}

		&__item {
			.dotted-module {
				padding: 55px 30px 0 30px;
				min-height: 293px;
			}
		}

		&__icon {
			text-align: center;
			margin-bottom: 45px;
			display: flex;
			height: 97px;
			align-items: center;
			justify-content: center;

			svg {
				fill: $bc2;
			}
		}

		&__number {
			color: $bc1;
			font-size: 3.125rem;
			line-height: 1;
		}

		&__desc {
			line-height: 1;
			font-size: 1.5rem;
		}

		.user_svg {
			width: 95px;
			height: 97px;
		}

		.briefcase_svg {
			width: 97px;
			height: 83px;
		}

		.edit_blue_svg {
			width: 88px;
			height: 88px;
		}

		@include mq($tablet) {
			&__row {
				grid-template-columns: repeat(3, calc((100% - 40px) / 3));
				grid-column-gap: 20px;
				grid-row-gap: 20px;
			}
			&__item {
				.dotted-module {
					padding: 35px 20px 0 20px;
					min-height: 280px;
				}
			}
		}
		@include mq(767) {
			&__row {
				display: block;
			}
			&__item {
				.dotted-module {
					padding: 30px 10px 30px 130px;
					min-height: 1px;
				}

				&:first-child {
					.dots.h:after {
						display: none;
					}

					~ .stats-module__item {
						border-top: 2px solid rgba($bc2, .15);
					}
					~ .stats-module__item .dotted-module .lines, .dots.h:before {
							display: none;
						}

					+ .stats-module__item .dotted-module .dots.h:after {
								display: none;
							}

				}

				// &:last-child .dots.h:before {display: none;}
			}
			&__icon {
				height: auto;
				display: block;
				position: absolute;
				z-index: 6;
				left: 60px;
				top: 50%;
				transform: translate(-50%, -50%) scale(0.7);
			}
		}
	}
	.clients-module {

		@include mq(979) {
			text-align: center;
		}

		&__row {
			position: relative;
			z-index: 1;

			padding: 15px 0;
			text-align: center;

			.cycle-cnt {
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;
				z-index: 1;
			}

			@include mq(979) {
				padding: 10px 0;

				.cycle-cnt {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		&__item {
			@include mqq(980) {
				position: static !important;
				visibility: visible !important;
			}
			@include mq($tablet) {
				transform: scale(.85);
			}
			@include mq(979) {
				display: inline-block;
				text-align: center;

				left: 50% !important;
				top: 50% !important;
				transform: translate(-50%, -50%);
			}

			svg {
				@include mq(979) {
					display: inline-block;
					text-align: center;
				}
			}
		}

		h5 {
			font-size: 1.125rem;
			color: #fff;
			background-color: $bc1;
			display: inline-block;
			height: 2rem;
			line-height: 2rem;
			padding: 0 40px 0 20px;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%);

			@include mq(767) {
				font-size: 1rem;
				left: -20px;
				right: -20px;
				padding: 0 20px;
			}
		}

		.logo-microsoft_svg {
			width: 171px;
			height: 37px;
		}

		.logo-homeywell_svg {
			width: 138px;
			height: 26px;
		}

		.logo-coca-cola_svg {
			width: 121px;
			height: 40px;
		}

		.logo-ibm_svg {
			width: 83px;
			height: 34px;
		}

		.logo-oracle_svg {
			width: 166px;
			height: 22px;
		}

		.cycle-prev, .cycle-next {
			background-color: transparent;
			cursor: pointer;
			@extend %transition;
			width: 12px;
			height: 25px;
			border-radius: 0;

			position: absolute;
			z-index: 2;
			top: 50%;
			transform: translateY(-50%);
			display: none;

			@include mq(979) {
				display: block;
			}

			svg {
				width: 12px;
				height: 25px;
				stroke: rgba($bc2, .2);
				@extend %transition;
			}

			&.inactive {
				opacity: .25;
				pointer-events: none;
			}

			&:hover {
				svg {
					stroke: rgba($bc2, .5);
				}
			}
		}

		.cycle-cnt {
			@include mq(979) {
				~ .cycle-prev, ~ .cycle-next {
					display: block;
				}
			}
		}

		.cycle-prev {
			left: 20px;
		}

		.cycle-next {
			right: 20px;
		}

		.btn {
			display: none;

			@include mq(767) {
				display: inline-block;
				transform: translate(0, 50%);
			}
		}
	}
	.story-module {
		h5 {
			font-size: 1.5625rem;
			line-height: 2rem;
			margin-bottom: 2.5rem;

			@include mq(767) {
				font-size: 1.35rem;
				line-height: 1.5;
				margin-bottom: 1.75rem;
			}
		}

		&__cnt {
			p {
				margin-bottom: 2rem;
			}

			@include mqq(767) {
				column-count: 2;
				column-gap: 40px;
				text-align: justify;
			}
		}

		&__team {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			padding-top: 55px;
			padding-bottom: 55px;

			@include mq(767) {
				padding-top: 30px;
				padding-bottom: 25px;
			}
		}

		&__member {
			text-align: center;
			width: 30%;
			margin-bottom: 55px;

			@include mq(767) {
				width: 45%;
				margin-bottom: 30px;
			}
			@include mq(500) {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		&__avatar {
			width: 196px;
			height: 196px;
			@include bg('r_dotted-avatar-bg.svg');
			background-size: 100%;
			border-radius: 50%;
			padding: 13px;
			margin: 0 auto 25px;

			img {
				display: block;
				border-radius: 50%;
			}

			@include mq($tablet) {
				width: 120px;
				height: 120px;
				padding: 7px;
				border-width: 2px;
				margin-bottom: 15px;
			}
		}

		&__name {
			color: $bc1;
		}

		&__name, &__title {
			font-size: 1.125rem;
			font-weight: bold;
			line-height: 1.5625rem;
		}

		&__title {
			margin-bottom: 1rem;
		}

		&__social {
			padding-top: 12px;

			li {
				width: 25px;
				height: 25px;
				display: inline-block;
				vertical-align: middle;
				margin: 0 8px;
				overflow: hidden;
			}

			a {
				display: block;
				width: 25px;
				height: 25px;
				background-color: rgba($bc2, .25);
				border-radius: 50%;
				position: relative;
				z-index: 1;

				&:hover {
					background-color: rgba($bc2, 1);
				}
			}

			svg.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				fill: #E7E6EA;

				&__fb {
					width: 6px;
				}

				&__yt {
					width: 11px;
				}

				&__insta {
					width: 11px;
				}

				&__linkedin {
					width: 12px;
					height: 11px;
				}
			}
		}
	}
	.contact-module {
		padding: 55px 0 85px;

		@include mq(767) {
			padding: 35px 0;
		}

		&__row {
			display: grid;
			grid-template-columns: repeat(2, calc((100% - 40px) / 2));
			grid-column-gap: 40px;
			grid-row-gap: 40px;

			@include mq(767) {
				display: block;
			}
		}

		&__intro {
			font-size: 1.5rem;
			line-height: 2rem;
			margin-bottom: 2rem;

			@include mq($tablet) {
				font-size: 1.15rem;
				line-height: 1.5;

			}
			@include mq(767) {
				font-size: 1rem;
				line-height: 1.25;
				margin-bottom: 1.5rem;
			}
		}

		&__desc {
			margin-bottom: 2rem;
		}

		&__coords {
			li {
				margin-bottom: 10px;
			}

			a {

				&.pin_ico {
					background-image: url('../../images/front/svg-sprite-source/r_pin.svg');
					background-size: 10px 14px;
				}

				&.phone_ico {
					background-image: url('../../images/front/svg-sprite-source/r_phone.svg');
					background-size: 10px 14px;
				}

				&.email_ico {
					background-image: url('../../images/front/svg-sprite-source/r_email.svg');
					background-size: 13px 14px;
				}

				padding-left: 22px;
				display: inline-block;
				background-repeat: no-repeat;
				background-position: 0 50%;
			}
		}

		form {
			.last-row {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.mandatory {
				font-size: 0.875rem;
				color: rgba($bc2, .5);
			}
		}
	}
	.blockquoteText {
		position: relative;
		z-index: 1;
		padding-left: 65px;

		&:before, &:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 20px 20px 0 0;
			border-color: #d9dee3 transparent transparent transparent;

			position: absolute;
			top: 0;
			z-index: 1;
			left: 0;
		}

		&:after {
			left: 25px;
		}

		&.mainQuote {
			font-size: 1.125rem;
			color: rgba($bc2, .5);
			padding-left: 100px;

			&:before, &:after {
				border-width: 30px 30px 0 0;
			}

			&:after {
				left: 40px;
			}
		}

		@include mq(767) {
			padding-top: 40px;
			padding-left: 0;

			&.mainQuote {
				padding-top: 50px;
				padding-left: 0;
			}
		}
	}
	.r_main_container {
		display: flex;
		justify-content: space-between;

		@include mq(767) {
			flex-flow: column;

			aside, section {
				width: 100%;
				min-width: 100%;
			}

			section > .tar {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
	.r_resume {
		.r_main_container {
			flex-flow: column;
		}
	}
	.r_nlist {

		ul {
			counter-reset: nlist;
			width: 100%;
			&.scroll{
				overflow-y: scroll;
				padding-right: 10px;
			}
		}

		li {
			counter-increment: nlist;
			position: relative;
			z-index: 1;
			padding-left: 30px;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				content: counter(nlist);
				font-size: 1rem;
				font-weight: bold;
				color: rgba($bc2, .15);
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
			}
		}
	}
	.editIco, .viewIco, .userNotes, .inviteIco, .sendMessage, .addRefferral {
		display: inline-block;
		user-select: none;
		cursor: pointer;
		height: 20px;
		position: relative;
		z-index: 6;
		text-align: right;
		min-width: 20px;

		svg {
			position: absolute;
			right: 0;
			top: 0;
			width: 20px;
			height: 20px;
			fill: rgba($bc2, .25);
			@extend %transition;
			cursor: pointer;
		}

		&:hover {
			svg {
				fill: $bc1;
			}
		}

		.labelLike {
			padding-left: 0;
			@extend %transition;
			opacity: 0;

			&:hover {
				color: $bc1;
			}
		}
	}
	.viewIco {
		height: 25px;
		min-width: 25px;
		svg {
			width: 25px;
			height: 25px;
		}
	}
	.backLink {
		position: relative;
		line-height: 17px;
		color: rgba($bc2, .5);
		display: flex;
		justify-content: flex-start;
		font-size: 13px;

		svg {
			width: 10px;
			height: 17px;
			stroke: rgba($bc2, .25);
			margin-right: 10px;
			@extend %transition;
		}

		@include mq(1000) {
			margin-left: 20px;
			margin-right: 20px;
		}

		&:hover {
			svg {
				stroke: $bc1;
			}
		}
	}
	.r_card {
		&.unpadded-card {
			padding-bottom: 0;
		}

		&.itExtends {
			padding-bottom: 0;

			.resumeSectionTitle {
				margin-bottom: 0;
				border-color: #fff;
				border-top: 4px solid transparent;
			}

			.toggleArr svg {
				@extend %transition;
			}
		}

		&.extended {
			.resumeSectionTitle {
				margin-bottom: 20px;
				border-bottom-color: rgba($bc2, .15);

				.toggleArr {
					transform: rotate(90deg);

					svg {
						transform: scale(-1);
					}
				}
			}

			.clipped-cnt {
				@extend %acc2;
			}
		}
	}
	.theProgressBar {
		position: relative;
		z-index: 2;
		height: 3px;
		background-color: rgba($bc2, 0.15);

		.ui-slider-range {
			background-color: $bc1;
			height: 3px;
		}

		.ui-slider-handle {
			position: absolute;
			z-index: 3;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
			width: 20px;
			height: 20px;
			border-radius: 50%;

			background: -moz-linear-gradient(top, $buttonc2 0%, $buttonc1 100%);
			background: -webkit-linear-gradient(top, $buttonc2 0%, $buttonc1 100%);
			background: linear-gradient(to bottom, $buttonc2 0%, $buttonc1 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$buttonc1', endColorstr='$buttonc2', GradientType=0);
			background-color: $buttonc1;
			cursor: pointer;

			@include mq(767) {
				width: 28px;
				height: 28px;
			}
		}
	}

	.r_intro-companies {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 50px;

		@include mq(767) {
			flex-direction: column;
			text-align: center;
		}

		p {
			line-height: 1.28;
			font-size: 1.5625rem;

			@include mq($tablet) {
				font-size: 1.2rem;
			}
		}

		&__cnt {
			width: 470px;

			@include mq(767) {
				margin-bottom: 25px;
				max-width: 100%;
			}
		}

		&__graphic {
			width: 380px;
			margin: 0 36px;

			@include mq($tablet) {
				width: 253px;
			}

			img {
				width: 380px;
				height: 400px;
				display: block;
				margin: 0 auto;

				@include mq($tablet) {
					width: 253px;
					height: 267px;
				}
				@include mq(767) {
					width: 100%;
					max-width: 100%;
				}
			}
		}
	}
	ul.arrows {
		li {
			@include bg('arr-r.svg', 0 9px, no-repeat);
			background-size: 7px 7px;
			padding-left: 27px;
			line-height: 1.5;

		}
	}
	.r_current-profiles {
		padding-bottom: 50px;

		h3 {
			margin-bottom: 34px;

			@include mq(1023) {
				margin-bottom: 26px;
			}
			@include mq(1023) {
				margin-bottom: 18px;
			}
		}

		@include mq(1023) {
			padding-bottom: 30px;
		}
		@include mq(767) {
			padding-bottom: 20px;
		}

		&__row {
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			text-align: center;
			overflow-x: hidden;

			@include mqq(1024) {
				margin-left: -10px;
			}

			@include mq(1023) {
				margin: 0 25px;
			}

			@include mq(767) {
				margin: 0 40px;
			}
		}

		&__item {
			width: 140px;
			min-width: 140px;
			padding: 3px;
			margin: 0 10px;
			position: relative !important;

			@include mq(1023) {
				width: calc((100vw - 180px) / 4);
				min-width: 1px;
			}
			@include mq(767) {
				width: calc((100vw - 170px) / 3);
				min-width: 1px;
			}
			@include mq(559) {
				width: calc((100vw - 160px) / 2);
				min-width: 1px;
			}
			@include mq(519) {
				margin: 0 25px;
				width: calc(100vw - 160px);
				min-width: 1px;
			}
		}

		&__icon {
			max-width: 100%;
			height: 110px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__title {
			width: 100%;
			font-weight: bold;
			font-size: .875rem;
			margin-bottom: 15px;
			margin-top: -5px;
		}

		&__number {
			height: 82px;
			background-color: #ffffff;
			color: $bc1;
			font-size: 1.9375rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		h3 .bc {
			display: inline;
		}

		svg {
			fill: $bc2;
		}

		.box_svg {
			width: 46px;
			height: 52px;
		}

		.headset_svg {
			width: 51px;
			height: 51px;
		}

		.mouse_svg {
			width: 32px;
			height: 51px;
		}

		.cog_svg {
			width: 48px;
			height: 48px;
		}

		.chat_svg {
			width: 53px;
			height: 42px;
		}

		.human_svg {
			width: 39px;
			height: 58px;
		}

		.supply-chain_svg {
			width: 70px;
			height: 70px;
		}

		.finance_svg {
			width: 50px;
			height: 50px;
		}

		.marketing-pr_svg {
			width: 45px;
			height: 45px;
		}

		.sales_svg {
			width: 70px;
			height: 57px;
		}

		.legal_svg {
			width: 64px;
			height: 56px;
		}

		.cycle-prev {
			@extend .cyclePrev;
			left: -115px;

			@include mq(1200) {
				left: -30px;
			}
			@include mq(1023) {
				left: 0;
			}
			@include mq(520) {
				left: -5px;
			}
		}

		.cycle-next {
			@extend .cycleNext;
			right: -115px;

			@include mq(1200) {
				right: -30px;
			}
			@include mq(1023) {
				right: 0;
			}
			@include mq(520) {
				right: -5px;
			}
		}
	}
	.r_conversion {
		padding: 60px 0 70px;

		@include mq(1023) {
			padding: 40px 0;
		}
		@include mq(767) {
			padding: 30px 0;
		}

		h3 {
			text-align: center;
			font-size: 2.5rem;

			@include mq(767) {
				font-size: 2rem;
			}
		}

		&__graph, &__details {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		&__graph {
			padding: 0 50px;
			margin-bottom: 110px;
			align-items: center;

			@include mq(1023) {
				margin-bottom: 70px;
				padding: 0 20px;
			}
			@include mq(767) {
				padding: 0;
			}
		}

		&__icon {
			width: 90px;
			position: relative;
			z-index: 1;

			@include mq(1023) {
				width: 180px;

				img {
					width: 90px;
				}
			}

			img {
				display: block;
				margin: 0 auto;
			}

			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				height: 3px;
				width: 260px;
				@include bg('r_dot.svg', 0 0, repeat);
				background-size: 10px auto;
				top: 50%;
				max-width: 20vw;

				@include mq(767) {
					display: none;
					content: unset;
				}
			}

			&:first-child:before {
				left: 100%;
				margin-left: 18px;
			}

			&:last-child:before {
				right: 100%;
				margin-right: 18px;
				background-position: 100% 0;
			}
		}

		&__graph-title {
			font-weight: bold;
			font-size: 1.6rem;
			white-space: nowrap;
			position: absolute;
			z-index: 1;
			top: 100%;
			left: 50%;
			transform: translate(-50%);
			margin-top: 20px;

			@include mq(767) {
				font-size: 1.2rem;
			}
		}

		.check_blue_svg {
			width: 83px;
			height: 67px;

			@include mq(767) {
				width: 42px;
				height: 34px;
			}
		}

		&__details {
			position: relative;
			z-index: 1;

			&:before {
				content: '';
				width: 1px;
				height: 100%;
				left: 50%;
				background-color: rgba($bc2, .15);
				position: absolute;
			}

			ul.arrows {
				li {
					background-position: 0 11px;
					font-size: 1.25rem;
				}
			}

			.item {
				width: 45%;
			}

			@include mq(767) {
				flex-direction: column;
				padding-top: 20px;

				h4 {
					margin-bottom: 1rem;
				}

				&:before {
					content: unset;
					display: none;
				}

				.item {
					width: 100%;
					max-width: 360px;
					margin: 0 auto;

					&:first-child {
						margin-bottom: 2rem;
					}
				}

				ul.arrows {
					li {
						font-size: 1.15rem;
					}
				}
			}
		}
	}
	.r_our-offer {
		margin-bottom: 80px;
		margin-top: -15px;

		@include mq(1023) {
			margin-bottom: 60px;
			margin-top: 0;
		}
		@include mq(767) {
			margin-bottom: 30px;
		}

		h3 .bc {
			display: inline;
		}

		h4 {
			font-size: 1.25rem;

			.bc {
				display: inline;
			}
		}

		p {
			line-height: 1.5rem;
		}

		&__cnt {
			position: relative;

			@include mqq(1025) {
				&:before, &:after {
					content: '';
					position: absolute;
					z-index: 1;
					height: 3px;
					width: calc((100vw - 960px) / 2);
					@include bg('r_dot.svg', 0 0, repeat);
					background-size: 10px auto;
				}

				&:before {
					top: 0;
					right: 100%;
				}

				&:after {
					bottom: 0;
					left: 100%;
				}
			}
		}

		&__item {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.dots.h:after {
				content: unset;
				display: none;
			}

			&:last-child .dots.h:after {
				content: '';
				display: block;
			}

			&:nth-child(odd) {
				.dots.v:before {
					content: unset;
					display: none;
				}
			}

			&:nth-child(even) {
				.dots.v:after {
					content: unset;
					display: none;
				}

				.lines {
					&:before, &:after {
						right: auto;
						left: 0;
					}
				}
			}

			@include mq(767) {
				align-items: flex-start;
			}
		}

		&__icon {
			width: 233px;
			height: 233px;
			display: flex;
			justify-content: center;
			align-items: center;

			@include mq(767) {
				width: 80px;
				height: 80px;
			}
		}

		&__desc {
			width: calc(100% - 233px);
			padding-right: 50px;

			@include mq(767) {
				padding: 25px 15px 25px 0;
				width: calc(100% - 80px);
			}
		}

		svg {
			fill: $bc2;

		}

		.house_svg {
			width: 67px;
			height: 67px;

			@include mq(767) {
				width: 33px;
				height: 33px;
			}
		}

		.check_blue_svg {
			width: 72px;
			height: 58px;

			@include mq(767) {
				width: 36px;
				height: 29px;
			}
		}

		.star_svg {
			width: 62px;
			height: 59px;

			@include mq(767) {
				width: 31px;
				height: 31px;
			}
		}

		.search_svg {
			width: 62px;
			height: 62px;

			@include mq(767) {
				width: 31px;
				height: 31px;
			}
		}
	}
	.r_pricing__price, .r_subscriptions__price {

		.thePrice {
			font-size: 2.3125rem;
			font-weight: bold;
		}

		small {
			display: block;
			font-size: 0.875rem;
			opacity: .5;
			line-height: .8;
		}
	}
	.r_pricing__item, .r_subscriptions__item {
		-webkit-font-smoothing: subpixel-antialiased;
		transition: transform 0.15s ease-in;
		-webkit-transform: translateZ(0) scale(1.0, 1.0);

		@include mqq(768) {
			&:hover, &.expanded {
				transform: scale(1.02);
				box-shadow: 0 0 10px 3px rgba($bc2, .1);
			}
		}

		&:hover, &.expanded {
			z-index: 3;
		}

		&:hover {
			.formTrigger {
				opacity: 1;
				visibility: visible;
			}
		}

		&.expanded {
			@include mqq(768) {
				.formTrigger {
					opacity: 0;
					visibility: hidden;
				}
			}


			.pricingForm {
				transform: translate(0, 0);
				opacity: 1;
				visibility: visible;
			}
		}

		.formTrigger {
			width: auto;

			@include mqq(768) {
				position: absolute;
				z-index: 1;
				bottom: 0;
				left: 50%;
				transform: translate3D(-50%, 75%, 0);
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.15s ease;
			}
			@include mq(767) {
				margin: 0 auto 20px;
			}
		}

		.pricingForm {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			box-shadow: 0 0 10px 3px rgba($bc2, .1);
			transition: all .25s ease-in;
			transform: translate(0, -15px);
			opacity: 0;
			visibility: hidden;
		}
	}
	@include mqq(768) {
		.r_subscriptions__item {
			.formTrigger {
				transform: translate3D(0, 75%, 0);
				left: 40px;
			}

			.pricingForm {
				right: 160px;
			}
		}
	}
	.r_pricing {
		margin-bottom: 25px;
		position: relative;
		z-index: 11;

		&__row {
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			@include mq(767) {
				flex-direction: column;
				align-items: center;
			}
		}

		&__item {
			width: calc((100% - 40px) / 3);
			background-color: #ffffff;
			position: relative;
			margin-bottom: 40px;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: stretch;

			&:first-child .mainIcon {
				width: 108px;
				height: 103px;
			}

			&:nth-child(2) .mainIcon {
				width: 108px;
				height: 108px;
			}

			&:nth-child(3) .mainIcon {
				width: 133px;
				height: 119px;
			}

			@include mq(767) {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		&__iconH {
			height: 185px;
		}

		&__title {
			text-align: center;
		}

		&__body {
			padding: 35px 20px;
			border-top: 2px solid rgba($bc2, .05);
			min-width: 100%;

			li:first-child {
				font-weight: bold;
			}

			@include mq(767) {
				padding: 20px;
			}
		}

		ul.arrows {
			li {
				padding-left: 20px;
			}

			@include mq(767) {
				max-width: 207px;
				margin: 0 auto;
			}
		}

		&__price {
			height: 115px;
			border-top: 2px solid rgba($bc2, .05);
			margin-top: auto;
			margin-bottom: 0;
			width: 100%;
		}

		&__iconH, &__price {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			@include mq(767) {
				height: auto;
				padding: 20px;
			}
		}

	}
	.r_subscriptions {
		z-index: 2;
		margin-bottom: 45px;

		@include mq(767) {
			margin-bottom: 20px;
		}

		h3 {
			margin-bottom: 2rem;
		}

		h4 {
			margin-bottom: 0;
		}

		&__row {
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			@include mq(767) {
				flex-direction: column;
				align-items: center;
			}
		}

		&__item {
			height: 115px;
			width: calc((100% - 20px) / 2);
			background-color: #ffffff;

			display: flex;
			align-items: stretch;
			justify-content: space-between;
			margin-bottom: 20px;

			@include mq(767) {
				width: 100%;
				flex-direction: column;
				height: auto;
			}
		}

		&__title {
			padding: 20px 40px;
		}

		&__price {
			width: 160px;
			border-left: 2px solid rgba($bc2, .05);
			text-align: center;

			@include mq(767) {
				border-left: 0 none;
				border-top: 2px solid rgba($bc2, .05);
			}
		}

		&__title, &__price {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			@include mq(767) {
				text-align: center;
				width: 100%;
				padding: 20px;
			}
		}
	}
	.r_register-trial {
		margin-bottom: 80px;
		position: relative;
		z-index: 1;
		font-size: 0;

		@include mq(767) {
			margin-top: 0;
			margin-bottom: 40px;

			h3 {
				margin-bottom: 1rem;
			}
		}

		&:before {
			content: '';
			position: absolute;
			z-index: 0;
			left: 0;
			top: 50%;
			height: 3px;
			width: 100vw;
			@include bg('r_dot.svg', 0 0, repeat);
			background-size: 10px auto;

			@include mq(767) {
				content: unset;
				display: none;
			}
		}

		h3 {

			@include mqq(767) {
				display: inline-block;
				vertical-align: middle;
				padding-right: 40px;
				position: relative;
				z-index: 2;
				margin-bottom: 0;
				background-color: $graybg;
			}


			.bc {
				display: inline;
			}
		}
	}
	.bubbled-module .r_register-trial {
		padding-top: 27px;
		padding-bottom: 27px;
		margin-top: 0;
		margin-bottom: 0;

		@include mqq(767) {
			h3 {
				background-color: #ffffff;
			}
		}
	}
	.r_webinar {

		.cell__graphic {
			.splash_svgs, ul.elems li.edit, ul.gray_lines li:nth-child(4), ul.gray_lines li:nth-child(2), ul.gray_lines li:nth-child(5) {
				top: 50%;

				@include mq(767) {
					top: 75%;
				}
			}

			.blue.edit svg {
				top: 55%;
			}
		}

		h3 {
			margin-bottom: 2rem;

			.bc {
				display: inline;
			}
		}

		h4 {
			margin-bottom: 20px;
		}

		.row__top .cell {
			padding-bottom: 40px;
			padding-right: 40px;

			@include mq(767) {
				padding: 25px;
			}
		}

		.row__specs {
			align-items: center;

			&:after {
				display: none;
			}

			.cell {
				padding: 50px 30px 50px 60px;

				@include mq(767) {
					padding: 25px;
				}
			}
		}

		.webinaraddress {
			font-size: 1.25rem;
			line-height: 2rem;
			padding-top: 20px;

			@include mq(767) {
				padding-top: 0;
			}

			li {
				padding-left: 35px;
			}
		}

		.ico-pin {
			@include bg('/svg-sprite-source/r_pin.svg', 0 7px);
			background-size: 13px 17px;
		}

		.ico-eta {
			@include bg('/svg-sprite-source/r_eta.svg', 0 7px);
			background-size: 13px 17px;
		}

		.ico-info {
			@include bg('/svg-sprite-source/r_info.svg', 2px 50%);
			background-size: 9px 23px;
		}

		.icoplay_svg {
			width: 162px;
			height: 162px;

			@include mq(767) {
				width: 80px;
				height: 80px;
			}
		}

		@include mq(767) {
			.cell__graphic {
				height: 150px;
				margin-bottom: 25px;
			}
		}
	}
	.r_company-testimonials {
		padding: 60px 0;

		@include mq(1023) {
			padding: 40px 0;
		}
		@include mq(767) {
			padding: 30px 0;
		}

		&__row {
			position: relative;
			z-index: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.cycle-cnt {
			@include mq(1023) {
				margin: 0 25px;
			}
			width: 100%;
		}

		&__item {
			position: relative;
			z-index: 1;
			display: flex !important;
			justify-content: space-between;
			align-items: center;

			@include mq(581) {
				flex-direction: column;
			}
		}

		h5 {
			font-size: 1.125rem;
			color: #fff;
			background-color: $bc1;
			display: inline-block;
			height: 2rem;
			line-height: 2rem;
			padding: 0 40px 0 20px;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%);

			@include mq(767) {
				font-size: 1rem;
				left: -20px;
				right: -20px;
				padding: 0 20px;
			}
		}

		.blockquoteText.mainQuote {
			padding-left: 170px;
			//width: 560px;
			max-width: 100%;
			color: $bc2;

			&:before, &:after {
				border-width: 40px 40px 0 0;
			}

			&:before {
				left: 10px;
			}

			&:after {
				left: 65px;
			}

			@include mq(1023) {
				width: calc(100% - 320px);
				padding-left: 120px;

				&:before, &:after {
					border-width: 25px 25px 0 0;
				}

				&:before {
					left: 0;
				}
				&:after {
					left: 30px;
				}
			}

			@include mq(767) {
				padding-left: 0;
				width: calc(100% - 220px);
			}
			@include mq(581) {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		.logoHolder {
			width: 400px;
			max-width: 100%;

			display: none;

			img {
				display: block;
				margin: 0 auto;
			}

			@include mq(1023) {
				width: 300px;
			}
			@include mq(767) {
				width: 200px;
			}
			@include mq(581) {
				width: 100%;
			}
		}

		.cycle-prev, .cycle-next {
			top: 0;
			bottom: 0;
			transform: none;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}

		.cycle-prev {
			@extend .cyclePrev;
			left: -115px;

			@include mq(1200) {
				left: -30px;
			}
			@include mq(1023) {
				left: 0;
			}
		}

		.cycle-next {
			@extend .cycleNext;
			right: -115px;

			@include mq(1200) {
				right: -30px;
			}
			@include mq(1023) {
				right: 0;
			}
		}
	}
	.r_blog-posts {
		margin-bottom: 120px;
		margin-top: -20px;

		@include mq(1023) {
			margin-bottom: 40px;
			margin-top: 0;
		}

		@include mq(767) {
			margin-bottom: 10px;
		}

		h3 {
			margin-bottom: 1.5rem;

			.bc {
				display: inline;
			}
		}

		&__row {
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			@include mq(767) {
				flex-direction: column;
			}
		}

		&__item {
			width: calc((100% - 40px) / 3);
			background-color: #ffffff;
			padding-bottom: 20px;
			position: relative;
			z-index: 1;
			margin-bottom: 40px;
			text-align: center;

			display: flex;
			flex-direction: column;

			@include mq(1023) {
				padding-bottom: 40px;
			}

			@include mq(767) {
				width: 100%;
				margin-bottom: 20px;
				padding-bottom: 20px;
			}
		}

		.btn {
			//position: absolute;
			//left: 50%;
			//bottom: 0;
			//transform: translate(-50%, 50%);
			//padding: 0 40px;
			//white-space: nowrap;

			margin: auto auto 0;

			@include mq(1200) {
				padding: 0 20px;
			}

			@include mq(767) {
				margin-top: 20px;
				position: static;
				transform: translate(0, 0);
			}
		}

		&__th {
			margin-bottom: 40px;
			width: 100%;
			min-width: 100%;
			position: relative;
			z-index: 1;

			img {
				display: block;
				width: 100%;
			}

			.tag {
				width: 64px;
				height: 64px;
				border-radius: 50%;
				position: absolute;
				top: 20px;
				right: 20px;
				z-index: 2;
				color: #fff;
				font-size: 0.9375rem;
				font-weight: bold;
				text-align: center;
				background-color: $bc1;

				display: flex;
				align-items: center;
				justify-content: center;
			}

			@include mq(1023) {
				margin-bottom: 20px;
			}
		}

		h4 {
			margin-bottom: 2rem;
			padding: 0 35px;
			text-align: left;

			@include mq(1023) {
				padding: 0 20px;
				margin-bottom: 1rem;
			}
		}

		&__excerpt {
			padding: 0 35px 40px;
			text-align: left;

			@include mq(1023) {
				padding: 0 20px 20px;
			}
		}
	}
	.immIncentive {
		width: 250px;
		position: fixed;
		z-index: 3;
		padding: 15px 35px 20px 20px;
		background-color: #ffffff;
		right: 0;
		top: 50%;
		transform: translate3D(0, -170%, 0);
		filter: drop-shadow(0 0 3px rgba($bc2, .1));
		opacity: 0;
		visibility: hidden;
		@extend %transition;

		&.visible {
			opacity: 1;
			visibility: visible;
		}

		h4 {
			font-size: 1rem;
			margin-bottom: 1rem;

			.bc {
				display: inline;
			}
		}

		.btn {
			height: 30px;
			line-height: 30px;
			padding: 0 14px;

			font-size: 0.6875rem;
		}
	}
	.r_hire-steps {
		margin-bottom: 30px;

		@include mq($tablet) {
			margin-bottom: 10px;
		}

		&__row {
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			@include mq(767) {
				flex-direction: column;
			}
		}

		&__item {
			background-color: #fff;
			width: calc((100% - 40px) / 3);
			margin-bottom: 15px;

			@include mq($tablet) {
				width: calc((100% - 30px) / 3);
			}
			@include mq(767) {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: stretch;
			}
		}

		&__iconHolder {
			height: 225px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 2px solid rgba($bc2, .05);

			img {
				height: 110px;

				@include mq(519) {
					height: 80px;
				}
			}

			@include mq(767) {
				height: auto;
				width: 120px;
				min-width: 120px;
				max-width: 120px;
				padding: 20px;
			}
			@include mq(519) {
				width: 80px;
				min-width: 80px;
				max-width: 80px;
			}
		}

		&__desc {
			padding: 35px 50px 40px 20px;

			@include mq($tablet) {
				padding: 20px 15px;
			}
			@include mq(767) {
				flex-grow: 1;
				padding: 15px 15px 15px 0;
			}
		}

		h4 {
			margin-bottom: 15px;

			@include mq($tablet) {
				font-size: 1.375rem;
			}
		}

		p {
			line-height: 1.5;
		}

		.bubbled-module {
			margin-bottom: 65px;
		}
	}
	.r_hiring-process {
		margin-bottom: 110px;

		@include mq($tablet) {
			margin-bottom: 40px;
		}

		&__cnt {
			position: relative;
			z-index: 1;

			@include mq(767) {
				display: flex;
				align-items: stretch;
				justify-content: space-between;

			}
		}

		.baseIcon {
			width: 190px;
			position: absolute;
			z-index: 3;
			right: 0;
			top: 55px;
			background-color: $graybg;

			@include mq($tablet) {
				width: 120px;
				top: 90px;
			}
			@include mq(767) {
				top: auto;
				bottom: 0;

				width: 90px;
				height: 90px;
				right: 50%;
				transform: translate(50%, 50%);
			}
		}

		&__row {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			position: relative;
			z-index: 1;
			counter-reset: orderno;

			&:before, &:after {
				content: '';
				width: 3px;
				height: 3px;
				background: url('../../images/front/r_dot.svg') 0 0 repeat;
				background-size: 10px 10px;
				position: absolute;
				z-index: 1;
			}

			&:before {
				width: calc(100% - 90px);

				@include mq($tablet) {
					width: calc(100% - 62px);
				}
				@include mq(767) {
					width: 10px;
					top: 20px;
					bottom: 0;
					height: auto;
				}
			}

			&:after {
				height: 150px;
				right: 87px;

				@include mq($tablet) {
					right: 60px;
				}

				@include mq(767) {
					height: 3px;
					width: auto;

				}
			}

			&.candidat {
				height: 300px;

				&:after {
					top: 0;
				}

				@include mq(767) {
					height: auto;

					&:after {
						left: 15px;
						right: 0;
						top: auto;
						bottom: 0;
					}
					&:before {
						top: 20px;
						bottom: 0;
						left: 13px;
						right: auto;
					}
				}
			}

			&.companie {

				&:after {
					bottom: 100%;
					margin-bottom: -3px;
				}

				@include mq(767) {

					&:after {
						right: 15px;
						top: auto;
						bottom: 0;
						margin-bottom: 0;
						left: 0;
					}
					&:before {
						top: 20px;
						bottom: 0;
						left: auto;
						right: 7px;
					}
					.cell {
						padding-left: 10px;
						padding-right: 45px;
						text-align: right;
					}
					.cell.cellmain {
						left: auto;
						right: 0;
					}
					.cell.cell-item:before {
						right: 0;
						left: auto;
					}
					.cell img {
						left: auto;
						right: 0;
						margin-right: 15px;
						transform: translate(50%, 0);
						margin-left: 0;
					}
				}
			}

			@include mq(767) {
				flex-direction: column;
				padding-bottom: 40px;
				padding-top: 70px;

			}
		}

		.cell {
			padding-top: 45px;
			padding-right: 20px;
			position: relative;
			z-index: 2;

			&.cellmain {
				width: 18.75%;
				padding-top: 43px;

				@include mq(767) {
					width: 100%;
					padding-top: 8px;
					margin-bottom: 30px;
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
				}
			}

			&.step1 {
				width: 23%;

				@include mq(767) {
					width: 100%;
				}
			}

			&.step2 {
				width: 21%;

				@include mq(767) {
					width: 100%;
				}
			}

			&.step3 {
				width: 14.5%;

				@include mq(767) {
					width: 100%;
				}
			}

			img {
				width: 60px;
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				transform: translateY(-50%);

				@include mq(767) {
					width: 40px;
					transform: none;
					margin-left: -5px;
				}
			}

			&.cell-item {
				counter-increment: orderno;
				font-size: 1.125rem;
				line-height: 1.166;

				&:before {
					content: counter(orderno);
					position: absolute;
					top: 0;
					left: 0;
					transform: translateY(-50%);
					width: 40px;
					height: 40px;
					text-align: center;
					background-color: $bc2;
					color: #fff;
					display: block;
					border-radius: 50%;
					line-height: 40px;
					font-weight: bold;
					font-size: 1.4375rem;
				}

				@include mq(767) {
					font-size: 1rem;
				}
			}

			@include mq(767) {
				padding: 5px 10px 5px 45px;
				margin-bottom: 20px;

				&.cell-item {
					&:before {
						transform: none;
						width: 30px;
						height: 30px;
						line-height: 30px;
						font-size: 1rem;
					}
				}
			}
		}

		h4 {
			margin-bottom: 0;
		}
	}
	.categoryLabel {
		font-size: 0.8125rem;
		opacity: .5;
		margin-bottom: 10px;
		font-weight: bold;
	}
	.r_cards__wrap {
		width: 100%;
		margin-bottom: 25px;

		.r_card {
			margin-bottom: 0;
			border-bottom: 1px solid rgba($bc2, .15);

			&:last-child {
				border-bottom: 0;
			}
		}

		@include mq($tablet) {
			> .categoryLabel {
				padding-left: 20px;
			}
		}
	}
	.avatar.avatar-switch {
		z-index: 4;
	}
	.avatar-switch {
		cursor: pointer;

		&.open {
			.avatar-switch__avatar-list {
				opacity: 1;
				visibility: visible;

				li {
					opacity: 1;
					visibility: visible;
					transform: translateX(0);
				}
			}
		}

		&__avatar-list {
			position: absolute;
			z-index: 11;
			left: 100%;

			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-left: 27px;
			filter: drop-shadow(0 0 15px rgba($bc2, .25));
			opacity: 0;
			visibility: hidden;
			top: 50%;
			transform: translate(0, -50%);
			cursor: none;

			@include mq($tablet) {
				left: 50%;
				top: 100%;
				margin-left: 0;
				flex-direction: column;
				transform: translate(-50%, 27px);

			}

			li {
				opacity: 0;
				visibility: hidden;

				width: 100px;
				height: 100px;
				display: block;
				background-color: #ffffff;

				text-align: center;
				color: rgba($bc2, .5);
				cursor: pointer;

				border-left: 1px solid rgba($bc2, .15);
				transform: translateX(30px);

				a {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100px;
					height: 100px;
				}

				@include mq($tablet) {
					transform: translateY(30px);
					border-top: 1px solid rgba($bc2, .15);
					border-left: 0;
				}

				&:nth-child(1) {
					transition: opacity 0.2s ease-in 0.05s, transform 0.2s ease-in 0.05s, color ease 0.25s, background-color ease 0.25s;
				}

				&:nth-child(2) {
					transition: opacity 0.2s ease-in 0.10s, transform 0.2s ease-in 0.10s, color ease 0.25s, background-color ease 0.25s;
				}

				&:nth-child(3) {
					transition: opacity 0.2s ease-in 0.15s, transform 0.2s ease-in 0.15s, color ease 0.25s, background-color ease 0.25s;
				}

				&:nth-child(4) {
					transition: opacity 0.2s ease-in 0.20s, transform 0.2s ease-in 0.20s, color ease 0.25s, background-color ease 0.25s;
				}

				&:nth-child(5) {
					transition: opacity 0.2s ease-in 0.25s, transform 0.2s ease-in 0.25s, color ease 0.25s, background-color ease 0.25s;
				}

				&:first-child {
					border-left: 0 none;

					@include mq($tablet) {
						border-top: 0 none;
					}
				}
				&:first-child:before {
					content: '';
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-right-color: #fff;
					position: absolute;
					right: 100%;
					top: 50%;
					margin-top: -10px;
					transition: all 0.25s ease;

					@include mq($tablet) {
						right: auto;
						bottom: 100%;
						top: auto;
						margin-top: 0;
						left: 50%;
						margin-left: -10px;
						border-right-color: transparent;
						border-bottom-color: #fff;
					}
				}

				&.selected {
					cursor: default;

					a {
						pointer-events: none;
					}
				}

				&:hover, &.selected {
					background-color: $bc2;

					a {
						color: #fff;
					}
				}
				&:hover:first-child:before, &.selected:first-child:before {
					border-right-color: $bc2;

					@include mq($tablet) {
						border-right-color: transparent;
						border-bottom-color: $bc2;
					}
				}
			}
		}

		&__avatar-ico {
			width: 50px;
			height: 50px;

			svg {
				display: block;
				width: 50px;
				height: 50px;
			}
		}

		&__avatar-title {
			padding-top: 10px;
			font-size: 12px;
		}
	}
	.cropperCnt {
		position: absolute;
		top: -65px;
		z-index: 2;
		left: 50%;
		transform: translate(-50%,15px);
		background-color: #fff;
		text-align: center;
		filter: $filter-shadow;
		padding: 0 0 20px;
		width: 300px;
		@extend %transition;
		opacity: 0;
		visibility: hidden;


		@include mq($tablet) {
			width: 250px;
		}

		&.expanded {
			opacity: 1;
			visibility: visible;
			transform: translate(-50%,0);
		}

	}
 /////////////////////////////////
	.profileCard {
		position: relative;
		z-index: 1;

		.form__group, .foot {
			width: 100%;
		}

		.editIco, .inviteIco {
			position: absolute;
			z-index: 3;
			right: 25px;
			top: 20px;
		}

		.userNotes, .sendMessage, .addRefferral {
			right: auto;
			left: 25px;
			position: absolute;
			z-index: 3;
			top: 20px;
		}

		.addRefferral {
			top: 65px;
		}

		.avatar {
			text-align: center;
			display: block;
			position: relative;
			z-index: 1;
			top: -60px;
			margin: 0 auto -30px;
			width: 120px;
			border-radius: 50%;

			&__pic {
				width: 120px;
				border-radius: 50%;
				display: block;
				margin: 0 auto;
				position: relative;
				z-index: 1;
			}

			label.icon-camera {
				width: 24px;
				height: 24px;
				display: block;
				margin: 0 auto;
				padding: 0;
				position: absolute;
				z-index: 2;
				bottom: 10px;
				left: 50%;
				transform: translate(-50%, 0);
				opacity: 0;
				visibility: hidden;
				@extend %transition;

				filter: $filter-shadow;

				svg {
					display: block;
					width: 24px;
					height: 24px;
					fill: #fff;
				}
			}

			input[type='file'] {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: -1;
				opacity: 0;
				visibility: hidden;
			}

			&:hover {
				label.icon-camera {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.userName {
			text-align: center;
			font-size: 1.375rem;
			margin-bottom: 25px;

			&__theName {
				font-weight: bold;
				color: $bc1;
			}

			&__theTitle {
				color: rgba($bc2, .5);
				font-size: 17px;
			}
		}

		.share-company {
			margin-top: -20px;
			.userName {
				margin-bottom: 15px;
			}
		}

		&__item {
			padding: 15px 0;
			border-bottom: 1px solid rgba($bc2, .15);

			&__hidden {
				display: none;
				@include mq(767) {
					display: block;
				}
			}

			&:last-child {
				border-bottom: 0;
			}

			span {
				display: block;
			}

			.value {
				font-size: 1.125rem;
				font-weight: bold;

				&.clip {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			.labelLike {
				padding-left: 0;
				margin-bottom: 0;

				&.line {
					display: block;
					position: relative;

					span {
						display: inline-block;
					}

					> .right {
						position: absolute;
						right: 0;
					}
				}
			}
		}

		.r_card__cnt {
			position: relative;
			z-index: 1;
		}

		.cropperCnt {
			position: absolute;
			top: -65px;
			z-index: 2;
			left: 50%;
			transform: translate(-50%, 15px);
			background-color: #fff;
			text-align: center;
			filter: $filter-shadow;
			padding: 0 0 20px;
			width: 300px;
			@extend %transition;
			opacity: 0;
			visibility: hidden;


			@include mq($tablet) {
				width: 250px;
			}

			&.expanded {
				opacity: 1;
				visibility: visible;
				transform: translate(-50%, 0);
			}

		}

	}

	.share-text {
		color: rgba(15, 35, 63, 0.5);
		font-size: 80%;
		margin-bottom: .5rem;
	}

	.share-button {
		padding: 0 4px 0 0;
		svg {
			width: 15px;
			height: 15px;
			fill: $bc1;

			&:hover {
				opacity: .8;
			}
		}
	}
}

@media all and (-ms-high-contrast: none) {
	.r_redesign {
		.contact-module, .stats-module {
			&__row {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
			}
		}

		.stats-module {
			&__item {
				width: calc((100% - 80px) / 3);
			}
		}

		.contact-module {
			&__desc, &__form-holder {
				width: calc((100% - 40px) / 2);
			}
		}
	}
}

.rowEssentials, .rowMoreDetails {
	display: flex;
	min-width: 100%;
	justify-content: space-between;

	.r_card {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.r_card__cnt {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		align-self: stretch;
		justify-content: center;
		min-height: calc(100% - 77px);
	}

	.outputResult, .edit {
		align-self: stretch;
		min-width: 100%;
	}

	@include mq(767) {
		flex-flow: column;

		aside, section {
			width: 100%;
			min-width: 100%;
		}
	}
}


.rowEssentials {

	section, aside {
		display: flex;
		flex-direction: column;
	}

	@include mq(767) {
		padding-top: 60px;
	}
}

.r_resume-edit {

	section {

		.tab {
			background-color: #fff;
			visibility: hidden;
			@extend %acc1;

			&__cnt {
				padding: 0 20px 20px;

				.tab__body:first-child {
					padding-top: 20px;
				}

				@include mq(767) {
					padding: 0 30px 30px;

					.tab__body:first-child {
						padding-top: 30px;
					}
				}

				&.google_api_placeholder .tab__body:first-child {
					padding-top: 0;

					@include mq(767) {
						padding-top: 0;
					}
				}
			}

			&.current {
				@extend %acc2;
				visibility: visible;
			}

			&.valuesperks {
				background-color: transparent;

				.r_card {
					min-height: 1px;
					height: auto;

					&:last-child {
						margin-bottom: 20px;
					}
				}

				.r_nlist {
					li {
						margin-bottom: 20px;
					}
					li:before {
						top: 50%;
						transform: translateY(-50%);
					}

					li:last-child {
						margin-bottom: 0;
					}

					.form__group {
						margin-bottom: 0;
					}
				}

				.r_cards {
					~ .add-row {
						justify-content: flex-end;
					}
				}
			}
		}
	}

	.resumeProgress {
		text-align: center;
		font-size: 0.8125rem;
		color: rgba($bc2, .5);
		position: relative;
		z-index: 1;

		@include mq(767) {
			padding-top: 20px;
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	.progressHolder {
		background-color: rgba($bc2, .1);
		height: 10px;
		border-radius: 5px;
		margin-bottom: 10px;
		position: relative;
		z-index: 1;
		overflow: hidden;

		.bar {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background-color: $bc1;
			@extend %transition;
		}
	}

	.resumeSectionTitle, .r_cadidateSpecs {
		.iconH {
			width: 35px;
		}

		svg:not(.edit_svg) {
			fill: $bc1;
		}

		.theTitle {
			font-weight: bold;
			font-size: 1rem;
			text-transform: uppercase;
			user-select: none;

			small {
				font-size: 0.8125rem;
				opacity: .5;
				text-transform: none;
				font-weight: normal;
			}
		}
	}

	.resumeSectionTitle {
		border-bottom: 4px dotted rgba($bc2, .15);
		display: flex;
		align-items: center;
		height: 52px;
		margin-bottom: 20px;
		@extend %transition;

		&:last-child {
			border-bottom: 0;
			height: auto;
			margin-bottom: 0;
			padding-top: 20px;
		}

		.yesnoresult {
			margin-right: -20px;
			margin-left: auto;
			text-transform: uppercase;
			width: 64px;
			text-align: center;
			border-left: 1px solid rgba($bc2, .15);
			font-size: 1rem;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}

		.switch-holder {
			margin-right: 0;
			margin-left: auto;
		}

		.labelLike {
			cursor: pointer;
			margin-right: 0;
			margin-left: auto;
			margin-bottom: 0;

			.ico {
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background-color: rgba($bc2, .25);
				display: inline-block;
				vertical-align: middle;
				position: relative;
				z-index: 1;
			}

			@include mq(599) {
				.txt {
					display: none;
				}
			}

			.icon__linkedin {
				width: 11px;
				height: 10px;
			}

			svg {
				width: 25px;
				height: 25px;
				fill: #fff;
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.tooltip {
			position: absolute;
			right: 0;
		}
	}

	.beacon {
		height: 0;
		position: absolute;
		top: -1px;
		left: 0;
		right: 0;
	}

	.skillssubrowspace {
		width: 100%;
		height: 0;
		clear: both;
	}

	.skillGroupTitle {
		padding-left: 20px;
		margin-bottom: 5px;
	}

	.row.notFilled {
		display: none;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;

		border-bottom: 1px solid rgba($bc2, .15);
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		z-index: 1;

		&.empty, &.add-row {
			border-bottom: 0;
		}

		&.add-row, &.flushBottom {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		&.flushBottom {
			border-bottom: 0 none;
		}

		&.empty ~ .foot, &.empty ~ .row .foot {
			display: none;
		}

		&.added ~ .foot {
			display: block;
			padding-top: 10px;
		}

		&.added ~ .row .foot {
			display: inline-block;

			@include mq($tablet) {
				min-width: calc(50% - 10px);

				.btn {
					width: 100%;
				}
			}

			@include mq(599) {
				display: block;
				width: 100%;
			}
		}

		&.added ~ .empty {
			display: none;
		}

		.cell {
			width: calc((100% - 20px) / 2);

			.subgroup {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			&.split {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
			}

			&.wider {
				width: 178px;
			}

			&.widest {
				width: calc(100% - 58px);

				@include mq($tablet) {
					width: calc(100% - 58px) !important;
				}
			}

			&.widest-2 {
				width: calc((100% - 58px) / 2);

				@include mq($tablet) {
					width: calc((100% - 58px) / 2) !important;
				}
			}

			.narrower {
				width: calc(100% - 198px);
			}

			&.narrowest {
				width: 38px;

				@include mq($tablet) {
					width: 38px !important;
				}
			}

			@include mq($tablet) {
				width: 100%;

				&.wider, &.narrower {
					width: 100%;
				}

				&.split {
					.cell {
						width: calc((100% - 20px) / 2);
					}
				}
			}
		}

		.full, .tar, .skillGroup {
			width: 100%;
		}

		.form__group:nth-last-child,
		.form__group:nth-last-child(2) {
			margin-bottom: 0;
		}

		.form__group.flushBottom {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		&.empty {
			align-items: center;
			min-height: 158px;
			padding-left: 30px;

			svg {
				fill: #bec7cf;
			}

			.placeholderDesc {
				width: 60%;
				color: rgba($bc2, .25);
				font-size: 1.375rem;
				padding-right: 40px;
			}

			.placeholderIco {
				width: 40%;
				text-align: center;
			}

			@include mq(599) {
				display: flex;
				flex-direction: column;
				padding-left: 15px;

				.placeholderDesc, .placeholderIco {
					width: 100%;
					min-width: 100%;
					text-align: center;
				}
				.placeholderDesc {
					padding-right: 0;
					padding-top: 25px;
				}

				.placeholderIco {
					order: -1;
				}
			}
		}
	}

	.outputResult {
		.row {
			margin-bottom: 25px;
			padding-bottom: 25px;
			&.realign{
				display: block;
				.previousJob{
					justify-content: flex-start;
					.previousJob__dates{
						flex: 0.3;
						white-space:normal;
					}
					.previousJob__desc{
						flex: 1;
					}
				}
			}
			&.educationRow.realign{
				display: flex;
				.educationRow__dates{
					max-width: inherit;
					flex: 0.3;
					min-width: 25%;
					white-space:normal;
				}
				.educationRow__desc{
					flex: 1;
					min-width: auto;
				}
			}
		}
	}

	.phoneNumber {
		.split__left {
			width: 80px;
		}

		.split__right {
			width: calc(100% - 80px);
		}
	}

	.welded {

		.split__left {
			select, input {
				border-radius: 18px 0 0 18px;
			}
		}

		.split__right {
			select, input {
				border-radius: 0 18px 18px 0;
			}
		}

		.split__middle {
			select, input {
				border-radius: 0;
			}
		}

	}

	.tar.foot {
		position: relative;
		margin-top: 10px;
	}

	.btn {
		font-size: 12px;
		min-width: 130px;
		padding-left: 15px;
		padding-right: 15px;

		@include mq(767) {
			margin-bottom: 5px;
		}
		// }
		@include mq($tablet) {
			margin-bottom: 20px;
			min-width: calc(50% - 10px);
		}
		@include mq(599) {
			min-width: 100%;
			margin: 0 0 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}

	}

	.tar.inline {
		float: right;
		width: auto;
		line-height: 1.6;
		margin-bottom: 0;

		.tar.inline {
			float: right;
			width: auto;
			line-height: 1.6;
			margin-bottom: 0;
		}

		@include mq($tablet) {
			.tar.inline:not(.removeSkillSubrow) {
				float: none;
				width: 100%;
			}
		}
	}

	.availabilitySection {
		.row {
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;

			&.spaced-row {
				border-top: 1px solid rgba($bc2, .15);
				margin-top: 20px;
				padding-top: 20px;
			}

			align-items: center;
		}

		.cell {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc((100% - 70px) / 2);

			&.full {
				width: 100%;
			}

			.form__group {
				width: calc(100% - 43px);
			}

			@include mq(767) {
				width: 100%;
			}
		}

		.icoH {
			width: 43px;
		}

		svg {
			fill: rgba($bc2, .15);
		}

		.clock_svg {
			width: 23px;
			height: 23px;
		}

		.euro_svg {
			width: 23px;
			height: 23px;
		}

		.largepin_svg {
			width: 23px;
			height: 27px;
		}

		.star_svg {
			width: 23px;
			height: 22px;
		}
	}



	.empty {
		.briefcase_svg {
			width: 106px;
			height: 94px;
		}

		.house_svg {
			width: 105px;
			height: 105px;
		}

		.updates_svg {
			width: 93px;
			height: 93px;
		}

		.star_svg {
			width: 125px;
			height: 119px;
		}

		.language_svg {
			width: 132px;
			height: 111px;
		}

		.info_svg {
			width: 40px;
			height: 100px;
		}

		.refferrals_svg {
			width: 110px;
			height: 110px;
		}
	}

	.tab, .edit {
		.knowledgeLevel {
			@include mq($tablet) {
				max-width: calc((100% - 20px) / 2);
			}

			@include mq(599) {
				margin-right: 0;

				.knolev {
					padding: 0 20px;

					label {
						width: 15px;
						height: 15px;
					}
				}
			}
		}
	}

	.knowledgeLevel {
		max-width: 100px;
		width: 100px;

		@include mq($tablet) {
			margin-right: 27px;
		}
		@include mq(599) {
			text-align: center;
			height: auto;
			max-width: 100%;
		}

		label {
			padding: 0;
		}

		> label {
			width: 100%;
		}

		.knolev {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			z-index: 1;
			height: 38px;

			label {
				width: 10px;
				height: 10px;
				display: inline-block;
				background-color: $bc1;
				border-radius: 50%;
				@extend %transition;
				min-width: 10px;
			}
		}

		input {
			width: 0;
			height: 0;
			opacity: 0;
			visibility: hidden;
			position: absolute;
			left: 0;
			top: 0;


			&:checked + label {
				~ label {
					background-color: rgba($bc2, .15);
				}
			}
		}
	}

	.addSubRow {
		display: inline-block;
		cursor: pointer;
		font-size: 1.2rem;
		height: 38px;
		width: 38px;
		border-radius: 50%;
		text-align: center;
		line-height: 38px;
		background-color: rgba($bc2, .05);
		@extend %transition;
		user-select: none;

		&:hover {
			background-color: $bc2;
			color: #fff;
		}
	}

	.experienceSection {
		@include mq($tablet) {
			.cell[class*='job_startedHolder'] + .cell > label {
				display: none;
			}

			.checkboxHolder {
				padding-left: 20px;
			}
		}
	}

	.skillsSection {
		//margin-bottom: 0;

		.skillGroup {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;

			&:nth-of-type(6) ~ .moreSkills {
				display: none;
			}
		}

		.moreSkills {
			width: 100%;

			.labelLike {
				cursor: default;
			}
		}

		@include mq(599) {
			.skillGroup {
				position: relative;
				z-index: 1;

				&:before {
					content: '';
					width: 1px;
					position: absolute;
					left: -15px;
					top: 43px;
					bottom: 33px;
					background-color: rgba($bc2, .15);
				}

				.cell:not(.split) {
					position: relative;
					z-index: 1;

					&:before {
						content: '';
						height: 1px;
						width: 15px;
						background-color: rgba($bc2, .15);
						position: absolute;
						right: 100%;
					}
				}

				> .cell:first-child:before, .cell.wider:before {
					top: 42px;
				}

				.cell.narrower:before {
					top: 40px;
				}
			}
			.edit .cell.split {
				flex-direction: column;
			}
			form {
				.cell, .cell.wider, .cell.narrower {
					min-width: 100%;
				}
			}
		}
	}

	.languagesSection {
		.added {
			min-height: 158px;

			~ .add-row {
				justify-content: flex-end;

				button.btn.gray {
					display: none;
				}
			}
		}
	}

}

.resumeTabs, .sideMenu,
aside.resumeTabs, aside.sideMenu {
	user-select: none;
	position: sticky;
	top: 10px;
	margin-bottom: auto;

	ul {
		margin-bottom: 20px;

		ul {
			margin-bottom: 0;
		}

		li {
			background-color: #fff;
			cursor: pointer;
			@extend %transition;
			padding: 19px 20px;
			border-top: 1px solid rgba($bc2, .15);
			text-transform: uppercase;
			font-weight: bold;
			color: rgba($bc2, .25);
			line-height: 1;
			font-size: 0.875rem;
			position: relative;
			z-index: 1;

			&:first-of-type {
				border-top: 0;
			}

			a {
				color: rgba($bc2, .25);
				display: block;

				&:before {
					content: '';
					position: absolute;
					z-index: 0;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;

				}
			}

			&.current, &:hover {
				background-color: $bc2;
				color: #fff;

				a {
					color: #fff;
				}
			}

			&.current + .sub {
				display: block;

				+ li {
					border-top: 0;

					@include mq($tablet) {
						border-top: 1px solid rgba($bc2, .15);
					}
				}
			}

			//@include mqq(768) {
			//	pointer-events: none;
			//}

			&.tabError {
				position: relative;

				&:after {
					content: '!';
					position: absolute;
					right: 20px;
					color: #fff;
					top: 50%;
					transform: translateY(-50%);
					font-size: 150%;
					width: 24px;
					height: 24px;
					line-height: 24px;
					background-color: $bc1;
					text-align: center;
					box-shadow: 0 0 3px rgba(#fff, .5) inset;
					border-radius: 50%;
					@extend %transition;
				}

				&:hover, &.current {
					&:after {
						color: $bc1;
						background-color: #ffffff;
						box-shadow: 0 0 3px rgba($bc2, .5) inset;
					}
				}
			}

			&.sub {
				@include bg('r_sideMenuArr.svg', 0 50%);
				background-size: 9px 10px;
				background-color: transparent;
				color: $bc2;
				border-top: 0 none;
				display: none;

				a {
					background-color: transparent;
					color: $bc2;

					&:hover {
						color: $bc1;
					}
				}

				@include mq($tablet) {
					border-top: 1px solid rgba($bc2, .15);
					font-weight: normal;
					background-position: 3px 50%;
					background-size: 5px 6px;
				}
			}
			&.sub.current {
				display: block;
				@include bg('r_sideMenuArr-active.svg', 0 50%);
				background-size: 9px 10px;

				+ li {
					border-top: 0;

					@include mq($tablet) {
						border-top: 1px solid rgba($bc2, .15);
					}
				}

				@include mq($tablet) {
					background-size: 5px 6px;
				}
			}

			@include mq($tablet) {
				padding: 15px;
			}
		}

		//@include mq($tablet) {
		//	max-height: initial !important;
		//	padding-top: 0 !important;
		//}
	}

	.toggleArr {
		padding: 3px;
		position: absolute;
		z-index: 3;
		right: 0;
		top: 0;
		height: 44px;
		width: 44px;
		@extend %transition;

		justify-content: center;
		align-items: center;
		transform: rotate(90deg);
		cursor: pointer;
		display: none;

		svg {
			stroke: #fff;
			width: 6px;
			height: 10px;
			@extend %transition;
			backface-visibility: hidden;
		}
	}

	//@include mq($tablet) {
	//	padding-top: 0 !important;
	//}

	@include mq($tablet) {
		position: relative;
		z-index: 2;
		margin-bottom: 20px;
		top: 0;

		.toggleArr {
			display: flex;
		}

		ul {
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			width: 100%;
			background-color: #fff;
			overflow: hidden;
			transition: max-height 0.65s cubic-bezier(0, 1, 0, 1);

			li {

				&.current {
					position: absolute;
					z-index: 2;
					top: 0;
					left: 0;
					width: 100%;
				}
			}
		}

		&.expanded {

			.toggleArr {

				svg {
					transform: scale(-1);
				}
			}

			ul {
				// padding-top: 0 !important;
				max-height: 9999px !important;
				filter: $filter-shadow;
				transition: max-height 0.95s cubic-bezier(1, 0, 1, 0)
				// , padding-top 0.95s ease
			;

				li {
					display: block;

					&.current {
						// position: relative;
					}
				}
			}
		}
	}

}

.sideMenu {
	@include mq($tablet) {
		position: static;
		margin-bottom: 0;

		ul, ul li.current {
			position: static;
		}
	}
}

.userNotes, .inviteIco, .sendMessage, .addRefferral, .responsiveness, .tooltip {
	z-index: 3;

	svg {
		position: static;
	}

	&[data-tooltip] {
		&:before {
			content: attr(data-tooltip);
			position: absolute;
			z-index: 3;
			top: 30px;
			left: -15px;
			display: block;
			color: #fff;
			background-color: $bc2;
			border-radius: 5px;
			padding: 10px;
			text-align: left;
			width: 240px;
			font-size: 14px;
			opacity: 0;
			visibility: hidden;
			transform: translateY(10px);
			@extend %transition;
		}

		&:after {
			z-index: 3;
			content: '';
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-bottom-color: $bc2;
			position: absolute;
			top: 10px;
			right: 5px;

			opacity: 0;
			visibility: hidden;
			transform: translateY(10px);
			@extend %transition;
		}

		&.white {
			&:before {
				color: $bc2;
				background-color: #fff;
			}
			&:after {
				border-bottom-color: #fff !important;
			}
		}

		&.small {
			&:before {
				font-size: 11px;
				line-height: 11px;
				width: 140px;
			}
			&:after {
				border: 7px solid transparent;
			}
		}

		&:hover:before, &:hover:after {
			opacity: 1;
			visibility: visible;
			transform: translate(0);
		}

		@include mq(767) {
			&:before, &:after {
				display: none;
			}
		}
	}

	&.reverse-tooltip {
		&:before {
			top: -45px;
		}

		&:after {
			border-top-color: $bc2;
			border-bottom-color: transparent;
			top: -10px;
		}
	}

	&.no-arrow-tooltip {
		&:after {
			display: none;
		}
	}

	&:before, &:after {
		@include mq(1200) {
			display: none;
		}
	}

	&.open {
		&:before, &:after {
			display: none;
		}
	}

	&.responsiveness-profile-tooltip {
		&:before {
			top: 65px !important;
			left: 0 !important;
		}

		&:after {
			top: 45px !important;
			left: 10px !important;
		}

		@include mq(1200) {
			&:before, &:after {
				display: block;
			}
		}
	}

	&.responsiveness-search-tooltip {
		&:before {
			font-size: 75%;
			opacity: .9 !important;
			width: 170px !important;
			top: 30px !important;
			left: -30px !important;
		}

		&:after {
			top: 10px !important;
			left: 5px !important;
		}

		@include mq(1200) {
			&:before, &:after {
				display: block;
			}
		}
	}

	&.std-job-title-tooltip {
		&:before {
			width: 400px !important;
		}
		&:after {
			left: -2px !important;
		}

		@include mq(767) {
			display: none;
		}

		@include mq(1200) {
			&:before, &:after {
				display: block;
			}
		}
	}

	&.skill-knowledge-tooltip {
		&:before {
			width: 145px !important;
			left: auto !important;
			right: -15px !important;
		}
		&:after {
			left: -2px !important;
		}

		@include mq(767) {
			display: none;
		}

		@include mq(1200) {
			&:before, &:after {
				display: block;
			}
		}
	}

	&.cv-upload-tooltip {
		&:before {
			left: auto !important;
			right: -15px !important;
		}
		&:after {
			left: auto !important;
			right: -5px !important;
		}

		@include mq(767) {
			display: none;
		}
	}

	&.notification-tooltip {
		&:before {
			top: 50px !important;
			font-size: 12px;
		}
		&:after {
			top: 30px !important;
		}
	}
}

.inviteIco {
	svg {
		width: 26px;
		height: 19px;
	}
}

.sendMessage {
	svg {
		width: 23px;
		height: 21px;
	}
}

.addRefferral {
	svg {
		width: 21px;
		height: 21px;
	}
}

.inviteIco:before {
	left: auto;
	right: -15px;
}

.knolev {
	label:nth-of-type(2) {
		transition-delay: 0.1s;
	}

	label:nth-of-type(3) {
		transition-delay: 0.2s;
	}

	label:nth-of-type(4) {
		transition-delay: 0.3s;
	}

	label:nth-of-type(5) {
		transition-delay: 0.4s;
	}
}
.r_card-title {
	width: 100%;
}
.r_card-title,
.resumeSectionTitle {
	border-bottom: 4px dotted rgba($bc2, .15);
	display: flex;
	align-items: center;
	min-height: 52px;
	margin-bottom: 25px;
	user-select: none;
}

.resumeSectionTitle {
	.labelLike, .editIco {
		margin-right: 0;
		margin-left: auto;
	}

	.labelLike {
		cursor: pointer;
		margin-bottom: 0;

		.ico {
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background-color: rgba($bc2, .25);
			display: inline-block;
			vertical-align: middle;
			position: relative;
			z-index: 1;
		}

		.icon__linkedin {
			width: 11px;
			height: 10px;
		}

		svg {
			width: 25px;
			height: 25px;
			fill: #fff;
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.theTitle {
		font-weight: bold;
		font-size: 1rem;
		text-transform: uppercase;
	}

	.iconH {
		width: 35px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-right: 5px;
	}

	svg {
		fill: $bc1;
		@extend %transition;
	}

	.toggleArr {
		width: 52px;
		height: 52px;

		margin-right: -20px;
		margin-left: auto;
		cursor: pointer;

		transform: rotate(90deg);
		@extend %transition;
		display: none;
		justify-content: center;
		align-items: center;

		svg {
			width: 6px;
			height: 10px;
			stroke: rgba($bc2, .25);

		}

		&.av {
			display: flex;
		}
	}

	.qty {
		opacity: .25;
	}
}

.public_view {
	@include mq(767) {
		.resumeSectionTitle {
			.toggleArr {
				display: flex;
			}
		}

		.rowEssentials, .rowMoreDetails {
			aside, section {
				margin-bottom: 0;
			}
		}
		.rowMoreDetails aside {
			order: 1;

			.r_card:last-child {
				.resumeSectionTitle {
					border-bottom: 0;
				}
			}
		}
		.rowMoreDetails .r_card.extended:last-child .resumeSectionTitle {
			border-bottom: 4px dotted rgba($bc2, .15);
		}


		.r_card {

			&:not(.extended) {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 1px solid rgba($bc2, .15);

				.resumeSectionTitle {
					border-bottom: 0;
					margin-bottom: 0;
				}

				.r_card__cnt {
					position: relative;
					opacity: 0;
					overflow: hidden;
					max-height: 0;
					transition: max-height 0.65s cubic-bezier(0, 1, 0, 1), opacity 0s linear;
					z-index: 1;
				}

				&.profileCard {
					margin-bottom: 20px;

					.r_card__cnt {
						opacity: 1;
						max-height: none;
					}
				}
			}

			&.extended {

				&:last-child {
					margin-bottom: 20px;
				}

				.toggleArr {
					transform: rotate(90deg) scale(-1);
				}
			}
		}
	}
}

.r_card {
	margin-bottom: 20px;
	background-color: #fff;
	max-width: 100%;
	padding: 0 0 25px;

	&:last-child {
		margin-bottom: 0;
	}

	&__cnt {
		overflow: hidden;
		padding-left: 20px;
		padding-right: 20px;

		@include mq(767) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	&.nocnt {

		.resumeSectionTitle {
			margin-bottom: 0;
			border-bottom: 0;
		}
	}

	.resumeSectionTitle {
		margin-left: 20px;
		margin-right: 20px;
		width: calc(100% - 40px);

		@include mq(767) {
			margin-left: 30px;
			margin-right: 30px;
			width: calc(100% - 60px);
		}
	}
	.documentCardHold{
		justify-content: flex-start;
	}
	.outputResult {
		// display: none;
		&.documentCard{
			&:last-of-type{
				.profileCard__item{
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: none;
				}
			}
			.profileCard__item{
				margin-bottom: 20px;
				padding-bottom: 20px;
				padding-top: 0;
				border-bottom: 1px solid rgba(15, 35, 63, 0.15);
				.labelLike, span.value{
					margin-bottom: 10px;
				}
				.btn{
					&.triggerDownload{
						font-size: 12px;
						line-height: 12px;
						min-width: 0;
						padding: 6px 10px;
						min-height: auto;
						height: auto;
					}
				}
			}
		}
		@extend %acc2;
		background-color: #fff;
	}

	form.edit {
		// display: none;
		@extend %acc1;
		background-color: #fff;
	}

	.outputResult, form.edit {
		margin-left: -20px;
		margin-right: -20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.labelLike.finishedEditing {
		pointer-events: none;
	}

	.secondaryButton {
		font-size: .75rem;
	}

	&.editmode {
		form.edit {
			@extend %acc2;
		}

		.outputResult {
			@extend %acc1;
		}

		.editIco {
			svg {
				opacity: 0;
				visibility: hidden;
				transform: translate(15px);
			}

			.labelLike {
				opacity: 1;
				visibility: visible;
			}

			.labelLike.finishedEditing {
				pointer-events: all;
			}
		}
	}


	&__search {
		width: 100%;
		height: auto;

		.r_card__cnt {
			padding-top: 25px;
		}

		form {
			width: 100%;

			.row {
				display: flex;
				justify-content: space-between;

				@include mq(767) {
					flex-direction: column;

					.cell {
						width: 100%;
						margin-bottom: 10px;
					}
				}
			}

			.cell {
				margin-bottom: 0;
				width: calc(100% - 165px);

				&--btn {
					width: 145px;

					.btn {
						width: 100%;
					}
				}
			}
		}

		input[type='text'], input[type='search'] {
			background-color: rgba($bc2, .05);
			border: 0;
		}
	}
}

.r_cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.downloadResume {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	top: -35px;
	right: 0;

	.ico-dl {
		margin-right: 8px;

		svg {
			width: 13px;
			height: 11px;
			fill: rgba($bc2, .25);
			@extend %transition;
		}
	}

	a {
		color: rgba($bc2, .25);
		font-size: 12px;
		font-weight: bold;

		&:hover {
			color: $bc1;

			.ico-dl svg {
				fill: $bc1;
			}
		}
	}

	@include mq($tablet) {
		right: 15px;
	}

	@include mq(767) {
		display: none;
	}
}
.loaderMessage{
	opacity:0;
	visibility: hidden;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	text-align: center;
	width: 60%;
	margin: 0 auto;
	font-size: 13px;
	line-height: 15px;
	color: rgba(15, 35, 63, 0.5);
	&.displayMessage{
		opacity: 1;
		visibility: visible;
	}
	@media screen and (max-width: 990px){
		top: 100%;
		width:100%;
		transform: translateY(0%);
	}
}
.r_resume {
	&__submit-action {
		padding: 20px;
		text-align: center;

		@include mqq($tablet) {
			padding: 10px;

			&.mobile {
				display: none;
			}
		}
	}

	section {
		position: relative;
		z-index: 1;
	}

	.row:last-child {
		border-bottom: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}

	.r_card {
		@extend %transition;

		&.r_card__about + .r_card.extended {
			@include mq(767) {
				padding-bottom: 15px;
			}
		}
	}

	.availabilitySection {

		@include mq(767) {
			padding-bottom: 5px;
		}

		.outputResult {
			padding-left: 75px;

			@include mq(767) {
				padding: 0;
				margin: 0;
			}

			.cell {
				padding: 15px 0;

				.form__group {
					margin-bottom: 0;
				}

				@include mq(767) {
					margin-bottom: 15px;
					padding-bottom: 15px;
					border-bottom: 1px solid rgba($bc2, .15);

					&:first-child {
						padding-top: 0;
					}

					&:last-child {
						margin-bottom: 0;
						padding-bottom: 0;
						border-bottom: 0 none;
					}
				}
			}
		}

		.edit {
			.row {
				margin-bottom: 20px;
			}
		}
	}

	.experienceSection .previousJob {
		display: flex;
		justify-content: space-around;
		align-items: flex-start;
		column-gap:15px;

		&__dates {
			padding-top: 2px;
		}

		&__dates, &__tagLike {
			white-space: nowrap;
			min-width: 115px;
			color: rgba($bc2, .5);
			font-size: 0.8125rem;
			line-height: 1.2rem;
		}

		&__title {
			margin-bottom: 15px;
			font-size: 1.125rem;
			line-height: 1.2rem;
		}

		&__position {
			font-weight: bold;
		}

		&__tagLike {
			padding-right: 15px;

			span {
				width: 100%;
				display: inline-block;
				padding-right: 15px;
				color: #fff;
				background-color: $bc1;
				border-radius: 0 10px 10px 0;
				position: relative;
				z-index: 1;

				&:before {
					content: '';
					position: absolute;
					right: 100%;
					top: 0;
					bottom: 0;
					background-color: $bc1;
					width: 30px;

				}
			}
		}

		@include mq(767) {
			flex-direction: column;

			&__dates, &__tagLike, &__desc {
				min-width: 100%;
				max-width: 100%;
			}

			&__dates, &__tagLike {
				margin-bottom: 15px;
			}
			&__tagLike {
				margin-bottom: 25px;
			}

			&__tagLike span {
				width: auto;
			}
		}
	}

	.educationSection .outputResult {
		padding-top: 10px;

		.educationRow {
			padding-bottom: 35px;
			margin-bottom: 35px;

			&:last-child {
				margin-bottom: 0;
				padding-bottom: 10px;
			}

			&__dates {
				white-space: nowrap;
				min-width: 115px;
				max-width: 115px;
				color: rgba($bc2, .5);
				font-size: 0.8125rem;
				line-height: 1.2rem;
				padding-top: 2px;
			}

			&__desc {
				min-width: calc(100% - 115px);
				font-size: 1.125rem;
				line-height: 1.2rem;
			}

			&__degree {
				font-weight: bold;
			}

			&__title {
				margin-bottom: 10px;
				font-size: 1.125rem;
				line-height: 1.2rem;
			}

			@include mq(767) {
				flex-direction: column;

				&__dates, &__desc {
					min-width: 100%;
					max-width: 100%;
				}
			}
		}

		@include mq(767) {
			padding-top: 0;

			.educationRow {
				padding-bottom: 25px;
				margin-bottom: 25px;

				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}

	.testedSkillSection {

		.outputResult {
			padding-top: 10px;
		}

		.testedSkillRow {
			padding-bottom: 35px;
			margin-bottom: 35px;

			&:last-child {
				margin-bottom: 10px;
				padding-bottom: 10px;
			}

			&__progressHolder {
				min-width: 115px;
				width: 115px;

				input {
					opacity: 0;
				}

				canvas + input {
					opacity: 1;
					padding: 0;
					pointer-events: none;
					font-size: 15px !important;
					font-weight: bold !important;
				}
			}

			&__desc {
				min-width: calc(100% - 115px);
				max-width: calc(100% - 115px);
				width: calc(100% - 115px);

			}

			&__title {
				margin-bottom: 10px;
				font-size: 1.125rem;
				line-height: 1.2rem;
				font-weight: bold;
			}

			.theProgress {
				padding-left: 15px;
			}

			@include mq(767) {
				flex-direction: column;

				&__progressHolder, &__desc {
					min-width: 100%;
					max-width: 100%;
				}
				&__progressHolder {
					text-align: center;
				}

				.theProgress {
					padding-left: 0;
					margin: 0 auto 10px;
					display: inline-block;
				}
			}
		}

		@include mq(767) {
			.outputResult {
				padding-top: 15px;
			}
		}
	}

	.skillsSection .outputResult {
		.skillsSectionRow {
			.cell.split {
				align-items: stretch;
				margin-bottom: 25px;

				@include mq($tablet) {
					align-items: baseline;
					justify-content: flex-start;
					margin-bottom: 10px;
				}

				.cell {
					display: flex;
					align-items: center;
				}
			}

			.knolev {
				height: auto;
			}

			&__title {
				color: $bc2, .5;
				font-size: 1.125rem;
				font-weight: bold;
				margin-bottom: 20px;
			}

			label {
				margin-bottom: 0;
			}

			&__knowledge {
				pointer-events: none;
				cursor: default;
			}

			&__skill-name {
				@extend %transition;
			}

			&__obtained {
				opacity: 0;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				@extend %transition;
				white-space: nowrap;

				@include mq($tablet) {
					position: relative;
					top: 0;
					transform: translate(0, 0);
					opacity: .5;
					font-style: italic;
				}
			}

			&__resultElem {
				position: relative;
				z-index: 1;
				cursor: pointer;
				padding-right: 20px;
				user-select: none;

				span {
					display: block;
					line-height: 1;

					@include mq($tablet) {
						line-height: 1.25;
					}
				}

				.dropHandler {
					width: 9px;
					height: 16px;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 2;
					transform: rotate(90deg);
					display: none;
					@extend %transition;

					svg {
						width: 9px;
						height: 16px;
						display: block;
						stroke: rgba($bc2, .2);
					}
				}

				@include mq($tablet) {
					padding-right: 0;

					&:hover .skillsSectionRow__skill-name {
							opacity: 0;
						}

					&:hover .skillsSectionRow__obtained {
							opacity: .5;
						}

				}

				&:hover {
					.skillsSectionRow__obtained {
						max-height: 100px;
						opacity: 0.5;
						line-height: 1.25;
						transform: none;
						top: 100%;
					}
				}
			}

			@include mq($tablet) {


				.expanded {
					.skillsSectionRow__obtained {
						max-height: 100px;
						opacity: 0.5;
						line-height: 1.25;
					}

					.skillsSectionRow__skill-name {
						padding-bottom: 5px;
					}

					.dropHandler {
						transform: rotate(90deg) scale(-1);
					}
				}

				//.dropHandler {
				//	display: block;
				//}
				.cell.narrower {
					width: 108px;
				}
				.cell.wider {
					width: calc(100% - 125px);
				}
			}
		}
	}

	.languagesSection {
		.outputResult {
			.languageSectionRow {
				display: flex;
				align-items: center;

				&__abbr {
					width: 115px;
					min-width: 115px;
					text-align: center;
					padding-bottom: 5px;

					font-size: 2.125rem;
					color: rgba($bc2, .15);
					font-weight: bold;
					line-height: 1;

					@include mq(767) {
						text-align: left;
						width: 110px;
						min-width: 110px;
					}
				}

				&__desc {
					width: calc(100% - 115px);
				}

				.proficiency {
					font-size: 0.8125rem;
					color: rgba($bc2, .5);
				}
			}
		}
	}

	.refferralsSlider {
		.sliderHolder {
			width: 100%;
		}

		.author {
			padding: 40px 30px 3px;
			text-align: center;
			font-size: 0.8125rem;
		}

		.slide {
			display: none;
			user-select: none;
		}

		.slide.cycle-slide {
			display: block;
			z-index: 1;
			height: auto;
		}

		.cycle-prev, .cycle-next {
			height: 21px;
			width: 12px;
			display: block;
			cursor: pointer;
			position: absolute;
			bottom: 0;
			z-index: 101;

			svg {
				height: 20px;
				width: 12px;
				stroke: rgba($bc2, .2);
			}
		}

		p {
			font-size: 14px;
		}

		.cycle-prev {
			left: 0;
		}

		.cycle-next {
			right: 0;
		}
	}

	.r_card_badges {
		z-index: unset;

		.r_card__cnt {
			align-items: flex-start;
			justify-content: flex-start;
		}

		.userBadges {
			ul {
				max-height: 100%;
				overflow: hidden;
			}

			li:hover {
				.userBadges__description {
					opacity: 1;
					visibility: visible;
					transform: translateY(25px);

					@include mq($tablet) {
						right: 15px !important;
						left: 15px;
					}
				}
			}

			&__description {
				position: absolute;
				z-index: 15;
				top: 100%;
				left: -12px;
				width: 420px;

				background-color: #fff;
				filter: drop-shadow(0 0 6px rgba($bc2, .4));
				border-bottom: 7px solid $bc1;
				padding: 15px 15px 25px;

				display: flex;
				justify-content: space-between;
				align-items: center;
				transform: translateY(45px);
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.5s ease, transform 0.5s ease;

				&:before {
					content: '';
					width: 0;
					height: 0;
					border: 20px solid transparent;
					border-bottom-color: #fff;
					position: absolute;
					bottom: 100%;
					left: 43px;
				}

				.iconHolder {
					img {
						width: 145px;
					}
				}

				.desc {
					width: calc(100% - 170px);
					font-size: 16px;
				}

				.title {
					display: block;
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 20px;
				}

				@include mq($tablet) {
					width: auto;
					max-width: calc(100% - 30px);

					.iconHolder {
						width: 90px;
					}
					.desc {
						width: calc(100% - 110px);
					}
				}
			}
		}
	}
}

.r_profile {

	.rowEssentials, aside {
		z-index: 2;
	}

	.rowMoreDetails, section {
		z-index: 1;
	}

	aside, section, .rowCards {
		position: relative;
	}

	.r_card {
		height: 300px;
		width: 310px;
		flex-grow: initial;
		padding-bottom: 20px;
		position: relative;
		z-index: 1;
		&.autoH{
			height: auto;
		}
		&__cnt {
			transition: max-height 0.65s cubic-bezier(0, 1, 0, 1), min-height 0.65s cubic-bezier(0, 1, 0, 1), opacity 0s linear;
			background-color: #ffffff;
		}

		&.isEmpty {
			.emptyBlock {
				display: flex;
			}

			.resumeSectionTitle {
				.qty, .toggleArr {
					display: none;
				}

				.newsCount {
					display: none !important;
				}

				svg {
					fill: #bec7cf;
				}
			}
		}

		&.extended {
			z-index: 2;
		}

		@include mq(767) {
			width: 100%;
		}

		&.r_profileCover {
			width: 100%;
			height: 200px;
			padding-bottom: 0;

			label.icon-camera {
				width: 24px;
				height: 24px;
				display: block;
				margin: 0 auto;
				padding: 0;
				position: absolute;
				z-index: 2;
				bottom: 30px;
				left: 50%;
				transform: translate(-50%, 0);
				opacity: 0;
				visibility: hidden;
				@extend %transition;

				filter: drop-shadow(0 0 3px rgba($bc2, .3));

				svg {
					display: block;
					width: 24px;
					height: 24px;
					fill: #fff;
				}
			}

			input[type='file'] {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: -1;
				opacity: 0;
				visibility: hidden;
			}

			&:hover {
				label.icon-camera {
					opacity: 1;
					visibility: visible;
				}
			}

			.cropperCnt2 {
				position: absolute;
				z-index: 2;
				background-color: #fff;
				text-align: center;
				filter: $filter-shadow;
				padding: 0 0 20px;
				width: 300px;
				@extend %transition;
				opacity: 0;
				visibility: hidden;


				@include mq($tablet) {
					width: 250px;
				}

				&.expanded {
					opacity: 1;
					visibility: visible;
					transform: translate(-50%, 0);
				}

			}
		}

		&__search {
			width: 100%;
			height: auto;
		}
	}

	aside {
		position: sticky;
		top: 15px;

		@include mq($tablet) {
			position: relative;
			top: 0;
		}
	}

	section {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 0;
		z-index: unset;
	}

	.resumeSectionTitle {
		.toggleArr {
			display: flex;

			svg {
				@extend %transition;
			}
		}
	}

	.r_card.extended .resumeSectionTitle {
		.toggleArr svg {
			transform: scale(-1);
		}
	}

	@include mq($tablet) {
		section, aside, .r_card {
			width: 100%;
			min-width: 100%;
			max-width: 100%;
		}
		.profileCard {
			height: auto;
		}
		.rowEssentials, .rowMoreDetails, section {
			flex-direction: column;
		}
	}

	.rowEssentials, .rowMoreDetails {

		.r_card {
			&__cnt {
				min-height: calc(100% - 82px);

				&:first-child {
					min-height: 100%;
					padding-top: 20px;
				}
			}
		}

		@include mq($tablet) {
			margin-bottom: 20px;
		}
	}

	.r_card__news {
		width: 100%;
		min-height: 300px;
		position: relative;
		z-index: 3;

		.r_card__cnt {
			background-color: #ffffff;
			position: relative;
		}

		@include mq(999) {
			height: auto;
		}

		.r_card {
			position: relative;
			z-index: 1;
		}

		.resumeSectionTitle .qty {
			@extend %transition;
		}

		.dismiss {
			position: absolute;
			bottom: 15px;
			right: 20px;
			opacity: 0;
			visibility: hidden;

			@include mq($tablet) {
				right: 30px;
				bottom: 5px;
			}
		}

		&.extended {
			.resumeSectionTitle .qty {
				opacity: 0;
			}

			.r_card {
				&__cnt {
					display: block;
					min-height: calc(100% - 82px + 345px);

					@include mq($tablet) {
						padding-bottom: 45px;
					}
				}
			}

			.dismiss {
				opacity: 1;
				visibility: visible;
			}

			.newsSlider {
				display: none;
			}

			.newsList {
				display: block;

				&__item {
					opacity: 1;
				}
			}
		}

		.emptyBlock {
			flex-direction: row;

			.placeholderDesc, .placeholderIco {
				width: 50%;
			}

			.placeholderDesc {
				font-size: 1.375rem;
				padding: 0 40px;
				text-align: left;
			}

			@include mq(999) {
				flex-direction: column;

				.placeholderDesc {
					text-align: center;
				}
			}
		}

		&.isEmpty {
			.newsSlider, .newsList {
				display: none;
			}

			.newsCount {
				display: none;
			}
		}
	}

	.profileCard, .newsSlider {
		.avatar {
			width: 100px;
			height: 100px;
			position: relative;
			z-index: 2;
			@include bg('user-blank-avatar.svg');
			background-size: 100% 100%;
			background-color: $bc1;
			border-radius: 50%;

			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 120px;
				height: 120px;
				@include bg('r_dotted-avatar-bg-s.svg');
				background-size: 100%;
			}

			img {
				border-radius: 50%;
			}
		}
	}

	.profileCard {
		height: 620px;

		.r_card__cnt {
			min-height: calc(100% - 75px);
		}

		.userName {
			margin-bottom: 35px;
		}



		.userPoints {
			height: 48px;
			line-height: 48px;
			border-radius: 24px;
			background-color: rgb($bc2, .05);
			font-weight: bold;
			font-size: 17px;
			text-align: center;
			position: relative;
			z-index: 1;
			margin-bottom: 50px;
			width: 100%;

			.btn {
				width: 32px;
				height: 32px;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				z-index: 1;
				right: 8px;
				top: 8px;
				min-width: 1px;

				svg {
					width: 17px;
					height: 17px;
					fill: #fff;

				}
			}
		}

		.userRank {
			text-align: center;

			span {
				display: block;
				font-size: 16px;
			}

			.theRank {
				font-size: 22px;
			}
		}

		.userSocial {
			position: absolute;
			z-index: 1;
			left: 25px;
			bottom: 0;
		}

		@include mq($tablet) {
			height: auto;

			.userName {
				margin-bottom: 15px;
			}
			.userPoints {
				margin-bottom: 15px;
			}
			.r_card__cnt {
				padding-bottom: 30px;
			}
		}
	}

	.newsSlider {
		width: 100%;
		height: 100%;

		.slide {
			width: 100%;
			align-items: center;
			justify-content: space-between;

			@include mqq(768) {
				display: flex !important;
				height: 100%;
			}
		}

		.slideDesc, .slideVisual, .slideNav, .slideLinks, .slideNotifElapsed {
			width: 46%;
		}

		.slideDesc {
			align-self: flex-start;

			.title {
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 25px;
				display: block;
			}
		}

		.slideVisual {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 48px;
			max-height: calc(100% - 50px);

			.badgeH {
				max-height: 100%;

				img {
					display: block;
					max-width: 140px;
					max-height: 140px;
				}
			}

			.number {
				color: $bc1;
				font-size: 7.3125rem;
				font-weight: bold;
				text-align: center;
				line-height: 1;

				small {
					display: block;
					font-size: 2rem;
					letter-spacing: -0.05em;
				}
			}

			.lvlup {
				color: $bc1;
				font-size: 5rem;
				font-weight: bold;
				text-align: center;
				line-height: .8;
				transform: skew(-20deg);

				small {
					display: block;
					font-size: 2.85rem;
					letter-spacing: -0.05em;
				}
			}

			.iconH {
				svg {
					fill: $bc1;
					width: 100px;
					height: 100px;
				}
			}
		}

		.slideNotifElapsed {
			position: absolute;
			z-index: 1;
			left: 0;
			bottom: 0;
			min-height: 21px;
			text-align: center;
			font-size: 0.8125rem;
			color: rgba($bc2, .25);
			line-height: 21px;
		}

		.slideNav {
			position: absolute;
			bottom: 0;
			left: 0;
			//z-index: 3;
		}

		.slideLinks {
			position: absolute;
			z-index: 2;
			right: 0;
			bottom: 0;
			font-size: 0.8125rem;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 1;

			li {
				margin-left: 15px;
				padding-left: 15px;
				border-left: 1px solid rgba($bc2, .25);

				&:first-child {
					padding-left: 0;
					border-left: 0;
					margin-left: 0;
				}
			}

			a {
				color: rgba($bc2, .25);

				&:hover {
					color: $bc1;
				}
			}
		}

		@include mq(767) {
			padding-bottom: 31px;
			align-items: flex-start;
			height: auto !important;
			align-self: flex-start;
			min-height: 100%;

			.slide {
				position: static !important;
				flex-direction: column;

				&.cycle-slide {
					display: none !important;

					&.cycle-slide-active {
						display: flex !important;
					}
				}
			}
			.slideVisual {
				align-self: flex-start;
				order: -1;
				margin-bottom: 25px;

				.number {
					font-size: 73px;
				}

				.iconH {
					svg {
						width: 65px;
						height: 64px;
					}
				}
			}

			.slideDesc, .slideVisual, .slideNav, .slideLinks, .slideNotifElapsed {
				width: 100%;
			}
			.slideNotifElapsed {
				//bottom: auto;
				//top: 100%;
			}
			.slideLinks {
				justify-content: flex-start;
				padding: 10px 0 20px;
				position: static;
			}

			.avatar {
				width: 60px;
				height: 60px;

				&:before {
					display: none;
				}
			}
		}
	}

	.newsList {
		display: none;
		width: 100%;
		align-self: flex-start;
		position: relative;
		z-index: 1;
		height: calc(100% - 45px);
		overflow-y: auto;


		&__item {
			opacity: 0;
			@extend %transition;

			border-bottom: 1px solid rgba($bc2, .15);
			margin-bottom: 20px;
			padding-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__title {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		&__cnt {
			display: flex;
			justify-content: space-between;

			@include mq(767) {
				flex-direction: column;
			}
		}

		&__excerpt {
			font-size: 0.8125rem;
			color: rgba($bc2, .25);
		}

		&__links {
			font-size: 0.8125rem;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			line-height: 1;

			li {
				margin-left: 15px;
				padding-left: 15px;
				border-left: 1px solid rgba($bc2, .25);

				&:first-child {
					padding-left: 0;
					border-left: 0;
					margin-left: 0;
				}
			}

			a {
				color: rgba($bc2, .25);
				display: flex;
			}
		}
	}

	.r_card__matches {
		text-align: center;
		position: relative;
		z-index: 1;

		.sliderHolder {
			width: 100%;
			height: 198px !important;
		}

		.slide {
			width: 100%;
			height: 100%;

			img {
				position: absolute;
				z-index: 1;
				top: 40%;
				left: 50%;
				transform: translate(-50%, -50%);
				margin-top: -2px;
			}

			.match-date {
				position: absolute;
				text-align: center;
				z-index: 1;
				bottom: 0;
				left: 0;
				right: 0;

				font-size: 0.8125rem;
				color: $bc2;
				opacity: .25;
			}
		}

		.emptyBlock {
			svg {
				width: 93px;
				height: 75px;
			}
		}
	}

	.r_card_it-expands {
		.r_card__cnt {
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
		}

		&.extended {
			.r_card__cnt {
				min-height: calc(100% - 82px + 345px);
				//padding-right: 6px;

				.r_simple-list__cnt {
					max-height: 100%;
					overflow-x: hidden;
					overflow-y: auto;
					margin-right: -10px;
					width: calc(100% + 9px);
				}
			}

			.userBadges {
				ul {
					max-height: 100%;
					overflow-x: hidden;
					overflow-y: auto;
				}
			}

			.r_friends-list {

				.r_simple-list__cnt {
					overflow: hidden;
					margin-right: 0;
					width: 100%;
				}

				.searchFriend {
					display: block;
				}

				.r_pagination {
					display: flex;
				}

				.inviteLink {
					display: none;
				}
			}
		}
	}

	.r_card_badges {
		z-index: unset;

		.r_card__cnt {
			align-items: flex-start;
			justify-content: flex-start;
		}

		.userBadges {
			ul {
				max-height: 100%;
				overflow: hidden;
			}

			li {
				@include mq($tablet) {
					.userBadges__description {
						right: 15px !important;
						left: 15px;
					}
				}

				&:hover {
					.userBadges__description {
						opacity: 1;
						visibility: visible;
						transform: translateY(25px);

						//@include mq($tablet) {
						//	right: 15px !important;
						//	left: 15px;
						//}
					}
				}
			}

			&__description {
				position: absolute;
				z-index: 15;
				top: 100%;
				right: -12px;
				width: 420px;

				background-color: #fff;
				filter: drop-shadow(0 0 6px rgba($bc2, .4));
				border-bottom: 7px solid $bc1;
				padding: 15px 15px 25px;

				display: flex;
				justify-content: space-between;
				align-items: center;
				transform: translateY(45px);
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.5s ease, transform 0.5s ease;

				&:before {
					content: '';
					width: 0;
					height: 0;
					border: 20px solid transparent;
					border-bottom-color: #fff;
					position: absolute;
					bottom: 100%;
					right: 30px;
				}

				.iconHolder {
					img {
						width: 145px;
					}
				}

				.desc {
					width: calc(100% - 170px);
					font-size: 16px;
				}

				.title {
					display: block;
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 20px;
				}

				@include mq($tablet) {
					width: auto;
					max-width: calc(100% - 30px);

					.iconHolder {
						width: 90px;
					}
					.desc {
						width: calc(100% - 110px);
					}
				}
			}
		}
	}

	.r_simple-list {
		&__cnt {
			max-height: 100%;
			overflow: hidden;
			width: 100%;
		}

		&__item {
			border-top: 2px solid rgba($bc2, .15);
			padding: 25px 0;

			&:hover {
				z-index: 2;
			}

			&:first-child {
				border-top: 0;
				padding-top: 0;
			}
		}

		&__imgHolder, &__desc, &__details {
			display: inline-block;
			vertical-align: top;
		}

		&__imgHolder {
			width: 90px;
			text-align: left;
		}

		&__desc {
			width: calc(100% - 120px);
			font-size: 13px;
		}

		&__title {
			font-weight: bold;
			font-size: 1rem;
			display: block;
			margin-bottom: 5px;
		}

		&__status {
			font-size: 0.8125rem;
			font-weight: bold;
			color: rgba($bc2, .25);

			&.status-yes {
				color: $green;
			}

			&.status-no {
				color: $bc1;
			}
		}

		&__details {

			&:hover {
				.r_simple-list__drop {
					opacity: 1;
					visibility: visible;
					transform: translateY(20px);
				}
			}
		}

		&__trigger {
			display: block;
			color: rgba($bc2, .25);
			font-size: 18px;
			font-weight: bold;
			line-height: 5px;
			cursor: pointer;
			position: relative;
			z-index: 1;
		}

		&__drop {
			position: absolute;
			z-index: 3;
			right: 2px;
			top: 0;
			filter: drop-shadow(0 0 6px rgba($bc2, .4));
			padding: 20px 20px;
			background-color: #fff;
			min-width: 150px;

			opacity: 0;
			visibility: hidden;
			transform: translateY(30px);
			transition: opacity 0.25s ease, transform 0.25s ease;

			&:before {
				content: '';
				width: 0;
				height: 0;
				border: 10px solid transparent;
				border-bottom-color: #fff;
				position: absolute;
				bottom: 100%;
				right: 20px;
			}

			li {
				font-size: 14px;
				font-weight: bold;
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&.r_friends-list {

			.userPop {
				position: absolute;
				z-index: 31;
				bottom: 10px;
				left: 7px;
				right: 7px;
				padding: 10px;
				background-color: #fff;
				filter: drop-shadow(0 0 6px rgba($bc2, .25));

				opacity: 0;
				visibility: hidden;
				transform: translateY(-15px);
				@extend %transition;

				&.open {
					opacity: 1;
					visibility: visible;
					transform: translate(0);
				}

				&:before {
					content: '';
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-top-color: #fff;
					position: absolute;
					top: 100%;
					right: 30px;
				}

				&.userNotesCnt {
					&:before {
						right: auto;
						left: 30px;
					}
				}

				.previousMessages {
					margin-bottom: 35px;

					.item {
						padding: 10px 0;
						border-bottom: 1px solid rgba($bc2, .15);
					}

					.item:last-child {
						border-bottom: 0;
					}

					.date {
						font-size: 0.8125rem;
						color: rgba($bc2, .5);
					}
				}

				input[type='email'] {
					margin-bottom: 5px;
					padding-left: 5px;
					padding-right: 5px;
				}

				textarea, input[type='email'] {
					background-color: #fff;
					border-radius: 5px;
					border: 1px solid rgba($bc2, .15);

				}

				textarea {
					min-height: 50px;
					padding: 5px;
				}

				.noteFoot {
					display: flex;
					justify-content: space-between;
					font-size: 0.8125rem;
					align-items: center;
					padding-top: 10px;

					a {
						cursor: pointer;
						color: rgba($bc2, .5);
					}
				}

				.btn {
					height: 20px;
					line-height: 20px;
					text-transform: none;
					font-weight: normal;
					font-size: 0.8125rem;
					min-width: 10px;
					padding: 0 23px;
				}

			}

			.searchFriend, .r_pagination {
				display: none;
			}

			.searchFriend {
				margin-bottom: 20px;
			}

			.r_simple-list__item {
				padding-top: 0;
				border-top: 0;
				padding-bottom: 17px;
			}

			.r_simple-list__imgHolder {
				width: 75px;

				img {
					border-radius: 50%;
					display: block;
					width: 50px;
					height: 50px;
				}
			}

			.r_simple-list__desc {
				width: calc(100% - 85px);
			}

			.inviteLink {
				display: block;
				color: rgba($bc2, .25);
				position: absolute;
				right: 20px;
				bottom: 0;
				font-size: 13px;
				z-index: 11;
				cursor: pointer;
			}

			&.searchingFriends {
				.friendsSliderHolder {
					max-height: 100%;
					overflow-x: hidden;
					overflow-y: auto;
				}

				.friendsListsCount {
					display: none;
				}

				.newSlide {
					position: static !important;
					opacity: 1 !important;
					visibility: visible !important;
				}

				.r_simple-list__item {
					display: none;

					&.resulted {
						display: block;
					}
				}
			}
		}
	}

	.theProgress {
		position: relative;
		z-index: 1;

		canvas + input {
			margin-top: 20px !important;
			border-radius: 0;
			pointer-events: none;
		}

		> div {
			position: relative;
			z-index: 1;
			display: block !important;

			&:before {
				content: '%';
				font-size: 13px;
				font-weight: bold;
				position: absolute;
				top: 50%;
				left: 0;
				line-height: 1;
				margin-top: 5px;
				width: 100%;
				text-align: center;
			}
		}
	}

	.r_card__diplomas {
		.emptyBlock {
			svg {
				width: 71px;
				height: 71px;
			}
		}
	}

	.emptyBlock {
		display: none;
		align-items: center;
		justify-content: space-between;
		min-height: 158px;
		width: 100%;
		flex-direction: column;
		height: 100%;

		svg {
			fill: #bec7cf;
		}

		.placeholderDesc, .placeholderIco {
			width: 100%;
		}

		.placeholderIco {
			text-align: center;
		}

		.placeholderDesc {
			color: rgba($bc2, .25);
			font-size: 1.125rem;
			text-align: center;
		}

		svg.bell_svg {
			width: 96px;
			height: 114px;
		}

		svg.cart_svg {
			width: 90px;
		}

		svg.friends_svg {
			width: 130px;
		}

		@include mq(599) {
			flex-direction: column;

			.placeholderDesc, .placeholderIco {
				width: 100%;
				min-width: 100%;
				text-align: center;
			}
			.placeholderDesc {
				padding-right: 0;
				padding-top: 25px;
			}

			.placeholderIco {
				order: -1;
			}
		}
	}

	.r_friends-list {
		.r_simple-list__cnt {
			padding-bottom: 50px;
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			height: 100%;

		}

		.newSlide {
			width: 100%;
		}

		.friendsSliderHolder {
			height: 100%;
			align-self: stretch;
			z-index: 1;
		}
	}

	&.r_company-profile {
		.profileCard {
			min-height: 200px;
			height: auto;

			.avatar {
				width: 120px;
				height: 120px;
				min-height: 120px;
				box-shadow: 0 0 0 10px $graybg;
				background: transparent;
				overflow: hidden;

				img {
					max-width: none;
					width: 124px;
					margin-top: -2px;
					margin-left: -2px;
				}

				&:before {
					content: unset;
				}
			}

			.userName {
				&__theTitle {
					color: $bc2;
				}
			}
		}

		section {
			display: block;
		}

		.tab {
			background-color: transparent;

			&__cnt {
				background-color: #ffffff;
				margin-bottom: 20px;
				width: 100%;
			}
		}

		.row {
			padding-top: 5px;

			&:first-child {
				padding-top: 0;
			}

			&.add-row {
				@include mq(767) {
					margin-bottom: 0;
				}
			}
		}

		.r_card {
			&.full {
				width: 100%;
			}

			&.company-main-post {
				height: auto;
				min-height: calc(100% - 20px);

			}
		}

		.btn {
			padding: 0 40px;

			&.add {
				padding: 0;
			}
		}

		.r_popup {
			.btn {
				padding: 0;

				label {
					width: 100%;
				}
			}
		}

		.r_card__cnt.r_nlist {
			justify-content: flex-start;
		}

		.userPop {
			.btn {
				padding: 0 23px;
			}
		}

		.row.empty {
			margin-bottom: 0;
			padding-bottom: 0;
			min-height: 170px;

			+ .add-row {
				padding-left: 30px;
				padding-bottom: 20px;
				padding-top: 0;

				@include mq(599) {
					padding-left: 15px;
				}
			}

			@include mq(767) {
				margin-bottom: 15px;
				padding-left: 0;

				+ .add-row {
					justify-content: center;
					padding-left: 0;
				}
			}

		}

		.aboutSection {

			.introText {
				font-size: 1.125rem;
				opacity: .5;
				margin-bottom: 40px;
				margin-top: 0;

				+ .row ~ .row {
					margin-bottom: 5px;
				}

				~ .row {
					padding-top: 0;

					&:last-child {
						padding-bottom: 15px;
					}
				}
			}


			.cell__desc {
				width: calc(100% - 115px);
			}

			.cell__labelLike {
				width: 115px;
				padding-right: 5px;
				opacity: .5;

				@include mq(767) {
					&:empty {
						display: none;
					}
					&:empty + div {
						width: 100%;
					}
				}
			}


			.sepparator {
				margin-bottom: 40px;
				padding-bottom: 40px;

				~ .row {
					align-items: center;

					.cell__labelLike {
						font-size: 13px;
					}
				}
			}

			.r_card {
				min-height: 235px;
				height: auto;

				&:last-child {
					margin-bottom: 20px;
				}
			}

			.r_cards .r_card {
				min-height: 1px;
			}


		}

		.google_api_placeholder, .api_placeholder {
			text-align: center;
			padding-top: 20px;

			.resumeSectionTitle {
				margin-top: -20px;
			}

			.tab__body {
				background-color: $graybg;
				height: 185px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: bold;
			}
		}

		.jobSection, .eventSection, .betterQuestionsSection, .challengesSection {
			.r_card {
				height: auto;
			}
		}

		.jobSection.current.tallest {
			max-height: none;
		}

		.videoSection {

			.videoH {
				margin-bottom: 15px;
				@extend %transition;
			}

			.vidList {

				&__ul {
					font-size: 0;
				}

				li {
					width: 108px;
					margin-bottom: 15px;
					margin-right: 15px;
					display: inline-block;
				}
				li:nth-child(6n-1) {
					margin-right: 0;

					@include mq($tablet) {
						margin-right: 15px;
					}
				}

				img, a {
					display: block;
				}

				a {
					width: 100%;
					height: 0;
					padding-top: 56.25%;
					position: relative;
					z-index: 1;
					overflow: hidden;
					cursor: pointer;
					opacity: .25;

					&.current {
						opacity: 1;
					}

					&:hover {
						opacity: 1;
					}

					img {
						position: absolute;
						top: 50%;
						left: 0;
						z-index: 1;
						width: 100%;
						transform: translateY(-50%);
					}
				}
			}

			h4 {
				font-size: 1.125rem;
				font-weight: bold;
				margin-bottom: 8px;

				.errorId {
					font-weight: normal;
					font-size: .8rem;
					color: red;
				}
			}

			.videoStatistics {
				margin-bottom: 25px;
				font-size: 13px;
				color: rgba($bc2, .5);

				&__views:empty ~ span {
					opacity: 0;
				}

				&__time {
					&:before {
						content: ' • ';
						margin: 0 5px;
					}
				}
			}

			h4, .videoStatistics {
				transform: translateY(15px);
				opacity: 0;
				visibility: hidden;

				&.visible {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					transition: all .5s ease;
				}
			}

			.videoDetails {
				min-height: 70px;
			}
		}

		.betterQuestionsSection {

			.r_card {
				@extend %transition;
			}

			.toggleArr {
				position: absolute;
				z-index: 3;
				right: 25px;
				top: 20px;

				justify-content: center;
				align-items: center;
				transform: rotate(90deg);
				cursor: pointer;

				svg {
					@extend %transition;
					stroke: rgba($bc2, .2);
					width: 6px;
					height: 10px;
				}
			}

		}

		.r_question-item {
			display: block;
			padding-right: 60px;

			h4 {
				font-size: 1rem;
				margin: 0;
			}

			p:first-child {
				padding-top: 30px;
			}

			p:last-child {
				padding-bottom: 10px;
			}

			&__cnt {
				@extend %acc1;
			}

			&.extended {
				.r_question-item__cnt {
					@extend %acc2;
				}

				.toggleArr {
					svg {
						transform: scale(-1);
					}
				}
			}
		}

		.r_job-item {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			justify-content: space-between;

			&__title {
				width: 55%;
				padding-right: 20px;
			}

			&__desc {
				width: 45%;

				@include mq(767) {
					width: 100%;
				}
			}

			.theTitle {
				font-weight: bold;
				font-size: 1.125rem;
				margin-bottom: 5px;
			}

			&:only-child {
				flex-grow: 1;

				.r_job-item__desc {
					display: flex;
					align-items: center;
				}

			}

			.userPop {
				top: 230px;
				left: 20px;
				width: 50%;

				@include mq(767) {
					width: 60%;
					position: absolute;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
				}
			}

			.userPop:before {
				left: 0;
				right: auto;

				@include mq(767) {
					position: absolute;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		.r_challenge-item {
			&__answers {
				font-size: 3.5rem;
				color: $bc1;
				text-align: right;
				padding: 30px;
				line-height: 1;
				font-weight: bold;

				@include mq(767) {
					padding: 0 20px;
					font-size: 2rem;
					text-align: center;
				}

				small {
					display: block;
					color: $bc2;
					font-size: 1.1875rem;
					padding-top: 2px;
				}
			}

			&__title {
				width: calc(100% - 140px);
			}
		}

		.r_job-item, .r_event-item, .r_challenge-item {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			justify-content: space-between;

			&__title {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				@include mq(767) {
					max-width: 100%;
					align-items: center;
					padding: 0;
					margin-bottom: 15px;
					width: 100%;

				}
			}

			.theTitle {
				font-weight: bold;
				font-size: 1.125rem;
				margin-bottom: 5px;
			}

			.theDate {
				font-size: 13px;
				color: rgba($bc2, .5);
				margin-bottom: 15px;
			}

			.statusOk {
				color: $bc1;
				font-weight: bold;
			}

			~ .editIco {
				position: absolute;
				right: 20px;
				top: 16px;
			}

			.btn {
				padding: 0 30px;
				min-width: 1px;

				@include mq($tablet) {
					margin-top: auto;
					margin-bottom: 0;
				}
			}

			@include mq(767) {
				flex-direction: column;
				align-items: center;
			}

		}

		.testimonialsSection {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.r_card {

				p {
					overflow: auto;
				}

				&:last-child {
					margin-bottom: 20px;
				}

				&__cnt {
					justify-content: space-between;
				}

				@include mq($tablet) {
					max-width: calc(50% - 10px);
					min-width: 1px;
				}
				@include mq(579) {
					max-width: 100%;
					min-width: 100%;
					height: auto;

					&__cnt {
						align-items: stretch;
					}
				}
			}

			.blockquoteText {
				padding-top: 35px;
				padding-left: 0;
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				height: 100%;
			}

			.author {
				font-size: 13px;
			}
		}

		.r_event-item {

			&__thumb {
				width: 215px;
			}

			&__title {
				width: calc(100% - 215px);
				padding-left: 20px;

				@include mq(767) {
					margin-bottom: 0;
					padding-top: 15px;
					padding-left: 0;
					width: 100%;
					text-align: center;
				}
			}

			@include mq($tablet) {

				.btn {
					align-self: flex-end;
				}
			}
		}

		.processSection {

			.introText {
				font-size: 1.125rem;
				opacity: .5;
				padding-top: 25px;
			}

			.row {
				padding-left: 112px;
				margin-bottom: 50px;

				&:last-child {
					margin-bottom: 0;
				}

				@include mq(767) {
					margin-bottom: 20px;
					padding-left: 0;
				}
			}

			ul.processSteps {
				width: 100%;
				font-weight: bold;
				position: relative;
				z-index: 1;
				counter-reset: section;
				margin-bottom: 30px;

				&:before {
					content: '';
					position: absolute;
					left: -67px;
					width: 3px;
					@include bg('r_dot.svg', 0 0, repeat);
					background-size: 10px auto;
					top: 32px;
					bottom: 19px;
				}

				li {
					margin-bottom: 46px;
					font-size: 1.125rem;
					position: relative;
					z-index: 1;
					counter-increment: section;

					&:last-child {
						margin-bottom: 0;
					}

					&:before {
						content: counter(section);
						position: absolute;
						z-index: 1;

						width: 25px;
						height: 25px;
						line-height: 25px;

						background-color: $bc2;
						border-radius: 50%;

						left: -78px;
						top: 50%;
						transform: translateY(-50%);

						color: #fff;
						text-align: center;
						font-weight: bold;
						font-size: 0.89rem;
					}
				}

				@include mq(767) {
					margin-left: 40px;

					&:before {
						left: -30px;
						top: 7px;
					}

					li {
						margin-bottom: 25px;
					}
					li:before {
						width: 24px;
						height: 24px;
						font-size: 1rem;
						line-height: 24px;
						font-weight: normal;
						left: -40px;
					}
				}
			}
		}

		.processSection.edit .row {
			margin-bottom: 20px;
		}

		.processSection.edit .row.wide {
			padding-left: 20px;
		}

		.processSection.edit .processSteps {
			padding-left: 76px;
			margin-bottom: 20px;

			&:before {
				left: 19px;
			}

			li {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				min-height: 36px;

				.form__group {
					display: none;

					.btn {
						padding: 0 !important;
						width: 18px;
						position: absolute;
						z-index: 3;
						right: 8px;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				&.editMode {
					.form__group {
						display: block;
					}

					.result, .editIco {
						display: none;
					}
				}

				&.last {
					&:before {
						display: none;
					}

					.r_simple-list__cnt {
						max-height: 100%;
						overflow-x: hidden;
						overflow-y: auto;
						margin-right: -10px;
						width: calc(100% + 9px);
					}

					.form__group {
						display: block;
					}

					label[id*=error] {
						position: absolute;
						top: 100%;
						left: 0;
						padding-top: 4px;
					}
				}

				.addSubRow {
					position: absolute;
					left: -55px;
					top: 0;
					background-color: #f2f4f5;
					color: #f2f4f5;
					// pointer-events: none;

					&:hover {
						background-color: $bc2;
					}
				}

				input.valid, input:focus {
					~ .addSubRow {
						color: $bc2;
						// pointer-events: unset;
					}
					~ .addSubRow:hover {
						background-color: $bc2;
						color: #fff;
					}
				}
			}

			.editIco {
				margin-left: 10px;
			}

			.form__group {
				margin-bottom: 0;
				margin-left: -20px;
				font-weight: normal;
				width: calc(100% + 20px);
			}

			@include mq(767) {
				margin-left: 0;
				padding-left: 36px;

				&:before {
					left: 12px;
				}

				li {
					&:before {
						left: -36px;
					}
				}

				li.last.addSubRow {
					left: -36px;
					width: 24px;
					height: 24px;
					line-height: 22px;
					top: 6px;
				}

				.form__group {
					margin-left: 0;
					width: 100%;
				}
			}
		}

		@include mq(767) {
			.rowEssentials {
				padding-top: 60px;
			}
			.r_card.r_profileCover {
				height: auto;
				padding-bottom: 0;
			}
		}
	}
}


.r_profile, .candidate-gamification {

	.userLevel {
		width: 150px;
		margin: 0 auto 10px;
		position: relative;
		z-index: 1;

		@include mq(767) {
			width: 100px;
		}

		input.progress {
			opacity: 0;
		}

		.levelProgress {
			border-radius: 50%;
			position: relative;
			z-index: 1;

			canvas {
				position: relative;
				z-index: 1;
				pointer-events: none;

				@include mq(767) {
					width: 100px !important;
					height: 100px !important;
				}
			}
		}

		.levelIndicators {
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
			width: 150px;
			height: 150px;
			border-radius: 50%;
			cursor: pointer;

			@include mq(767) {
				width: 100px;
				height: 100px;
			}
		}

		.levelIndicators:hover {

			.theLevel {
				opacity: 0;
			}

			.toNextLevel {
				opacity: 1;
			}
		}

		.theLevel, .toNextLevel {
			position: absolute;
			width: 80px;
			text-align: center;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@extend %transition;

			span {
				display: block;
			}
		}

		.theLevel {
			opacity: 1;
			font-size: 14px;
			font-weight: bold;
			color: $bc2;
			line-height: 25px;

			&:hover {
				background-color: $bc1;
			}

			.no {
				font-size: 40px;

				@include mq(767) {
					font-size: 30px;
				}
			}
		}

		.toNextLevel {
			opacity: 0;
			font-size: 0.8125rem;
			color: $bc2;

			.no {
				color: $bc1;
				font-size: 38px;

				@include mq(767) {
					font-size: 24px;
				}
			}
		}

	}

	.lvlup {
		color: $bc1;
		font-weight: bold;
		text-align: center;
		line-height: .8;
		transform: skew(-20deg);

		small {
			display: block;
			letter-spacing: -0.05em;
		}
	}
}

.r_profile {
	.userLevel {
		margin-bottom: 25px;
	}
}

.r_profile, .r_resume .r_card_badges {
	.resumeSectionTitle {
		.toggleArr {
			display: flex;

			svg {
				@extend %transition;
			}
		}

		.iconH {
			width: 25px;
		}
	}
}

.r_card_it-expands {
	height: 300px;
	flex-grow: initial;
	padding-bottom: 20px;
	position: relative;
	z-index: 1;

	.r_card__cnt {
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		transition: max-height 0.65s cubic-bezier(0, 1, 0, 1), min-height 0.65s cubic-bezier(0, 1, 0, 1), opacity 0s linear;
		background-color: #ffffff;
	}

	&.extended {
		.r_card__cnt {
			min-height: calc(100% - 82px + 345px);
			//padding-right: 6px;
		}
	}
}

.r_card_badges {
	z-index: unset;

	.r_card__cnt {
		align-items: flex-start;
		justify-content: flex-start;
	}

	.userBadges {
		ul {
			max-height: 100%;
			overflow: hidden;
		}

		li {
			&:hover {
				.userBadges__description {
					opacity: 1;
					visibility: visible;
					transform: translateY(25px);

					@include mq(1299) {
						right: 15px !important;
						left: 15px;
					}
				}
			}
		}

		&__description {
			position: absolute;
			z-index: 15;
			top: 100%;
			right: -12px;
			width: 420px;

			background-color: #fff;
			filter: drop-shadow(0 0 6px rgba($bc2, .4));
			border-bottom: 7px solid $bc1;
			padding: 15px 15px 25px;

			display: flex;
			justify-content: space-between;
			align-items: center;
			transform: translateY(45px);
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.5s ease, transform 0.5s ease;

			&:before {
				content: '';
				width: 0;
				height: 0;
				border: 20px solid transparent;
				border-bottom-color: #fff;
				position: absolute;
				bottom: 100%;
				right: 30px;
			}

			.iconHolder {
				img {
					width: 145px;
				}
			}

			.desc {
				width: calc(100% - 170px);
				font-size: 16px;
			}

			.title {
				display: block;
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 20px;
			}

			@include mq(1299) {
				display: block;
				width: auto;
				max-width: calc(100% - 30px);

				.iconHolder {
					width: 100%;

					img {
						margin: 0 auto;
					}
				}
				.desc {
					width: 100%;
				}
			}
		}
	}
}

.r_pagination {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	align-items: center;
	padding-top: 25px;

	&__indicator {
		font-size: 13px;
		color: rgba($bc2, .25);
	}

	.prev, .next {
		background-color: transparent;
		cursor: pointer;
		@extend %transition;
		width: 6px;
		border-radius: 0;
		display: block;

		svg {
			width: 6px;
			height: 10px;
			stroke: rgba($bc2, .2);
			@extend %transition;
		}

		&.inactive {
			opacity: .25;
			pointer-events: none;
		}

		&:hover {
			svg {
				stroke: rgba($bc2, .5);
			}
		}
	}
}

.skills-array {
	&:disabled {
		display: none;
	}
}

.r_graduates-page {

	.resumeSectionTitle {
		margin-bottom: 0;
		border-bottom: 0;
		padding-right: 20px;
		padding-left: 20px;
	}

	section {
		.resumeSectionTitle {
			padding-right: 0px;
			padding-left: 0px;
		}
	}

	.filterHandler {

		svg {
			@extend %transition;
		}

		&.active {
			svg {
				transform: scaleY(-1);
			}
		}
	}

	.filterHolder {
		display: flex;
		max-height: 0;
		overflow: hidden;
		@extend %transition;
		padding: 0 20px;

		&.active {
			max-height: 400px;
		}

		select {
			padding-left: 9px;
		}

		&__cell {
			padding: 0 5px 18px;
			flex-grow: 1;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		@include mq($tablet) {
			flex-wrap: wrap;

			&.active {
				padding-bottom: 10px;
			}

			&__cell {
				padding: 5px 10px 5px 0;
				width: 33%;

				&:last-child {
					padding-right: 10px;
				}
			}
		}
		@include mq(767) {
			&__cell {
				width: 50%;

				&:nth-child(even) {
					padding-right: 0;
				}
			}
		}
	}

	.toggleArr {
		@extend %transition;

		justify-content: center;
		align-items: center;
		transform: rotate(90deg);
		cursor: pointer;
		display: block;
		width: 8px;
		height: 15px;

		margin-right: 15px;
		margin-left: 15px;

		svg {
			stroke: rgba($bc2, .25);
			width: 6px;
			height: 10px;
			@extend %transition;
			backface-visibility: hidden;
		}

		@include mq($tablet) {
			margin-left: 10px;
			margin-right: 0;
		}
	}

	.activeRow .toggleArr svg {
		transform: scale(-1);
	}

	.tab__body {
		font-size: 13px;
	}
}

.r_challenge-page {

	.tab__cnt {
		background-color: #fff;

		.row {
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
			padding-right: 20px;

			@include mq(767) {
				margin-left: -30px;
				margin-right: -30px;
				padding-left: 30px;
				padding-right: 30px;
			}

			&.add-row {
				justify-content: flex-end;

				.btn {
					margin-left: 20px;
				}

				@include mq(767) {
					justify-content: space-between;

					.btn {
						margin-left: 0;
					}
				}
			}
		}
	}

	.row.options {
		position: relative;
		z-index: 1;

		&:before {
			content: '';
			width: 20px;
			height: 20px;
			@include bg('colt-tl.svg');
			background-size: 20px 20px;
			position: absolute;
			z-index: 1;
			left: -1px;
			top: -21px;

			@include mq(999) {
				left: 0;
			}
		}

		.form__group.full {
			margin-bottom: 15px;
		}
	}

	.controls {
		justify-content: space-between;
		display: flex;

		.multiChoice {
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			align-items: center;

			.legend {
				margin-right: 12px;
				font-size: 13px;
				opacity: .5;
			}
		}
	}

	.challengesSection {
		z-index: 2;

		.r_cards {
			counter-reset: useranswerno;
		}
	}

	.r_profile .r_card {
		z-index: unset;
	}

	.user-answer {
		counter-increment: useranswerno;
		overflow: visible;

		.switch-holder {
			margin-left: auto;
			margin-right: 0;

			label {
				width: 100px;
			}

			input:checked ~ label:before {
				transform: translateX(78px);
			}
		}

		&__header {
			width: 100%;
			position: relative;
			padding-right: 20px;

			.lock_svg {
				width: 11px;
				height: 14px;
				fill: rgba($bc2, .25);
				position: absolute;
				z-index: 1;
				right: 0;
				top: 3px;
				opacity: 0;
				visibility: hidden;
				@extend %transition;
			}
		}

		&.submitted {
			.user-answer__header {
				.lock_svg {
					opacity: 1;
					visibility: visible;
				}
			}

			.user-answer__progressbar {
				opacity: .5;
				pointer-events: none;
			}

			.switch-holder {
				opacity: 0;
				visibility: hidden;
			}
		}

		&__header, &__points {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.user-name {
			font-weight: bold;

			&:before {
				content: '#' counter(useranswerno);
				opacity: .5;
				margin-right: 5px;
			}
		}

		&__body {
			padding: 20px 0 35px;
			width: 100%;
		}

		&__result {
			width: 100%;
		}

		&__options {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			@include mq(767) {
				flex-direction: column;
				align-items: flex-start;

				.user-answer__footer {
					margin-left: 0;
					padding: 10px 0;

					&:before {
						content: unset;
					}
				}

				.switch-holder {
					margin-right: auto;
					margin-left: 0;
				}
			}
		}

		&__points {
			font-size: 13px;

			.current {
				font-weight: bold;
				font-size: 18px;

				.points-no {
					color: $bc1;
				}
			}
		}

		&__progressbar {
			position: relative;
			z-index: 1;
			height: 3px;
			margin-bottom: 20px;

			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				width: 100%;
				height: 0;
				border-bottom: 3px dotted rgba($bc2, .15);
			}

			.theProgressBar {
				height: 3px;
				position: relative;
				z-index: 2;
				display: block;

			}
		}

		&__footer {
			font-size: 13px;
			position: relative;
			padding-left: 20px;
			margin-left: 20px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&:before {
				content: '';
				height: 20px;
				width: 1px;
				background-color: rgba($bc2, .5);
				display: inline-block;
				position: absolute;
				z-index: 1;
				left: 0;
				top: 50%;
				margin-top: -10px;
			}

			@include mq(767) {
				width: 100%;
			}

			.userPop {
				width: 300px;
				top: 30px;
			}

			.userNotes {
				height: auto;
				line-height: 20px;
				z-index: 1;
			}
		}

		&__controls {
			width: 100%;
			padding-top: 15px;
			text-align: right;
			position: relative;

			.userPop {
				min-width: 250px;
				left: auto;
				right: 0;
				top: 35px;

				&.userNotesCnt:before {
					left: auto;
					right: 30px;
				}
			}

		}
	}

	.inviteUser.userPop {
		right: -10px;
		top: 35px;
		width: 300px;
		left: auto;

		select {
			margin-bottom: 15px;
		}
	}

	.userPop {
		@include mq(599) {
			.btn {
				margin-bottom: 0;
			}
		}
	}

	@include mq($tablet) {
		aside.sideMenu {
			margin-bottom: 25px;
		}
	}
}

.r_company-profile-edit {

	button.btn, span.btn, a.btn, input.btn {
		font-size: 12px;
	}

	.row.add-row {
		padding-top: 20px;
		text-align: right;
		justify-content: flex-end;

		&.sb {
			justify-content: space-between;
		}
	}

	.tab__cnt, .row, .added-row {
		+ .row.add-row {
			padding-top: 0;
		}
	}

	.updates-settings {

		&__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px;
			border-bottom: 1px solid rgba($bc2, .15);
			margin: 0 -20px;
		}


	}

	.r_nlist {
		.form__group {
			margin-bottom: 0;
		}

		li {
			margin-bottom: 20px;

			&:before {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.posted-job {
		&__list {
			background-color: #ffffff;
		}

		&__item {
			padding: 24px 50px 16px 20px;
			position: relative;
			z-index: 1;
			border-bottom: 1px solid rgba($bc2, .15);

			&:last-child {
				border-bottom: 0;
			}

			.editIco {
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}

			.viewIco {
				position: absolute;
				right: 50px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&__title {
			font-size: 1.125rem;
			font-weight: bold;
			margin-bottom: 5px;
		}

		&__elapsed {
			font-size: 13px;
			opacity: .5;
		}
	}

	.videoSection {
		.row {
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 0;
		}
	}

	.betterQuestionsSection, .testimonialsSection {
		textarea {
			min-height: 80px;
		}
	}

	.row.wide {
		margin-left: -20px;
		margin-right: -20px;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 0;
	}

	.row.empty {
		display: none;

		&.show, &:nth-last-child(3) {
			display: flex;
		}
	}

	.jobSection {
		//.row > .cell:nth-child(2) .subgroup {
		//	opacity: 1;
		//	pointer-events: unset;
		//}

		.cell.editable .subgroup {
			//&:first-child:not(:last-child) { // lele
			//	.form__group {
			//		margin-bottom: 0;
			//	}
			//
			//	.widest .select2, .widest select, .narrowest {
			//		display: none;
			//	}
			//
			//	+ .subgroup .form__group {
			//		padding-top: 0;
			//	}
			//}

			//&:first-child:last-child {
			//
			//	.widest .select2, .widest select {
			//		opacity: 0;
			//		visibility: hidden;
			//	}
			//} // end lele
		}

		.cell.editable.previous .subgroup {
			.widest .select2, .widest select {
				opacity: 1 !important;
				visibility: visible !important;
			}

			.widest .select2, .widest select, .narrowest {
				display: block !important;
			}

			+ .subgroup .form__group {
				padding-top: 15px !important;
			}
		}

		.tab__body + .posted-job__list {
			margin: 0 -20px -20px;

			@include mq(767) {
				margin: 0 -30px -30px;
			}
		}
	}
}

.r_imm-landing {

	.r_intro-companies {
		margin-bottom: 0;

		h2 {
			margin-bottom: 2rem;
		}

		@include mq(767) {
			margin-bottom: 30px;
		}

		&__cnt {
			width: 425px;

			@include mq($tablet) {
				transform: translateY(-35px);
			}
		}
	}

	.bubbled-module .r_register-trial {
		&:before {
			left: 8px;
		}

		h3 {
			padding-right: 60px;
		}

		.btn {
			padding: 0 64px;
			height: 40px;
			line-height: 40px;

			@include mq(767) {
				height: 30px;
				line-height: 30px;
				padding: 0 30px;
			}
		}
	}

	.r_conversion {
		padding: 70px 0 85px;
		font-size: 1.25rem;

		@include mq(767) {
			font-size: 1rem;
			padding: 20px 0 50px;
		}

		h4 {
			margin-bottom: 20px;
		}

		.arrows li {
			background-position: 0 11px;

			@include mq(767) {
				background-position: 0 9px;
			}
		}

		&__benefits {
			padding-bottom: 125px;
			@include bg('r_imm-conversion.svg', 0 100%);
			margin-bottom: 50px;
			display: flex;
			justify-content: center;
			align-items: flex-start;

			.item {
				margin-left: 45px;
			}

			@include mq(767) {
				padding-bottom: 28%;
				margin-bottom: 30px;

				.item {
					width: 100%;
					margin-left: 0;
				}
			}
		}
	}

	.r_our-offer {
		margin-bottom: 60px;
		margin-top: 0;
		padding-top: 25px;

		&__cnt {
			&:after {
				left: auto;
				right: 100%;
			}
		}

		.edit_blue_svg {
			width: 63px;
			height: 63px;

			@include mq(767) {
				width: 30px;
				height: 30px;
			}
		}
	}
}

.r_search {
	aside {
		.innerA:not(.selectMandatory) {
			position: sticky;
			top: 20px;
		}

		@include mq($tablet) {
			padding-left: 15px;
		}

		h2 {
			font-size: 2.5rem;
			margin-bottom: 1.25rem;
			line-height: 1.05;

			@include mq(767) {
				text-align: center;
				font-size: 2rem;
			}
		}

		.btn {
			min-width: 210px;
			max-width: 100%;
		}

		@include mq(767) {
			padding-left: 0;

			.innerA {
				padding: 0 20px;
			}

			h2 {
				br {
					display: none;
				}

				.bc {
					display: inline;
				}
			}

			.btn {
				max-width: 300px;
				display: block;
				margin: 0 auto 20px;
			}
		}
	}

	.switchToMandatory, .legend {
		width: 210px;
		max-width: 100%;

		@include mq(767) {
			width: 360px;
			margin: 0 auto;
		}
	}

	.switchToMandatory {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		padding-top: 40px;

		@include mq(767) {
			margin-bottom: 5px;
		}

		.labelLike {
			margin-bottom: 0;
			padding-left: 0;
		}
	}

	.legend {
		display: block;
		opacity: .7;
		font-size: .85rem;

	}

	.row {
		padding-bottom: 0;

	}

	.row > .cell:nth-child(2) {

		.subgroup {
			opacity: .5;
			pointer-events: none;
		}

		&.editable .subgroup {
			opacity: 1;
			pointer-events: unset;
		}
	}

	.restricted {
		opacity: .5;
		pointer-events: none;
	}

	.row-control {
		//position: absolute;
		z-index: 1;
		bottom: 20px;
		left: 0;
		height: 36px;
		//line-height: 36px;
		color: rgba($bc2, .5);
		padding: 0 20px;
		font-weight: bold;
		font-size: 13px;
		cursor: pointer;

		@include mq($tablet) {
			position: static;
			margin-bottom: 20px;
			line-height: 1;
			height: auto;
		}
	}

	.form__group {
		background-color: #ffffff;

		label {
			line-height: 22px;
		}

		.switch-holder {
			position: absolute;
			z-index: 1;
			right: 0;
			top: 0;
			opacity: 0;
			visibility: hidden;
		}
	}

	.r_card {


		@include mq($tablet) {
			margin-bottom: 0;
			border-bottom: 1px solid rgba($bc2, .15);
		}
	}

	.skills-subgroup.beacon {
		margin-bottom: 15px;
		position: static;
	}

	.subgroup {
		.form__group {
			padding-top: 15px;
			margin-bottom: 5px;
		}

		&:first-child {
			.form__group {
				padding-top: 0;
			}

			~ .subgroup .form__group > label {
				display: none;

				@include mq($tablet) {
					display: block;
				}
			}
		}

		//&:nth-last-child(2) .form__group {
		//	margin-bottom: 0;
		//}
	}

	.selectMandatory {

		.row:not(:first-child) .pushSwitch {

			&.added {
				margin-top: 5px;
			}

			.switch-holder {
				top: -25px;

				@include mq($tablet) {
					top: 0;
				}
			}
		}

		.form__group {
			.switch-holder {
				opacity: 1;
				visibility: visible;

				&.restricted {
					opacity: .5;
				}
			}
		}

		.restricted .form__group .switch-holder.restricted {
			opacity: 1;
		}

		.subgroup {
			.form__group {
				padding-top: 40px;
				margin-bottom: 3px;

				.switch-holder {
					top: 15px;
				}

				@include mq($tablet) {
					padding-top: 0;

					.switch-holder {
						top: 0;
					}
				}
			}

			&:first-child .form__group {
				padding-top: 0;

				.switch-holder {
					top: 0;
				}
			}
		}
	}

	.row > .form__group:last-child {
		margin-bottom: 15px;
	}

	@include mq($tablet) {
		.hideLabels {
			.row:first-child ~ .row label:not(.alwaysShow) {
				display: none;
			}

			.row .cell .subgroup:first-child .cell label {
				display: block;
			}
		}
	}
}

.r_search_results {

	.r_card {
		margin-bottom: 0;
		border-bottom: 1px solid rgba($bc2, .15);

		&:last-of-type {
			margin-bottom: 20px;
			border-bottom: 0;
		}

		&.extended .resumeSectionTitle {
			border-bottom: 0;
		}
	}

	aside {
		.resumeSectionTitle {
			margin-left: 0;
			margin-right: 0;
			padding-left: 20px;
			padding-right: 20px;
			width: 100%;
		}

		.r_card.extended .resumeSectionTitle {
			box-shadow: 0 1px 1px rgba($bc2, .15);
		}

		.theTitle {
			color: $gray;
			@extend %transition;
		}

		.iconH {
			opacity: .5;
			@extend %transition;
		}

		.extended {
			.theTitle {
				color: $bc2;
			}

			.iconH {
				opacity: 1;
			}
		}
	}

	section {
		counter-reset: section;

		.r_card {
			counter-increment: section;

			.r_matchingScale {
				padding-left: 30px;
				position: relative;
				z-index: 1;

				&:before {
					content: counter(section);
					position: absolute;
					color: $bc2;
					left: 0;
					font-size: 0.8125rem;
				}
			}
		}
	}

	aside, section {
		@extend %transition;
	}

	.btn {
		min-width: 1px;
		padding: 0 38px;
	}

	&.refiningSearch {
		aside {
			width: calc(100% - 320px);
		}

		section {
			width: 300px;
		}

		.r_cadidateSpecs {

			ul {
				width: 100%
			}
		}

		.resultsFoot {
			flex-direction: column;

			.btn {
				order: 0;
				margin-top: 15px;
			}
		}

		.matchingPercentage {
			width: 52px;
		}

		label {
			display: block;
		}

		.select2-container {
			width: 100% !important;
		}
	}

	.r_cadidateSpecs {
		padding-left: 2px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;

		&:after {
			content: '';
			width: 28%;
		}

		ul {
			width: 28%;
			margin-bottom: 38px;
			padding-left: 30px;
			position: relative;
			font-size: 0.875rem;
		}

		li {
			margin-bottom: 5px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.bc {
			font-weight: bold;
		}

		.iconH {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
		}

		.theTitle {
			padding-bottom: 5px;
			display: block;
		}

		&.missingSpecs {
			border-top: 1px solid rgba($bc2, .15);
			padding-top: 10px;

			.theTitle.main {
				margin-bottom: 30px;
				color: $gray;
				display: block;
				width: 100%;
			}

			ul {
				color: $gray;
			}

			.iconH svg {
				fill: $gray;
			}
		}
	}

	.resultsFoot {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $gray;

		.btn {
			order: -1;
		}

		@include mq(767) {
			flex-direction: column;

			.btn {
				order: 0;
				margin-top: 15px;
			}
		}
	}

	.r_candidateOptions {
		padding-bottom: 20px;
		display: flex;
		justify-content: flex-end;

		.btn {
			margin-left: 20px;

			&:first-child {
				margin-left: 20px;
			}
		}
	}
}

.r_search, .r_search_results {
	@include mq(767) {
		.r_card.itExtends.extended {
			padding-bottom: 5px;
		}
	}
}

.r_matchingScale {

	.progressHolder {
		margin-bottom: 0;
		flex-grow: 1;
		padding-left: 30px;
	}

	.matchingPercentage {
		width: 92px;
		padding-left: 17px;
		text-align: center;
		font-weight: bold;
		@extend %transition;
	}
}

.r_job-page {

	.profileCard {
		.userName {
			margin-bottom: 25px;
		}
	}

	.r_cards {
		align-items: stretch;

		.r_card {
			min-height: 225px;

			&:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.r_card {
		height: auto;
	}

	.row {
		width: 100%;
	}

	aside {
		.row {
			margin-bottom: 12px;
			padding-bottom: 16px;

			&:last-child {
				margin-bottom: 0;
				border-bottom: 0 none;
				padding-bottom: 0;
			}
		}

		li {
			margin-bottom: 3px;

			&:first-child {
				margin-bottom: 9px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.rowEssentials {
		aside, .section {
			margin-bottom: 0;

			.r_card {
				margin-bottom: 20px;
			}
		}
	}
}

.r_profile.r_company-profile .r_job-page {
	.btn {
		padding: 0 30px;
	}

	.processSection .row {
		padding-left: 68px;
	}

	ul.processSteps li:before {
		left: -63px;
	}

	ul.processSteps:before {
		left: -52px;
	}
}

.r_company-profile.r_search {
	.row-control.addRow, .row-control.autoFill {
		height: auto;
	}

	.row-control.autoFill {
		width: 100%;
		margin-top: 16px;
		padding-right: 0;
	}

	.r_cards {
		align-items: stretch;

		.r_card {
			min-height: 225px;

			&:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.processSection.edit .row {
		margin-bottom: 0;
	}

	.pb0 {
		padding-bottom: 0;
	}

	.salary_optionsSection {
		.form__group {
			margin-bottom: 0;
		}
	}
	.salary-type > div {
		flex-grow: 1;
	}

	.rangeSel {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@include mq($tablet) {
			padding-top: 15px;
		}

		.spacer {
			width: 20px;
			text-align: center;
		}
	}
}

.r_profile.r_company-profile .processSection.edit ul.processSteps li:before {
	left: -68px;

	@include mq(767) {
		left: -36px;
	}
}

.r_pageErrorContainer {
	text-align: center;
	min-height: 500px;
	width: 100%;

	.icoHolder {
		display: flex;
		justify-content: center;
		margin-bottom: 40px;
	}

	.ico404_svg {
		width: 155px;
		height: 209px;
	}

	h3 {
		.bc {
			display: inline;
		}
	}

	a {
		font-weight: bold;
	}
}

.r_upgrades {


	@include mq($tablet) {
		.r_main_container {
			padding-left: 20px;
			padding-right: 20px;
			flex-direction: column;
		}

		h2 {
			br {
				display: none;
			}

			.bc {
				display: inline;
			}
		}
	}

	aside, section {
		width: calc(50% - 20px);

		@include mq($tablet) {
			width: 100%;
			margin-bottom: 0;
		}
	}

	.r_upgrade-option {

		&__item {
			background-color: #ffffff;
			display: flex;
			justify-content: space-between;
			min-height: 115px;
			margin-bottom: 30px;

			@include mq(767) {
				min-height: 80px;
			}
		}

		&__title {
			width: calc(100% - 160px);
			padding-left: 35px;
			font-size: 1.5rem;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			@include mq(767) {
				font-size: 1.25rem;
				font-weight: normal;
				width: calc(100% - 120px);
				padding-left: 20px;
			}
		}

		.qty {
			margin-right: 4px;
			display: inline-block;
			width: 28px;
			text-align: right;
		}

		&__price {
			border-left: 2px solid rgba($bc2, .05);
			width: 160px;
			font-size: 2.3125rem;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: center;

			@include mq(767) {
				font-size: 1.5rem;
				width: 120px;
			}
		}

		&__selector {
			margin-bottom: 40px;
		}

		.theProgressBar {
			height: 3px;
			background-color: rgba($bc2, .15);
		}
	}

	.r_upgrade-summary {
		&__row {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 10px;
		}

		&__cell {
			max-width: 47%;

			&.wBtn {
				order: -1;
				opacity: 0;
				visibility: hidden;
				@extend %transition;

				&.enable {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.price, .bc {
			font-weight: bold;
		}

		.rowTotal {
			border-top: 1px solid rgba($bc2, .15);
			margin-top: 25px;
			padding-top: 30px;
			align-items: center;

			@include mq(767) {
				padding-top: 15px;
				margin-top: 15px;
			}
		}

		.totalPrice, .totalPrice + .bc {
			font-size: 1.5rem;

			@include mq(767) {
				font-size: 1.25rem;
			}
		}
	}
}

.r_friends {

	input[type='text'], input[type='email'], input[type='password'], input[type='url'], input[type='tel'], input[type='search'], textarea, select {
		background-color: rgba($bc2,.05);
		border: 0;
	}

	.rowEssentials {
		align-items: flex-start;

		@include mq(767) {
			padding-top: 20px;
		}
	}

	.profileCard {
		height: auto;

		h3 {
			font-size: 1.375rem;
			width: 100%;
			height: auto;

			@include mq($tablet) {
				text-align: center;
			}
		}

		.userSocial {
			position: static;

			a {
				margin-right: 0;
			}
		}
		.r_card__cnt {
			@include mq($tablet) {
				padding-bottom: 0;
			}
		}
	}

	&__avatar {
		margin-bottom: 44px;

		img {
			width: 142px;
		}
	}

	.referForm {
		padding-bottom: 10px;


		textarea {
			min-height: 38px;
			padding: 8px 20px;
		}

		.form__group {
			margin-bottom: 10px;
		}

		.btn {
			width: 100%;
			height: 38px;
			line-height: 38px;
		}
	}

	&__social {
		text-align: right;
		padding-top: 20px;
		border-top: 1px solid rgba($bc2,.15);
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.labelLike {
			font-size: 0.875rem;
			color: rgba($bc2,.5);
		}

		ul {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			li {
				margin-left: 20px;
			}
		}

		svg {
			fill: rgba($bc2,.15);
		}
	}

	.r_card {
		width: 100%;
		height: auto;
	}

	.search-friend {
		width: 100%;

		.row {
			display: flex;
			justify-content: space-between;

			@include mq(767) {
				flex-direction: column;

				.cell {
					width: 100%;
					margin-bottom: 10px;
				}
			}
		}

		.cell {
			margin-bottom: 0;
			width: calc(100% - 165px);

			&--btn {
				width: 145px;

				.btn {
					width: 100%;
				}
			}
		}
	}

	.toggleSearch {
		width: 17px;
		height: 17px;
		margin-left: auto;
		margin-right: 0;

		svg {
			width: 17px;
			height: 17px;
			fill: rgba($bc2,.15);
		}
	}

	.open .toggleSearch,
	.toggleSearch:hover {
		svg {
			fill: $bc1;
		}
	}

	.searchDrop {
		position: absolute;
		z-index: 2;
		top: 5px;
		right: 0;
		height: 42px;
		padding: 0 20px;
		transform: translate(-30px,0);
		opacity: 0;
		visibility: hidden;
		transition: $transition;
		background-color: #ffffff;

		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 42px;
		}

		.form__group {
			margin: 0;
		}

		input {
			height: 30px;
			max-width: 205px;
		}

		@include mq(767) {
			width: 100%;

			.form__group.cell {
				width: 100%;
			}

			input {
				max-width: 100%;
			}
		}
	}

	.open .searchDrop {
		opacity: 1;
		transform: translate(0,0);
		visibility: visible;
	}

	.r_main-friends-list, .r_referrals-list {
		width: 100%;

		&__row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			color: rgba($bc2,.5);
			border-bottom: 2px solid rgba($bc2,.15);
			padding-bottom: 20px;
			margin-bottom: 20px;

			&:last-of-type {
				border-bottom: 0;
				margin-bottom: 0;
				padding-bottom: 0;
			}

			@include mq(767) {
				flex-wrap: wrap;
				margin-bottom: 15px;
				padding-bottom: 15px;
			}
		}

		&__name {
			color: $bc2;
			font-weight: bold;
		}

	}

	.r_main-friends-list {

		&__avatar {
			width: 50px;
			height: 50px;
			margin-right: 20px;
		}
		&__info {
			width: 40%;
			margin-right: 20px;

			@include mq(767) {
				width: calc(100% - 70px);
				margin-right: 0;
			}
		}
		&__activity {
			width: 48%;
			font-size: 0.875rem;

			@include mq(767) {
				width: 100%;
				padding-top: 10px;
			}
		}
	}

	.r_referrals-list {

		&__desc {
			text-align: right;
			padding: 0 20px;
			font-size: 0.875rem;

			@include mq(767) {
				text-align: left;
				padding-left: 0;
			}
		}

		.btn {
			min-width: 100px;
			font-size: 11px;
		}

		&__name, &__desc {
		  width: calc(50% - 50px);
		}

		@include mq(767) {
			&__name, &__desc {
				width: 100%;
				margin-bottom: 5px;
			}
		}

	}

	.r_pagination {
		padding-top: 40px;
	}

	.refer-incentive {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5px;
		flex-direction: row;
		padding: 10px 20px;
		font-size: 0.875rem;
		font-weight: bold;

		.btn {
			width: 32px;
			height: 32px;
			padding: 0;
			min-width: 1px;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				fill: #fff;
				width: 17px;
				height: 17px;
			}
		}

		@include mq(767) {
			padding: 10px 30px;

			span {
				width: calc(100% - 50px);
			}
		}
	}
}

.tabsAbove {
	display: flex;
	justify-content: space-between;
	align-items: stretch;

	margin-bottom: 25px;
	width: 100%;

	@include mq($tablet) {
		padding-left: 15px;
		padding-right: 15px;
	}

	@include mq(767) {
		flex-direction: column;
	}

	li {
		background-color: #ffffff;
		flex-grow: 1;
		flex-basis: 0;
		text-align: center;
		min-height: 53px;
		padding: 10px;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		color: rgba($bc2, .25);

		display: flex;
		justify-content: center;
		align-items: center;
		border-left: 1px solid rgba($bc2, .15);
		cursor: pointer;
		@extend %transition;

		&:first-child {
			border-left: 0 none;
			border-radius: 5px 0 0 5px;
			
		}
		&:last-child {
			border-radius: 0 5px 5px 0;
		}

		&:hover, &.current {
			color: #fff;

			.notif-new {
				background-color: #fff;
				color: $bc1;
			}
		}

		&:hover {
			background: $bc1;
		}

		&.current, &.current:hover {
			background: transparent linear-gradient(0deg, rgba($bc1, 1) 50%, rgba($bc1grad, 1) 100%) 0% 0% no-repeat padding-box;
		}

		@include mq(767) {
			border-left: 0 none;
			border-top: 1px solid rgba($bc2, .15);
			min-height: unset;
			justify-content: flex-start;

			&:first-child {
				border-top: 0 none;
			}
		}
	}
}

.notif-new, .notif-no {
	margin: 0 10px;
	padding: 0 5px;
	min-height: 22px;
	line-height: 22px;
	background-color: $bc1;
	border-radius: 12px;

	color: #fff;
	font-size: 14px;
	text-align: center;

	display: inline-block;
	vertical-align: middle;
	@extend %transition;
	min-width: 22px;


}

.notif-no {
	margin: 0;
}

.countdown {
	background-color: $bc2;
	height: 30px;
	padding: 0 5px 0 10px;
	color: #fff;
	border-radius: 15px;
	display: inline-block;
	font-size: 13px;
	font-weight: bold;

	&__cnt {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
		height: 30px;
	}

	.btn.svgcnt {
		padding: 0 !important;
		min-width: 20px;
		height: 20px;
		width: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 10px;
		pointer-events: none;

		&:after {
			background-color: #ffffff;
		}

		&:hover svg {
			filter: drop-shadow(0 0 0 3px rgba($bc2, .5));
		}
	}
}

.matching-lists {

	h4 {
		font-size: 1.25rem;
		margin-bottom: 0;
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;
		user-select: none;
		background-color: #fff;
		padding: 15px 22px;
		border-radius: 5px;

		&:before {
			content: '';
			width: 14px;
			height: 8px;
			display: block;
			background: url(../../images/front/r_chevron.svg) 0 0 no-repeat;
			background-size: 100%;
			transform: scale(-1);
			@extend %transition;
			margin-right: 25px;
			opacity: .3;

			&:hover {
				opacity: .8;
			}
		}

		&.expand {
			border-radius: 5px 5px 0 0;

			&:before {
				transform: none;
			}

			~ .tab__body {
				display: block;
			}
		}

		@include mq($tablet) {
			padding: 10px 20px;
		}

		+ .tab__body .tabular-data {
			border-radius: 0 0 5px 5px;
		}
	}

	.resumeSectionTitle {
		background-color: #ffffff;
		border-bottom: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	.tab__cnt {
		background-color: transparent;
		display: none;

		@include mq($tablet) {
			padding-left: 15px;
			padding-right: 15px;
		}

		&.current {
			display: block;
		}
	}

	&__job-item {
		margin-bottom: 20px;
	}

	.tab__body {
		display: none;
	}

	&__message {
		padding: 20px;
		font-size: 1.15rem;
		opacity: .75;
		margin-bottom: 40px;
		background-color: #ffffff;

		a {
			color: $bc1;
			text-decoration: underline;

		}
	}
}

.r_settings {
	.rowEssentials {
		align-items: flex-start;

		@include mq(767) {
			padding-top: 20px;
		}
	}
	.r_card {
		height: auto;
		width: 100%;
	}

	.row {
		width: 100%;

		.cell {
			margin-bottom: 15px;

			@include mq($tablet) {
				&.flushBottom {
					order: 2;
				}
			}
		}
	}

	@include mq($tablet) {
		.btn {
			margin-bottom: 0;
		}
	}

	&__avatar {
		width: 228px;
		height: 228px;
		@include bg('r_dotted-avatar-bg.svg');
		padding: 19px;
		margin: 0 auto;

		img {
			display: block;
			border-radius: 50%;
		}

		label.icon-camera {
			width: 36px;
			height: 36px;
			display: block;
			margin: 0 auto;
			padding: 0;
			position: absolute;
			z-index: 2;
			bottom: 40px;
			left: 50%;
			transform: translate(-50%,0);
			opacity: 0;
			visibility: hidden;
			@extend %transition;
			filter: $filter-shadow;

			svg {
				display: block;
				width: 36px;
				height: 36px;
				fill: #fff;
			}
		}

		input[type='file'] {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: -1;
			opacity: 0;
			visibility: hidden;
		}

		&:hover {
			label.icon-camera {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__notifications {
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: 20px;
		width: calc(100% + 40px);

		@include mq(767) {
			margin-left: -30px;
			margin-right: -30px;
			width: calc(100% + 60px);
		}

		&--item {
			display: flex;
			justify-content: space-between;
			padding: 20px;
			border-bottom: 1px solid rgba($bc2,.15);
		}
	}
}

.responsiveness {
	position: relative;
}
.article {
	line-height: 1.44;
	font-size: 1.125rem;
	margin-bottom: 2rem;

	img {
		height: auto;
	}

	p:empty {
		display: none;
	}

	a {
		color: $bc1;

		&:hover {
			text-decoration: underline;
		}
	}

	ul, ol {
		margin-bottom: 1.25rem;

		li:not(:last-child) {
			margin-bottom: 0.6rem;
		}
	}

	ul {
		padding-left: 30px;

		li:before {
			content: '\2022';
			color: $bc1;
			display: inline-block;
			width: 1em;
			margin-left: -1em;
		}
	}

	ol {
		list-style: decimal outside;
		padding-left: 0;
		margin-left: 23px;

		&[start] {
			list-style: none;

			&:before {
				content: attr(start)'.';
				display: inline;
				font-weight: bold;
			}
			li {
				display: inline;
			}
		}
	}
}

.new-entity-warn {
	display: none;
	font-size: 75%;
	padding: 0 20px;
	margin-top: 5px;
}

.display-new-entity-warn {
	.new-entity-warn {
		display: block;
	}
}

.white_svg {
	fill: #fff;
}

ul.red-check {
	li {
		margin-bottom: 12px;
		position: relative;
		z-index: 1;
		display: flex;
		align-items: flex-start;

	}
	svg {
		min-width: 16px;
		width: 16px;
		height: 12px;
		fill: $bc1;
		margin-right: 20px;
		margin-top: 4px;
	}
}

.course-rating {
	span {
		display: inline-block;
		position: relative;
		margin-right: 7px;
		width: 11px;
		height: 10px;
		background: url('../../images/front/r_star_red.svg');

		&:before {
			content:'';
			width: 100%;
			height: 100%;
			background: url('../../images/front/r_star_red.svg');
			opacity: 0;
			background-size: 11px 10px;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
		}
	}

	&[data-rating='0'] {
		span {
background-image: url('../../images/front/r_star_gray.svg');
}
	}
	&[data-rating='0.5'] {
		span:first-child {
			background-image: url('../../images/front/r_star_gray.svg');

			&:before {
				width: 50%;
				opacity: 1;
			}
			~ span {
background-image: url('../../images/front/r_star_gray.svg');
}
		}
	}
	&[data-rating='1'] {
		span:first-child {
			background-image: url('../../images/front/r_star_red.svg');
			~ span {
background-image: url('../../images/front/r_star_gray.svg');
}
		}
	}
	&[data-rating='1.5'] {
		span:first-child {
			background-image: url('../../images/front/r_star_red.svg');

			+ span:before {
				width: 50%;
				opacity: 1;
			}
			~ span {
background-image: url('../../images/front/r_star_gray.svg');
}
		}
	}
	&[data-rating='2'] {
		span {
			background-image: url('../../images/front/r_star_red.svg');
		}
		span:nth-child(2) ~ span {
			background-image: url('../../images/front/r_star_gray.svg');
		}
	}
	&[data-rating='2.5'] {
		span {
			background-image: url('../../images/front/r_star_red.svg');
		}
		span:nth-child(3) {
			background-image: url('../../images/front/r_star_gray.svg');
			&:before {
				width: 50%;
				opacity: 1;
			}
			~ span {
				background-image: url('../../images/front/r_star_gray.svg');
			}
		}
	}
	&[data-rating='3'] {
		span {
			background-image: url('../../images/front/r_star_red.svg');
		}
		span:nth-child(3) ~ span {
			background-image: url('../../images/front/r_star_gray.svg');
		}
	}
	&[data-rating='3.5'] {
		span {
			background-image: url('../../images/front/r_star_red.svg');
		}
		span:nth-child(4) {
			background-image: url('../../images/front/r_star_gray.svg');

			&:before {
				width: 50%;
				opacity: 1;
			}
			~ span {
				background-image: url('../../images/front/r_star_gray.svg');
			}
		}
	}
	&[data-rating='4'] {
		span {
			background-image: url('../../images/front/r_star_red.svg');
		}
		span:nth-child(4) ~ span {
			background-image: url('../../images/front/r_star_gray.svg');
		}
	}
	&[data-rating='4.5'] {
		span {
			background-image: url('../../images/front/r_star_red.svg');
		}
		span:nth-child(5) {
			background-image: url('../../images/front/r_star_gray.svg');

			&:before {
				width: 50%;
				opacity: 1;
			}
			~ span {
				background-image: url('../../images/front/r_star_gray.svg');
			}
		}
	}
	&[data-rating='5'] {
		span {
			background-image: url('../../images/front/r_star_red.svg');
		}
	}
}

.add-review {
	position: relative;
	z-index: 1;

	input[type='radio'] {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		visibility: hidden;
	}
	label {
		position: relative;
		padding: 0;
		width: 21px;
		height: 20px;
		margin: 0 7px;
		background-image: url('../../images/front/r_star_gray.svg');
		background-size: 100%;

		&:before {
			content: '';
			position: absolute;
			background-image: url('../../images/front/r_star_red.svg');
			display: block;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-size: 100%;
			transition: all .2s ease;
		}

	}

	input[type='radio']:checked + label ~ label:before {
		opacity: 0;
	}
}
.dropHandler {
	cursor: pointer;
	user-select: none;
}

.noAccount {
	color: rgba(15, 35, 63, 0.25);
	font-size: 14px;
	font-style: italic;
	font-weight: bold;
}

.gray {
	color: rgba(15, 35, 63, 0.25) !important;
}