.r_shop {

	&__list {

		aside {
			width: 220px;

			ul li, ul li.expanded:hover {
				color: $bc2;
			}
		}

		section {
			width: calc(100% - 245px) !important;
		}

		&-card {
			background-color: #fff;
			display: flex;
			flex-direction: column;
			position: relative;
			z-index: 1;
			padding-bottom: 20px;
			margin-bottom: 20px;
			width: 220px;
			text-align: center;

			img {
				display: block;
			}
		}
		&-item-price {
			position: absolute;
			z-index: 1;
			right: 0;
			top: 20px;
			background-color: #fff;
			border-radius: 15px 0 0 15px;
			line-height: 30px;
			height: 30px;
			min-width: 70px;
			text-align: center;
			font-weight: bold;
		}
		&-item-name {
			min-height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			padding: 20px;

		}

	}

	&__total-points {
		text-align: center;
		background-color: #ffffff;
		height: 48px;
		line-height: 48px;
		border-radius: 24px;
		margin-bottom: 20px;
		font-size: 1.125rem;
		font-weight: bold;
	}

	.theProgressBar {
		margin-top: 2px;
		margin-bottom: 25px;

		.ui-slider-handle {
			width: 15px;
			height: 15px;
		}
	}

	input.price-range-min, input.price-range-max {
		width: 80px;
		height: 32px;
		line-height: 32px;
		background-color: rgba($bc2,.05);
		border-radius: 16px;
		padding: 0 5px 0 12px;
		position: static;
		opacity: 1;
		visibility: visible;
	}

	&__single {

		.r_main_container {
			flex-wrap: wrap;
		}

		section {
			display: flex;
			flex-direction: column;
		}

		&-slider {
			margin: 0 auto 20px;
			width: 100%;
			max-width: 300px;

			img {
				display: block;
			}
		}

		&-header {
			font-size: 30px;
			font-weight: bold;
			padding: 40px 150px 40px 20px;
			position: relative;
			z-index: 1;
		}

		&-price {
			background-color: $graybg;
			height: 60px;
			width: 142px;
			line-height: 60px;
			text-align: center;
			border-radius: 30px 0 0 30px;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -30px;
		}

		&-desc {
			padding: 20px;
			background-color: #fff;
			flex-grow: 1;

			@include mq(767) {
				padding: 20px 30px;
			}
		}

		&-checklist {

			li {
				margin-bottom: 12px;
			}

			.icon {
				margin-right: 15px;
				width: 16px;
				height: 13px;
			}

			a.btn.small {
				margin-right: 15px;
				padding: 0 7px !important;
				text-transform: uppercase !important;
			}

			svg {
				fill: rgba($bc2,.25)
			}
			.completed svg {
				fill: $bc1;
			}
			.missing {
				color: rgba($bc2,.25);
			}

		}

		.btn.full {
			width: 100%;
			margin-top: 40px;
		}

		&-related {
			width: 100%;
			padding-top: 40px;

			h4 {
				font-size: 1.125rem;
				margin-bottom: 1rem;
			}

			a {
				height: 111px;
				display: flex;
				background-color: #ffffff;
				min-width: calc((100% - 40px) / 3);
				max-width: calc((100% - 40px) / 3);
				margin-bottom: 20px;

				@include mq(767) {
					min-width: 100%;
					max-width: 100%;
				}

			}

			img {
				min-width: 111px;
				display: block;
			}

			.related-title {
				flex-grow: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 15px;
				font-weight: bold;
				text-align: center;
			}
		}
	}

	.stock-icon {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 20px;
		background-color: #fff;
		border-radius: 0 15px 15px 0;
		height: 30px;
		line-height: 32px;
		min-width: 35px;
		text-align: center;

		span {
			height: 15px;
			width: 15px;
			background-color: $green;
			border-radius: 50%;
			display: inline-block;

			&.low {
				background-color: $orange;
			}

			&.no {
				background-color: $bc1;
			}
		}

		.tooltip {
			&:before {
				top: 37px !important;
				left: 0 !important;
				border-radius: 0 5px 5px 0 !important;
			}
			&:after {
				top: 23px !important;
				right: 10px !important;
			}
		}
	}

	.details-button {
		display: flex;
		height: 100%;
		align-items: flex-end;
		justify-content: center;
	}
}
