// $imagedir:'../images/';
$imagedir: '../../images/front/';

@mixin bg($filename,$position:0 0,$repeat: no-repeat) {
	background: transparent url(#{$imagedir}#{$filename}) $repeat $position;
}

@mixin bgi($filename) {
	background-image: url(#{$imagedir}#{$filename});
	background-repeat: no-repeat;
}

@mixin mq($point, $query1: max, $query2: width) {
	@media screen and (#{$query1}-#{$query2}: $point +px) {
		@content;
	}
}

@mixin mqq($point, $query1: min, $query2: width) {
	@media screen and (#{$query1}-#{$query2}: $point +px) {
		@content;
	}
}

@mixin placeholder {
	::-webkit-input-placeholder {
		@content


}
	::-moz-placeholder {
		@content


}
	:-ms-input-placeholder {
		@content


}
	::placeholder {
		@content


}
}

%cf {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin rounded-avatar {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: -2px;
		right: -2px;
		top: -2px;
		bottom: -2px;
		border: 4px solid #fff;
		border-radius: 50%;
	}

	img {
		display: block;
		border-radius: 50%;
	}
}

.rounded-avatar {
	@include rounded-avatar;
}

.cf {
	@extend %cf;
}

%transition {
	transition: all 0.25s ease-in-out;
}

.transition {
	transition: all 0.25s ease-in-out;
}

%t2 {
	transition: all 0.5s ease-in-out;
}

%acc1 {
	position: relative;
	opacity: 0;
	overflow: hidden;
	max-height: 0;
	transition: all .25s ease-out, max-height 0 cubic-bezier(0, 1, 0, 1), opacity 0s linear;
	z-index: 1;
}

%acc2 {
	position: relative;
	opacity: 1;
	max-height: 9999px;
	transition: all .25s ease-in-out, max-height 0.95s cubic-bezier(1, 0, 1, 0), opacity 0.25s linear;
	z-index: 2;
}

%scroll {
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-button:start:decrement,
	::-webkit-scrollbar-button:end:increment {
		display: block;
		height: 0;
		background-color: transparent;
	}

	::-webkit-scrollbar-track-piece {
		background-color: #FAFAFA;
		-webkit-border-radius: 0;
		-webkit-border-bottom-right-radius: 8px;
		-webkit-border-bottom-left-radius: 8px;
	}

	::-webkit-scrollbar-thumb:vertical {
		height: 50px;
		background-color: rgba($bc2,.25);
		-webkit-border-radius: 8px;
	}

	::-webkit-scrollbar-thumb:horizontal {
		width: 50px;
		background-color: rgba($bc2,.25);
		-webkit-border-radius: 8px;
	}
}

