.r_redesign {

	input[type='text'], input[type='number'], input[type='email'], input[type='password'], input[type='url'], input[type='tel'], input[type='search'], textarea, select {
		font: inherit;
		font-size: 1rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		width: 100%;
		height: 38px;
		border-radius: 18px;

		color: $bc2;
		padding-left: 20px;
		padding-right: 20px;
		transition: box-shadow 0.25s ease-in;
		border: 1px solid rgba($bc2, .25);
		background-color: transparent;

		min-width: 1px;

		&:focus {
			box-shadow: 0 0 4px 1px rgba($bc2, .15) inset;
			filter: drop-shadow(0 0 4px rgba($bc2, .15));
		}
	}

	.quill-wrapper {
		background-color: rgba($bc2, .05);
		border-radius: 18px;
		padding: 3px;

		.ql-toolbar.ql-snow, .ql-container {
			font-family: $font;
		}

		.ql-toolbar.ql-snow, .ql-container.ql-snow {
			border: 0;
		}

		.ql-toolbar.ql-snow {
			border-bottom: 1px solid #fff;
			background-color: $bc1;
			border-radius: 17px 17px 0 0;
			color: #fff;
		}

		.ql-editor {
			padding: 12px;
			min-height: 120px;
		}

		.ql-snow .ql-picker {
			color: #fff;
		}

		.r_redesign .quill-wrapper svg, .r_redesign .quill-wrapper .ql-snow .ql-stroke, .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:hover .ql-fill,
		.ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill,
		.ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
		.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
		.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
		.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
		.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
		.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
		.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
		.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
		.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
		.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
			fill: #fff;
		}

		.ql-snow .ql-stroke,
		.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke,
		.ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke,
		.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
		.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
		.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
		.ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
		.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
		.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
		.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
		.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
		.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
			stroke: #fff;
		}

		.ql-picker-options .ql-stroke {
			stroke: $bc2;
		}

		.ql-snow.ql-toolbar .ql-picker-options .ql-picker-item:hover .ql-stroke,
		.ql-snow.ql-toolbar .ql-picker-options .ql-picker-item.ql-selected .ql-stroke {
			stroke: $bc1;
		}

		.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
			border: 0;

		}

		.ql-picker-options {
			color: $bc2;

		}

		.ql-picker-item .ql-selected {
			color: $bc1;
		}

		.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus,
		.ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover,
		.ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover,
		.ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
			color: $bc1;
		}

		.ql-toolbar.ql-snow .ql-picker-label {
			border: 0;

			&:hover {
				color: #fff
			}
		}

		.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
			padding: 0 18px;
			border-radius: 18px;
			color: #fff;
			background-color: $bc1;
			display: inline-block;
			height: 26px;
			line-height: 26px;
			@extend %transition;
		}

		.ql-snow .ql-tooltip.ql-editing a.ql-action:hover:after {
			background-color: $bc2;
		}
	}

	.required.error {
		&:focus {
			box-shadow: 0 0 2px 1px rgba($bc1, .5) inset, 0 0 2px rgba($bc1, .5);
		}
	}

	.form__group {
		margin-bottom: 25px;
		position: relative;
		&.cancel_mb{
			margin-bottom: 0;
		}
		@include mq($tablet) {
			margin-bottom: 15px;
		}

		&.options {
			margin-bottom: 15px;
		}

		&.last {
			margin-bottom: 0;
		}
	}

	.row > .form__group:last-child {
		margin-bottom: 0;
	}

	select {
		appearance: none;
		-webkit-appearance: none;
		padding-right: 25px;
		background-image: url(../../images/front/r_select_arr.svg);
		background-position: calc(100% - 15px) 50%;
		background-repeat: no-repeat;
		background-size: 6px 3px;
		cursor: pointer;
	}

	select::-ms-expand {
		display: none;
	}

	textarea {
		resize: vertical;
		min-height: 100px;
		padding: 10px 15px;
		line-height: 1.23;
	}

	@include placeholder {
		opacity: 1;
		color: rgba($bc2, .5);
	}

	#jsTranslatedString{
		display: none;
	}
	.agree {
		font-size: 0.875rem;
		position: relative;
		z-index: 1;

		label {
			display: block;
			padding-left: 30px;
			position: relative;
			z-index: 1;

			&:before {
				content: '';
				width: 22px;
				height: 22px;
				border: 3px solid $bc1;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 50%;
				background-color: transparent;
				padding: 4px;
				background-clip: content-box;
				@extend %transition;
			}

			a {
				text-decoration: underline;
			}
		}

		input {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			visibility: hidden;

			&:checked + label:before {
				background-color: red;
			}
		}
	}

	label[for], .btn, input[type='submit'], button {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-appearance: none;
		appearance: none;
		border: 0;
		background-color: transparent;
		cursor: pointer;
	}

	label[id*='error'] {
		font-size: 12px !important;
		color: red !important;
	}

	label.validation {
		font-size: 12px !important;
		color: red !important;
	}

	.btn {
		min-width: 120px;
		color: #fff;
		height: 34px;
		line-height: 34px;
		border-radius: 18px;

		user-select: none;

		font-size: 14px;
		font-weight: 500;
		text-shadow: none;

		padding: 0 20px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		z-index: 1;
		overflow: hidden;

		background-color: transparent;
		max-width: 100%;
		text-align: center;
		transition: all 0.25s ease-in-out;

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: 60px;
			z-index: -2;
			@extend %transition;
		}

		&.disabled {
			pointer-events: none;
			opacity: .4;

			&:before {
				opacity: 0;
			}

			&:after {
				opacity: 1;
			}
		}

		&.hidden {
			opacity: 0;
			visibility: hidden;
		}

		&:before {
			background: -moz-linear-gradient(top, $buttonc2 0%, $buttonc1 100%);
			background: -webkit-linear-gradient(top, $buttonc2 0%, $buttonc1 100%);
			background: linear-gradient(to bottom, $buttonc2 0%, $buttonc1 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$buttonc1', endColorstr='$buttonc2', GradientType=0);
			background-color: $buttonc1;
		}

		&:after {
			z-index: -1;
			background: $bc2;
			opacity: 0;
		}

		&:hover {
			color: #fff;

			&:after {
				opacity: 1;
			}
		}

		@include mq(767) {
			font-size: 12px;
		}

		&.gray {
			background-color: rgba($bc2, .05);
			color: $bc2;

			&:before {
				opacity: 0;
			}

			&:hover {
				color: #fff;

				// &:after {opacity: 0;}
				// &:before {opacity: 1;}
			}
		}

		&.big {
			height: 60px;
			line-height: 60px;
			padding: 0 30px;
			font-size: 1.37rem;
			min-width: 160px;

			@include mq($tablet) {
				font-size: 1.2rem;
				height: 45px;
				line-height: 45px;
				padding: 0 20px;
			}
		}

		&.med {
			height: 50px;
			line-height: 50px;
			padding: 0 40px;
			font-size: 1.125rem; // 18 px desktop

			@include mq($tablet) {
				font-size: 1rem;
				height: 40px;
				line-height: 40px;
				padding: 0 20px;
			}
		}

		&.add {
			width: 22px;
			height: 22px;
			line-height: 20px;
			padding: 0;
			min-width: 22px;
			font-weight: normal;
		}

		&.hollow {
			background-color: transparent;
			color: $bc2;
			box-shadow: 0 0 0 1px $bc2 inset;

			&:before, &:after {
				content: unset;
				display: none;
			}

			&:hover {
				color: #fff;
				background-color: $bc2;
			}

			&.white {
				color: #fff;
				border-color: #fff;
				box-shadow: 0 0 0 1px #fff inset;

				&:hover {
					background-color: #ffffff;
					color: $bc2;
				}
			}
		}

		&.orange {
			&:before {
				background: $orange;
			}
		}
		&.green {
			&:before {
				background: $green;
			}
		}


		&.inactive {
			pointer-events: none;
		}

		&.icon {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			padding: 0;
			min-width: 1px;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				width: 20px;
				height: 20px;

				path {
					fill: #fff;
				}
			}
		}

		@keyframes expand {
			from {
				transform: scale(0);
			}
		}

		&.suggestion {
			min-width: auto;
			text-transform: none;
			color: #797b83;
			height: 30px;
			line-height: 30px;
			padding: 0 10px !important;
			margin: 2px;
			animation: expand .5s ease-in-out;
		}
	}

	main {
		.btn {
			&.small {
				height: 24px;
				line-height: 24px;
				padding: 0 18px !important;
				font-size: 10px;
				min-width: 1px;
				text-transform: none;
			}
		}
	}

	.r_resume-edit {
		input[type='text'], input[type='email'], input[type='password'], input[type='url'], input[type='tel'], input[type='search'], textarea, select {
			border: 0;
			background-color: rgba($bc2, .05);
			font-size: 16px;

			width: 100%;
			height: 38px;
			border-radius: 18px;

			color: $bc2;
			transition: box-shadow 0.25s ease-in, filter 0.25s ease-in;

			&.split {

				&__left {
					border-radius: 18px 0 0 18px;
					border-right: 1px solid rgba($bc2, .15);
					padding-right: 12px;
				}

				&__right {
					border-radius: 0 18px 18px 0;
					padding: 0 15px;
				}

				&__middle {
					border-radius: 0;
					border-right: 1px solid rgba($bc2, .15);
					padding: 0 15px;
				}
			}
		}

		.select2-container {
			max-width: 100%;
		}

		.select2-container--default .select2-selection--single .select2-selection__rendered {
			line-height: 38px;
			padding: 0;
		}

		.select2-container--default .select2-selection--single .select2-selection__arrow {
			height: 100%;
			right: 0;
		}

		textarea {
			min-height: 110px;
		}
		.document_upload_btn_holder{
			label{
				margin-bottom: 0;
			}
		}
		label, .labelLike {
			padding-left: 20px;
			display: inline-block;
			color: rgba($bc2, .5);
			margin-bottom: 5px;
			font-size: 13px;
			cursor: pointer;
			user-select: none;
			&.btn{
				color: #fff;
			}
		}

		.hasSplit {
			display: flex;
			width: 100%;

			select {
				background-position: calc(100% - 10px) 50%;
				padding-right: 15px;
			}
		}

		.checkboxHolder {
			position: relative;
			z-index: 1;
			height: 36px;
			display: flex;
			align-items: center;

			label {
				padding-left: 32px;
				position: relative;
				z-index: 1;
				margin: 0;
				font-size: 1rem;
				color: $bc2;

				&:before, &:after {
					content: '';
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					position: absolute;
					z-index: 1;
					border-radius: 50%;
				}

				&:before {
					width: 20px;
					height: 20px;
					background-color: rgba($bc2, .15);
				}

				&:after {
					width: 10px;
					height: 10px;
					left: 5px;
					background-color: #fff;
					opacity: 0;
					@extend %transition;
				}
			}

			input[type='checkbox'], input[type='radio'] {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				visibility: hidden;

				&:checked + label:after {
					opacity: 1;
				}
			}
		}
		.row{
			&.flex_align_center{
				align-items: center;
				select{
					margin-bottom: 0;
				}
			}
		} 
	}

	.select2-container--default {

		.select2-selection--single {
			border: 0;
			background-color: $graybg;
			font-size: 16px;

			width: 100%;
			height: 38px;
			border-radius: 18px;

			color: $bc2;
			padding: 0 20px;

		}

		.select2-selection__arrow {
			background-image: url(../../images/front/r_select_arr.svg);
			background-position: calc(100% - 10px) 50%;
			background-repeat: no-repeat;
			background-size: 6px 3px;
			width: 30px;

			b {
				display: none;
			}
		}

		&.select2-container--open {

			z-index: 999;

			.select2-dropdown, .select2-selection {
				filter: drop-shadow(0 2px 2px rgba($bc2, .15));
			}

			.select2-selection--single {
				border-radius: 18px 18px 0 0;
			}

			&.select2-container--above {

				.select2-dropdown, .select2-selection {
					filter: drop-shadow(0 -2px 2px rgba($bc2, .15));
				}

				.select2-selection--single {
					border-radius: 0 0 18px 18px;
				}
			}
		}
	}

	.select2-dropdown {
		background-color: $graybg;
		border: 0;
		border-radius: 0 0 18px 18px;
		padding-bottom: 15px;

		&.select2-dropdown--above {
			border-radius: 18px 18px 0 0;
			padding-bottom: 0;
		}

		.select2-results {
			&__option {
				@extend %transition;
				color: rgba($bc2, .75);

				&[aria-selected=true] {
					color: #fff;
					background-color: $bc1;
				}

				&--highlighted {
					color: #fff;
					background-color: $bc2;
				}
			}
		}

		.select2-search__field {
			padding: 0 10px;
			height: 30px;
			background-color: #ffffff;

			&:focus {
				box-shadow: none;
			}
		}
	}

	.split__left + .select2-container--default {

		.select2-selection--single {
			border-radius: 18px 0 0 18px;
			padding-left: 10px;
		}

		&.select2-container--open .select2-selection--single {
			border-radius: 18px 0 0 0;
		}

		&.select2-container--open.select2-container--above .select2-selection--single {
			border-radius: 0 0 0 18px;
		}
	}

	.split__right + .select2-container--default {

		.select2-selection--single {
			border-radius: 0 18px 18px 0;
			padding-right: 10px;
		}

		&.select2-container--open .select2-selection--single {
			border-radius: 0 18px 0 0;
		}

		&.select2-container--open.select2-container--above .select2-selection--single {
			border-radius: 0 0 18px 0;
		}
	}

	.split__middle + .select2-container--default {

		.select2-selection--single {
			border-radius: 0;
			padding: 0 10px;
		}

		&.select2-container--open .select2-selection--single {
			border-radius: 0;
		}

		&.select2-container--open.select2-container--above .select2-selection--single {
			border-radius: 0;
		}
	}

	.Zebra_DatePicker_Icon {
		opacity: 0;
	}

	.switch-holder {
		position: relative;
		z-index: 1;

		input {
			position: absolute;
			z-index: 0;
			left: 0;
			top: 0;
			opacity: 0;
			visibility: hidden;
		}

		label {
			position: relative;
			z-index: 2;
			height: 22px;
			width: 60px;
			background-color: rgba($bc2, .05);
			border-radius: 11px;
			text-transform: uppercase;
			line-height: 22px;
			display: flex !important;
			font-size: 12px;
			font-weight: bold;
			color: $bc2;
			cursor: pointer;
			margin: 0;

			&:before, &:after {
				content: '';
				position: absolute;
				display: block;
				@extend %transition;
			}

			&:before {
				z-index: 3;
				width: 14px;
				height: 14px;
				background-color: #fff;
				border-radius: 50%;
				left: 4px;
				top: 4px;
				box-shadow: 1px 1px 0 0 rgba($bc2, .2);
			}

			&:after {
				z-index: 1;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				border-radius: 11px;
				background: -moz-linear-gradient(top, $buttonc2 0%, $buttonc1 100%);
				background: -webkit-linear-gradient(top, $buttonc2 0%, $buttonc1 100%);
				background: linear-gradient(to bottom, $buttonc2 0%, $buttonc1 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$buttonc1', endColorstr='$buttonc2', GradientType=0);
				background-color: $buttonc1;
				opacity: 0;
			}

			span {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				text-align: center;
				@extend %transition;
				display: block;
				width: 100%;
				@extend %transition;
			}

			.on {
				padding-right: 12px;
				opacity: 0;
				color: #fff;
			}

			.off {
				padding-left: 12px;
			}

			&.error {
				position: absolute;
				top: 100%;
			}
		}

		input:checked ~ label {
			&:before {
				transform: translateX(38px);
			}

			.off {
				opacity: 0;
			}

			.on {
				opacity: 1;
			}

			&:after {
				opacity: 1;
			}
		}

		input:disabled ~ label:after {
			background: #666 !important;
		}
	}

	.blueForm {
		background-color: $bc2;
		padding: 28px 18px 20px;

		input[type='text'], input[type='email'], input[type='password'], input[type='url'], input[type='tel'], input[type='search'], textarea, select {
			border: 2px solid #fff;
			color: #fff;
		}

		::-webkit-input-placeholder {
			color: #fff;
		}

		::-moz-placeholder {
			color: #fff;
		}

		:-ms-input-placeholder {
			color: #fff;
		}

		::placeholder {
			color: #fff;
		}

		.btn {
			&:after {
				content: unset;
			}

			&:hover {
				background-color: #ffffff;
				color: $bc2;

				&:before {
					content: unset;
					background: transparent none;
				}
			}
		}

		.form__group {
			margin-bottom: 18px;
		}

		label[id*='error'] {
			color: $bc1 !important;
		}
	}

	.r_overlay {
		position: fixed;
		background-color: rgba(#000, .5);
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}


	label.error {
		font-weight: normal;
		white-space: nowrap;
		font-size: 0.8rem;
		text-transform: none;

		background: transparent none;

		&:before, &:after {
			content: unset;
			display: none;
		}
	}

}

div[id*='__lpform'] {
	display: none !important;
}

.r_popup {
	opacity: 0;
	visibility: hidden;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1000;
	display: flex;

	&.active {
		opacity: 1;
		visibility: visible;

		z-index: 1000;

		.r_popup__cnt {
			transform: translateY(0);
		}

		.r_overlay {
			opacity: 1;
		}
	}

	&__cnt {
		background-color: #ffffff;
		box-shadow: 0 0 10px rgba(#000, .25);
		width: 550px;
		max-width: 92vw;
		position: relative;
		z-index: 2;
		transition: transform 1s cubic-bezier(0, 1, 0, 1);
		transform: translateY(50px);

		@include mq(1280) {
			overflow-x: hidden;
			overflow-y: auto;
			max-height: 95vh;

			.row ~ br {
				display: none;
			}
		}

		&.book {
			width: 956px;
			border-radius: 0 0 10px 10px;

			.r_popup__body {
				padding: 60px 80px 40px;

				@include mq(1000) {
					padding: 30px;
				}
			}
			.r_popup__close {
				background-color: $bc2;
				width: 65px;
				height: 64px;
				border-radius: 10px 0 10px 10px;

				img {
					width: 16px;
					height: 16px;
				}

				@include mq(1000) {
					width: 42px;
					height: 42px;

					img {
						width: 12px;
						height: 12px;
					}
				}
			}

		}
	}

	.book-a-demo {
		display: flex;
		align-items: stretch;
		justify-content: space-between;

		@include mq(1000) {
			display: block;
		}

		h2 {
			font-size: 45px;
			margin-bottom: 30px;
			font-weight: normal;
			color: $bc2;

			.bc {
				font-weight: bold;
				display: inline;
			}

			@include mq(1000) {
				font-size: 30px;
				margin-bottom: 15px;
			}
		}

		h3 {
			font-size: 22px;
			color: $bc2;
			margin-bottom: 30px;

			@include mq(1000) {
				font-size: 18px;
				margin-bottom: 15px;
			}
		}

		input[type=text], input[type=email] {
			height: 45px;

			@include mq(1000) {
				height: 40px;
			}
		}

		input[type=text], textarea, input[type=email] {
			border-radius: 8px;
		}

		label {
			padding: 0 20px;
		}

		.btn {
			width: 100%;
			height: 46px;

			@include mq(1000) {
				height: 40px;
				width: auto;
			}
		}

		&__desc {
			color: #979797;
			width: 460px;
			padding-right: 25px;
			font-size: 1rem;

			@include mq(1000) {
				width: 100%;
				margin-bottom: 30px;
			}

			ul {
				margin-bottom: 1.25rem;
				color: $bc2;

				li {
					position: relative;
					z-index: 1;
					padding-left: 18px;
					margin-bottom: 25px;
					display: block;

					&:before {
						content: '•';
						color: $bc1;
						position: absolute;
						left: 0;
						line-height: 17px;
						z-index: 1;
						font-size: 30px;
					}
				}
			}
		}

		&__form {
			width: 307px;
			padding: 50px 0;
			border-top: 1px solid #E8E8E8;
			border-bottom: 1px solid #E8E8E8;
			margin: 20px 0;

			@include mq(1000) {
				width: 100%;
				max-width: 300px;
				margin: 0 auto;
				padding: 20px 0 0;
				border-bottom: 0;
			}
		}

		p, ul {
			font-size: 1rem;

			&:not(:last-child) {
				margin-bottom: 50px;

				@include mq(1000) {
					margin-bottom: 20px;
				}
			}
		}
	}

	.r_overlay {
		opacity: 0;
		transition: opacity 0.25s ease-in;
	}

	&__title {
		background: $bc1;
		color: #fff;
		font-size: 1.0625rem;
		font-weight: bold;
		line-height: 2.375;
		padding: 0 70px 0 20px;
		position: relative;
		z-index: 1;
	}

	&__foot {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&.sar {
			justify-content: space-around;
		}
	}

	&__close {
		position: absolute;
		z-index: 2;
		right: 0;
		top: 0;
		width: 50px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		img {
			display: block;
			width: 10px;
			height: 10px;
		}
	}

	&__body {
		padding: 25px 20px 20px;

		@include mq(1280) {
			padding: 15px 10px 10px;
		}

		img {
			width: 30vh;
		}
	}

	h4 {
		margin-bottom: 1rem;
		font-size: 1.3125rem;
	}

	.row.options {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;

		@include mq(767) {
			flex-direction: column;
			justify-content: center;
		}

		span {
			padding: 15px;
		}

		h4 {
			margin: 0;
		}

		img {
			@extend %transition;
		}

		a:hover img {
			filter: saturate(2.5);
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.checkboxRow {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		label {
			margin: 0;
		}
	}

	&__options {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.btn {
			min-width: 95px;
			margin-right: 5px;
		}

		@include mq($tablet) {
			flex-direction: column;
		}
	}

	.btn.gray:after {
		background: -moz-linear-gradient(top, $buttonc2 0%, $buttonc1 100%);
		background: -webkit-linear-gradient(top, $buttonc2 0%, $buttonc1 100%);
		background: linear-gradient(to bottom, $buttonc2 0%, $buttonc1 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$buttonc1', endColorstr='$buttonc2', GradientType=0);
		background-color: $buttonc1;
	}

	.btn {
		padding: 0;

		label {
			padding: 0;
			margin: 0;
			color: inherit;
		}
	}

	.preventDialog {
		position: relative;
		z-index: 1;

		@include mq($tablet) {
			padding-top: 15px;
		}

		input {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			visibility: hidden;

			&:checked ~ label:after {
				opacity: 1;
			}
		}

		label {
			color: rgba($bc2, .25);
			font-size: 0.8125rem;
			position: relative;
			z-index: 1;
			padding-right: 28px;

			&:before, &:after {
				content: '';
				width: 28px;
				border-radius: 50%;
				display: block;
				position: absolute;
				right: 0;
				top: 0;
			}

			&:before {
				width: 17px;
				height: 17px;
				border: 2px solid rgba($bc2, .25);
			}

			&:after {
				background-color: $bc1;
				width: 9px;
				height: 9px;
				right: 4px;
				top: 4px;
				opacity: 0;
				@extend %transition;

			}
		}
	}
}

.user-type-login {

	.tab__cnt {
		display: none;

		&.current {
			display: block;
			padding-top: 30px;

			@include mq(767) {
				padding-top: 15px;
			}
		}
	}

	.tabbedcnt {
		justify-content: center;

		li {
			padding: 0 5px;
			height: 38px;
			line-height: 38px;
			text-transform: uppercase;
			font-weight: bold;
			font-size: .875rem;
			cursor: pointer;
			flex-grow: 1;
			border: 1px solid rgba($bc2, .15);
			color: rgba($bc2, .25);
			@extend %transition;
			text-align: center;

			&:first-child ~ li {
				border-left: 0;
			}

			&:hover, &.current {
				background-color: $bc2;
				color: #fff;
			}
		}
	}
}

.upload_svg, .icon-upload {
	width: 30px;
	height: 24px;
}

.delete_svg {
	transform: rotate(45deg);
}

button {
	font: inherit;
}

.r_redesign .switch-holder.validator {
	position: absolute;
	right: 10px;
	top: 32px;
}

.r_redesign .switch-holder.validator input:checked ~ label::before {
	transform: translateX(78px);
}




.document_upload_btn_holder{
    position: relative;
	text-align: center;
	input[type='file']{
		position: absolute;
		top: 0;
		left:0;
		opacity: 0;
		visibility: hidden;
		z-index: 0;
		pointer-events: none;
	}
	.btn{
		color: #ffffff;
	}
	label{
		margin-bottom: 0;
	}
	.uploaded_file_name{
		position: absolute;
		top: calc(100% + 3px);
		left: 0;
		right: 0;
		font-size: 12px;
		line-height: 15px;
	}
	label{
		&.error{
			position: absolute;
			padding: 0 0;
			top: -20px;
			left: 0;
			right: 0;
			font-size: 12px;
			line-height: 15px;
		}
	}
}
