// html {
// 	::-webkit-scrollbar {
// 		width: 10px;
// 		height: 10px;
// 	}

// 	::-webkit-scrollbar-button:start:decrement,
// 	::-webkit-scrollbar-button:end:increment {
// 		display: block;
// 		height: 0;
// 		background-color: transparent;
// 	}

// 	::-webkit-scrollbar-track-piece {
// 		background-color: $graybg;
// 		-webkit-border-radius: 0px;
// 		box-shadow: 0 0 3px 3px rgba($bc2, .05) inset;
// 	}

// 	::-webkit-scrollbar-thumb:vertical {
// 		height: 50px;
// 	}

// 	::-webkit-scrollbar-thumb:horizontal {
// 		width: 50px;
// 	}

// 	::-webkit-scrollbar-thumb:vertical,
// 	::-webkit-scrollbar-thumb:horizontal {
// 		background-color: $bc1;
// 		-webkit-border-radius: 5px;
// 	}
// }
body {
	background-color: $graybg;
	overflow-x: hidden;
}
//html {
//	box-sizing: border-box;

//*, *:before, *:after {
//	box-sizing: inherit;
//}
//
//* {
//	outline: 0 none;
//}
//}
.r_redesign {
	box-sizing: border-box;

	*, *:before, *:after {
		box-sizing: inherit;
	}

	* {
		outline: 0 none;
	}

	// html {
	font-size: 1rem;
	font-family: $font;
	line-height: 1.23;
	color: $bc2;

	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	// }
	// }
	// body {
	//	background-color: $graybg;
	min-width: 320px;
	max-width: 100%;
	// overflow-y: auto;
	// overflow-x: hidden;


	h1, h2, h3, h4 {
		font-weight: bold;

		.bc {
			display: block;

			&.inline {
				display: inline;
			}
		}
	}

	h1 {
		font-size: 3.125rem; // 50px desktop
		margin-bottom: 3rem;

		@include mq($tablet) {
			font-size: 2.375rem;
			margin-bottom: 1.25rem;
		}
	}

	h2 {
		font-size: 2.5rem;
		line-height: 1.2;
		margin-bottom: 3rem;

		@include mq($tablet) {
			font-size: 2rem;
			margin-bottom: 1.5rem;
		}
	}

	h3 {
		font-size: 1.875rem; // 30px desktop
		margin-bottom: 3rem;

		@include mq($tablet) {
			margin-bottom: 2rem;
			font-size: 1.5rem;
		}
	}

	h4 {
		font-size: 1.5rem; // 24px desktop
		margin-bottom: 2rem;

		@include mq($tablet) {
			margin-bottom: 1.5rem;
			font-size: 1.25rem;
		}
	}

	h5 {
		font-size: 1.75em; // 28px desktop
		line-height: 2.25rem;
		margin-bottom: 2rem;

		@include mq($tablet) {
			font-size: 1.5em; // 28px desktop
			line-height: 1.5;
			margin-bottom: 1.5rem;
		}
	}

	h6 {
		font-size: 1.35rem;
		font-weight: normal;
		margin-bottom: 15px;

		@include mq($tablet) {
			font-size: 1.15rem;
			margin-bottom: 10px;
		}
	}

	hr {
		border: 0;
		border-bottom: 1px solid rgba($bc2, .15);
		margin: 25px auto;
		width: 100%;
		clear: both;

		@include mq(767) {
			margin: 60px auto;
		}
	}

	sup, sub {
		vertical-align: baseline;
		position: relative;
		top: -0.4em;
		font-size: .75em;
	}

	sub {
		top: 0.4em;
	}

	b, strong {
		font-weight: 600;
	}

	.centred {
		text-align: center;
		justify-content: center;
	}

	em, i {
		font-style: italic;
	}

	a {
		text-decoration: none;
		@extend %transition;
		color: $bc2;
		cursor: pointer;

		&:hover {
			color: $bc1;
		}
	}

	p {
		margin-bottom: 1.25rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	img {
		max-width: 100%;
	}


	.bc, .bc1 {
		color: $bc1;
	}

	.strikethrough {
		text-decoration: line-through;
	}

	// Structure
	.r_container {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		position: relative;
		z-index: 1;

		@include mq($tablet) {
			width: auto;
			max-width: 100%;
			margin-left: 20px;
			margin-right: 20px;
		}
	}

	.r_main_container.r_container {
		@include mq($tablet) {
			margin-left: 0px;
			margin-right: 0px;
		}
	}

	.white-bg {
		background-color: #fff;
	}

	.bubble-bg {
		background-image: url("../../images/front/bubble-bg.svg");
		background-position: 50% 15px;
		background-repeat: no-repeat;
		background-size: 95%;
	}

	.dark-bg {
		background-color: $bc2;

		color: #fff;

		> .r_container > p, h1, h4 {
			color: #fff;
		}
		
		.btn:after {
			background-color: #fff;
		}

		.btn:hover {
			color: $bc2;
		}
	}

	.splash_svgs {
		width: 176px;
		height: 176px;
		position: relative;
		z-index: 1;

		@include mq($tablet) {
			width: 120px;
			height: 120px;
		}

		.splash_svg {
			width: 176px;
			height: 176px;
			position: relative;
			z-index: 3;
			left: auto;
			top: auto;
			right: auto;
			bottom: auto;
			transform: none;

			@include mq($tablet) {
				width: 120px;
				height: 120px;
			}
		}

		svg {
			position: absolute;
			z-index: 4;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

		}

		.flag {
			width: 70px;
			height: 80px;
			position: absolute;
			z-index: 4;
			left: 50%;
			top: 50%;
			transform: translate(-20px, -27px);

			svg {
				width: 70px;
				height: 80px;
				position: static;
				transform: none;
			}

			@include mq($tablet) {
				svg {
					transform: scale(0.7);
				}
			}
		}

		.rocket_svg {
			width: 78px;
			height: 81px;

			@include mq($tablet) {
				transform: translate(-50%, -50%) scale(0.7);
			}
		}

		.ribbon_svg {
			width: 78px;
			height: 89px;

			@include mq($tablet) {
				transform: translate(-50%, -50%) scale(0.7);
			}
		}
	}

	.cyclePrev, .cycleNext {
		background-color: transparent;
		cursor: pointer;
		@extend %transition;
		width: 12px;
		height: 25px;
		border-radius: 0;

		position: absolute;
		z-index: 105;
		top: 50%;
		transform: translateY(-50%);
		display: block;

		svg {
			width: 12px;
			height: 25px;
			stroke: rgba($bc2, .2);
			@extend %transition;
		}

		&.inactive {
			opacity: .25;
			pointer-events: none;
		}

		&:hover {
			svg {
				stroke: rgba($bc2, .5);
			}
		}
	}
}

.r_homepage .r_container {
	max-width: 960px;

	@include mq($tablet) {
		max-width: 100%;
	}
}

.r_resume-edit .skillsSection .row.added ~ .row.add-row .btn.gray {
	opacity: 0;
	visibility: hidden;
}

.r_popup .grecaptcha-badge {
	display: none;
}

.r_companies-page main {
	overflow: hidden;
}

.files {
	.file {
		background-repeat: no-repeat;
		background-position: 0 50%;
		line-height: 18px;
		padding: 18px 0 18px 48px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&--video {
			background-image: url('../../images/front/icon-file-video.svg');
			background-size: 40px 32px;
		}
		&--photo {
			background-image: url('../../images/front/icon-file-photo.svg');
			background-size: 40px 32px;
		}
		&--doc {
			background-image: url('../../images/front/icon-file-doc.svg');
			background-size: 40px 32px;
		}
	}

	li.file {
		&:not(:last-child) {
			border-bottom: 1px solid #D9DEE3;
		}
	}
}
.userSocial {
	font-size: 0;

	a {
		display: inline-block;
		margin-right: 20px;
	}

	.ico-fb {
		width: 10px;
		height: 18px;
	}

	.ico-insta {
		width: 16px;
		height: 16px;
	}

	.ico-ref {
		width: 16px;
		height: 16px;
	}

	svg {
		fill: rgb($bc2, .25);
		@extend %transition;

	}

	a:hover svg {
		fill: $bc1;
	}
}

.videoH {
	position: relative;
	height: 0;
	padding-top: 56.25%;
	width: 100%;
}
.videoH iframe {
	position: absolute;
	top: 0;
	left: 0;
	height: 100% !important;
	width: 100% !important;
}

a.see-more {
	color: #6E8CA0;
	padding-right: 10px;
	font-size: 12px;
	background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.451476 8C0.391579 8.00008 0.332315 7.98926 0.277442 7.96822C0.222569 7.94718 0.173282 7.91638 0.132706 7.87778C0.0906532 7.8412 0.0572773 7.7977 0.0345015 7.74977C0.0117257 7.70184 0 7.65043 0 7.59852C0 7.5466 0.0117257 7.4952 0.0345015 7.44727C0.0572773 7.39934 0.0906532 7.35583 0.132706 7.31925L3.92307 3.99557L0.167583 0.674943C0.082947 0.600797 0.0353992 0.500232 0.0353992 0.395373C0.0353992 0.290513 0.082947 0.189949 0.167583 0.115802C0.252218 0.0416552 0.367009 0 0.486702 0C0.606394 0 0.721185 0.0416552 0.805821 0.115802L4.88008 3.68514C4.9308 3.72869 4.96736 3.7833 4.986 3.84334C5.00463 3.90337 5.00467 3.96668 4.9861 4.02674V4.08784C4.96606 4.16538 4.93014 4.23909 4.88008 4.30539L0.770247 7.87534C0.729297 7.9137 0.679996 7.9445 0.625249 7.96591C0.570502 7.98732 0.511417 7.99891 0.451476 8Z' fill='%236E8CA0'/%3e%3c/svg%3e ") 100% 50% no-repeat;
	background-size: 5px 8px;
	line-height: 18px;
	white-space: nowrap;

	&:hover {
		background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.451476 8C0.391579 8.00008 0.332315 7.98926 0.277442 7.96822C0.222569 7.94718 0.173282 7.91638 0.132706 7.87778C0.0906532 7.8412 0.0572773 7.7977 0.0345015 7.74977C0.0117257 7.70184 0 7.65043 0 7.59852C0 7.5466 0.0117257 7.4952 0.0345015 7.44727C0.0572773 7.39934 0.0906532 7.35583 0.132706 7.31925L3.92307 3.99557L0.167583 0.674943C0.082947 0.600797 0.0353992 0.500232 0.0353992 0.395373C0.0353992 0.290513 0.082947 0.189949 0.167583 0.115802C0.252218 0.0416552 0.367009 0 0.486702 0C0.606394 0 0.721185 0.0416552 0.805821 0.115802L4.88008 3.68514C4.9308 3.72869 4.96736 3.7833 4.986 3.84334C5.00463 3.90337 5.00467 3.96668 4.9861 4.02674V4.08784C4.96606 4.16538 4.93014 4.23909 4.88008 4.30539L0.770247 7.87534C0.729297 7.9137 0.679996 7.9445 0.625249 7.96591C0.570502 7.98732 0.511417 7.99891 0.451476 8Z' fill='%23e95b63'/%3e%3c/svg%3e ");
	}
}
.r_challenges__card.landing-row-card {
	width: calc((100% - 60px) / 4);
}
.landing-related-content {
	h3 {
		margin-bottom: 10px;
		font-size: 2.1875rem;
		font-weight: normal;

		@include mq(767) {
			font-size: 1.75rem;
		}
	}
}