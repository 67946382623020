.check_svg {
	width: 19px;
	height: 15px;
}

.user_svg {
	width: 14px;
	height: 15px;
}

.briefcase_svg {
	width: 18px;
	height: 16px;
}

.house_svg {
	width: 16px;
	height: 16px;
}

.star_svg {
	width: 18px;
	height: 17px;
}

.language_svg {
	width: 20px;
	height: 16px;
}

.ribbon_svg {
	width: 15px;
	height: 17px;
}

.heart_svg {
	width: 15px;
	height: 14px;
}

.pluss_svg {
	width: 16px;
	height: 16px;
}

.refferrals_svg {
	width: 16px;
	height: 16px;
}

.badges_svg {
	width: 15px;
	height: 17px;
}

.bell_svg {
	width: 15px;
	height: 19px;
	margin-top: 5px;
}

.updates_svg {
	width: 15px;
	height: 15px;
}

.about_svg {
	width: 20px;
	height: 13px;
}

.euro_svg {
	width: 15px;
	height: 16px;
}

.star-empty_svg {
	width: 20px;
	height: 19px;
}

.car_svg {
	width: 18px;
	height: 15px;
}

.expand_svg {
	width: 19px;
	height: 12px;
	margin-top: 4px;
}

.absolvent_svg {
	width: 24px;
	height: 17px;
}

.pipeline_svg {
	width: 20px;
	height: 20px;
}

.play_svg {
	width: 14px;
	height: 15px;
}

.chat_svg {
	width: 16px;
	height: 17px;
}

.info_svg {
	width: 8px;
	height: 20px;
	margin-left: 4px;
}

.refresh_svg {
	width: 16px;
	height: 16px;
}

.chevron_down_svg {
	width: 10px;
	height: 10px;
}

.qmk_svg {
	width: 5px;
	height: 15px;
	fill: #fff;
}

.link_svg {
	width: 18px;
	height: 18px;
	vertical-align: sub;
}

.view-icon {
	svg {
		width: 15px;
		height: 10px;
		fill: rgba($bc2,.25);
		@extend %transition;
		margin-left: 5px;
	}

	&:hover {
		svg {
			fill: $bc1
		}
	}
}
.toggleArr {
	transform: rotate(90deg);
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	svg {
		width: 6px;
		height: 10px;
		stroke: rgba($bc2,.25);
	}
}