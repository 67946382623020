.memory-game-main {

    main, .r_popup {
        .bc, h3 .bc, h4 .bc {
            display: inline;
        }
    }

    @include mq(767) {
        .r_container {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    h4 {
        margin-bottom: 1rem;

        @include mq(767) {
            margin-bottom: 5px;
        }
    }

    .r_playground-games .r_main_container {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        box-shadow: 0 0 9px rgba(#000,0.1);
        padding: 15px;
        border-radius: 13px;
        background-color: #ffffff;

        @include mq(767) {
            padding: 10px;
            border-radius: 8px;
        }
    }

    .memory-game-dashboard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 250px;
        padding-right: 15px;

        @include mq(767) {
            width: 100%;
            padding-right: 0;

            h3 {
                margin-bottom: 10px;
            }

            .msplit {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .btn {
            margin-top: 10px;
            min-width: 130px;
        }

        small {
            font-size: .8em;
            display: block;
            margin-bottom: 25px;
        }

        &__scores {
            border-top: 1px solid rgba($bc2,.15);

            @include mq(767) {
                display: flex;
                justify-content: space-between;
            }
        }

        &__row {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid rgba($bc2,.15);

            @include mq(767) {
                width: 50%;

                &:nth-child(1), &:nth-child(2) {
                    padding-right: 10px;
                }
            }
        }

        &__top {
            margin-bottom: 20px;

            @include mq(767) {
                order: 1;
                margin-bottom: 5px;
            }
        }
        &__bottom {
            @include mq(767) {
                margin-bottom: 10px;
            }
        }
    }

    .memory-game-leaderboard, .memory-game-board {
        width: 100%;
        max-width: 660px;
    }

    .memory-game-board {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px 20px 0;
        box-shadow: inset 0 0 9px rgba($bc2,0.1);
        border-radius: 10px;
        background-color: $graybg;

        @include mq(767) {
            padding: 10px 10px 0;
            border-radius: 6px;
        }

        &__item {
            height: 110px;
            width: calc((100% - 80px) / 5);
            margin: 0 5px 20px;
            position: relative;
            z-index: 1;
            cursor: pointer;

            @include mq(767) {
                width: calc((100% - 30px) / 4);
                height: 65px;
                margin: 0 0 10px;
            }
        }

        &__inside {
            transition: all .15s ease;
            transform-style: preserve-3d;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;

            &.picked, &.matched {
                transform: rotateY(180deg);
            }

            .name {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                color: $bc1;
                background-color: rgba(#fff,.9);
                font-size: 13px;
                font-weight: bold;
                width: 100%;
                text-align: center;
                border-radius: 0 0 8px 8px;

                @include mq(767) {
                    font-weight: normal;
                    font-size: 11px;
                }
            }
        }

        &__front, &__back {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            //backface-visibility: hidden;
            //-webkit-backface-visibility: hidden;
            z-index: 1;
            height: 100%;
            width: 100%;
            border-radius: 8px;
            filter: drop-shadow(0 0 3px rgba($bc2,.3));
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
        }

        &__front {
            transform: rotateY(-180deg);
            background-color: #fff;

            img {
                max-width: 90%;
                max-height: 90%;
                border-radius: 7px;
            }

            &:before {
                content:'';
                position: absolute;
                z-index: 3;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                @include bg('svg-sprite-source/r_check.svg', 50% 50%);
                background-color: $bc2;
                background-size: 10px;
                right: -3px;
                bottom: -3px;
                opacity: 0;
                @extend %transition;
            }
        }

        .matched .memory-game-board__front:before {
            opacity: 1;
        }

        &__back {
            background:
                    url('../../images/front/iconfinder_Santa_Hat.svg') 50% 5% no-repeat,
                    url('../../images/front/svg-sprite-source/r_user.svg') 50% 20px no-repeat
                    ;
            background-size: 30px auto, 75px auto;
            background-color: $bc1;
            width: 100%;
            height: 100%;
            transform: rotateY(0);

            @include mq(767) {
                background-size: 30px auto, 55px auto;
            }
        }
    }

    .memory-game-leaderboard {
        &__row {
            display: flex;
            justify-content: space-between;

            border-bottom: 1px solid rgba($bc2,.15);
            padding: 10px 0;
            line-height: 30px;

            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }

        &__head {
            font-size: 1rem;
            color: $bc1;
            font-weight: bold;
        }


        &__rank {
            font-weight: bold;
            width: 60px;
            text-align: center;
        }

        &__avatar {
            width: 70%;
            padding-left: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                width: 30px;
                display: block;
                margin-right: 10px;
            }
        }

        &__time, &__moves {
            width: 65px;
            text-align: center;
        }
    }

    @include mq(767) {
        .the-leaderboard {
            .memory-game-dashboard {
                order: 1;
                padding-top: 20px;

                h3 {
                    display: none;
                }
            }

        }
    }
}

.js-memoryPopEnd h4 .bc {
display: inline;
}