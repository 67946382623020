// Onboarding Page
.onboarding-page {

	&__intro {
		position: fixed;
		z-index: 12;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: $bc2;
		@extend %transition;
	}

	main {
		z-index: 12;
	}

	&__overlay, &__video-container, &__caption {
		position: absolute;
		width: 100vw;
		height: 100vh;
	}

	&__video-container {
		z-index: 1;

		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}

	video {
		object-fit: cover;
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
	}

	&__overlay {
		z-index: 2;
		top: 0;
		left: 0;
		background-color: rgba($bc2,.5);
	}

	&__clear-iamge {
		margin-right: 15px;
	}

	&__caption {
		color: $graybg;
		z-index: 3;
		font-size: 2rem;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;



		@include mq(650,max,height) {
			font-size: 1.5rem;
		}

		@include mq(550,max,height) {
			font-size: 1.15rem;
		}
		@include mq($tablet) {
			font-size: 1.25rem;
		}
		@include mq(768) {
			font-size: 1rem;
		}

		&--cnt {
			line-height: 1.5;
			max-width: 90%;
			transform: translateY(50%);
			@include animation(slideCnt, 9s, 1.5s);
		}
		&--item {
			text-shadow: 1px 1px 3px rgba($bc2,.5);
			padding: 10px 0;
			opacity: 0;

			&:first-child {
				font-size: 3rem;
				@include animation(fadeIn, 0.5s, 1.5s);
			}

			&:nth-child(2) {
				@include animation(fadeIn, 0.5s, 3s);
			}
			&:nth-child(3) {
				@include animation(fadeIn, 0.5s, 6s);
			}

			&:last-child {
				font-size: 1.5rem;
				@include animation(fadeIn, 0.5s, 9s);

				@include mq(798) {
					font-size: 1rem;
				}
			}
		}
	}

	.onb-logo {
		width: 150px;
		display: inline-block;
		vertical-align: text-top;
		vertical-align: -webkit-baseline-middle;

		@include mq(798) {
			width: 100px;
		}

		svg {
			fill: $graybg;
		}

		.ball {
			@include animation(bounce, 0.3s, 3.25s);
			animation-direction: alternate;
			animation-timing-function: cubic-bezier(.5,0.05,1,.5);
			animation-iteration-count: 15;
		}
	}

	&.viewed-intro {
		.onboarding-page__intro {
			opacity: 0;
			visibility: hidden;
		}
		.bubbled-module {
			opacity: 1;
			visibility: visible;
		}
		.r_main_container {
			overflow: hidden;
		}
		main {
			z-index: 2;
		}
	}

	&.direct {
		fieldset {
			display: none;

			&.current {
				display: block;
				position: static;
			}
		}

		.onboarding-page__intro {
			display: none;
		}
	}

	.bubbled-module {
		position: relative;
		z-index: 13;
		opacity: 0;
		visibility: hidden;
		@extend %transition;

		h3 {
			margin-bottom: 1.5rem;
		}
	}

	&__form {
		text-align: center;
		padding: 35px 0 60px;
		color: rgba($bc2,.5);


		fieldset {
			min-width: 100%;
			max-width: 100%;
			z-index: 1;
			display: none;
			position: static;
			transition: none;
			transform: none !important;

			&.current {
				display: block;
			}

			&.last {
				.badge {
					display: block;
					margin: 0 auto 40px;
				}
				a {
					text-decoration: none;
				}
			}
		}

		input {
			text-align: center;
		}

		.form__group {
			width: 98%;
			max-width: 432px;
			margin-left: auto;
			margin-right: auto;

			&.half {
				margin-bottom: 15px;
			}

			&.last {
				margin-bottom: 0;
			}
		}

		.quiz-module__nav {
			max-width: 340px;
			margin-left: auto;
			margin-right: auto;

			span {
				min-width: 30px;
			}
			span.sep {
				margin: 0 35px;
				&:first-child:before,
				&:last-child:after {
					display: none;
				}
				&:first-child {
					margin-left: 0px;
				}
				&:last-child {
					margin-right: 0px;
				}
				&:before, &:after {
					width: 30px;
				}
			}
		}

		a:not(.btn), .link {
			color: $bc1;
			text-decoration: underline;
		}

		h3 {
			color: $bc2;
		}

		.hidden {
			opacity: 0;
			visibility: hidden;
			display: none;
		}
	}

	.form-upload-photo {
		.btn {
			margin-bottom: 10px;

			&.icon {
				position: absolute;
				right: 0;

				&.croppie-upload {
					bottom: 0;
				}
				&.js-clearImage {
					top: 0;
				}

			}
		}
	}

	&__fields {
		position: relative;
		z-index: 1;

		@include mq(1279) {
			height: auto !important;
		}
	}

	&__avatar-holder {
		position: relative;
		z-index: 1;
		width: 228px;
		margin-left: auto;
		margin-right: auto;

		display: flex;
		//justify-content: center;
		align-items: center;
		background-size: 100%;

		&--cnt {
			width: 190px;
			height: 190px;
			border-radius: 50%;
			position: absolute;
			z-index: 2;
			left: 50%;
			top: 20px;
			transform: translate(-50%,0);

			svg {
				width: 50px;
				height: 50px;
			}

			&:before {
				content: '';
				@include bg('r_dotted-avatar-bg-s.svg');
				width: 228px;
				height: 228px;
				display: block;
				position: absolute;
				z-index: 3;
				top: -20px;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.cropperCnt {
			top: -25px;
			position: relative;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			margin-top: 20px;
			@extend %transition;

			&:before {
				top: -20px;
				transform: translateX(-50%);
			}

			&.expanded, &.visible {
				opacity: 1;
				visibility: visible;
				z-index: 3;
			}
		}
	}

	input[type='file'] {
		position: absolute;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
	}
	.cr-slider {
		cursor: pointer;
		padding: 0;
	}

	.croppie-container {
		padding-top: 10px;

		.cr-boundary {
			border-radius: 50%;
			z-index: 4;
		}
		.cr-vp-circle {
			width: 100% !important;
			height: 100% !important;
			border: 0 none;
		}
		.cr-slider-wrap {
			margin-bottom: 0;
		}
	}

	.world-map-holder {
		width: 95vw;
		max-width: 1280px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);

		&--inner {
			height: 0;
			position: relative;
			padding-top: 50%;

			h3 {
				position: absolute;
				z-index: 2;
				bottom: 10%;
				left: 50%;
				transform: translateX(-50%);
				margin: 0;
			}
		}

		svg {
			width: 100%;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			stroke: $bc1;
			height: 100%;

			path {
				fill: rgba($bc2,.01);
				@extend %transition;
				cursor: pointer;

				&:hover {
					fill: $bc1;
				}
			}
		}

		@include mq(1200) {
			display: none;
		}
	}

	.world-map-replacement {
		display: none;

		@include mq(1200) {
			display: block;
			margin-bottom: 30px;
		}

		.select2-container {
			min-width: 100%;
			max-width: 100%;
		}
	}

	.location-field {
		@include mqq(1280) {
			.btn {
				transform: translateY(-30px);
			}
		}
	}

}

.onboarding-page label.onboarding-page__avatar-holder--cnt {
	padding: 0;
	margin: 0;
	background-color: $bc2;
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes slideCnt {
	0% {
transform: translateY(50%);
}
	16.67% {
transform: translateY(50%);
}
	33.33% {
transform: translateY(33.33%);
}
	50% {
transform: translateY(33.33%);
}
	66.66% {
transform: translateY(16.66%);
}
	82% {
transform: translateY(16.66%);
}
	100% {
transform: translateY(0);
}
}

