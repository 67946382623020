section ._quick-filter {
	position: absolute;
	bottom: 100%;
	left: 0;
	right: 0;
	margin-bottom: 7px;

	font-size: 12px;
	color: #8090A1;

	@include mq($tablet) {
		position: static;
		padding: 0 15px;
	}


	.select2-container {
		width: auto !important;

		&--open {

			.select2-selection__rendered {
				color: $bc1;
			}
			.select2-selection--single {
				border-radius: 0 !important;
				filter: none !important;
			}
		}

		.select2-selection--single {
			.select2-selection__arrow {
				height: 14px;
				width: 14px;
				background-position: 50% 50%;
			}
		}
	}
}
